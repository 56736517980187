import React, { useState, useEffect, Fragment, useMemo } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import moment from "moment-timezone";
import { formatDateTime } from "../utils/dateTime";
import { reactTableConfig } from "../lib/reactTableConfig";
import useOrderData from "../Hooks/OrderData";
const config = reactTableConfig("Completed Orders");

function PendingOrders(props) {
  const [record, setRecord] = useState([]);
  const [refreshed, setRefreshed] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 6 };
  }, []);

  const { data, loading } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  function deliveryStatus(finishAt, expectedDeliveryDate) {
    const finishAtObject = new Date(finishAt);
    const deliveryDateObject = new Date(expectedDeliveryDate);
    const finishAtMoment = moment(finishAtObject);
    const deliveryDateMoment = moment(deliveryDateObject);
    // Calculate the difference in days between the order date and the expected delivery date

    const daysUntilDelivery = deliveryDateMoment.diff(finishAtMoment, "days");
    return daysUntilDelivery >= 0
      ? `On Time`
      : `delayed by ${Math.abs(daysUntilDelivery)} ${
          Math.abs(daysUntilDelivery) === 1 ? "day" : "days"
        }`;
  }

  const columns = [
    {
      key: `${record.id + "1"}`,
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },
    {
      key: "name",
      text: "Sales Person",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: `${Number(record.amount) - Number(record.gstAmount)}`,
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {record.amount} INR</Fragment>;
      },
    },

    {
      key: "counter",
      text: "Total Time Taken",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {showTimerCounterHtml(record.finishAt, record.createdAt)}
            {/* {showTimerCounterHtml(
              "2023-11-07 13:59:41",
              "2023-11-07T06:27:28.000Z"
            )} */}
          </Fragment>
        );
      },
    },

    {
      key: "createdAt",
      text: "Order Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)}</Fragment>;
      },
    },
    {
      key: "finishAt",
      text: "Finished Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.finishAt)}</Fragment>;
      },
    },
    {
      key: "estimated_delivery_date",
      text: "Delivery Status",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span
              style={{
                color: deliveryStatus(
                  record.finishAt,
                  record.estimated_delivery_date
                ).startsWith("On")
                  ? "#66FF00"
                  : "red",
              }}
            >
              {deliveryStatus(record.finishAt, record.estimated_delivery_date)}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/process-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const showTimerCounterHtml = (finishAt, createAt) => {
    var initialDate = moment(createAt)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD HH:mm:ss");
   
    var endDate = moment(finishAt)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD HH:mm:ss");

    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();
    const secondsExpiry = moment(endDate, "YYYY-MM-DD HH:mm:ss").unix();

    var remainingSeconds = secondsExpiry - secondsStart;
    const duration = moment.duration(remainingSeconds, "seconds");

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsRemaining = duration.seconds();

    return `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`;
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ORDERS COMPLETED</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    className="table table-bordered table-striped"
                    config={config}
                    records={record}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
      </div>
    </div>
  );
}
export default PendingOrders;
