import React, { useState, useEffect, Fragment, useMemo } from "react";
import axios from "axios";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./Footer";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Total Orders");

function VendorProcessOrder(props) {
  const [sampleImage, setSampleImage] = useState("");
  const [record, setRecord] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 1, orderType: "vendor" };
  }, []);

  const { data, loading, refetch } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  const vendorColumns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity === null ? "N/A" : record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.price === null ? "N/A" : record.price} INR
          </Fragment>
        );
      },
    },
    {
      key: "gst",
      text: "GST(%)",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.tax === null ? "N/A" : record.tax}</Fragment>;
      },
    },
    {
      key: "total",
      text: "Total Price",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.total === null ? "N/A" : record.total} INR
          </Fragment>
        );
      },
    },
    {
      key: "ordrtype",
      text: "Order Type",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "cus",
      text: "Customize File",
      className: "Amount",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.product_file === null ||
            record.product_file === "NULL" ||
            record.product_file === "null" ? (
              "N/A"
            ) : (
              <a
                href={baseUrl + "/static/customizeImage/" + record.product_file}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                {!record.product_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                  "View File"
                ) : (
                  <img
                    src={
                      baseUrl + "/static/customizeImage/" + record.product_file
                    }
                    alt=""
                    className="img-fluid"
                  />
                )}
              </a>
            )}{" "}
          </Fragment>
        );
      },
    },
    {
      key: "up",
      text: "Upload Sample File",
      className: "Amount",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.product_file === null ? (
              <td>N/A</td>
            ) : (
              <>
                {!record.sample_file ? (
                  <td>
                    <span style={{ color: "red" }}>
                      {record.sample_file_status === "2" ? "Rejected" : ""}
                    </span>
                    <span style={{ color: "green" }}>
                      {record.sample_file_status === "1" ? "Accepted" : ""}
                    </span>
                    <input
                      className="form-control"
                      type="file"
                      onChange={onFileChange}
                    />
                    <button
                      // style={{borderRadius:".25rem"}}
                      type="button"
                      onClick={(e) => imageSubmit(e, record)}
                      className="form-control"
                      disabled={disableBtn}
                    >
                      Upload
                    </button>
                  </td>
                ) : (
                  <td>
                    <a
                      href={
                        baseUrl + "/static/sampleImage/" + record.sample_file
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      {!record.sample_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                        "View File"
                      ) : (
                        <img
                          src={
                            baseUrl +
                            "/static/sampleImage/" +
                            record.sample_file
                          }
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </a>
                    <span style={{ color: "green" }}>
                      {record.sample_file_status === "1" ? "Accepted" : ""}
                    </span>
                  </td>
                )}
              </>
            )}
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendors-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Status",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <select
              className="form-control"
              value={record.status}
              onChange={(e) => changeStatusProduct(e, record)}
            >
              <option>Select</option>
              <option value="1">In Process</option>
              <option value="2">Completed</option>
              <option value="3">Rejected</option>
            </select>
          </Fragment>
        );
      },
    },
  ];

  const adminColumns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "vendor_name",
      text: "Assigned Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "ordrtype",
      text: "Order Type",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "createdAt",
      text: "Date Of Order",
      className: "doo",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{moment(record.created_at).format("DD/MM/YYYY")}</Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendor-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="form-control"
              onClick={(e) => changeStatusProductAdmin(record)}
              data-toggle="tooltip"
              data-placement="top"
              title="Reject"
            >
              <i className="mdi mdi-close-circle-outline"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const imageSubmit = (e, value) => {
    e.preventDefault();
    if (!sampleImage) {
      toast.error("Please select sample image or file");
      return false;
    }
    if (!sampleImage.name.match(/\.(jpg|jpeg|png|gif|pdf|doc)$/)) {
      toast.error("Please select valid image jpg,jpeg,png,gif or pdf,doc");
      return false;
    }
    if (sampleImage.size >= 3e6) {
      toast.error("Sample image size should not be more than 3MB");
      return false;
    }

    const formData = new FormData();
    formData.append("SampleImage", sampleImage);
    formData.append("id", value.id);
    axios.post(baseUrl + "/frontapi/sampleImage", formData).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setDisableBtn(true);
        toast.dismiss();
        toast.success(resp.message);
        // productData();
        refetch();
        return false;
      }
      setDisableBtn(false);
      toast.dismiss();
      toast.error(resp.message);
    });
  };

  const onFileChange = (e) => {
    e.preventDefault();
    setSampleImage(e.target.files[0]);
  };

  const changeStatusProductAdmin = (value) => {
    let data = {
      orderId: value.id,
      status: "3",
      quantity: value.quantity,
      productId: value.product_id,
      against_order_id: value.against_order_id,
    };

    Conn(data);
  };

  const changeStatusProduct = (e, value) => {
    let data = {
      orderId: value.id,
      status: e.target.value,
      quantity: value.quantity,
      productId: value.product_id,
      against_order_id: value.against_order_id,
    };

    if (e.target.value === "Select") {
      return false;
    }

    if (e.target.value === "2") {
      if (value.orderType === "Customize") {
        if (value.sample_file_status === "1") {
          Conn(data);
          return false;
        }
        toast.dismiss();
        toast.error("Sample file is not approved, approve it first");
        return false;
      }
      Conn(data);
      return false;
    }
    Conn(data);
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const changeStatus = (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/change-vendororder-status", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          // productData();
          refetch();
          if (data.status === "3") {
            setTimeout(() => {
              window.location.href = "/vendor-rejected-orders";
            }, 2000);
            return false;
          }

          setTimeout(() => {
            window.location.href = "/vendor-order-completed";
          }, 2000);
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  return (
    <>
      <div id="layout-wrapper">
        {/* <Header />
        <Navbar /> */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>VENDOR INPROCESS ORDERS</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-12">
                  <div className="product-list-outer customer-list-outer">
                    {localStorage.getItem("userType") === "Vendor" ? (
                      <ReactDatatable
                        className="table table-bordered table-striped "
                        config={config}
                        records={record}
                        columns={vendorColumns}
                        loading={loading}
                      />
                    ) : (
                      <ReactDatatable
                        className="table table-bordered table-striped "
                        config={config}
                        records={record}
                        columns={adminColumns}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default VendorProcessOrder;
