import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Country, State } from "country-state-city";
import Select from "react-select";
import { selectStyleDark } from "../Hooks/SelectStyle";
const baseUrl = myConstList.baseUrl;

const LeadsView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [catData, setCatData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [assignedTo, setAssignedTo] = useState("");

  // estimation
  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [industryName, setIndustryName] = useState("");
  const [ventureType, setVentureType] = useState("");
  const [ventureName, setVentureName] = useState("");
  const [designation, setDesignation] = useState("");
  const [leadSource, setLeadSource] = useState("");

  const [industryNameData, setIndustryNameData] = useState([]);
  const [ventureTypeData, setVentureTypeData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincode, setPincode] = useState("");

  const [formValues, setFormValues] = useState([
    {
      image: [],
      customerName: "",
      prodName: "",
      prodId: "",
      salesPerson: "",
      quantity: 0,
      estimatedDate: "",
      price: 0,
      salesPrice: 0,
      customize: "",
      priceTotal: 0,
      priceTotalTax: 0,
      salesTotal: 0,
      salesTotalTax: 0,
      customizeImage: [],
      customizeName: "",
      isCustom: false,
      specification: "",
      tax: 0,
    },
  ]);

  // estimations errors

  const [formValuesError, setFormValuesError] = useState([
    {
      image: [],
      customerName: "",
      prodName: "",
      prodId: "",
      salesPerson: "",
      quantity: "",
      estimatedDate: "",
      price: "",
      salesPrice: "",
      customize: "",
      priceTotal: "",
      priceTotalTax: "",
      salesTotal: "",
      salesTotalTax: "",
      customizeImage: "",
      customizeName: "",
      isCustom: "",
      specification: "",
      tax: "",
    },
  ]);

  useEffect(() => {
    categoryData();
    LeadsData();
    salesPersonData();
    getIndustryData();
    getDesignationData();
    getLeadSourceData();
  }, []);

  const LeadsData = async () => {
    const data = {
      id: atob(params.id),
    };
    await axios.post(baseUrl + "/frontapi/leads-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        const leadsData = resp.data.sqlLeadsRun[0];

        setName(leadsData.name);
        setEmail(leadsData.email);
        setNumber(leadsData.phone);
        setDescription(leadsData.description);
        setAssignedTo(leadsData.assignedTo);
        setCategoryId(JSON.parse(leadsData.categoryId));

        setDesignation(leadsData.designation);
        setVentureName(leadsData.ventureName);
        setLeadSource(leadsData.leadSource);

        setIndustryName(JSON.parse(leadsData.industryType));
        getVentureData(JSON.parse(leadsData.industryType));
        setVentureType(JSON.parse(leadsData.ventureType));

        setCountry(leadsData.country);
        setStateValue(leadsData.state);
        setCity(leadsData.city);
        setPincode(leadsData.pincode);

        productDataFunc(JSON.parse(leadsData.categoryId));

        if (leadsData.assignedTo == leadsData.salesManagerId) {
          setAssignedTo("Self");
        } else {
          setAssignedTo(leadsData.assignedTo);
        }

        setDiscount(leadsData.discount ? leadsData.discount : 0);
        setDiscountAmount(
          leadsData.discountAmount ? leadsData.discountAmount : 0
        );
        setTotalAmount(leadsData.totalAmount ? leadsData.totalAmount : 0);

        // estimation
        const leadsDetailData = resp.data.sqlDetailRun;

        if (resp.data.sqlDetailRun.length > 0) {
          setIsEstimationOpen(true);
          setFormValues(leadsDetailData);

          let html = [];
          leadsDetailData.forEach((element) => {
            let tempArr = [
              {
                image: [],
                customerName: "",
                prodName: "",
                prodId: "",
                salesPerson: "",
                quantity: 0,
                estimatedDate: "",
                price: 0,
                salesPrice: 0,
                customize: "",
                priceTotal: 0,
                priceTotalTax: 0,
                salesTotal: 0,
                salesTotalTax: 0,
                customizeImage: [],
                customizeName: "",
                isCustom: false,
                specification: "",
                tax: 0,
              },
            ];
            html.push(tempArr);
          });
          setFormValuesError(html);
        } else {
          setFormValues(leadsDetailData);
        }
      }
    });
  };

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };

  const getIndustryData = () => {
    axios
      .post(baseUrl + "/frontapi/industry-data-deleted", getIndustryData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setIndustryNameData(resp.data);
        }
      });
  };

  const getVentureData = (selectedOption) => {
    let html = [];
    for (let i = 0; i < selectedOption.length; i++) {
      const element = selectedOption[i];

      axios
        .post(baseUrl + "/frontapi/venture-data-byIndustryId", {
          industryId: element.value,
        })
        .then((res) => {
          var resp = res.data;
          if (resp.status === true) {
            resp.data.map((compo) => {
              html.push(compo);
            });
            setVentureTypeData(html);
          }
        });
    }
  };

  const getDesignationData = () => {
    axios
      .post(baseUrl + "/frontapi/designation-data-deleted", getDesignationData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setDesignationData(resp.data);
        }
      });
  };

  const getLeadSourceData = () => {
    axios
      .post(baseUrl + "/frontapi/lead-source-data-deleted", getLeadSourceData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setLeadSourceData(resp.data);
        }
      });
  };

  const salesPersonData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(
        baseUrl + "/frontapi/sales-person-manager-data-deleted",
        salesPersonData,
        config
      )
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setSalesData(resp.data);
        }
      });
  };

  const salesHtml = () => {
    const salesHtml = [];
    var SData = salesData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SData.map((value, i) => {
      return salesHtml.push(<option value={value.id}>{value.name}</option>);
    });
    return salesHtml;
  };

  const designationHtml = () => {
    const designationHtml = [];
    var DData = designationData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    DData.map((value, i) => {
      return designationHtml.push(
        <option value={value.id}>{value.designation}</option>
      );
    });
    return designationHtml;
  };

  const leadSourceHtml = () => {
    const leadSourceHtml = [];
    var LData = leadSourceData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    LData.map((value, i) => {
      return leadSourceHtml.push(
        <option value={value.id}>{value.leadSource}</option>
      );
    });
    return leadSourceHtml;
  };

  const prodHtml = () => {
    const producthtml = [];
    var ProdData = productData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    ProdData.map((value, i) => {
      return producthtml.push(<option value={value.id}>{value.name}</option>);
    });
    return producthtml;
  };

  const productDataFunc = (selectedOption) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    let html = [];

    selectedOption.map((element) => {
      axios
        .post(
          baseUrl + "/frontapi/product-data-byCatId",
          { categoryId: element.value },
          config
        )
        .then((res) => {
          var resp = res.data;
          if (resp.status === true) {
            resp.data.map((compo) => {
              html.push(compo);
            });
            setProductData(html);
          }
        });
    });
  };

  const countryDropDownHtml = () => {
    const countries = Country.getAllCountries();

    // Find the index of India in the country list
    const indiaIndex = countries.findIndex(
      (country) => country.isoCode === "IN"
    );

    // Move India to the beginning of the country list
    if (indiaIndex !== -1) {
      const india = countries.splice(indiaIndex, 1)[0];
      countries.unshift(india);
    }

    // Generate the HTML for country dropdown options
    const options = countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));

    return options;
  };

  const stateDropDownHtml = () => {
    const html = [];
    const states = State.getStatesOfCountry(country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  const totalPriceHTML = () => {
    const html = [];

    let totalPrice = 0;
    let totalPriceNoGst = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.salesTotalTax
        ? parseFloat(element.salesTotalTax)
        : 0;

      totalPriceNoGst += element.salesTotal ? element.salesTotal : 0;
    }

    html.push(
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12 more-padding ">
          <div className="form-group">
            <div className="person-detail">
              <p>
                Order Value (With GST):{" "}
                <b>{parseFloat(totalPrice).toFixed(2)} INR</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    );

    return html;
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>View Lead</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            disabled
                            value={name}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            disabled
                            value={email}
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="number"
                            name="number"
                            placeholder="Mobile Number"
                            maxLength={10}
                            disabled
                            value={number}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <Select
                            isDisabled={true}
                            value={categoryId}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="catId"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No category found"}
                            options={catData.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Assign To</label>
                          <select
                            className="form-control"
                            name="assignedTo"
                            disabled
                            value={assignedTo}
                          >
                            <option>Please select</option>
                            <option>Self</option>
                            {salesHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Industry Type</label>
                          <Select
                            isDisabled={true}
                            value={industryName}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="industryId"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No industry found"}
                            options={industryNameData.map((option) => ({
                              value: option.id,
                              label: option.industry,
                            }))}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Venture Type</label>
                          <Select
                            isDisabled={true}
                            value={ventureType}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="ventureType"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No venture type found"}
                            options={ventureTypeData.map((option) => ({
                              value: option.id,
                              label: option.venture,
                            }))}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Venture Name</label>
                          <input
                            type="text"
                            name="ventureName"
                            placeholder="Venture Name"
                            disabled
                            value={ventureName}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            type="text"
                            name="country"
                            disabled
                            value={country}
                            placeholder="Country"
                            className="form-control"
                          >
                            <option>Select Country</option>
                            {countryDropDownHtml()}
                          </select>
                          <span></span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            type="text"
                            name="state"
                            disabled
                            value={stateValue}
                            placeholder="State"
                            className="form-control"
                          >
                            <option>Select State</option>
                            {stateDropDownHtml()}
                          </select>
                          <span></span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            disabled
                            value={city}
                            placeholder="City"
                            className="form-control"
                          />
                          <span></span>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Pincode</label>
                          <input
                            type="number"
                            name="zipcode"
                            disabled
                            value={pincode}
                            maxLength={6}
                            placeholder="Pincode"
                            className="form-control"
                          />
                          <span></span>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Designation</label>
                          <select
                            type="text"
                            name="designation"
                            placeholder="Designation"
                            className="form-control"
                            disabled
                            value={designation}
                          >
                            <option value={""}>Select Designation</option>
                            {designationHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Lead Source</label>
                          <select
                            type="text"
                            name="leadSource"
                            placeholder="Lead Source"
                            className="form-control"
                            disabled
                            value={leadSource}
                          >
                            <option value={""}>Select Lead Source</option>
                            {leadSourceHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="Description"
                            disabled
                            value={description}
                            className="form-control resize"
                          />
                        </div>
                      </div>
                    </div>

                    {isEstimationOpen && (
                      <>
                        <div className="section-heading">
                          <h3>
                            <b>Estimation Creation: </b>
                          </h3>
                        </div>

                        <div className="row">
                          {formValues.map((element, index) => (
                            <>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Product Name</label>
                                  <select
                                    className="form-control"
                                    name="prodId"
                                    disabled
                                    value={element.prodId || ""}
                                  >
                                    <option value="">Please select</option>
                                    {prodHtml()}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Quantity</label>
                                  <input
                                    type="number"
                                    name="quantity"
                                    placeholder="Quantity"
                                    className="form-control"
                                    disabled
                                    value={element.quantity || ""}
                                  />
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].quantity}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Tax(%)</label>
                                  <input
                                    type="number"
                                    name="tax"
                                    placeholder="Tax"
                                    className="form-control"
                                    disabled
                                    value={element.tax || ""}
                                  />
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].tax}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Sales Price</label>
                                  <input
                                    type="number"
                                    name="salesPrice"
                                    placeholder="Sales Price"
                                    className="form-control"
                                    disabled
                                    value={element.salesPrice || ""}
                                  />
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].salesPrice}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Specifications</label>
                                  <input
                                    type="text"
                                    name="specification"
                                    placeholder="Specifications"
                                    className="form-control"
                                    disabled
                                    value={element.specification || ""}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group w-100">
                                    <label>Customization</label>
                                    <select
                                      className="form-control"
                                      name="customize"
                                      disabled
                                      value={element.customize || ""}
                                    >
                                      <option value="">Please select</option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                      <option value="via">
                                        Sent via Email
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-12">
                                  <div className="form-group mt-2 mb-5">
                                    <label>
                                      <b>
                                        Sales Price Total(exclusive tax):{" "}
                                        {element.salesTotal}
                                      </b>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                  <div className="form-group mt-2 mb-5">
                                    <label>
                                      <b>
                                        Sales Price Total(inclusive tax):{" "}
                                        {element.salesTotalTax}
                                      </b>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                  <div className="form-group mt-2 mb-5">
                                    <label>
                                      <b>
                                        Price Total(exclusive tax) :{" "}
                                        {element.priceTotal}
                                      </b>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                  <div className="form-group mt-2 mb-5">
                                    <label>
                                      <b>
                                        Price Total(inclusive tax) :{" "}
                                        {element.priceTotalTax}
                                      </b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="form-group">
                              <label>Discount %</label>

                              <input
                                type="number"
                                className="form-control"
                                name="discount"
                                disabled
                                value={discount}
                                id="discount"
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-group">
                              <label>Discount Amount</label>

                              <input
                                type="number"
                                className="form-control"
                                name="discountAmount"
                                disabled
                                value={discountAmount}
                                id="discountAmount"
                              />
                            </div>
                          </div>
                        </div>

                        {totalAmount <= 0 ? (
                          <div>{totalPriceHTML()}</div>
                        ) : (
                          <div className="row">
                            <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
                              <div className="form-group">
                                <div className="person-detail">
                                  <p>
                                    Order Value (With GST):{" "}
                                    <b>
                                      {parseFloat(totalAmount).toFixed(2)} INR
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </form>

                  <div className="btn-group">
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/leads")}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
};

export default LeadsView;
