/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

function Sidebar({ getNotificationData }) {
  useEffect(() => {
    getUserDetails();
  }, []);
  const [userType, setUserType] = useState("");
  const [customerManagment, setCustomerManagment] = useState("");
  const [vendorManagment, setVendorManagment] = useState("");
  const [productManagment, setProductManagment] = useState("");
  const [catogryManagment, setCatogryManagment] = useState("");
  const [catogryPersonManager, setCatogryPersonManager] = useState("");
  const [salseManagment, setSalseManagment] = useState("");
  const [operationManagment, setOperationManagment] = useState("");
  const [gstManagment, setGstManagment] = useState("");
  const [attributeManagment, setAttributeManagment] = useState("");
  const [orderManagment, setOrderManagment] = useState("");
  const [venderOrder, setVenderOrder] = useState("");

  const getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/get-rightes", {}, config).then((res) => {
      var resp = res.data;
      var data = resp.data[0];
      setCustomerManagment(data.customerManagment);
      setVendorManagment(data.vendorManagment);
      setProductManagment(data.productManagment);
      setCatogryManagment(data.catogryManagment);
      setCatogryPersonManager(data.catogryPersonManager);
      setSalseManagment(data.salseManagment);
      setOperationManagment(data.operationManagment);
      setGstManagment(data.gstManagment);
      setAttributeManagment(data.attributeManagment);
      setOrderManagment(data.orderManagment);
      setVenderOrder(data.venderOrder);
      setUserType(data.userType);
    });
  };

  return (
    <div id="scrollbar">
      <div className="simplebar-content" style={{ padding: "0px" }}>
        <div className="container-fluid p-0">
          <ul
            className="navbar-nav"
            id="navbar-nav"
            onClick={getNotificationData}
          >
            <li className="nav-item">
              <Link
                to={"/dashboard"}
                className={
                  window.location.pathname === "/dashboard"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer" />{" "}
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>

            {userType === "CategoryManager" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={"/products-CatMan"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Product</span>
                    </div>
                    <Link to={"/product-add-CatMan"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/myCategories"}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link "
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">My Category</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/vendor"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon3.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Vendor</span>
                    </div>
                    <Link to={"/vendor-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/vendor-pipeline"}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link "
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Vendor Pipeline</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/inventory-in-out"}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link "
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Inventory In Out</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/all-sales-catman"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link "
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">All Sales</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/support"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Support</span>
                    </div>
                  </NavLink>
                </li>
              </>
            )}

            {userType === "SalesManager" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={"/leads"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Leads</span>
                    </div>
                    <Link to={"/add-leads"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/sales-person"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Sales Person</span>
                    </div>
                    <Link to={"/add-sales-person"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/customer"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon2.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Customer</span>
                    </div>
                    <Link to={"/customer-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    to={"/customer-document"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Customer Document</span>
                    </div>
                    <Link to={"/customer-document-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    to={"/industry"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Industry</span>
                    </div>
                    <Link to={"/add-industry"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/venture"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Venture</span>
                    </div>
                    <Link to={"/add-venture"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/lead-source"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Lead Source</span>
                    </div>
                    <Link to={"/lead-source-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/reminders"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Reminder</span>
                    </div>
                    <Link to={"/reminders-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/issue-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Issue Type</span>
                    </div>
                    <Link to={"/issue-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    to={"/document-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Document Type</span>
                    </div>
                    <Link to={"/document-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    to={"/training-manager"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Training</span>
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/campaign-manager"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Campaign</span>
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/support"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Support</span>
                    </div>
                  </NavLink>
                </li>
              </>
            )}

            {/* dropdown for CRM  */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/assets/images/menu-icon4.png"
                  alt=""
                  className="img-fluid"
                />{" "}
                <span data-key="t-dashboards">CRM</span>
              </a>
              <ul
                className={
                  window.location.pathname === "/customer" ||
                  window.location.pathname === "/customer-add" ||
                  window.location.pathname === "/customer-document" ||
                  window.location.pathname === "/customer-document-add" ||
                  window.location.pathname === "/lead-source" ||
                  window.location.pathname === "/lead-source-add" ||
                  window.location.pathname === "/issue-type" ||
                  window.location.pathname === "/issue-type-add" ||
                  window.location.pathname === "/document-type" ||
                  window.location.pathname === "/document-type-add" ||
                  window.location.pathname === "/inventory-in-out" ||
                  window.location.pathname === "/add-inventory" ||
                  window.location.pathname === "/zone" ||
                  window.location.pathname === "/zone-add" ||
                  window.location.pathname === "/support" ||
                  window.location.pathname === "/leads" ||
                  window.location.pathname === "/add-leads"
                    ? "dropdown-menu position-static show"
                    : "dropdown-menu position-static"
                }
                aria-labelledby="navbarDropdown"
              >
                {customerManagment === 1 && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        to={"/customer"}
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link has-icon active"
                            : "nav-link has-icon"
                        }
                      >
                        <div>
                          <img
                            src="/assets/images/menu-icon2.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          <span data-key="t-dashboards">Customer</span>
                        </div>
                        <Link to={"/customer-add"}>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </Link>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to={"/customer-document"}
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link has-icon active"
                            : "nav-link has-icon"
                        }
                      >
                        <div>
                          <img
                            src="/assets/images/menu-icon1.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          <span data-key="t-dashboards">Customer Document</span>
                        </div>
                        <Link to={"/customer-document-add"}>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </Link>
                      </NavLink>
                    </li>
                  </>
                )}

                <li className="nav-item">
                  <NavLink
                    to={"/lead-source"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Lead Source</span>
                    </div>
                    <Link to={"/lead-source-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/issue-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Issue Type</span>
                    </div>
                    <Link to={"/issue-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/document-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Document Type</span>
                    </div>
                    <Link to={"/document-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/inventory-in-out"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Inventory In Out</span>
                    </div>
                    <Link to={"/add-inventory"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/zone"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Zone</span>
                    </div>
                    <Link to={"/zone-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/support"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Support</span>
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/leads"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Leads</span>
                    </div>
                    <Link to={"/add-leads"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>
              </ul>
            </li>

            {productManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/products"}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "nav-link has-icon"
                      : isActive
                      ? "nav-link has-icon active"
                      : "nav-link has-icon"
                  }
                  // className={
                  //   window.location.pathname === "/products"
                  //     ? "nav-link has-icon active"
                  //     : "nav-link has-icon"
                  // }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Product</span>
                  </div>
                  <Link to={"/product-add"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}
            {catogryManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/categories"}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "nav-link has-icon"
                      : isActive
                      ? "nav-link has-icon active"
                      : "nav-link has-icon"
                  }
                  // className={
                  //   window.location.pathname === "/categories"
                  //     ? "nav-link has-icon active"
                  //     : "nav-link has-icon"
                  // }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Category</span>
                  </div>
                  <Link to={"/Category-add"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}
            {catogryManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/sub-categories"}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "nav-link has-icon"
                      : isActive
                      ? "nav-link has-icon active"
                      : "nav-link has-icon"
                  }
                  // className={
                  //   window.location.pathname === "/sub-categories"
                  //     ? "nav-link has-icon active"
                  //     : "nav-link has-icon"
                  // }
                >
                  <div to={"/sub-categories"}>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Sub Category</span>
                  </div>
                  <Link to={"/sub-category-add"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}
            {gstManagment === 1 && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={"/gst"}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "nav-link has-icon"
                        : isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                    // className={
                    //   window.location.pathname === "/gst"
                    //     ? "nav-link has-icon active"
                    //     : "nav-link has-icon"
                    // }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">GST</span>
                    </div>
                    <Link to={"/gst-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={"/units"}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "nav-link has-icon"
                        : isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Unit</span>
                    </div>
                    <Link to={"/units-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>
              </>
            )}

            {attributeManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/attributes"}
                  className={({ isActive }) =>
                    isActive ? "nav-link has-icon active" : "nav-link has-icon"
                  }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Attribute</span>
                  </div>
                  <Link to={"/attribute-add"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}

            {/* {customerManagment === 1 && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={"/customer"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon2.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Customer</span>
                    </div>
                    <Link to={"/customer-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/customer-document"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Customer Document</span>
                    </div>
                    <Link to={"/customer-document-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>
              </>
            )} */}

            {vendorManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/vendor"}
                  className={({ isActive }) =>
                    isActive ? "nav-link has-icon active" : "nav-link has-icon"
                  }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon3.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Vendor</span>
                  </div>
                  <Link to={"/vendor-add"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}
            {catogryPersonManager === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/category-manager"}
                  className={({ isActive }) =>
                    isActive ? "nav-link has-icon active" : "nav-link has-icon"
                  }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Category Manager</span>
                  </div>
                  <Link to={"/add-category-manager"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}

            {salseManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/sales-manager"}
                  className={({ isActive }) =>
                    isActive ? "nav-link has-icon active" : "nav-link has-icon"
                  }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Sales Manager</span>
                  </div>
                  <Link to={"/add-sales-manager"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}

            {salseManagment === 1 && (
              <li className="nav-item">
                <NavLink
                  to={"/sales-person"}
                  className={({ isActive }) =>
                    isActive ? "nav-link has-icon active" : "nav-link has-icon"
                  }
                >
                  <div>
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">Sales Person</span>
                  </div>
                  <Link to={"/add-sales-person"}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </Link>
                </NavLink>
              </li>
            )}
            {operationManagment === 1 && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={"/operation-executive"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Operation Executive</span>
                    </div>
                    <Link to={"/add-operation-executive"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/designation"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Designation</span>
                    </div>
                    <Link to={"/designation-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/industry"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Industry</span>
                    </div>
                    <Link to={"/industry-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/venture"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Venture</span>
                    </div>
                    <Link to={"/venture-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    to={"/lead-source"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Lead Source</span>
                    </div>
                    <Link to={"/lead-source-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/issue-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Issue Type</span>
                    </div>
                    <Link to={"/issue-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/document-type"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Document Type</span>
                    </div>
                    <Link to={"/document-type-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/inventory-in-out"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Inventory In Out</span>
                    </div>
                    <Link to={"/add-inventory"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/zone"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Zone</span>
                    </div>
                    <Link to={"/zone-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/support"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Support</span>
                    </div>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    to={"/all-sales"}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards">All Sales</span>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    to={"/leads"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Leads</span>
                    </div>
                    <Link to={"/add-leads"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    to={"/training"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Training</span>
                    </div>
                    <Link to={"/training-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={"/campaign"}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link has-icon active"
                        : "nav-link has-icon"
                    }
                  >
                    <div>
                      <img
                        src="/assets/images/menu-icon1.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span data-key="t-dashboards">Campaign</span>
                    </div>
                    <Link to={"/campaign-add"}>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Link>
                  </NavLink>
                </li>
              </>
            )}

            {/* 
            {operationManagment === 1 && (
              <>
                <li>
                  <Link
                    to={"/all-sales"}
                    className={
                      window.location.pathname === "/all-sales"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    <img
                      src="/assets/images/menu-icon1.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    <span data-key="t-dashboards"> Month Wise Sales</span>
                  </Link>
                </li>
              </>
            )} */}

            {orderManagment === 1 && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/images/menu-icon4.png"
                    alt=""
                    className="img-fluid"
                  />{" "}
                  <span data-key="t-dashboards">Order</span>
                </a>
                {userType === "Vendor" ? (
                  <>
                    <ul
                      className={
                        window.location.pathname === "/vendor-pending-orders" ||
                        window.location.pathname ===
                          "/vendor-rejected-orders" ||
                        window.location.pathname === "/process-order" ||
                        window.location.pathname === "/vendor-order-completed"
                          ? "dropdown-menu position-static show"
                          : "dropdown-menu position-static"
                      }
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link
                          to={"/vendor-pending-orders"}
                          className={
                            window.location.pathname ===
                            "/vendor-pending-orders"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Pending Order
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={
                            window.location.pathname === "/process-order"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/process-order"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Order Inprocess
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={
                            window.location.pathname ===
                            "/vendor-order-completed"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/vendor-order-completed"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Completed Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            window.location.pathname ===
                            "/vendor-rejected-orders"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/vendor-rejected-orders"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Rejected Order
                        </Link>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul
                      className={
                        window.location.pathname === "/pending-orders" ||
                        window.location.pathname === "/inprocess-orders" ||
                        window.location.pathname === "/order-completed" ||
                        window.location.pathname === "/rejected-orders" ||
                        window.location.pathname === "/total-orders"
                          ? "dropdown-menu position-static show"
                          : "dropdown-menu position-static"
                      }
                      aria-labelledby="navbarDropdown"
                    >
                      {userType === "oprationExecutive" ? (
                        ""
                      ) : (
                        <li>
                          <Link
                            to={"/pending-orders"}
                            className={
                              window.location.pathname === "/pending-orders"
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                          >
                            <img
                              src="/assets/images/menu-icon4.png"
                              alt=""
                              className="img-fluid"
                            />{" "}
                            Pending Order
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link
                          className={
                            window.location.pathname === "/inprocess-orders"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/inprocess-orders"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Inprocess Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            window.location.pathname === "/order-completed"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/order-completed"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Completed Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            window.location.pathname === "/rejected-orders"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/rejected-orders"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Rejected Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            window.location.pathname === "/total-orders"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to={"/total-orders"}
                        >
                          <img
                            src="/assets/images/menu-icon4.png"
                            alt=""
                            className="img-fluid"
                          />{" "}
                          Total Orders
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
            )}

            {venderOrder === 1 && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/images/menu-icon4.png"
                    alt=""
                    className="img-fluid"
                  />{" "}
                  <span data-key="t-dashboards">Vendor Order</span>
                </a>
                <ul
                  className={
                    window.location.pathname === "/add-orders" ||
                    window.location.pathname === "/vendor-pending-orders" ||
                    window.location.pathname === "/process-order" ||
                    window.location.pathname === "/vendor-rejected-orders" ||
                    window.location.pathname === "/vendor-order-completed"
                      ? "dropdown-menu position-static show"
                      : "dropdown-menu position-static"
                  }
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/add-orders"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/add-orders"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Add Buy Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/add-orders-manufacturer"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/add-orders-manufacturer"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Add Manufacture Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/vendor-pending-orders"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/vendor-pending-orders"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Pending Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/process-order"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/process-order"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Inprocess Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/vendor-order-completed"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/vendor-order-completed"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Completed Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        window.location.pathname === "/vendor-rejected-orders"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to={"/vendor-rejected-orders"}
                    >
                      <img
                        src="/assets/images/menu-icon4.png"
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Rejected Order
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
