import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import moment from "moment";

const baseUrl = myConstList.baseUrl;
function VendorsPendingOrderDetails(props) {
  const [orderId, setOrderId] = useState("");
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [againstOrderId, setAgainstOrderId] = useState(null);
  const [category, setCategory] = useState("");
  const [tableData, setTableData] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  const [rawMaterialData, setRawMaterialData] = useState([]);

  const [remainingTime, setRemainingTime] = useState("");
  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    orderData(Params);
    getOrderDetails();
    setOrderId(Params);
  }, []);

  const getCatData = (id) => {
    const data = {
      catId: id,
    };

    axios.post(baseUrl + "/frontapi/order-single-product", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCategory(resp.data.catData[0].name);
      }
    });
  };

  const RawData = async (id) => {
    const data = {
      orderId: id,
    };
    await axios
      .post(baseUrl + "/frontapi/getRawMaterialDetail", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setRawMaterialData(resp.data);
        }
      });
  };

  const orderData = async (Params) => {
    const data = {
      id: Params,
      userType: "yes",
    };
    await axios.post(baseUrl + "/frontapi/order-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setTableData(resp.data);
        setAgainstOrderId(resp.data[0].against_order_id);
        setDateOfOrder(resp.data[0].createdAt);
        getCatData(resp.data[0].category_id);
        getVendorName(resp.data[0].vendor_id);
        setOrderStatus(resp.data[0].status);

        showTimerCounterHtml(resp.data[0].created_at);

        setOrderType(resp.data[0].orderType);
        RawData(resp.data[0].against_order_id);
      }
    });
  };

  let getVendorName = async (id) => {
    let requestData = { id: id };
    await axios
      .post(baseUrl + "/frontapi/vendor-single", requestData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          return;
        }
        if (resp.status === true) {
          setVendorName(resp.data.sqlRun[0].name);
        }
      });
  };

  const getOrderDetails = () => {
    const data = {
      id: Params,
    };
    axios
      .post(baseUrl + "/frontapi/get-orderdetails-admin", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
        }
      });
  };

  const totalSumHtml = () => {
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.total);
    });
    return sum;
  };

  const tableHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      var amt = value.price * value.quantity;
      var gstTotal = (amt / 100) * value.tax;
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.name}</th>
          <th>
            {value.quantity} {value.units}
          </th>
          <th>{value.price} INR</th>
          <th>{value.tax}%</th>
          <th>{gstTotal}</th>
          <th>
            {" "}
            {value.orderType === "Customize" ? (
              <>
                {value.product_file === null ||
                value.product_file === "NULL" ||
                value.product_file === "null" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl + "/static/customizeImage/" + value.product_file
                    }
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    {!value.product_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.product_file
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          {value.status === "1" ? (
            <th>
              {!value.sample_file ? (
                "N/A"
              ) : (
                <>
                  <a
                    href={baseUrl + "/static/sampleImage/" + value.sample_file}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    {!value.sample_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl + "/static/sampleImage/" + value.sample_file
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                  {value.sample_file_status === "1" ? "Accepted" : ""}
                  {value.sample_file_status === "2" ? "Rejected" : ""}
                </>
              )}
            </th>
          ) : (
            ""
          )}

          <th>{value.total} INR</th>
        </tr>
      );
    });
    return html;
  };

  const rawHtml = () => {
    const html = [];
    rawMaterialData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name : <b>{value.att[0].prod_name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Quantity : <b>{value.locked_qty}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const showTimerCounterHtml = (startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();

    setInterval(async () => {
      var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
      const secondsCurrent = moment(currentDate, "YYYY-MM-DD HH:mm:ss").unix();

      var remainingSeconds = secondsCurrent - secondsStart;

      const duration = moment.duration(remainingSeconds, "seconds");
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const secondsRemaining = duration.seconds();
      setRemainingTime(
        `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
      );
    }, 1000);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>
                    {" "}
                    {orderStatus === "0" ? "PENDING" : ""}
                    {orderStatus === "1" ? "INPROCESS" : ""} ORDERS DETAIL
                  </b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id : <b>{orderId}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Date of Order:{" "}
                        <b>{moment(dateOfOrder).format("DD/MM/YYYY")}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Vendor Name: <b>{vendorName}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Time Taken: <b>{remainingTime}</b>
                      </p>
                    </div>
                  </div>
                  {againstOrderId != null ? (
                    <div className="col-md-3 col-12">
                      <div className="detail-inner">
                        <p>
                          Against Order ID: <b>{againstOrderId}</b>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Order Details</h3>
                <div className="table-responsive">
                  {orderStatus === "1" ? (
                    <table className="w-100">
                      <tr>
                        <th>S.No</th>
                        <th>Products Name</th>
                        <th>Order Quantity</th>
                        <th>Price</th>
                        <th>Gst</th>
                        <th>Gst Amount</th>
                        <th>Customize Image</th>
                        <th>Sample Image</th>
                        <th>Total Amount</th>
                      </tr>
                      {tableHtml()}
                      <tr align="right">
                        <td colSpan="9">
                          <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <table className="w-100">
                      <tr>
                        <th>S.No</th>
                        <th>Products Name</th>
                        <th>Order Quantity</th>
                        <th>Price</th>
                        <th>Gst</th>
                        <th>Gst Amount</th>
                        <th>Customize Image</th>
                        {/* <th>Sample Image</th> */}
                        <th>Total Amount</th>
                      </tr>
                      {tableHtml()}
                      <tr align="right">
                        <td colSpan="9">
                          <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
              </div>

              {orderType === "manufacture" && (
                <div className=" customer-detail-outer">
                  <div className="order-detail-outer-middle pending-list-outer">
                    <div className="col-12">
                      <div className="person-contact customer-detail">
                        <h3>Raw Material Details</h3>
                        {rawHtml()}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Total Price : </small>
                        <b>INR {totalSumHtml()}-/-</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category : </small>
                        <b>
                          {category === null || !category ? "N/A" : category}
                        </b>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default VendorsPendingOrderDetails;
