import React, { useState, useEffect, Fragment, useMemo } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import Footer from "./Footer";
import { formatDateTime } from "../utils/dateTime";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import useOrderData from "../Hooks/OrderData";
import { Button } from "./Common/Elements/Button";
import * as myConstList from "./BaseUrl";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Pending Orders");

function PendingOrders(props) {
  const [record, setRecord] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [vendorListData, setVendorListData] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [step, setStep] = useState("");
  const [estimatedDate, setEstimatedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({});
  const [quantities, setQuantities] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [totalOrderQuantity, setTotalOrderQuantity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 0 };
  }, []);
  const { data, loading, refetch } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },
    {
      key: "assignName",
      text: "Category Manager",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.assignName == null ? "N/A" : record.assignName}
          </Fragment>
        );
      },
    },
    {
      key: "name",
      text: "Sales Person",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.amount} INR</Fragment>;
      },
    },
    {
      key: "createdAt",
      text: "Order Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)}</Fragment>;
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/pending-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "status",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            <button
              data-toggle="tooltip"
              data-placement="top"
              title="Assign"
              className="btn btn-primary me-2"
              // value={5}
              onClick={(e) => getVendorsList(record)}
            >
              <i className="mdi mdi-check-bold"></i>
            </button>
            <button
              className="btn btn-primary"
              data-toggle="tooltip"
              data-placement="top"
              title="Reject"
              // value={5}
              onClick={(e) => changeStatusProduct(record)}
            >
              <i className="mdi mdi-close-circle-outline"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const updateStatus = (record) => {
    axios
      .post(baseUrl + "/frontapi/change-product-status", record)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          // productData();
          refetch();
          setTimeout(() => {
            window.location.href = "/rejected-orders";
          }, 2000);
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to reject?",
      buttons: [
        {
          label: "Yes",
          onClick: () => updateStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const orderStatusUpdate = () => {
    if (!vendorId) {
      toast.dismiss();
      toast.error("Please select assigned to person");
      return false;
    }

    if (!deliveryStatus) {
      toast.dismiss();
      toast.error("Please select delivery status");
      return false;
    }

    if (deliveryStatus === "yes" && step === "" && step <= 0) {
      toast.dismiss();
      toast.error("Please add step for partials delivery");
      return false;
    }

    if ((deliveryStatus === "yes" && Number(step) <= 1) || Number(step) > 15) {
      toast.dismiss();
      toast.error("Quantity must be greater than 1 or max 15");
      return false;
    }

    const totalQuantity = quantities.reduce((acc, qty) => Number(acc + qty), 0);

    if (deliveryStatus === "yes" && totalQuantity !== totalOrderQuantity) {
      setIsValid(false);
      return;
    }

    const checkAnyZero = quantities.some((quantity) => quantity <= 0);
    if (deliveryStatus === "yes" && checkAnyZero) {
      setIsValid(false);
      return;
    }

    if (!estimatedDate || estimatedDate === 0) {
      toast.dismiss();
      toast.error("Please enter estimated delivery date");
      return false;
    }

    // return;
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    let requestData = {
      orderId,
      status: 1,
      estimatedDate,
      vendorId,
      deliveryStatus,
      steps: step,
      subOrders: quantities,
    };

    // return;
    axios
      .post(baseUrl + "/frontapi/order-status-update", requestData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return false;
        }
        if (resp.status === true) {
          toast.success(resp.message);
          closeModal();
          // productData();
          refetch();
          // setTimeout(() => {
          //   window.location = "/inprocess-orders";
          // }, 2000);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeStatusProduct = (value) => {
    let productData = {
      productStatus: "5",
      id: value.id,
    };
    Conn(productData);
    return false;
  };

  const getVendorsList = (value) => {
    const data = {
      catManId: Number(value.assigned_to),
      // userType: localStorage.getItem("userType")
    };
    setTotalOrderQuantity(Number(value.quantity));
    setOrderId(value.id);

    axios
      .post(baseUrl + "/frontapi/opration-executive-data", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setVendorListData(resp.data);
          setShowModal(true);
        }
      });
  };

  const vendorListHtml = () => {
    const html = [];
    html.push(<option value={"self"}>Self</option>);
    vendorListData.map((item) => {
      return html.push(<option value={item.id}>{item.name}</option>);
    });
    return html;
  };

  const handleChangeDate = (e) => {
    let { value } = e.target;
    setEstimatedDate(value);
  };

  const onChangeVendorList = (e) => {
    let { value } = e.target;
    setVendorId(value);
  };

  const closeModal = () => {
    setShowModal(false);
    setVendorId("");
    setEstimatedDate("");
    setDeliveryStatus(null);
    setStep(null);
    setError({});
    setIsValid(true);
    setQuantities([]);
  };

  function isDecimalNumber(input) {
    const decimalRegex = /^\d*$/;
    return input.match(decimalRegex);
  }

  const handlePartialsDelivery = (e) => {
    const { value, name } = e.target;
    const newErrors = {};
    if (name === "deliveryStatus") {
      setDeliveryStatus(value);
      if (value.trim() === "") {
        newErrors.deliveryStatusError = "Please select delivery status";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        setStep(null);
        return;
      }
      if (value === "no") {
        setIsValid(true);
        setQuantities([]);
        setStep(null);
      }
      setError({});
    }

    if (name === "step") {
      setStep(value);
      if (Number(value.trim()) <= 1 || value.trim() === "") {
        newErrors.stepError =
          "Quantity must be a positive value or greater than 1";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }

      let error = isDecimalNumber(value);

      if (!error) {
        newErrors.stepError = "Steps should be in number";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }

      if (Number(value.trim()) > 15) {
        newErrors.stepError = "Quantity must be greater than 1 or max 15";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }
      setQuantities(Array(Number(value)).fill(0));
      setError({});
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "." ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleQuantityChange = (event, stepIndex) => {
    const newQuantities = [...quantities];
    const newValue = parseFloat(event.target.value);
    newQuantities[stepIndex] = newValue;

    // Validate input
    if (newValue <= 0 || isNaN(newValue)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }

    setQuantities(newQuantities);
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>PENDING ORDER</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    // className="table table-bordered table-striped table-responsive"
                    config={config}
                    records={record}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal className="modal-update" show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title className="m-0" style={{ color: "#757575" }}>
                Assign Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="assign-vendor">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <div className="product-price">
                    <label>Assign To: </label>
                    <select
                      className="form-control"
                      name="vendorId"
                      value={vendorId}
                      onChange={onChangeVendorList}
                    >
                      <option value={""}>Select</option>
                      {vendorListHtml()}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 col-12">
                  <div className="product-price">
                    <label>Part Delivery: </label>
                    <select
                      className="form-control"
                      name="deliveryStatus"
                      value={deliveryStatus}
                      onChange={handlePartialsDelivery}
                    >
                      <option value={""}>Select</option>
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                    <p style={{ color: "red" }}>
                      {" "}
                      {error?.deliveryStatusError}{" "}
                    </p>
                  </div>
                </div>

                {deliveryStatus === "yes" && (
                  <div className="col-md-12 col-12">
                    <label>Total Ordered Quantity : {totalOrderQuantity}</label>
                    <div className="product-price">
                      <label>Steps: </label>
                      <input
                        type="number"
                        onKeyDown={handleValidationDot}
                        className="form-control"
                        name="step"
                        onChange={handlePartialsDelivery}
                        value={step}
                      />
                      <p style={{ color: "red" }}> {error?.stepError} </p>
                    </div>
                  </div>
                )}

                {deliveryStatus === "yes" &&
                  quantities.length > 0 &&
                  quantities.map((quantity, index) => (
                    <div className="col-md-12 col-12">
                      <div className="product-price" key={index}>
                        <label htmlFor={`quantity-${index}`}>
                          Add delivery quantity for {index + 1} order:
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          onKeyDown={handleValidation}
                          id={`quantity-${index}`}
                          value={quantity}
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                        />
                      </div>
                    </div>
                  ))}

                {!isValid && (
                  <div
                    style={{ color: "red" }}
                    className="error-message col-md-12 col-12"
                  >
                    Please ensure that quantities are valid, total of quantities
                    must be equal to ordered quantity({totalOrderQuantity}).
                  </div>
                )}

                <div className="col-md-12 col-12">
                  <div className="product-price">
                    <label>Expected Delivery Date:</label>
                    <input
                      type="date"
                      name="estimatedDate"
                      placeholder=" Estimated Date"
                      className="form-control"
                      onChange={handleChangeDate}
                      value={estimatedDate}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="submit-btn">
                    <Button onClick={orderStatusUpdate} loading={isLoading}>
                      Assign
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
      </div>
    </div>
  );
}
export default PendingOrders;
