import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function AddCategoryManager() {
  const [name, setName] = useState("");
  const [catData, setCatData] = useState([]);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [formValues, setFormValues] = useState([{ categoryId: "" }]);
  const [formValuesError, setFormValuesError] = useState([{ categoryId: "" }]);
  const [loading, setLoading] = useState(false);

  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [passErr, setPassErr] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
  useEffect(() => {
    categoryData();
  }, []);

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    var CData = catData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const handleChangeName = async (event) => {
    let eventValue = event.target.value;

    if (event.target.name === "name") {
      setName(eventValue);
      if (!eventValue) {
        setNameErr("Please enter name");
        return false;
      }
      setNameErr("");
    }

    if (event.target.name === "email") {
      setEmail(eventValue);
      if (!eventValue) {
        setEmailErr("Please enter email");
        return false;
      }
      if (!eventValue.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }
      setEmailErr("");
    }

    if (event.target.name === "number") {
      setNumber(eventValue);
      if (!eventValue) {
        setNumErr("Please enter mobile number");
        return false;
      }

      if (!/^[6-9]/.test(eventValue)) {
        setNumErr("Please enter valid mobile number");
        return false;
      }

      if (eventValue.length < 10 || eventValue.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }
    if (event.target.name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      setPassword(eventValue);
      if (!eventValue) {
        setPassErr("Please enter password");
        return false;
      }

      if (!eventValue.match(regex)) {
        setPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }

      setPassErr("");
    }
  };

  let addFormFields = () => {
    setFormValues([...formValues, { categoryId: "" }]);
    setFormValuesError([...formValuesError, { categoryId: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.name === "categoryId") {
      if (
        !newFormValues[i].categoryId ||
        newFormValues[i].categoryId === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "Please select category";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
    if (!name && !email && !number && !password) {
      setNameErr("Please enter name");
      setEmailErr("Please enter email");
      setNumErr("Please enter mobile number");
      setPassErr("Please enter password");
      return false;
    }

    if (!name) {
      setNameErr("Please enter name");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Please enter email");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Please enter mobile number");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    if (!password) {
      setPassErr("Please enter password");
      return false;
    }

    if (!password.match(regex)) {
      setPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    error = checkSpace("password", password);
    if (error) {
      setPassErr(error);
      return false;
    }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.categoryId || element.categoryId === "Please select") {
        formError[i].categoryId = "Please select category";
        setFormValuesError(formError);
        return false;
      }

      html.push(element.categoryId);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate category found");
      return false;
    }

    let customerData = {
      name: name,
      email: email,
      categoryId: formValues,
      number: number,
      password: password,
    };
    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/category-manager-add", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Category Manager Added Successfully");
          setTimeout(() => {
            window.location = "/category-manager";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD Category Manager</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={name}
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}>{nameErr}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            className="form-control"
                            onChange={handleChangeName}
                            autoComplete="off"
                          />
                          <p style={{ color: "red" }}>{emailErr}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="number"
                            name="number"
                            onKeyDown={handleValidation}
                            placeholder="Mobile Number"
                            maxLength={10}
                            value={number}
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}>{numErr}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type={typePass}
                            name="password"
                            placeholder="Password"
                            value={password}
                            className="form-control"
                            onChange={handleChangeName}
                            autoComplete="off"
                          />
                          <span
                            className="password__show eye1"
                            role="button"
                            onClick={() => handleClick()}
                          >
                            {typePass === "text" ? (
                              <i className="fa fa-eye"></i>
                            ) : (
                              <i className="fa fa-eye-slash"></i>
                            )}
                          </span>
                          <p style={{ color: "red" }}>{passErr}</p>
                        </div>
                      </div>
                      <div className="row">
                        {formValues.map((element, index) => (
                          <>
                            <div className="col-md-4 col-12">
                              <div className="add-row-group d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                  <label>Category Name</label>
                                  <select
                                    className="form-control"
                                    name="categoryId"
                                    value={element.categoryId || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  >
                                    <option>Please select</option>
                                    {categoryHtml()}
                                  </select>
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].categoryId}{" "}
                                  </p>
                                </div>
                                <div className="add-row">
                                  {index ? (
                                    <a
                                      className="remove"
                                      href="#!"
                                      onClick={() => removeFormFields(index)}
                                    >
                                      -
                                    </a>
                                  ) : null}
                                  <a
                                    className="add-btn add"
                                    href="#!"
                                    onClick={() => addFormFields()}
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/category-manager"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default AddCategoryManager;
