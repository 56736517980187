import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import * as myConstList from "./BaseUrl";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Customer Documents");

function CustomerDocuments() {
  const [record, setRecord] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    documentDataApi();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "customer_name",
      text: "Customer Name",
      className: "customer_name",
      align: "left",
      sortable: true,
    },
    {
      key: "document_name",
      text: "Document Type",
      className: "document_name",
      align: "left",
      sortable: true,
    },

    {
      key: "document",
      text: "Document",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        let doc = JSON.parse(record.document);
        return (
          <Fragment>
            {doc.length > 0 ? (
              <button
                type="button"
                onClick={() => {
                  setDocumentData(JSON.parse(record.document));
                  setShowModal(true);
                }}
                className="btn btn-primary"
              >
                View
              </button>
            ) : (
              "N/A"
            )}
          </Fragment>
        );
      },
    },

    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href =
                  "/customer-document-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>

            {record.is_deleted === "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const documentDataApi = () => {
    axios.post(baseUrl + "/frontapi/customer-document-data", {}).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setRecord(resp.data);
      }
    });
  };
  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }

    let params = {
      id: id,
      status: temp,
    };
    axios
      .post(baseUrl + "/frontapi/customer-document-delete", params)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          documentData();
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  const closeShowModel = () => {
    setDocumentData([]);
    setShowModal(false);
  };

  const documentHtml = () => {
    const html = [];
    documentData.map((value, i) => {
      return html.push(
        <div className="col-md-6 col-12">
          <div className="product-price">
            <a
              href={baseUrl + "/static/document/" + value.doc}
              target="_blank"
              rel="noreferrer"
            >
              <b>{value.doc}</b>
            </a>
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <ContentLayout
        title="Customer Document List"
        buttonTitle="Add Customer Document"
        buttonUrl="/customer-document-add"
        className="product-list-outer customer-list-outer"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />
      </ContentLayout>

      <Modal className="modal-update" show={showModal} onHide={closeShowModel}>
        <Modal.Header closeButton>
          <Modal.Title className="m-0" style={{ color: "#757575" }}>
            {" "}
            Document{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="assign-vendor">
          <div className="row align-items-center">{documentHtml()}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CustomerDocuments;
