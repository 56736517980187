import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer";

const baseUrl = myConstList.baseUrl;

function ProductDetails(props) {
  const [aData, setAData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [id, setId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const Params = useParams();

  useEffect(() => {
    productData();
  }, []);

  const productData = async () => {
    const data = {
      id: atob(Params.id),
    };
    await axios.post(baseUrl + "/frontapi/product-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setAData(resp.data.sqlRun2);
        setRawData(resp.data.sqlRun3);
        setId(resp.data.sqlRun[0].id);
        setProdData(resp.data.sqlRun[0]);
        setImage(resp.data.sqlRun[0].image);
        categorySingle(resp.data.sqlRun[0].category_id);
      }
    });
  };

  const categorySingle = async (id) => {
    let data = {
      id: id,
    };
    await axios
      .post(baseUrl + "/frontapi/category-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setCategoryName(resp.data.sqlRun[0].name);
          subCategoryData(resp.data.sqlRun[0].id);
        }
      });
  };

  const subCategoryData = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/frontapi/sub-category-by-catid", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          // setSubCatData([]);
          return false;
        }
        if (resp.status === true) {
          setSubCategoryName(resp.data[0].name);
        }
      });
  };

  const dataHtml = () => {
    const html = [];

    aData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                <b>{value.valueName} : </b>
                {value.value}
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const dataHtmlRawMaterial = () => {
    const html = [];

    rawData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                <b>{value.material_type} : </b>
                {value.material_quantity} {value.material_units}
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const imageDataHtml = () => {
    const html = [];
    if (image) {
      let allImages = image.split(",");
      html.push(
        <div className="detail-left">
          <img
            id="singleImage"
            src={baseUrl + "/static" + allImages[0]}
            alt=""
            className="img-fluid"
          />
        </div>
      );
      allImages.map((value, i) => {
        return html.push(
          <div className="col-md-3">
            <div className="item-images">
              <a href="#!">
                <img
                  src={baseUrl + "/static" + value}
                  onClick={() => changeImage(baseUrl + "/static" + value)}
                  alt=""
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        );
      });
    } else {
      html.push(
        <div className="detail-left">
          <img
            id="singleImage"
            src="/assets/images/no-image-procurit.png"
            alt=""
            className="img-fluid"
          />
        </div>
      );
    }

    return html;
  };

  const changeImage = (image_link) => {
    $("#singleImage").attr("src", image_link);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>PRODUCT DETAIL</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-12">
                  <div className="add-product-outer product-detail-outer">
                    <div className="row">
                      <div className="col-md-5 col-12">
                        {imageDataHtml()}
                        {/* <div className="row"></div> */}
                      </div>

                      <div className="col-md-7 col-12">
                        <div className="detail-right">
                          <h2>
                            <b>{prodData.name}</b>
                          </h2>
                          <p>
                            <b>Product Id: </b>
                            {id < 10
                              ? prodData.subShortName + "000" + id
                              : prodData.subShortName + "00" + id}
                          </p>
                          <p>
                            <b>Category: </b>
                            {categoryName}
                          </p>
                          <p>
                            <b>Sub Category: </b>
                            {subCategoryName}
                          </p>
                          <p>
                            <b>Available Quantity: </b>
                            {prodData.avaliable_qty} {prodData.units}
                          </p>
                          <p>
                            <b>Units: </b>
                            {prodData.units}
                          </p>
                          <p>
                            <b>Product Type:</b>{" "}
                            {prodData.prod_type === "raw" ? "Raw" : ""}
                            {prodData.prod_type === "finished"
                              ? "Finished"
                              : ""}
                          </p>

                          <p>
                            <b> HSN Code: </b>
                            {prodData.hsnCode}
                          </p>

                          <p>
                            <b>Product Price:</b> INR {prodData.price}/-
                          </p>
                          <p>
                            <b>Sales Price:</b> INR {prodData.sale_price}/-
                          </p>
                          <p>
                            <b> Tax: </b>
                            {prodData.tax}%
                          </p>
                          <h5>
                            <b>Description</b>
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: prodData.description,
                            }}
                          ></p>

                          <div className="col-12">
                            <div className="person-contact customer-detail">
                              <h5>
                                <b>Attributes</b>
                              </h5>

                              {dataHtml()}
                            </div>
                          </div>

                          {prodData.prod_type === "finished" && (
                            <div className="col-12">
                              <div className="person-contact customer-detail">
                                <h5>
                                  <b>Raw Materials</b>
                                </h5>

                                {dataHtmlRawMaterial()}
                              </div>
                            </div>
                          )}

                          <div className="btn-group align-items-start">
                            <a
                              href={"/product-edit/" + Params.id}
                              className="btn btn-primary"
                            >
                              Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              limit={1}
              autoClose={3000}
              pauseOnHover={false}
              pauseOnFocusLoss={false}
              theme={"dark"}
            />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default ProductDetails;
