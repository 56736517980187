import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import Select from "react-select";

const baseUrl = myConstList.baseUrl;

function AddOrders(props) {
  const [produtData, setProductData] = useState([]);
  const [vendorDataList, setVendorDataList] = useState([]);
  const [defaultVendor, setDefaultVendor] = useState(null);
  const [selectedOptionsArray, setSelectedOptionsArray] = useState([null]);
  const [pData, setPData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [units, setUnits] = useState("");
  const noImage = "assets/images/no-image-procurit.png";
  const [formValues, setFormValues] = useState([
    {
      price: 0,
      tax: 0,
      hsnCode: "",
      quantity: 0,
      totalPrice: 0,
      withoutTotalPrice: 0,
      vendorId: "",
      productId: "",
      image: [],
      specification: "",
    },
  ]);

  const [formValuesError, setFormValuesError] = useState([
    {
      price: "",
      tax: "",
      hsnCode: "",
      quantity: "",
      totalPrice: "",
      withoutTotalPrice: "",
      vendorId: "",
      productId: "",
      image: [],
      specification: "",
    },
  ]);

  useEffect(() => {
    productData();
    vendorData();
  }, [props]);

  const productData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          const filteredData = resp.data.filter((value) => {
            return value.isDeleted === "1";
          });

          setProductData(filteredData);
        }
      });
  };

  const submitData = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      attributes: JSON.stringify(formValues),
      units: units,
    };

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let addressError = [...formValuesError];

      if (!element.vendorId || element.vendorId === "Select Vendor") {
        addressError[i].vendorId = "This field is required";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.productId || element.productId === "") {
        addressError[i].productId = "This field is required";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.quantity) {
        addressError[i].quantity = "This field is required";
        setFormValuesError(addressError);
        return false;
      }

      if (element.quantity <= 0) {
        addressError[i].quantity = "Quantity cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.price) {
        addressError[i].price = "This field is required";
        setFormValuesError(addressError);
        return false;
      }
      if (element.price <= 0) {
        addressError[i].price = "Price cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.totalPrice) {
        addressError[i].totalPrice = "This field is required";
        setFormValuesError(addressError);
        return false;
      }
      if (element.totalPrice <= 0) {
        addressError[i].totalPrice = "Total price cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }
    }

    axios
      .post(baseUrl + "/frontapi/add-order-vendor", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2500);

          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };
  const vendorData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios.post(baseUrl + "/frontapi/vendor-data", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        let sorted = resp.data
          .filter((item) => item.is_deleted !== "1")
          .sort((a, b) => a.name.localeCompare(b.name));
        setVendorDataList(sorted);
      }
    });
  };

  const optionStyles = {
    control: (base, state) => ({
      ...base,

      background: "#3e3e3e",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "white" : "white",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
      },
      margin: "0",
      padding: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : "#3e3e3e",
        color: "#333333",
        padding: "2px 10px",
      };
    },
    noOptionsMessage: (provided) => ({
      ...provided,
      backgroundColor: "#999999", // Change background color for "No options" message
      color: "red", // Change text color for "No options" message
      padding: "2px 10px",
      // Add any other styles you want
    }),
  };

  const handleVendor = (selectedOption, index) => {
    let newFormValues = [...formValues];

    const valv = newFormValues[index];
    valv["vendorId"] = selectedOption.value;

    const updatedSelectedOptionsArray = [...selectedOptionsArray];
    updatedSelectedOptionsArray[index] = selectedOption;
    setSelectedOptionsArray(updatedSelectedOptionsArray);

    const data = {
      id: valv.vendorId,
    };
    axios.post(baseUrl + "/frontapi/vendor-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setIsShow(true);
        setPData(resp.data.sqlRun3);
        setVendorType(resp.data.sqlRun[0].vendorType);
      }
    });
  };

  const handleProductChange = (selectedOption, index) => {
    let newFormValues = [...formValues];

    const valv = newFormValues[index];
    valv["productId"] = selectedOption.value;

    let newFormValuesError = [...formValuesError];
    newFormValuesError[index][`productId`] = selectedOption.value;
    // setFormValues(newFormValues);

    if (!newFormValues[index].productId) {
      newFormValuesError[index][`productId`] = "Please select product";
      setFormValuesError(newFormValuesError);
      valv.quantity = 0;
      valv.totalPrice = 0;
      valv.withoutTotalPrice = 0;
      valv.price = 0;
      valv.tax = 0;
      valv.hsnCode = "";
      valv.image = "";

      return false;
    } else {
      newFormValuesError[index][`productId`] = "";
      setFormValuesError(newFormValuesError);

      const config = {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      };
      let pramas = {
        id: valv.productId,
      };
      axios
        .post(baseUrl + "/frontapi/product-single", pramas, config)
        .then((res) => {
          var resp = res.data;
          if (resp.status === false) {
            return;
          }
          if (resp.status === true) {
            valv.quantity = 0;
            valv.totalPrice = 0;
            valv.withoutTotalPrice = 0;
            valv.price = resp.data.sqlRun[0] ? resp.data.sqlRun[0].price : "";
            valv.tax = resp.data.sqlRun[0] ? resp.data.sqlRun[0].tax : "";
            valv.hsnCode = resp.data.sqlRun[0]
              ? resp.data.sqlRun[0].hsnCode
              : "";

            if (resp.data.sqlRun[0].image) {
              valv.image = resp.data.sqlRun[0]
                ? // ? resp.data.sqlRun[0].image
                  resp.data.sqlRun[0].image.split(",", 1)
                : "";
            } else {
              valv.image = [];
            }

            setFormValues(newFormValues);

            setUnits(resp.data.sqlRun[0].units);
          }
        });
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    // setFormValues(newFormValues);

    if (e.target.name === "vendorId") {
      if (!newFormValues[i].vendorId) {
        newFormValuesError[i][e.target.name] = "Please select vendor";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);

        const data = {
          id: valv.vendorId,
        };
        axios.post(baseUrl + "/frontapi/vendor-single", data).then((res) => {
          var resp = res.data;
          if (resp.status === true) {
            setIsShow(true);
            setPData(resp.data.sqlRun3);
            setVendorType(resp.data.sqlRun[0].vendorType);
          }
        });
      }
    }

    if (e.target.name === "quantity") {
      if (!newFormValues[i].quantity) {
        valv.totalPrice = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].quantity <= 0) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] =
          "Quantity cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);

      let amount = valv.price * valv.quantity;
      let getGstAmnout = (amount / 100) * valv.tax;
      let totalAmount = amount + getGstAmnout;
      valv.totalPrice = totalAmount;

      valv.withoutTotalPrice = valv.price * valv.quantity;
    }

    if (e.target.name === "price") {
      if (!newFormValues[i].price) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].price <= 0) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] =
          "Price cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }

      let amount = valv.price * valv.quantity;
      let getGstAmnout = (amount / 100) * valv.tax;
      let totalAmount = amount + getGstAmnout;
      valv.totalPrice = totalAmount;

      valv.withoutTotalPrice = valv.price * valv.quantity;

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
    }

    // if (e.target.name === "specification") {
    //   setFormValuesError(newFormValuesError);
    // }

    setFormValuesError(newFormValuesError);
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    const updatedSelectedOptionsArray = [...selectedOptionsArray];

    setFormValues([
      ...formValues,
      { vendorId: selectedOptionsArray[selectedOptionsArray.length - 1].value },
    ]);
    setFormValuesError([...formValuesError, {}]);

    const getSelectedVendor = vendorDataList.filter(
      (vendor) => vendor.id === formValues[formValues.length - 1].vendorId
    );

    updatedSelectedOptionsArray[formValues.length - 1] = {
      vendorId: getSelectedVendor[0].id,
    };

    setDefaultVendor({
      value: getSelectedVendor[0].id,
      label: getSelectedVendor[0].name,
    });
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  const prodHtml = () => {
    const html = [];

    pData.map(function (value, i) {
      return html.push(
        <div className="row">
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name : <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                {vendorType === "Raw Material Manufacturer" ||
                "Goods Manufacturer"
                  ? "Production"
                  : "Delivery"}{" "}
                Capacity : <b>{value.mCapacity}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                {vendorType === "Raw Material Manufacturer" ||
                "Goods Manufacturer"
                  ? "Manufacturing"
                  : "Warehouse"}{" "}
                Location : <b>{value.mLocation}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                SellingPrice : <b>{value.mSPrice}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const totalPriceHTML = () => {
    const html = [];

    let totalPrice = 0;
    let totalPriceNoGst = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.totalPrice ? element.totalPrice : 0;

      totalPriceNoGst += element.withoutTotalPrice
        ? element.withoutTotalPrice
        : 0;
    }
    html.push(
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
          <div className="person-detail">
            <p>
              Order Value (Without GST):{" "}
              <b>{parseFloat(totalPriceNoGst).toFixed(2)} INR</b>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 more-padding ">
          <div className="person-detail">
            <p>
              Order Value (With GST):{" "}
              <b>{parseFloat(totalPrice).toFixed(2)} INR</b>
            </p>
          </div>
        </div>
      </div>
    );

    return html;
  };

  return (
    <>
      <div id="layout-wrapper">
        {/* <Header />
        <Navbar /> */}
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>ADD ORDER</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-12">
                  <div className="add-product-outer customer-detail-outer add-order-form">
                    {formValues.map((element, index) => (
                      <div className="add-product-outer">
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group mb-0">
                                <label>Product Image</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="products-images-outer d-flex">
                                {/* {imagesHtml(index)} */}
                                <div className="product-img-left order-image">
                                  <img
                                    // src={baseUrl + "/static/" + element.image}
                                    src={
                                      element.image &&
                                      element.image.length == "0"
                                        ? noImage
                                        : baseUrl + "/static/" + element.image
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                            <>
                              {/* <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Vendor Name</label>
                                  <select
                                    className="form-control "
                                    name="vendorId"
                                    value={element.vendorId || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                    // data-live-search="true"
                                  >
                                    <option value=""> Select Vendor </option>
                                    <option value="jk"> Select Vendor </option>
                                    {htmlVendorDropdown()}
                                  </select>
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].vendorId}
                                  </p>
                                </div>
                              </div> */}
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Vendor Name</label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                      handleVendor(option, index)
                                    }
                                    // value={selectedOptions[index]}
                                    isSearchable={true}
                                    styles={optionStyles}
                                    // name="vendorId"
                                    noOptionsMessage={() => "No vendor found"}
                                    name={`vendorId-${index}`}
                                    placeholder="Select Vendor"
                                    defaultValue={defaultVendor}
                                    // options={vendorDataList }

                                    options={vendorDataList.map((option) => ({
                                      value: option.id,
                                      label: option.name,
                                    }))}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].vendorId}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Product Name</label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                      handleProductChange(option, index)
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={optionStyles}
                                    // value={element.productId || ""}
                                    // name="vendorId"
                                    noOptionsMessage={() => "No product found"}
                                    name={`productId-${index}`}
                                    placeholder="Select Product"
                                    options={produtData.map((option) => ({
                                      value: option.id,
                                      label: option.name,
                                    }))}
                                  />
                                  {/* <select
                                    className="form-control"
                                    name="productId"
                                    value={element.productId || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  >
                                    <option value=""> Select Product </option>
                                    {produtData.map((value, index) => {
                                      return (
                                        <option value={value.id}>
                                          {value.name}
                                        </option>
                                      );
                                    })}
                                  </select> */}
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].productId}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>QUANTITY</label>
                                  <input
                                    type="number"
                                    onKeyDown={handleValidation}
                                    className="form-control"
                                    name="quantity"
                                    placeholder="Quantity"
                                    value={element.quantity || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].quantity}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Price</label>
                                  <input
                                    type="number"
                                    name="price"
                                    onKeyDown={handleValidation}
                                    placeholder="Price"
                                    value={element.price || ""}
                                    className="form-control"
                                    onChange={(e) => handleChangeAttr(index, e)}
                                    // disabled="true"
                                  />

                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].price}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>TAX</label>
                                  <input
                                    type="number"
                                    name="tax"
                                    onKeyDown={handleValidation}
                                    placeholder="Tax"
                                    value={element.tax || ""}
                                    className="form-control"
                                    onChange={(e) => handleChangeAttr(index, e)}
                                    disabled="true"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>HSN Code</label>
                                  <input
                                    type="text"
                                    name="hsnCode"
                                    placeholder="HSN Code"
                                    className="form-control"
                                    disabled="true"
                                    value={element.hsnCode || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Without GST Total Price</label>
                                  <input
                                    type="number"
                                    className="form-control price-input"
                                    disabled="true"
                                    onKeyDown={handleValidation}
                                    placeholder="Total Price"
                                    name="totalPrice"
                                    value={
                                      parseFloat(
                                        Number(element.price) *
                                          Number(element.quantity)
                                      ).toFixed(2) || ""
                                    }
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <label className="inr-label position-absolute">
                                    INR
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>With GSt Total Price</label>
                                  <input
                                    type="number"
                                    className="form-control price-input"
                                    disabled="true"
                                    onKeyDown={handleValidation}
                                    placeholder="Total Price"
                                    name="totalPrice"
                                    value={
                                      parseFloat(element.totalPrice).toFixed(
                                        2
                                      ) || ""
                                    }
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <label className="inr-label position-absolute">
                                    INR
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group position-relative">
                                    <label>Specification</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Specification"
                                      name="specification"
                                      value={element.specification || ""}
                                      onChange={(e) =>
                                        handleChangeAttr(index, e)
                                      }
                                    />
                                  </div>
                                  <div className="add-row">
                                    {index ? (
                                      <a
                                        href="#!"
                                        className="add-btn add"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        -
                                      </a>
                                    ) : null}
                                    <a
                                      href="#!"
                                      onClick={() => addFormFields()}
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </form>
                      </div>
                    ))}

                    <div>{totalPriceHTML()}</div>

                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={submitData}
                        className="btn btn-primary"
                      >
                        {" "}
                        Place Order{" "}
                      </button>
                    </div>
                    {isShow === true ? (
                      <div className="col-12">
                        <div className="person-contact customer-detail">
                          <h5>
                            <b>Product Reference</b>
                          </h5>
                          {prodHtml()}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default AddOrders;
