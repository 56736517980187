import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatDateTime } from "../utils/dateTime";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomerProcessSubOrderDetails from "./CustomerProcessSubOrderDetails";
import { selectStyle } from "../Hooks/SelectStyle";

const baseUrl = myConstList.baseUrl;

function CustomerProcessOrderDetails(props) {
  const [orderId, setOrderId] = useState("");

  const [dateOfOrder, setDateOfOrder] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [salsePersonName, setSalsePersonName] = useState("");
  const [customerDataById, setCustomerDataById] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [vendorListData, setVendorListData] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [price, setPrice] = useState(0);
  const [productQuantity, setProductQuantity] = useState("");
  const [total, setTotal] = useState(0);
  const [totalWithoutGst, setTotalWithoutGst] = useState(0);
  const [status, setStatus] = useState("0");
  const [totalCost, setTotalCost] = useState("");
  const [specifications, setSpecifications] = useState(null);
  const [vendorSpecifications, setVendorSpecifications] = useState("");
  const [lock_quantity, setLock_quantity] = useState(0);
  const [lockProd, setLockProd] = useState("");
  const [lockOrderId, setLockOrderId] = useState("");
  const [lockOrderItemId, setLockOrderItemId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [inWords, setInWords] = useState("");

  //suborder data
  const [subOrdersTable, setSubOrdersTable] = useState([]);
  const [partialDeliveryStatus, setPartialDeliveryStatus] = useState("no");

  const [productName, setProductName] = useState("");
  const [tax, setTax] = useState("");
  const [hsn, setHsn] = useState("");
  const [vendorTotal, setVendorTotal] = useState(0);
  const [vendorTotalWithoutGst, setVendorTotalWithoutGst] = useState(0);

  const [orderItemId, setOrderItemId] = useState("");
  const [units, setUnits] = useState("");

  const [catManager, setCatManager] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [productId, setProductId] = useState("");
  const [isAssigned, setIsAssigned] = useState("");
  const [productImage, setProductImage] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [showBuyModel, setShowBuyModel] = useState(false);
  const [materialPrice, setMaterialPrice] = useState("");
  const [materialQuantity, setMaterialQuantity] = useState("");
  const [singleOrderId, setSingleOrderId] = useState("");
  const [singleProductId, setSingleProductId] = useState("");
  const [sData, setSData] = useState([]);
  const [remainingTime, setRemainingTime] = useState("");
  const [showLockQtyModal, setShowLockQtyModal] = useState(false);

  // errors //
  const [buyNameErr, setBuyNameErr] = useState("");
  const [buyPriceErr, setBuyPriceErr] = useState("");
  const [vendorNameErr, setVendorNameErr] = useState("");
  const [vendorPriceError, setVendorPriceError] = useState("");
  const [lockQtyErr, setLockQtyErr] = useState("");

  // for completed quantity modal
  const [isQtyModalOpen, setIsQtyModalOpen] = useState(false);
  const [completedQty, setCompletedQty] = useState(0);
  const [completeData, setCompleteData] = useState({});

  const tempId = useParams();
  const Params = atob(tempId.id);

  const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function convertToWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          ""
        : "";
    return str;
  }

  useEffect(() => {
    const local =
      partialDeliveryStatus === "no"
        ? localStorage.setItem("suborder", partialDeliveryStatus)
        : null;
    orderData();
    getOrderDetails();
    setOrderId(Params);
    getCustomerDetailsAdmin();
    getVensdorList();
  }, []);

  const getVensdorList = () => {
    axios.get(baseUrl + "/frontapi/get-vendor-list").then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setVendorListData(resp.data);
      }
    });
  };

  const getOrderDetails = () => {
    const data = {
      id: Params,
    };
    axios
      .post(baseUrl + "/frontapi/get-orderdetails-admin", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          if (resp.message == "Refreshed") {
            setTimeout(() => {
              window.location.href = "/inprocess-orders";
            }, 2000);
          }
          setTableData(resp.data);
          setPartialDeliveryStatus(resp.data[0].partial_delivery_status);
          setSubOrdersTable(resp.subOrders);
          setLockOrderId(resp.data[0].orderId);
          setLockProd(resp.data[0].product_Id);
          setLockOrderItemId(resp.data[0].id);
          setCustomerName(resp.data[0].customerName);
          getCatData(resp.data[0].category_id);
          setProductName(resp.data[0].productName);
          setTax(resp.data[0].gst);
          setHsn(resp.data[0].hsn);
          setStatus(resp.data[0].order_status);
          getTotalCost(resp.data[0]);
          getCustomerShippingAddress(
            resp.data[0].customer_id,
            resp.data[0].shipping_id
          );
          setSpecifications(resp.data[0].specifications);

          // setSingleOrderId(resp.data[0].orderId)
          // orderId
        }
      });
  };

  const getCustomerShippingAddress = (id, addressId) => {
    const data = {
      id: id,
      addressId: addressId,
    };
    axios.post(baseUrl + "/frontapi/customer-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setSData(resp.data.sqlRun2);
      }
    });
  };

  const getCatData = (id) => {
    const data = {
      catId: id,
    };

    axios.post(baseUrl + "/frontapi/order-single-product", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCatManager(resp.data.catManData[0].name);
        // setCategory(resp.data.catData[0].name);
      }
    });
  };

  const getCustomerDetailsAdmin = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/get-customer-admin", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCustomerDataById(resp.data);
      }
    });
  };

  const orderData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/order-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setDateOfOrder(resp.data[0].createdAt);

        showTimerCounterHtml(resp.data[0].createdAt);
        setSalsePersonName(resp.data[0].salsePersonName);
        if (resp.data[0].status === "2" || resp.data[0].status === 2) {
          // setStatusMessage("Packing Started");
        }
        if (resp.data[0].status === 3) {
          // setStatusMessage("Out For Delivery");
        }
        if (resp.data[0].status === 4) {
          // setStatusMessage("Delivered");
        }
      }
    });
  };

  const updateVendor = (e) => {
    e.preventDefault();

    var regg = /^-\d*\.?\d+$/;

    if (!vendorId || vendorId === "Select Vendor") {
      setVendorNameErr("Please select vendor");
      return false;
    }

    if (!price) {
      setVendorPriceError("Please enter price");
      return false;
    }

    if (!price || price == 0 || price < 0) {
      setVendorPriceError("Price cannot be zero or negative");
      return false;
    }

    if (price.match(regg)) {
      setVendorPriceError("Please enter valid price");
      return false;
    }

    if (!vendorTotal || vendorTotal == 0 || vendorTotal < 0) {
      toast.dismiss();
      toast.error("Total cannot be zero or negative value");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      productId: productId,
      orderItemId: orderItemId,
      vendorId: vendorId,
      total: vendorTotal,
      price: price,
      quantity: productQuantity,
      productFile: productImage,
      orderId: orderId,
      tax: tax,
      hsnCode: hsn,
      units: units,
      specification: vendorSpecifications,
    };

    axios
      .post(baseUrl + "/frontapi/assign-vendor", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          closeShowEditModel();
          setProductId("");
          getOrderDetails();
          toast.dismiss();
          toast.success(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  // add lock quantity functions //

  const handleChangeLockQty = async (event) => {
    let fieldValue = event.target.value;
    let fieldName = event.target.name;
    let quantity = tableData[0].quantity;
    let locked_available_qty = tableData[0].locked_available_qty;
    let actual_required_qty = Number(quantity - locked_available_qty);

    if (fieldName === "lock_quantity") {
      if (!fieldValue) {
        setLock_quantity(fieldValue);
        setLockQtyErr("Please enter lock quantity");
        return false;
      }

      var regex = /^-\d*\.?\d+$/;

      if (fieldValue.match(regex)) {
        setLock_quantity(fieldValue);
        setLockQtyErr("Please enter valid lock quantity");
        return false;
      }

      if (fieldValue > actual_required_qty) {
        setLock_quantity(fieldValue);
        setLockQtyErr(
          "Lock quantity should not be more than required quantity"
        );
        return false;
      }

      setLockQtyErr("");
      setLock_quantity(fieldValue);
    }
  };

  const submitLockQty = (e) => {
    e.preventDefault();

    let quantity = tableData[0].quantity;
    let locked_available_qty = tableData[0].locked_available_qty;
    let actual_required_qty = Number(quantity - locked_available_qty);
    var regex = /^-\d*\.?\d+$/;

    if (!lock_quantity) {
      setLockQtyErr("Please enter lock quantity");
      return false;
    }

    if (lock_quantity.match(regex)) {
      setLockQtyErr("Please enter valid lock quantity");
      return false;
    }

    if (lock_quantity > actual_required_qty) {
      setLockQtyErr("Lock quantity should not be more than required quantity");
      return false;
    }

    let data = {
      productId: lockProd,
      orderItemId: lockOrderItemId,
      lock_quantity: lock_quantity,
    };

    axios.post(baseUrl + "/frontapi/lock_quantity", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        closeLockQtyModel();
        orderData();
        getOrderDetails();
        toast.dismiss();
        toast.success(resp.message);
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const handleVendorChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedOption(selectedOption);
      setVendorId(null);
      setBuyNameErr("Please select vendor");
      setVendorNameErr("Please select vendor");
      return;
    }
    setSelectedOption(selectedOption);
    setVendorId(selectedOption.value);
    setVendorNameErr("");
    setBuyNameErr("");
  };

  const tableHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <>
          <tr key={i}>
            <th>{i + 1}</th>
            <th>{value.productName}</th>
            <th>
              {value.avaliable_qty} {value.units} ,Quantity Locked (
              {value.locked_available_qty} {value.units})
              {Number(value.quantity) > Number(value.locked_available_qty) ? (
                value.isCustomize === "Y" && value.is_assigned === "Y" ? (
                  ""
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setShowLockQtyModal(true);
                      }}
                    >
                      Lock Qty
                    </button>
                  </>
                )
              ) : (
                ""
              )}
            </th>
            <th>
              {value.quantity} {value.units}
            </th>
            <th>{value.price} INR</th>
            <th>{value.gst + "%"}</th>
            <th>{parseFloat(value.gstAmount).toFixed(2)} INR</th>
            <th>{parseFloat(value.subTotal).toFixed(2)} INR</th>
            <th>
              {Number(value.quantity) > Number(value.locked_available_qty) ? (
                value.manufactureOrBuy == null ||
                value.manufactureOrBuy === "null" ? (
                  value.isCustomize === "Y" && value.is_assigned === "Y" ? (
                    "Sent for customization"
                  ) : (
                    <>
                      {value.order_status > 1 ? (
                        "-"
                      ) : (
                        <>
                          {value.prod_type === "finished" ? (
                            <select
                              className="form-control"
                              onChange={(e) => getSelectValue(value, e)}
                              name="selectedValue"
                              value={selectedValue}
                            >
                              <option>Select</option>
                              <option value="buy">Buy</option>
                              <option value="manufacture">Manufacture</option>
                            </select>
                          ) : (
                            <select
                              className="form-control"
                              onChange={(e) => getSelectValue(value, e)}
                              name="selectedValue"
                              value={selectedValue}
                            >
                              <option>Select</option>
                              <option value="buy">Buy</option>
                            </select>
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {value.manufactureOrBuy === "no"
                ? "Available quantity locked"
                : ""}

              {value.manufactureOrBuy === "orderGiven" ? (
                <button
                  className="btn btn-primary"
                  onClick={() => getNewValues(value)}
                >
                  View Request
                </button>
              ) : (
                ""
              )}

              {value.manufactureOrBuy === "buy" ? (
                <button
                  className="btn btn-primary"
                  onClick={() => getNewValues(value)}
                >
                  View Request
                </button>
              ) : (
                ""
              )}
            </th>

            <th>{value.isCustomize === "Y" ? "Yes" : "No"}</th>
            <th>
              {value.isCustomize === "Y" ? (
                <>
                  {value.customize_image == null ||
                  value.customize_image === "NULL" ||
                  value.customize_image === "null" ? (
                    "Sent via Email"
                  ) : (
                    <a
                      href={
                        baseUrl +
                        "/static/customizeImage/" +
                        value.customize_image
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      {!value.customize_image.match(/\.(jpg|jpeg|png|gif)$/) ? (
                        "View File"
                      ) : (
                        <img
                          src={
                            baseUrl +
                            "/static/customizeImage/" +
                            value.customize_image
                          }
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </a>
                  )}
                </>
              ) : (
                "N/A"
              )}
            </th>
            <th>
              {value.isCustomize === "Y" ? (
                <>
                  {value.is_assigned === "N" ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => assignVendor(value)}
                    >
                      Assign
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => viewAssignValues(value)}
                    >
                      View
                    </button>
                  )}
                </>
              ) : (
                "N/A"
              )}
            </th>
            <th>
              <select
                className="form-control"
                name="productStatus"
                value={value.order_status}
                onChange={(e) => changeStatusProduct(e, value)}
              >
                <option value="0">Select</option>
                <option value="2">Packing Started</option>
                <option value="3">Out For Delivery</option>
                <option value="4">Delivered</option>
                <option value="6">Completed</option>
              </select>
            </th>
          </tr>
        </>
      );
    });
    return html;
  };

  const getNewValues = (item) => {
    window.location.href =
      "/manufacture-details/" +
      btoa(Params) +
      "/" +
      btoa(item.manufactureOrBuy);
  };

  const getSelectValue = (item, e) => {
    if (e.target.value === "manufacture") {
      setTimeout(() => {
        window.location.href =
          "/manufacture/" + btoa(item.id) + "/" + btoa(undefined);
      }, 1000);
    }
    if (e.target.value === "buy") {
      let locked_available_qty = item.locked_available_qty;
      let quantity = item.quantity;
      let diff = Number(quantity) - Number(locked_available_qty);

      setMaterialQuantity(diff);
      getMaterialDetails(item, diff);
      setSingleOrderId(item.id);
      setUnits(item.units);
      setSingleProductId(item.product_Id);
    }
  };

  const viewAssignValues = (item) => {
    window.location.href = "/assign-vendor-details/" + btoa(item.orderId);
  };

  const assignVendor = (item) => {
    if (item.locked_available_qty <= 0) {
      toast.dismiss();
      toast.error("Quantity not available, please buy or manufacture first");
      return false;
    }

    getMaterialDetails(item);
    setProductId(item.product_Id);
    setOrderItemId(item.id);
    setIsAssigned(item.is_assigned);
    setProductImage(item.customize_image);
    setUnits(item.units);

    // if (item.locked_available_qty >= item.quantity) {
    //   getMaterialDetails(item);
    //   setProductId(item.product_Id);
    //   setOrderItemId(item.id);
    //   setIsAssigned(item.is_assigned);
    //   setProductImage(item.customize_image);
    //   setUnits(item.units);
    //   return false;
    // } else {
    //   toast.dismiss();
    //   toast.error("Quantity not available, please Buy or Manufacture First");
    // }
  };

  const changeStatusProduct = (e, value) => {
    e.preventDefault();
    let productData = {
      productStatus: e.target.value,
      id: value.id,
      orderId: orderId,
      isCustomized: value.isCustomize,
      isAssigned: value.is_assigned,
    };

    if (e.target.value == "6") {
      // open a modal for quantity
      setCompleteData(productData);
      setIsQtyModalOpen(true);
      return false;
      // Conn(productData);
    }

    if (e.target.value) {
      Conn(productData);
    }
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const changeStatus = (data) => {
    data.completedQty = completedQty;
    axios
      .post(baseUrl + "/frontapi/change-product-status", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          getOrderDetails();
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  const getTotalCost = (value) => {
    const data = {
      orderId: value.orderId,
      orderItemId: value.id,
      orderType: value.manufactureOrBuy,
      assignVendor: value.is_assigned,
    };

    axios.post(baseUrl + "/frontapi/total-cost", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        // getOrderDetails();
        setTotalCost(resp.data);
        return false;
      }
    });

    // orderId,orderItemId
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.subTotal);
    });
    return sum;
  };

  const totalSumHtml = () => {
    // orderId,orderItemId
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.subTotal);
    });
    return parseFloat(sum).toFixed(2);
  };

  const vendorListHtml = () => {
    const html = [];
    html.push(<option>Select Vendor</option>);
    vendorListData.map((item) => {
      return html.push(<option value={item.id}>{item.name}</option>);
    });
    return html;
  };

  const specificationsHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.specifications}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const paymentModeHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.payment_mode}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const customerDetailsHtml = () => {
    const html = [];
    customerDataById.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav" key={i}>
            <li>
              Customer Name : <b>{value.name}</b>
            </li>
            <li>
              Company Name : <b>{value.companyName}</b>
            </li>
            <li>
              Email : <b>{value.email}</b>
            </li>
            <li>
              Pan Number : <b>{value.panNumber}</b>
            </li>
            <li>
              Phone Number : <b>{value.phoneNumber}</b>
            </li>
            <li>
              Billing Address : <b>{value.billingAddress}</b>
            </li>
            <li>
              Website : <b>{value.website}</b>
            </li>
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Shipping Address :</h5> {addressHtml()}
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Contact Person :</h5> {attributeCheck()}
          </ul>
        </div>
      );
    });
    return html;
  };

  const addressHtml = () => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-4 col-12">
              <li key={index}>
                Address: <b> {element.address}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Country: <b> {element.country}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                State: <b> {element.state}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                City: <b> {element.city}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Phone No: <b> {element.phone_no}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Pincode: <b> {element.zipcode}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };

  const attributeCheck = (value) => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-3 col-12">
              <li key={index}>
                Email: <b> {element.aEmail}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Name: <b> {element.aName}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Phone No: <b> {element.aPhoneNo}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Designation: <b> {element.selected}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };

  const closeLockQtyModel = () => {
    setShowLockQtyModal(false);
    setLockQtyErr("");
    setLock_quantity(0);
  };

  const closeShowEditModel = () => {
    setShowVendorModal(false);
    setShowBuyModel(false);
    setProductPrice("");
    setProductQuantity("");
    setPrice(0);
    setMaterialQuantity("");
    setVendorId("");
    setSingleOrderId("");
    setSingleProductId("");
    setMaterialPrice("");
    setTotal("");
    setBuyNameErr("");
    setBuyPriceErr("");
    setUnits("");
    setVendorNameErr("");
    setVendorPriceError("");
    setVendorTotal(0);
    setVendorTotalWithoutGst(0);
    setVendorSpecifications("");
  };

  const handleChangeInput = (e) => {
    let { name, value } = e.target;
    var regg = /^-\d*\.?\d+$/;
    let total;
    if (name === "materialPrice") {
      if (!value) {
        setTotal(0);
        setTotalWithoutGst(0);
        setMaterialPrice(value);
        setBuyPriceErr("Please enter price");
        return false;
      }
      if (!value || value == 0 || value < 0) {
        setTotal(0);
        setTotalWithoutGst(0);
        setMaterialPrice(value);
        setBuyPriceErr("Price cannot be zero or negative");
        return false;
      }

      if (value.match(regg)) {
        setTotal(0);
        setTotalWithoutGst(0);
        setBuyPriceErr("Please enter valid price");
        setMaterialPrice(value);
        return false;
      }
      setBuyPriceErr("");
      setMaterialPrice(value);
      total = value * materialQuantity;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
      return false;
    }
    if (name === "materialQuantity") {
      setMaterialQuantity(value);
      total = materialPrice * value;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }
    if (name === "specifications") {
      setVendorSpecifications(value);
      return false;
    }
  };

  const handleSubmitMaterial = () => {
    if (!vendorId || vendorId === "Select Vendor") {
      setBuyNameErr("Please select vendor");
      return false;
    }

    if (!materialPrice) {
      setBuyPriceErr("Please enter price");
      return false;
    }
    if (materialPrice == 0 || materialPrice < 0) {
      setBuyPriceErr("Price cannot be zero or negative");
      return false;
    }
    if (!total || total == 0 || total < 0) {
      toast.dismiss();
      toast.error("Total cannot be zero or negative value");
      return false;
    }

    let data = {
      vendorId: vendorId,
      orderId: orderId, // order ki id
      singleProductId: singleProductId,
      materialPrice: materialPrice,
      materialQuantity: materialQuantity,
      materialTotal: total,
      singleOrderId: singleOrderId, // orderItems ki id
      type: "buy",
      tax: tax,
      hsnCode: hsn,
      units: units,
      specification: vendorSpecifications,
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios
      .post(baseUrl + "/frontapi/add-buy-product", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          closeShowEditModel();
          // setShowBuyModel(false);
          getOrderDetails();
          toast.success(resp.message);
        } else {
          toast.error(resp.message);
        }
      });
  };

  const getMaterialDetails = async (item, diff) => {
    let data = {
      orderId: item.product_Id,
      runThis: "yes",
    };
    await axios
      .post(baseUrl + "/frontapi/get-material-list", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          // setPrice(resp.data[0].price);
          setTax(resp.data[0].tax);
          setHsn(resp.data[0].hsnCode);
          setMaterialPrice(resp.data[0].price);
          if (diff !== undefined) {
            let total = Number(resp.data[0].price) * Number(diff);
            const taxValue = (total * resp.data[0].tax) / 100;
            const theValue = taxValue + total;
            setTotal(theValue);
            setTotalWithoutGst(total);
            setShowBuyModel(true);
            return false;
          } else {
            setProductQuantity(item.locked_available_qty);

            // let total =
            //   Number(resp.data[0].price) * Number(item.locked_available_qty);
            // const taxValue = (total * resp.data[0].tax) / 100;
            // const theValue = taxValue + total;
            // setVendorTotal(theValue);
            setShowVendorModal(true);
          }
        }
      });
  };

  const handleChangeVendor = (e) => {
    let { name, value } = e.target;
    var regg = /^-\d*\.?\d+$/;
    let total;
    if (name === "product_price") {
      if (!value) {
        setPrice(value);
        setVendorTotal(0);
        setVendorTotalWithoutGst(0);
        setVendorPriceError("Please enter price");
        return false;
      }
      if (!value || value == 0 || value < 0) {
        setPrice(value);
        setVendorTotal(0);
        setVendorTotalWithoutGst(0);
        setVendorPriceError("Price cannot be zero or negative");
        return false;
      }

      if (value.match(regg)) {
        setVendorTotal(0);
        setVendorTotalWithoutGst(0);
        setVendorPriceError("Please enter valid price");
        setPrice(value);
        return false;
      }
      setVendorPriceError("");

      setPrice(value);
      total = value * productQuantity;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;

      setVendorTotal(theValue);
      setVendorTotalWithoutGst(total);
      return false;
    }

    if (name === "specifications") {
      setVendorSpecifications(value);
      return false;
    }
    if (name === "product_quantity") {
      setProductQuantity(value);
      total = value * price;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setVendorTotalWithoutGst(total);
      setVendorTotal(theValue);

      return false;
    }
  };

  const showTimerCounterHtml = (startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();

    const intervalStart = setInterval(async () => {
      var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
      const secondsCurrent = moment(currentDate, "YYYY-MM-DD HH:mm:ss").unix();

      var remainingSeconds = secondsCurrent - secondsStart;

      const duration = moment.duration(remainingSeconds, "seconds");
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const secondsRemaining = duration.seconds();

      setRemainingTime(
        `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
      );
    }, 1000);
  };

  const handleCloseQtyModal = () => {
    setIsQtyModalOpen(false);
    setCompleteData({});
    setCompletedQty(0);
    setInWords("");
  };

  const handleChangeComplete = (e) => {
    setCompletedQty(e.target.value);

    if (
      !e.target.value ||
      Number(e.target.value) === 0 ||
      Number(e.target.value) < 0
    ) {
      setInWords("");
      return false;
    } else {
      const amountInWords = convertToWords(e.target.value);

      const words = capitalizeEveryWord(amountInWords);
      setInWords(words);

      function capitalizeEveryWord(inputString) {
        return inputString.replace(/\b\w/g, (char) => char.toUpperCase());
      }
    }
  };

  const callSubmit = (e) => {
    e.preventDefault();

    if (completedQty <= 0) {
      toast.dismiss();
      toast.error("Delivering quantity should not be zero or below");
      return false;
    }

    setIsQtyModalOpen(false);

    Conn(completeData);
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="manager-heading-outer section-heading">
                <h2>
                  <b>INPROCESS ORDER DETAIL</b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id: <b>{orderId}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Date of Order: <b>{formatDateTime(dateOfOrder)}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Customer Name:{" "}
                        <b>
                          {customerName == null
                            ? salsePersonName
                            : customerName}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Time Taken: <b>{remainingTime}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {partialDeliveryStatus === "yes" ? (
                <CustomerProcessSubOrderDetails
                  mainOrderData={tableData}
                  subOrderData={subOrdersTable}
                  deliveryStatus={partialDeliveryStatus}
                  vendorList={vendorListData}
                  getOrderDetails={getOrderDetails}
                  productName={productName}
                  productId={lockProd}
                />
              ) : (
                <div className="order-details-status">
                  <div className="row">
                    <div className="col-12">
                      <div className="order-detail-outer-middle pending-list-outer">
                        <h3>Order Details</h3>
                        <table className="w-100 table-responsive">
                          <tr>
                            <th>S.No</th>
                            <th>Products Name</th>
                            <th>Available Quantity</th>
                            <th>Order Quantity</th>
                            <th>Price</th>
                            <th>Gst</th>
                            <th>Gst Amount</th>
                            <th>Total Amount</th>
                            <th>Buy/Manufacture</th>
                            <th>Customized</th>
                            <th>Customized Image</th>
                            <th>Assigned Vendor</th>
                            <th>Status</th>
                          </tr>
                          {tableHtml()}
                          <tr align="right">
                            <td colSpan="16">
                              <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Customer Details</h3>
                {customerDetailsHtml()}
              </div>

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  {specifications ? (
                    <div className="col-md-6 col-12">
                      <div className="detail-last-inner detail-inner">
                        <h3>Specification</h3>
                        {specificationsHtml()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h3>Payment Mode</h3>
                      {paymentModeHtml()}
                    </div>
                  </div>
                </div>
              </div>

              {/* {specifications ? (
                <div className="order-detail-outer-middle pending-list-outer">
                  <h3>Specification</h3>
                  {specificationsHtml()}
                </div>
              ) : (
                ""
              )} */}

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Sales Executive: </small>
                        <b>{customerName == null ? "N/A" : salsePersonName}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category Manager: </small>
                        <b>
                          {catManager === null || !catManager
                            ? "N/A"
                            : catManager}
                        </b>
                      </h5>
                    </div>
                  </div>
                  {status !== "0" ? (
                    <div className="col-md-4 col-12">
                      <div className="detail-last-inner detail-inner">
                        <h5 className="m-0">
                          <small>Total Cost: </small>
                          <b>INR {totalCost}-/-</b>
                        </h5>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Add Lock Quantity */}

              <Modal
                className="modal-update"
                show={showLockQtyModal}
                onHide={closeLockQtyModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m-0" style={{ color: "#757575" }}>
                    {" "}
                    {productName}{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="assign-vendor">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-12">
                      <div className="product-price">
                        <label>Lock Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          name="lock_quantity"
                          placeholder="Lock Quantity"
                          onKeyDown={handleValidation}
                          value={lock_quantity}
                          onChange={handleChangeLockQty}
                        />
                        <p style={{ color: "red" }}>{lockQtyErr}</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="submit-btn">
                        <button
                          className="btn btn-primary laga do 3"
                          onClick={submitLockQty}
                        >
                          Lock Now
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* // */}

              <Modal
                className="modal-update"
                show={showVendorModal}
                onHide={closeShowEditModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m-0" style={{ color: "#757575" }}>
                    {" "}
                    {productName}{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="assign-vendor on-customize">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="product-price">
                        <label>Vendor:</label>
                        <Select
                          value={selectedOption}
                          onChange={handleVendorChange}
                          styles={selectStyle}
                          name="vendorId"
                          isClearable={true}
                          noOptionsMessage={() => "No vendor found"}
                          options={vendorListData.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))}
                        />
                        {/* <select
                          className="form-control"
                          name="vendorId"
                          onChange={handleChange}
                          value={vendorId}
                        >
                          {vendorListHtml()}
                        </select> */}
                        <p style={{ color: "red" }}>{vendorNameErr}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="product-price">
                        <label>TAX:</label>
                        <input
                          type="text"
                          placeholder="TAX"
                          name="tax"
                          value={tax}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="product-price">
                        <label>HSN Code:</label>
                        <input
                          type="text"
                          placeholder="HSN"
                          name="hsn"
                          value={hsn}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Price:</label>
                        <input
                          type="number"
                          name="product_price"
                          onKeyDown={handleValidation}
                          value={price}
                          onChange={handleChangeVendor}
                          className="form-control"
                          placeholder="Price"
                        />
                        <p style={{ color: "red" }}>{vendorPriceError}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          onKeyDown={handleValidation}
                          name="product_quantity"
                          placeholder="Quantity"
                          value={productQuantity}
                          onChange={handleChangeVendor}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Units</label>
                        <input
                          type="text"
                          className="form-control"
                          name="units"
                          placeholder="Units"
                          value={units}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Specifications</label>
                        <input
                          type="text"
                          className="form-control"
                          name="specifications"
                          placeholder="Specifications"
                          value={vendorSpecifications}
                          onChange={handleChangeVendor}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="product-total-price">
                        <p className="m-0">
                          Total Price(Without GST):
                          <b>
                            {" "}
                            {parseFloat(vendorTotalWithoutGst).toFixed(2)} INR
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="product-total-price">
                        <p className="m-0">
                          Total Price(With GST):{" "}
                          <b> {parseFloat(vendorTotal).toFixed(2)} INR</b>
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-12">
                      <div className="product-price">
                        <label>Total Price:</label>
                        <h5 className="m-0">
                          <b>{vendorTotal} INR</b>
                        </h5>
                      </div>
                    </div> */}
                    <div className="col-md-6 col-12">
                      <div className="product-price d-flex flex-wrap align-items-center form-group"></div>
                    </div>
                    {isAssigned == "N" && (
                      <div className="col-12">
                        <div className="submit-btn">
                          <button
                            className="btn btn-primary laga do 1"
                            onClick={updateVendor}
                          >
                            Place Order
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
              {/* Buy Edit Modal */}
              <Modal
                className="modal-update place-order-vendor-popup buy-vendor"
                show={showBuyModel}
                onHide={closeShowEditModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m-0" style={{ color: "#757575" }}>
                    Buy {productName}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="assign-vendor on-buy">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="form-group place-vendor-inner">
                        <label>Vendor:</label>
                        <Select
                          value={selectedOption}
                          onChange={handleVendorChange}
                          styles={selectStyle}
                          name="vendorId"
                          isClearable={true}
                          noOptionsMessage={() => "No vendor found"}
                          options={vendorListData.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))}
                        />
                        {/* <select
                          className="form-control"
                          value={vendorId}
                          name="vendorId"
                          onChange={handleChange}
                        >
                          {vendorListHtml()}
                        </select> */}
                        <p style={{ color: "red" }}>{buyNameErr}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="product-price">
                        <label>TAX:</label>
                        <input
                          type="text"
                          placeholder="TAX"
                          name="tax"
                          value={tax}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="product-price">
                        <label>HSN Code:</label>
                        <input
                          type="text"
                          placeholder="HSN"
                          name="hsn"
                          value={hsn}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Price:</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Price"
                          onKeyDown={handleValidation}
                          name="materialPrice"
                          value={materialPrice}
                          onChange={handleChangeInput}
                        />
                        <p style={{ color: "red" }}>{buyPriceErr}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Quantity"
                          name="materialQuantity"
                          onKeyDown={handleValidation}
                          disabled
                          value={materialQuantity}
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Units</label>
                        <input
                          type="text"
                          className="form-control"
                          name="units"
                          placeholder="Units"
                          value={units}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Specification</label>
                        <input
                          type="text"
                          className="form-control"
                          name="specifications"
                          placeholder="Specification"
                          value={vendorSpecifications}
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ color: "#ffffff" }}>
                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Net Purchase Value:
                            <b> {parseFloat(totalWithoutGst).toFixed(2)} INR</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Gross Purchase Value:{" "}
                            <b> {parseFloat(total).toFixed(2)} INR</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="placeorder-btn">
                        <button
                          className="btn btn-primary laga do 2"
                          onClick={handleSubmitMaterial}
                        >
                          Placeorder
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <Modal show={isQtyModalOpen} onHide={handleCloseQtyModal}>
              <Modal.Header closeButton>
                <Modal.Title className="m-0" style={{ color: "#757575" }}>
                  Enter Delivering Quantity
                </Modal.Title>
              </Modal.Header>
              <Modal.Body show={isQtyModalOpen} className="assign-vendor">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group place-vendor-inner mb-2">
                      <label>Delivering Quantity</label>

                      <input
                        type="number"
                        value={completedQty}
                        className="form-control"
                        name="completedQty"
                        onChange={handleChangeComplete}
                        onKeyDown={handleValidation}
                        placeholder="Delivering Quantity"
                      />
                      <p>{inWords}</p>
                      {/* <p style={{ color: "green" }}>{inWords}</p> */}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleCloseQtyModal}>
                  Cancel
                </Button>
                <Button onClick={callSubmit} type="submit" variant="primary">
                  Completed The Order
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}
export default CustomerProcessOrderDetails;
