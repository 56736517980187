import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import ReactDatatable from "@mkikets/react-datatable";
import { formatDate, momentObj } from "../utils/dateTime";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
import { formatCurrency } from "../utils/format";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { addDays } from "date-fns";
import { DatepickerCustomRanges } from "../lib/DatePickerCustomRanges";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const config = reactTableConfig("Total Orders");

function TotalOrders() {
  const [records, setRecords] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateShow, setStartDateShow] = useState(null);
  const [endDateShow, setEndDateShow] = useState(null);
  const [currantMonthSales, setCurrantMonthSales] = useState(0);
  const [showPicker, setShowPicker] = useState(false);
  const buttonRef = useRef(null);
  const datePickerRef = useRef(null);
  const [state, setDateSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { isComplete: "completed" };
  }, []);

  const { data, loading, respData } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data && respData) {
      setRecords(data);
      setCurrantMonthSales(respData.currantMonthSales);
    }
  }, [data, respData]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order Id",
      className: "ao",
      align: "left",
      sortable: true,
      cell: (record) => record.id,
    },
    {
      key: "name",
      text: "Sales Person",
      className: "phoneno",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "phoneno",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },

    {
      key: "quantity",
      text: "Quantity",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },

    {
      key: "price",
      text: "Price Per Unit",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },

    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> INR {record.amount}</Fragment>;
      },
    },

    {
      key: "status",
      text: "Status",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.status === "1" ? "Inprocess" : ""}{" "}
            {record.status === "0" ? "Pending" : ""}{" "}
            {record.status === "5" ? "Rejected" : ""}{" "}
            {record.status === "6" ? "Completed" : ""}{" "}
          </Fragment>
        );
      },
    },

    {
      key: "Detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.status === "1" ? (
              <a href={"/inprocess-order-details/" + btoa(record.id)}>
                <img
                  src="assets/images/view-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
            ) : (
              ""
            )}{" "}
            {record.status === "0" ? (
              <a href={"/pending-order-details/" + btoa(record.id)}>
                <img
                  src="assets/images/view-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
            ) : (
              ""
            )}{" "}
            {record.status === "6" ? (
              <a href={"/process-order-details/" + btoa(record.id)}>
                <img
                  src="assets/images/view-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
            ) : (
              ""
            )}{" "}
          </Fragment>
        );
      },
    },
  ];

  const calculateSalesSum = (startDate, endDate) => {
    let totalSalesWithGST = 0;
    let gst = 0;
    let filteredRecord = [];
    for (const sale of data) {
      const saleDate = momentObj(sale.createdAt);
      if (
        saleDate.isSameOrAfter(startDate) &&
        saleDate.isSameOrBefore(endDate)
      ) {
        totalSalesWithGST += sale.amount;
        gst += sale.gstAmount;
        filteredRecord.push(sale);
      } else if (
        saleDate.isSame(startDate, "day") ||
        saleDate.isSame(endDate, "day")
      ) {
        // Include sales that are on the same day as the start or end date
        totalSalesWithGST += sale.amount;
        gst += sale.gstAmount;
        filteredRecord.push(sale);
      }
    }
    setRecords(filteredRecord);
    return totalSalesWithGST - gst;
  };

  const handleSalesSum = () => {
    if (!startDate) {
      showError("Start date is required");
      return;
    }

    if (!endDate) {
      showError("End date is required");
      return;
    }

    if (startDate > endDate) {
      showError("Please enter a valid start date");
      return;
    }

    const startDateMoment = momentObj(startDate);
    const endDateMoment = momentObj(endDate);
    const totalSales = calculateSalesSum(startDateMoment, endDateMoment);

    setStartDateShow(startDateMoment);
    setEndDateShow(endDateMoment);
    setCurrantMonthSales(totalSales);
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setStartDateShow("");
    setEndDateShow("");
    setCurrantMonthSales(respData.currantMonthSales);
    setRecords(data);
  };

  const showError = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const handleDateChanges = ([date]) => {
    const { startDate, endDate } = date;
    setStartDate(startDate);
    setEndDate(endDate);
    setDateSelection([date]);
  };

  const handleOutsideClick = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      // Click occurred outside the date picker
      if (buttonRef.current && buttonRef.current.contains(e.target)) {
        // Click occurred on the button, do not close the date picker
        return;
      }
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPicker]);

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>TOTAL ORDERS</b>
              </h2>
            </div>
            <div className="invent-form">
              {showPicker && (
                <div ref={datePickerRef} className=" date-picker-local1">
                  <DateRangePicker
                    onChange={(item) => handleDateChanges([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    editableDateInputs={true}
                    maxDate={new Date()}
                    months={2}
                    ranges={state}
                    rangeColors={["#333333", "#333333", "#333333"]}
                    direction="horizontal"
                    staticRanges={[
                      ...defaultStaticRanges,
                      ...DatepickerCustomRanges,
                    ]}
                  />
                </div>
              )}
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Select Date Range</label>
                    <button
                      className="date-btn btn"
                      ref={buttonRef}
                      onClick={() => setShowPicker(!showPicker)}
                    >
                      {startDate ? "Order date: " : "Select Date"}
                      <span style={{ fontSize: "12px" }}>
                        {startDate &&
                          formatDate(startDate, "MMM D, YYYY") + "-"}
                        {endDate && formatDate(endDate, "MMM D, YYYY")}
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-1 col-md-4 col-12 align-self-end">
                  <button className="btn btn-primary" onClick={handleSalesSum}>
                    Search
                  </button>
                </div>
                <div className="col-lg-1 col-md-4 col-12 align-self-end">
                  <button className="btn btn-primary" onClick={handleClear}>
                    Clear
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-12 align-self-center">
                  <div>
                    <h5 className="section-heading m-0">
                      {startDateShow ? "Order date: " : "This month sales"}
                      {startDateShow &&
                        formatDate(startDateShow, "MMM D, YYYY") + "-"}{" "}
                      {endDateShow && formatDate(endDateShow, "MMM D, YYYY")}:{" "}
                      {formatCurrency(currantMonthSales)}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    className="table table-bordered table-striped"
                    config={config}
                    records={records}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default TotalOrders;
