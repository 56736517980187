import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import * as myConstList from "./BaseUrl";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Reminders");

const Reminders = () => {
  const [record, setRecord] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    reminderData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr. No.",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "leadId",
      text: "Lead Id",
      className: "leadId",
      align: "left",
      sortable: true,
    },

    {
      key: "name",
      text: "Reminder Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "reminder_date",
      text: "Pre Reminder Date & Time",
      className: "reminder_date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{moment(record.reminder_date).format("lll")}</Fragment>
        );
      },
    },

    {
      key: "date",
      text: "Reminder Date & Time",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.date).format("lll")}</Fragment>;
      },
    },

    {
      key: "createdAt",
      text: "Created Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.createdAt).format("lll")}</Fragment>;
      },
    },

    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              title=" Edit"
              onClick={() => {
                window.location.href = "/reminders-edit/" + btoa(record.id);
              }}
            >
              <FaEdit />
            </button>

            <button
              title="Delete"
              style={{ marginRight: "5px" }}
              className="btn btn-danger btn-sm img-fluid"
              onClick={() => {
                Conn(record);
              }}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const reminderData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      userType: "SalesManager",
    };

    axios
      .post(baseUrl + "/frontapi/reminder-data", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          // toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
          setFilterData(resp.data);
        }
      });
  };
  const deleted = (id) => {
    axios
      .post(baseUrl + "/frontapi/reminder-delete", { id: id })
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === true) {
          toast.success(resp.message);
          reminderData();
          return false;
        }
        toast.error(resp.message);
      });
  };

  const handleChangeFilter = (e) => {
    let value = e.target.value;
    setFilterValue(value);

    if (value === "") {
      setFilterData(record);
      return false;
    } else {
      const date = new Date(); // Assuming you have a date object
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so we add 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = date.getHours();
      const minutes = date.getMinutes();

      const currentDate = `${year}-${month}-${day}-${hours}-${minutes}`;

      if (value === "Past") {
        const filteredData = record.filter((item) => {
          const enteredDate = new Date(item.date); // Assuming you have a date object
          const enteredYear = enteredDate.getFullYear();
          const enteredMonth = String(enteredDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Month starts from 0, so we add 1
          const enteredDay = String(enteredDate.getDate()).padStart(2, "0");
          const enteredHours = enteredDate.getHours();
          const enteredMinutes = enteredDate.getMinutes();

          const enteredFormattedDate = `${enteredYear}-${enteredMonth}-${enteredDay}-${enteredHours}-${enteredMinutes}`;

          return currentDate > enteredFormattedDate;
        });
        setFilterData(filteredData);
      }

      if (value === "Upcoming") {
        const filteredData = record.filter((item) => {
          const enteredDate = new Date(item.date); // Assuming you have a date object
          const enteredYear = enteredDate.getFullYear();
          const enteredMonth = String(enteredDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Month starts from 0, so we add 1
          const enteredDay = String(enteredDate.getDate()).padStart(2, "0");
          const enteredHours = enteredDate.getHours();
          const enteredMinutes = enteredDate.getMinutes();

          const enteredFormattedDate = `${enteredYear}-${enteredMonth}-${enteredDay}-${enteredHours}-${enteredMinutes}`;

          return currentDate < enteredFormattedDate;
        });
        setFilterData(filteredData);
      }
    }
  };

  const filterComponent = () => {
    return (
      <div className="add-product-btn text-center">
        <h4>Filter By Date</h4>
        <select
          className="form-control"
          value={filterValue}
          onChange={handleChangeFilter}
        >
          <option value="">All</option>
          <option value="Past">Past</option>
          <option value="Upcoming">Upcoming</option>
        </select>
      </div>
    );
  };

  return (
    <ContentLayout
      title="Reminder List"
      buttonTitle="Add Reminder"
      buttonUrl="/reminders-add"
      className="product-list-outer customer-list-outer"
      ExtraComponent={filterComponent}
    >
      <ReactDatatable
        className="table table-bordered table-striped "
        config={config}
        records={filterData}
        columns={columns}
      />
    </ContentLayout>
  );
};

export default Reminders;
