import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import moment from "moment";
const baseUrl = myConstList.baseUrl;

const DeliveredOrderDetails = () => {
  const [orderId, setOrderId] = useState("");
  const [record, setRecord] = useState([]);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    setOrderId(Params);
  }, []);

  useEffect(() => {
    async function fetchDeliveryData() {
      const data = { orderId : Params };
      
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        };

        const { data: resp } = await axios.post(
          baseUrl + "/frontapi/delivery-details",
          data,
          config
        );

        if (resp.status) {
          // toast.dismiss();
          // toast.success(resp.message);
          setRecord(resp.data);
        } else {
          // toast.dismiss();
          // toast.error(resp.message);
          setRecord(resp.data);
        }
      } catch (error) {
      
        toast.dismiss();
        toast.error(error.message);
      }
    }
    fetchDeliveryData();
  }, []);

  const tableHtml = () => {
    const html = [];
    record.map((value, i) => {
      
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.product_name}</th>
          <th>
            {value.name}
          </th>
          <th>
            {value.order_id}
          </th>    
          <th>
            {value.delivered_quantity}
          </th>       
          <th>
            {moment(value.createAt).format("lll")}
          </th>       
        </tr>
      );
    });
    return html;
  };

  const totalQuantitySum = () => {
    // orderId,orderItemId
    let sum = 0;
    record.map((value) => {
      return (sum += value.delivered_quantity);
    });
    return sum;
  };
  

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="manager-heading-outer section-heading">
                <h2>
                  <b>DELIVERY ORDER DETAIL</b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id: <b>{orderId}</b>
                      </p>
                    </div>
                  </div>                                
                </div>
              </div>
              <div className="order-details-status">
                <div className="row">
                  <div className="col-12">
                    <div className="order-detail-outer-middle pending-list-outer">
                      <h3>Order Details</h3>
                      <table className="w-100 table-responsive">
                        <tr>
                          <th>S.No</th>
                          <th>Products</th>
                          <th>Customer</th>
                          <th>Order Id</th>
                          <th>Sent Quantity</th>
                          <th>Date</th>                          
                        </tr>
                        {tableHtml()}
                        <tr align="right">
                          <td colSpan="13">
                            <b>Total Quantity Sent : </b>{totalQuantitySum()}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default DeliveredOrderDetails;
