import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { useEffect } from "react";
const baseUrl = myConstList.baseUrl;

function AddCustomerDocuments() {
  const [customerData, setCustomerData] = useState([]);
  const [docTypeData, setDocTypeData] = useState([]);
  const [custId, setCustId] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [documentFileName, setDocumentFileName] = useState("");
  const [customerErr, setCustomerErr] = useState("");
  const [documentTypeErr, setDocumentTypeErr] = useState("");
  const [documentFileErr, setDocumentFileErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    customerDataApi();
    documentDataApi();
  }, []);

  const customerDataApi = () => {
    const data = {
      userType: localStorage.getItem("userType"),
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios.post(baseUrl + "/frontapi/get-customer", data, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setCustomerData(resp.data);
      }
    });
  };

  const customerHtml = () => {
    const customerhtml = [];
    var CustData = customerData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CustData.map((value, i) => {
      return customerhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return customerhtml;
  };

  const documentDataApi = () => {
    axios
      .get(baseUrl + "/frontapi/document-type-data-deleted", {})
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setDocTypeData(resp.data);
        }
      });
  };

  const documentHtml = () => {
    const dochtml = [];
    var docData = docTypeData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    docData.map((value, i) => {
      return dochtml.push(<option value={value.id}>{value.name}</option>);
    });
    return dochtml;
  };

  const handleChangeAll = async (event) => {
    let { name, value } = event.target;

    if (name === "documentType") {
      setDocumentType(value);
      if (!value) {
        setDocumentTypeErr("Please select document type");
        return false;
      }
      setDocumentTypeErr("");
    }

    if (name === "custId") {
      setCustId(value);
      if (!value) {
        setCustomerErr("Please select customer");
        return false;
      }
      setCustomerErr("");
    }

    if (name === "document") {
      let ImagesArray = Object.entries(event.target.files).map((e) =>
        URL.createObjectURL(e[1])
      );

      var temp_doc = event.target.files[0];
      // setDocumentFileName("");
      if (temp_doc === false) {
        setDocumentFileErr("Please select document");
        return false;
      }

      if (!temp_doc.name.match(/\.(jpg|jpeg|png|pdf|doc)$/)) {
        setDocumentFileErr("Please select valid input jpg,jpeg,png,pdf,doc");
        return false;
      }

      if (temp_doc.size >= 3e6) {
        setDocumentFileErr("Document size should not be more than 3MB");
        return false;
      }

      setDocumentFileName([...documentFileName, ...ImagesArray]);
      setDocumentFile([...documentFile, event.target.files]);

      setDocumentFileErr("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!custId) {
      setCustomerErr("Please select customer");
      return false;
    }

    if (!documentType) {
      setDocumentTypeErr("Please select document type");
      return false;
    }

    console.log("documentFile", documentFile);

    if (!documentFile || documentFile.length < 0) {
      setDocumentFileErr("Please select document");
      return false;
    }

    if (documentFile.length === 0) {
      for (let i = 0; i < documentFile.length; i++) {
        const element = documentFile[i][0];

        if (!element.name.match(/\.(jpg|jpeg|png|pdf|doc)$/)) {
          setDocumentFileErr(
            "Please select valid document jpg,jpeg,png,pdf,doc"
          );
          return false;
        }

        if (element.size >= 3e6) {
          setDocumentFileErr("Document size should not be more than 3MB");
          return false;
        }
      }
    }

    setLoading(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const formData = new FormData();

    for (const key of Object.keys(documentFile)) {
      formData.append("DocumentFile", documentFile[key][0]);
    }

    formData.append("custId", custId);
    formData.append("documentType", documentType);
    formData.append("userType", localStorage.getItem("userType"));

    axios
      .post(baseUrl + "/frontapi/customer-document-add", formData, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === false) {
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(function () {
            window.location = "/customer-document";
          }, 2000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  const showImages = () => {
    const html = [];

    if (documentFile.length > 0) {
      documentFile.map((element, index) => {
        return html.push(
          <div className="imageSize" key={element}>
            <b>{element[0].name}</b>
            {/* <img className="uploaded-images" src={element} alt="" /> */}
            <span
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(index)}
            >
              X
            </span>
          </div>
        );
      });
    }
    return html;
  };

  const removeItem = (element) => {
    const s = documentFileName.filter((item, index) => index !== element);
    const indexToRemove = element; // The index of the file to remove
    let filesArray = Array.from(documentFile); // Convert FileList to an array
    filesArray.splice(indexToRemove, 1); // Remove the file from the array
    setDocumentFile(filesArray);
    setDocumentFileName(s);
  };

  return (
    <ContentLayout
      title="ADD Customer Document"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label>Customer</label>
              <select
                type="text"
                name="custId"
                value={custId}
                placeholder=""
                className="form-control"
                onChange={handleChangeAll}
              >
                <option value="">Select Customer</option>
                {customerHtml()}
              </select>
              <p style={{ color: "red" }}> {customerErr} </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label>Document Type</label>
              <select
                type="text"
                name="documentType"
                value={documentType}
                placeholder=""
                className="form-control"
                onChange={handleChangeAll}
              >
                <option value="">Select Document Type</option>
                {documentHtml()}
              </select>
              <p style={{ color: "red" }}>{documentTypeErr}</p>
            </div>
          </div>
          <div className="form-group mb-0">
            <label>Document</label>
          </div>

          <div className="form-group file-upload position-relative">
            <div className="">
              <input
                type="file"
                name="document"
                placeholder=""
                onChange={handleChangeAll}
                // accept="pdf"
                className="profile_input"
              />
            </div>
          </div>
          <p style={{ color: "red" }}>{documentFileErr}</p>
        </div>

        <div className="row">
          <div id="uploadedImages">{showImages()}</div>
        </div>
      </form>

      <ButtonGroup
        onClick={handleSubmit}
        link="/customer-document"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default AddCustomerDocuments;
