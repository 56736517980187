import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import { selectStyleLight } from "../Hooks/SelectStyle";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import { reactTableConfig } from "../lib/reactTableConfig";
import Footer from "./Footer";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("inventory");

const InventoryInOut = () => {
  const [record, setRecord] = useState([]);
  const [tempRecord, setTempRecord] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [prodId, setProdId] = useState("all");
  const [status, setStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [user, setUser] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("userType");
    setUser(user);
  }, []);

  useEffect(() => {
    fetchInventoryData();
    productData();
  }, []);

  const fetchInventoryData = useCallback(async () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const {
      data: { status, data },
    } = await axios.post(baseUrl + "/frontapi/inventory-in-out", {}, config);
    if (status) {
      setRecord(data);
      setTempRecord(data);
    } else {
      // toast.dismiss();
      // toast.error(message);
      return;
    }
  }, [record]);

  const productData = useCallback(() => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          const sortByName = resp.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setProdData(sortByName);
        }
      });
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "order_id",
      text: "Order ID",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.order_id}{" "}
            {record.sub_order_id ? `-Sub(${record.sub_order_id})` : ""}
          </Fragment>
        );
      },
    },
    // {
    //   key: "name",
    //   text: "Product Name",
    //   className: "name",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "quantity",
      text: "Quantity",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.quantity}</Fragment>;
      },
    },
    {
      key: "opening_quantity",
      text: "Opening Quantity",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.opening_balance}</Fragment>;
      },
    },
    {
      key: "closing_quantity",
      text: "Closing Quantity",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.closing_balance}</Fragment>;
      },
    },
    {
      key: "product_quantity",
      text: "Product Quantity",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.product_quantity}</Fragment>;
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.created_on).format("lll")} </Fragment>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "status inventory-in-out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span
              style={{
                color: record.status.startsWith("in") ? "#66FF00" : "red",
              }}
            >
              {record.status}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "customer",
      text: "Customer",
      className: "status inventory-in-out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.customer_name ? record.customer_name : "N/A"}
          </Fragment>
        );
      },
    },
    {
      key: "vendor",
      text: "Vendor",
      className: "status inventory-in-out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{record.vendor_name ? record.vendor_name : "N/A"}</Fragment>
        );
      },
    },
    {
      key: "type",
      text: "Type",
      className: "status inventory-in-out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.type}</Fragment>;
      },
    },
  ];

  const handleChangeAll = (e) => {
    let { name, value } = e.target;

    if (name === "startDate") {
      setStartDate(value);
    }

    if (name === "endDate") {
      setEndDate(value);
    }

    if (name === "status") {
      setStatus(value);
    }
  };

  const handleVendorChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedOption(selectedOption);
      setProdId("all");
      return;
    }
    setSelectedOption(selectedOption);
    setProdId(selectedOption.value);
  };

  useEffect(() => {
    submitSearch();
  }, [prodId, startDate, endDate, status]);

  const submitSearch = (e) => {
    if (!startDate && endDate) {
      toast.dismiss();
      toast.error("Start date is required");
      return false;
    }

    if (endDate !== "" && startDate > endDate) {
      toast.dismiss();
      toast.error("Please enter valid start date");
      return false;
    }

    if (!startDate && !endDate && (prodId == "all" || !prodId)) {
      // fetchInventoryData();
      if (status === "all") {
        setRecord(tempRecord);
      } else {
        let statusData = tempRecord.filter((item) => item.status === status);
        setRecord(statusData);
      }
    } else {
      let prodTypehtml = [];
      let dateTypeHtml = [];

      if (prodId !== "all") {
        for (let i = 0; i < tempRecord.length; i++) {
          const element = tempRecord[i];

          if (status === "all") {
            if (element.product_id == prodId) {
              prodTypehtml.push(element);
            }
          }

          if (element.product_id == prodId && element.status === status) {
            prodTypehtml.push(element);
          }
        }

        if (endDate) {
          for (let j = 0; j < prodTypehtml.length; j++) {
            const value = prodTypehtml[j];

            // Your date string from the database
            const dateString = value.created_on;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (
              outputDateFormatted >= startDate &&
              outputDateFormatted <= endDate
            ) {
              dateTypeHtml.push(value);
            }
          }

          setRecord(dateTypeHtml);
        } else if (startDate && !endDate) {
          for (let k = 0; k < prodTypehtml.length; k++) {
            const value = prodTypehtml[k];

            // Your date string from the database
            const dateString = value.created_on;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (outputDateFormatted >= startDate) {
              dateTypeHtml.push(value);
            }
          }
          setRecord(dateTypeHtml);
        } else {
          setRecord(prodTypehtml);
        }
      } else {
        if (endDate) {
          for (let l = 0; l < tempRecord.length; l++) {
            const value = tempRecord[l];

            // Your date string from the database
            const dateString = value.created_on;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (
              outputDateFormatted >= startDate &&
              outputDateFormatted <= endDate
            ) {
              dateTypeHtml.push(value);
            }
          }
          if (status === "all") {
            setRecord(dateTypeHtml);
          } else {
            let statusData = dateTypeHtml.filter(
              (item) => item.status === status
            );
            setRecord(statusData);
          }
        } else if (startDate && !endDate) {
          for (let j = 0; j < tempRecord.length; j++) {
            const value = tempRecord[j];

            // Your date string from the database
            const dateString = value.created_on;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (outputDateFormatted >= startDate) {
              dateTypeHtml.push(value);
            }
          }
          if (status === "all") {
            setRecord(dateTypeHtml);
          } else {
            let statusData = dateTypeHtml.filter(
              (item) => item.status === status
            );
            setRecord(statusData);
          }
        }
      }
    }
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between mb-4">
              <h2>
                <b>Inventory In-Out</b>
              </h2>
              {user === "ADMIN" && (
                <div className="add-product-btn text-center">
                  <Link to={"/add-inventory"} className="btn btn-primary">
                    Add Inventory
                  </Link>
                </div>
              )}
            </div>
            <div className="invent-form">
              <div className="row align-items-end">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Products</label>
                    <Select
                      value={selectedOption}
                      onChange={handleVendorChange}
                      styles={selectStyleLight}
                      name="prodId"
                      className="custom-select"
                      isClearable={true}
                      noOptionsMessage={() => "No vendor found"}
                      options={prodData.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={handleChangeAll}
                      value={status}
                    >
                      <option value="all">All</option>
                      <option value="in">In</option>
                      <option value="out">Out</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Start Date</label>

                    <input
                      type="date"
                      name="startDate"
                      placeholder=" Start Date"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={startDate}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      placeholder=" End Date"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={endDate}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <button className="btn btn-primary" onClick={submitSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    // className="table table-bordered table-striped table-responsive"
                    // onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default InventoryInOut;
