import {
  addDays,
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
} from "date-fns";

export const DatepickerCustomRanges = [
  {
    label: "Quarter 1 (Apr-Jun)",
    range() {
      return {
        startDate: new Date(new Date().getFullYear(), 3, 1),
        endDate: new Date(new Date().getFullYear(), 5, 30),
      };
    },
    isSelected(range) {
      // Define your custom isSelected logic for this range
      // For example:
      return (
        range.startDate.getTime() === this.range().startDate.getTime() &&
        range.endDate.getTime() === this.range().endDate.getTime()
      );
    },
  },
  {
    label: "Quarter 2 (Jul-Sep)",
    range() {
      return {
        startDate: new Date(new Date().getFullYear(), 6, 1),
        endDate: new Date(new Date().getFullYear(), 8, 30),
      };
    },
    isSelected(range) {
      // Define your custom isSelected logic for this range
      // For example:
      return (
        range.startDate.getTime() === this.range().startDate.getTime() &&
        range.endDate.getTime() === this.range().endDate.getTime()
      );
    },
  },
  {
    label: "Quarter 3 (Oct-Dec)",
    range() {
      return {
        startDate: new Date(new Date().getFullYear(), 9, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
      };
    },
    isSelected(range) {
      // Define your custom isSelected logic for this range
      // For example:
      return (
        range.startDate.getTime() === this.range().startDate.getTime() &&
        range.endDate.getTime() === this.range().endDate.getTime()
      );
    },
  },
  {
    label: "Quarter 4 (Jan-Mar)",
    range() {
      return {
        startDate: new Date(new Date().getFullYear() + 1, 0, 1), // Always use the next year
        endDate: new Date(new Date().getFullYear() + 1, 2, 31),
      };
    },
    isSelected(range) {
      // Define your custom isSelected logic for this range
      // For example:
      return (
        range.startDate.getTime() === this.range().startDate.getTime() &&
        range.endDate.getTime() === this.range().endDate.getTime()
      );
    },
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1)),
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: "This Year",
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfDay(new Date()),
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
];
