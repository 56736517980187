import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import Footer from "./Footer";
import axios from "axios";
import { DashboardCard } from "./DashboardCard";
import { baseUrl } from "./BaseUrl";
import { formatCurrency } from "../utils/format";

function Dashboard() {
  const [customer, setCustomer] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [totalOrderCat, setTotalOrderCat] = useState("");
  const [totalOrderOPS, setTotalOrderOPS] = useState("");
  const [products, setProducts] = useState("");
  const [completedOrder, setCompletedOrder] = useState("");
  const [pendingOrder, setPendingOrder] = useState("");
  const [vendorPendingOrder, setVendorPendingOrder] = useState("");
  const [vendorRejectedOrder, setVendorRejectedOrder] = useState("");
  const [totalSales, setTotalSales] = useState(null);
  const [leads, setLeads] = useState("");

  const [inProcessOrder, setInProcessOrder] = useState("");
  const [vendor, setVendor] = useState("");
  const [vendorCompleted, setVendorCompleted] = useState("");
  const [vendorInprocess, setVendorInprocess] = useState("");
  const [categories, setCategories] = useState("");
  const [catManager, setCatManager] = useState("");
  const [subCategories, setSubCategories] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [OPSExecutive, setOPSExecutive] = useState("");
  const [userType, setUserType] = useState("");
  const [loading, setLoading] = useState(false);

  if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      window.location.href = "/";
      localStorage.clear();
    }
  }
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/getDashboardData", {}, config)
      .then((res) => {
        var resp = res.data;
        setUserType(resp.data.userData && resp.data.userData[0].userType);
        setVendorPendingOrder(
          resp.data.vendorPendingOrder &&
            resp.data.vendorPendingOrder[0]["COUNT(id)"]
        );
        setVendorCompleted(
          resp.data.vendorCompleted && resp.data.vendorCompleted[0]["COUNT(id)"]
        );
        setVendorInprocess(
          resp.data.vendorInprocess && resp.data.vendorInprocess[0]["COUNT(id)"]
        );
        setVendorRejectedOrder(
          resp.data.vendorRejectedOrder &&
            resp.data.vendorRejectedOrder[0]["COUNT(id)"]
        );
        setInProcessOrder(
          resp.data.inProcessOrder && resp.data.inProcessOrder[0]["COUNT(id)"]
        );
        setPendingOrder(
          resp.data.pendingOrder && resp.data.pendingOrder[0]["COUNT(id)"]
        );
        setCompletedOrder(
          resp.data.completedOrder && resp.data.completedOrder[0]["COUNT(id)"]
        );
        setTotalOrderCat(
          resp.data.totalOrder && resp.data.totalOrder[0]["COUNT(id)"]
        );
        setTotalOrderOPS(
          resp.data.totalOrder && resp.data.totalOrder[0]["COUNT(id)"]
        );
        setTotalSales(resp.data.monthlySales && resp.data.monthlySales);
        setCustomer(resp.data.customers && resp.data.customers[0]["COUNT(id)"]);
        setTotalOrder(
          resp.data.totalOrder && resp.data.totalOrder[0]["COUNT(id)"]
        );
        setProducts(
          resp.data.totalProducts && resp.data.totalProducts[0]["COUNT(id)"]
        );
        setVendor(resp.data.vendors && resp.data.vendors[0]["COUNT(id)"]);
        setCategories(
          resp.data.categories && resp.data.categories[0]["COUNT(id)"]
        );
        setSubCategories(
          resp.data.subCategories && resp.data.subCategories[0]["COUNT(id)"]
        );
        setCatManager(
          resp.data.catManager && resp.data.catManager[0]["COUNT(id)"]
        );
        setOPSExecutive(
          resp.data.opsExecutive && resp.data.opsExecutive[0]["COUNT(id)"]
        );
        setSalesPerson(
          resp.data.salesPerson && resp.data.salesPerson[0]["COUNT(id)"]
        );
        setLeads(resp.data.totalLeads && resp.data.totalLeads[0]["COUNT(id)"]);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div id="layout-wrapper">
        <div className="main-content" id="sometoggler">
          <div className="page-content">
            <div className="container-fluid p-0">
              {loading ? (
                <div className="row gap">
                  {new Array(12).fill(1).map(() => (
                    <div className="col-sm-6 col-md-3" aria-hidden="true">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title placeholder-glow">
                            <span className="placeholder col-6"></span>
                          </h5>
                          <p className="card-text placeholder-glow">
                            <span className="placeholder col-7"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-6"></span>
                            <span className="placeholder col-8"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="row">
                  {userType === "ADMIN" && (
                    <>
                      <DashboardCard
                        title="This Month Sales"
                        data={formatCurrency(totalSales)}
                        cls="success"
                        url="/all-sales"
                        type="cart"
                      />
                      <DashboardCard
                        title="Total Orders"
                        data={totalOrder}
                        cls="warning"
                        url="/total-orders"
                        type="hand"
                      />
                      <DashboardCard
                        title="Pending Order"
                        data={pendingOrder}
                        cls="danger"
                        url="/pending-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Inprocess Order"
                        data={inProcessOrder}
                        cls="warning"
                        url="/inprocess-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Completed Order"
                        data={completedOrder}
                        cls="success"
                        url="/order-completed"
                        type="cart"
                      />
                      <DashboardCard
                        title="Vendor Pending Order"
                        data={vendorPendingOrder}
                        cls="danger"
                        url="/vendor-pending-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Vendor Inprocess Order"
                        data={vendorInprocess}
                        cls="warning"
                        url="/process-order"
                        type="box"
                      />
                      <DashboardCard
                        title="Vendor Completed Order"
                        data={vendorCompleted}
                        cls="success"
                        url="/vendor-order-completed"
                        type="box"
                      />
                      <DashboardCard
                        title="Vendor Rejected Order"
                        data={vendorRejectedOrder}
                        cls="danger"
                        url="/vendor-rejected-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Products"
                        data={products}
                        cls="secondary"
                        url="/products"
                        type="box"
                      />
                      <DashboardCard
                        title="Category"
                        data={categories}
                        cls="secondary"
                        url="/categories"
                        type="box"
                      />
                      <DashboardCard
                        title="Sub Category"
                        data={subCategories}
                        cls="success"
                        url="/sub-categories"
                        type="box"
                      />
                      <DashboardCard
                        title="Vendors"
                        data={vendor}
                        cls="secondary"
                        url="/vendor"
                        type="box"
                      />
                      <DashboardCard
                        title="Customers"
                        data={customer}
                        cls="primary"
                        url="/customer"
                        type="customer"
                      />
                      <DashboardCard
                        title="Sales Person"
                        data={salesPerson}
                        cls="secondary"
                        url="/sales-person"
                        type="box"
                      />
                      <DashboardCard
                        title="Category Manager"
                        data={catManager}
                        cls="primary"
                        url="/category-manager"
                        type="box"
                      />
                      <DashboardCard
                        title="Operation Executive"
                        data={OPSExecutive}
                        cls="warning"
                        url="/operation-executive"
                        type="box"
                      />
                    </>
                  )}

                  {(userType === "oprationExecutive" ||
                    userType === "CategoryManager") && (
                    <>
                      {userType === "CategoryManager" && (
                        <DashboardCard
                          title="This Month Sales"
                          data={formatCurrency(totalSales)}
                          cls="success"
                          url="/all-sales-catman"
                          type="cart"
                        />
                      )}
                      <DashboardCard
                        title="Total Orders"
                        data={
                          userType === "oprationExecutive"
                            ? totalOrderOPS
                            : totalOrderCat
                        }
                        cls="warning"
                        url="/total-orders"
                        type="hand"
                      />
                      <DashboardCard
                        title="Inprocess Order"
                        data={inProcessOrder}
                        cls="warning"
                        url="/inprocess-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Completed Order"
                        data={completedOrder}
                        cls="success"
                        url="/order-completed"
                        type="cart"
                      />
                      <DashboardCard
                        title="Vendor Pending Order"
                        data={vendorPendingOrder}
                        cls="danger"
                        url="/vendor-pending-orders"
                        type="cart"
                      />
                      <DashboardCard
                        title="Vendor Inprocess Order"
                        data={vendorInprocess}
                        cls="warning"
                        url="/process-order"
                        type="box"
                      />
                      <DashboardCard
                        title="Vendor Completed Order"
                        data={vendorCompleted}
                        cls="success"
                        url="/vendor-order-completed"
                        type="box"
                      />
                      <DashboardCard
                        title="Vendor Rejected Order"
                        data={vendorRejectedOrder}
                        cls="danger"
                        url="/vendor-rejected-orders"
                        type="cart"
                      />
                    </>
                  )}

                  {userType === "SalesManager" && (
                    <>
                      <DashboardCard
                        title="Leads"
                        data={leads}
                        cls="danger"
                        url="/leads"
                        type="cart"
                      />
                      <DashboardCard
                        title="Sales Person"
                        data={salesPerson}
                        cls="warning"
                        url="/sales-person"
                        type="hand"
                      />
                      <DashboardCard
                        title="Customers"
                        data={customer}
                        cls="warning"
                        url="/customers"
                        type="hand"
                      />
                      <DashboardCard
                        title="This Month Sales"
                        data={formatCurrency(totalSales)}
                        cls="success"
                        url="/all-sales"
                        type="cart"
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
