import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Sales Person");

function SalesPerson(props) {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    SalesPersonData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Sales Person Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "phoneNumber",
      text: "Mobile No.",
      className: "phoneno",
      align: "left",
      sortable: true,
    },

    {
      key: "salesManager",
      text: "Sales Manager",
      className: "salesManager",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href =
                  localStorage.getItem("userType") === "SalesManager"
                    ? "/sales-person-manager-edit/" + btoa(record.id)
                    : "/sales-person-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>

            {record.is_deleted == "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const SalesPersonData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/sales-person-data", SalesPersonData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status == true) {
          const sort = resp?.data?.sort((a, b) => b.name.localeCompare(a.name));
          setRecord(sort);
        }
      });
  };
  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }
    let params = {
      id: id,
      status: temp,
    };
    axios
      .post(baseUrl + "/frontapi/sales-person-delete", params)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return false;
        }
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          SalesPersonData();
        }
      });
  };

  return (
    <ContentLayout
      title="Sales Person List"
      buttonTitle="Add Sales Person"
      buttonUrl={
        localStorage.getItem("userType") === "SalesManager"
          ? "/add-sales-person-manager"
          : "/add-sales-person"
      }
      className="product-list-outer customer-list-outer"
    >
      <ReactDatatable
        className="table table-bordered table-striped "
        config={config}
        records={record}
        columns={columns}
      />
    </ContentLayout>
  );
}
export default SalesPerson;
