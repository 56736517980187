import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function AddCampaign() {
  const [loading, setLoading] = useState(false);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [image, setImage] = useState([]);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");

  // errors //
  const [imageErr, setImageErr] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");

  const handleChangeAll = async (event) => {
    let { name, value } = event.target;
    if (name === "title") {
      setTitle(value);
      if (!value) {
        setTitleErr("Please enter name");
        return false;
      }
      setTitleErr("");
    }

    if (name === "description") {
      setDescription(value);
      if (!value) {
        setDescriptionErr("Please enter description");
        return false;
      }
      setDescriptionErr("");
    }

    if (name === "startDate") {
      setStartDate(value);
      if (!value) {
        setStartDateErr("Please select category");
        return false;
      } else {
        // productDataFunc(value);
      }
      setStartDateErr("");
    }

    if (name === "endDate") {
      setEndDate(value);
      if (!value) {
        setEndDateErr("Please select assign to");
        return false;
      }
      setEndDateErr("");
    }

    if (name === "image") {
      let ImagesArray = Object.entries(event.target.files).map((e) =>
        URL.createObjectURL(e[1])
      );

      var temp_doc = event.target.files[0];

      if (!temp_doc) {
        setImage("");
        return;
      }

      if (temp_doc) {
        if (!temp_doc.name.match(/\.(jpg|jpeg|png)$/)) {
          setImageErr("Please select valid input jpg,jpeg,png");
          return false;
        }

        if (temp_doc.size >= 3e6) {
          setImageErr("Document size should not be more than 3MB");
          return false;
        }
      }

      setShowSelectedImages([...showSelectedImages, ...ImagesArray]);
      setImage([...image, event.target.files]);
      setImageErr("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = "";

    if (!title) {
      setTitleErr("Please enter name");
      return false;
    }

    error = checkSpace("title", title);
    if (error) {
      setTitleErr(error);
      return false;
    }

    if (!startDate) {
      setStartDateErr("Please enter start date");
      return false;
    }

    if (!endDate) {
      setEndDateErr("Please enter end date");
      return false;
    }

    if (!description) {
      setDescriptionErr("Please enter description");
      return false;
    }

    // error = checkSpace("description", description);
    // if (error) {
    //   setDescriptionErr(error);
    //   return false;
    // }

    if (image.length < 0) {
      setImageErr("Please select image");
      return false;
    } else {
      for (let i = 0; i < image.length; i++) {
        const element = image[i][0];

        if (!element.name.match(/\.(jpg|jpeg|png)$/)) {
          setImageErr("Please select valid document jpg,jpeg,png");
          return false;
        }

        if (element.size >= 3e6) {
          setImageErr("Document size should not be more than 3MB");
          return false;
        }
      }
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    const formData = new FormData();

    for (const key of Object.keys(image)) {
      formData.append("Image", image[key][0]);
    }

    formData.append("title", title);
    formData.append("description", description);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);

    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/campaign-add", formData, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/campaign";
          }, 3000);
          return false;
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const showImages = () => {
    const html = [];

    if (showSelectedImages.length > 0) {
      showSelectedImages.map((element, index) => {
        return html.push(
          <div className="imageSize" key={element}>
            <img className="uploaded-images" src={element} alt="" />
            <span
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(index)}
            >
              X
            </span>
          </div>
        );
      });
    }
    return html;
  };

  const removeItem = (element) => {
    const s = image.filter((item, index) => index !== element);
    const m = showSelectedImages.filter((item, index) => index !== element);

    setShowSelectedImages(m);
    setImage(s);
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD Campaign</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={title}
                            className="form-control"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{titleErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Start Date</label>
                          <input
                            type="datetime-local"
                            name="startDate"
                            placeholder="Start Date"
                            value={startDate}
                            className="form-control"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{startDateErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>End Date</label>
                          <input
                            type="datetime-local"
                            name="endDate"
                            placeholder="End Date"
                            value={endDate}
                            className="form-control"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{endDateErr}</p>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={description}
                            className="form-control resize"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{descriptionErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-5 col-12">
                        <div className="form-group">
                          <label>Image </label>
                        </div>

                        <div className="form-group file-upload position-relative">
                          <input
                            className="profile_input"
                            type="file"
                            name="image"
                            placeholder=""
                            onChange={handleChangeAll}
                          />
                        </div>
                        <p style={{ color: "red" }}>{imageErr}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div id="uploadedImages">{showImages()}</div>
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/campaign"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default AddCampaign;
