import React, { useState, useEffect, Fragment, useMemo } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import useOrderData from "../Hooks/OrderData";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { reactTableConfig } from "../lib/reactTableConfig";
const config = reactTableConfig("Rejected Orders");

const RejectedOrders = () => {
  const [record, setRecord] = useState([]);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 5 };
  }, []);

  const { data, loading } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Total Price",
      className: "Total",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.amount} INR</Fragment>;
      },
    },
    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/process-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  return (
    <ContentLayout
      title="REJECTED ORDERS"
      className="product-list-outer customer-list-outer"
    >
      <ReactDatatable
        className="table table-bordered table-striped "
        config={config}
        records={record}
        columns={columns}
        loading={loading}
      />
    </ContentLayout>
  );
};

export default RejectedOrders;
