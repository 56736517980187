import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "./BaseUrl";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";

const SubCategoryEdit = () => {
  const [subCatName, setSubCatName] = useState("");
  const [subShortName, setSubShortName] = useState("");
  const [catData, setCatData] = useState([]);
  const [id, setId] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [shortErr, setShortErr] = useState("");
  const tempId = useParams();
  const [loading, setLoading] = useState(false);
  const Params = atob(tempId.id);
  useEffect(() => {
    getSubCategoryById();
    categoryData();
  }, []);

  const getSubCategoryById = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/sub-category-by-id", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setSubCatName(resp.data.name);
        setSubShortName(resp.data.short_name);
        setId(resp.data.category_id);
      }
    });
  };

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setCatData(
            resp.data.sort(function (a, b) {
              var textA = a.name;
              var textB = b.name;
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
          );
        }
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      if (!value) {
        setSubCatName(value);
        setNameErr("Sub Category name is required");
        return false;
      }
      setNameErr("");
      setSubCatName(value);
    }
    if (name === "shortName") {
      if (!value) {
        setSubShortName(value);
        setShortErr("Short name is required");
        return false;
      }
      setShortErr("");
      setSubShortName(value);
    }
    if (name === "categoryId") {
      if (!value || value === "") {
        setId(value);
        toast.dismiss();
        toast.error("Please select category");
        return false;
      }

      setId(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!subCatName && !subShortName) {
      setNameErr("Sub Category name is required");
      setShortErr("Short name is required");
      return false;
    }

    if (!id || id === "") {
      toast.dismiss();
      toast.error("Please select category");
      return false;
    }

    if (!subCatName) {
      setNameErr("Sub Category name is required");
      return false;
    }

    if (!subShortName) {
      setShortErr("Short name is required");
      return false;
    }
    setLoading(true);
    let data = {
      id: Params,
      subCatName: subCatName,
      subShortName: subShortName,
      catId: id,
    };
    axios
      .post(baseUrl + "/frontapi/edit-sub-category", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location.href = "/sub-categories";
          }, 2000);
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <ContentLayout
      title="Edit Sub Category"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                onChange={handleChange}
                name="categoryId"
                value={id}
                disabled
              >
                <option value="">Select Category</option>
                {catData.map((item, i) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={subCatName}
                placeholder="Name"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Short Name</label>
              <input
                type="text"
                name="shortName"
                value={subShortName}
                maxLength="4"
                placeholder="Short Name"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{shortErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/sub-categories"
        loading={loading}
      />
    </ContentLayout>
  );
};

export default SubCategoryEdit;
