import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import { Modal } from "react-bootstrap";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Sales Manager");

function SalesManager(props) {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    SalesManagerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Sales Manager Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_no",
      text: "Phone No.",
      className: "phoneno",
      align: "left",
      sortable: true,
    },

    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href = "/sales-manager-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>

            {record.is_deleted == "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const SalesManagerData = () => {
    axios
      .post(baseUrl + "/frontapi/sales-manager-data", SalesManagerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
        }
      });
  };
  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }
    let params = {
      id: id,
      status: temp,
    };
    axios
      .post(baseUrl + "/frontapi/sales-manager-delete", params)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          SalesManagerData();
        }
      });
  };

  return (
    <>
      <ContentLayout
        title="Sales Manager List"
        buttonTitle="Add Sales Manager"
        buttonUrl="/add-sales-manager"
        className="product-list-outer customer-list-outer"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />
      </ContentLayout>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
}
export default SalesManager;
