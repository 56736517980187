export const reactTableConfig = (name) => {
  return {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: name,
    no_data_text: `No ${name} found!`,
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
};
