import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import moment from "moment";
import { Modal } from "react-bootstrap";

const baseUrl = myConstList.baseUrl;

function CustomerDetails(props) {
  const [aData, setAData] = useState([]);
  const [sData, setSData] = useState([]);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [pNumber, setPNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [gst, setGst] = useState("");
  const [panNum, setPanNum] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [state, setState] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [customerOrderModal, setCustomerOrderModal] = useState(false);
  const [pincode, setPincode] = useState("");
  const [zonesData, setZonesData] = useState("");
  const [oredrData, setOredrData] = useState([]);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    customerData();
  }, []);

  const customerData = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/customer-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }

        if (resp.status === true) {
          setAData(resp.data.sqlRun2);
          setSData(resp.data.sqlRun2);
          setName(resp.data.sqlRun[0].name);
          setState(resp.data.sqlRun[0].state);
          setCustomerType(resp.data.sqlRun[0].customerType);
          setPincode(resp.data.sqlRun[0].pincode);
          setCompanyName(resp.data.sqlRun[0].companyName);
          setEmail(resp.data.sqlRun[0].email);
          setPNumber(resp.data.sqlRun[0].phoneNumber);
          setWebsite(resp.data.sqlRun[0].website);
          setGst(resp.data.sqlRun[0].gst);
          setPanNum(resp.data.sqlRun[0].panNumber);
          setBillingAddress(resp.data.sqlRun[0].billingAddress);
          setZonesData(resp.data.sqlRun[0].zone_name);
        }
      });
  };

  const getOrdersCustomer = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/getOrderByCustomer", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setOredrData(resp.data);
        setCustomerOrderModal(true);
      }
    });
  };

  const closeCustomerModal = () => {
    setCustomerOrderModal(false);
  };

  const customerOrderDetailsHtml = () => {
    const html = [];
    oredrData.map((item, i) => {
      var status;
      if (item.order_status == 0) {
        status = "Pending";
      }
      if (item.order_status == 1) {
        status = "InProgress";
      }
      if (item.order_status == 2) {
        status = "Packing Complete";
      }
      if (item.order_status == 3) {
        status = "Out for Deliver";
      }
      if (item.order_status == 6) {
        status = "Delivered";
      }

      return html.push(
        <tr>
          <td>{i + 1}</td>
          <td>{item.orderId}</td>
          <td>{moment(item.createAt).format("ll")}</td>
          <td>
            {item.estimated_delivery_date &&
              moment(item.estimated_delivery_date).format("ll")}
          </td>
          <td>{item.name}</td>
          <td>{item.isCustomize}</td>
          <td>{item.quantity}</td>
          <td>{item.price}</td>
          <td>{item.gst}</td>
          {/* <td>{item.gstAmount}</td> */}
          <td>{parseFloat(item.subTotal).toFixed(2)}</td>
          <td>
            {parseFloat(Number(item.subTotal) + Number(item.gstAmount)).toFixed(
              2
            )}
          </td>
        </tr>
      );
    });
    return <tbody>{html}</tbody>;
  };

  const dataHtml = () => {
    const html = [];

    aData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Person Name: <b>{value.aName}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Person Email: <b>{value.aEmail}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Person Designation: <b>{value.selected}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Person Phone: <b>{value.aPhoneNo}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  function copyToClipboard(value) {
    var message = `${value.address} , 
    ${value.city} , 
    ${value.state} , 
    ${value.zipcode} `;
    navigator.clipboard.writeText(message);
    toast.dismiss();
    toast.success("Copied to the textboard!");
    // setCopySuccess("Copied!");
  }

  const dataHtmlShipping = () => {
    const html = [];

    sData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <a
              href="#!"
              className="btn btn-primary"
              onClick={() => copyToClipboard(value)}
            >
              Copy
            </a>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Country: <b>{value.country}</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                State: <b>{value.state}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                City: <b>{value.city}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Pincode: <b>{value.zipcode}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Phone No: <b>{value.phone_no}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Address: <b>{value.address}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>CUSTOMER DETAIL</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-12">
                  <div className="add-product-outer customer-detail-outer">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Customer Type: <b>{customerType}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Name: <b>{name}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding ">
                        <div className="customer-detail">
                          <p>
                            Company name: <b>{companyName}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Email: <b>{email}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Phone Number: <b>{pNumber}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding ">
                        <div className="customer-detail">
                          <p>
                            Website: <b>{website}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            GST: <b>{gst}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            PAN Number: <b>{panNum}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding ">
                        <div className="customer-detail">
                          <p>
                            Location: <b>India</b>
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Customer Type: <b>{customerType}</b>
                          </p>
                        </div>
                      </div> */}
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            Billing Address: <b>{billingAddress}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                        <div className="customer-detail">
                          <p>
                            State: <b>{state}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 more-padding ">
                        <div className="customer-detail">
                          <p>
                            Pincode: <b>{pincode}</b>
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12 more-padding">
                        <div className="customer-detail">
                          <p>
                            Zone: <b>{zonesData}</b>
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12 more-padding">
                        <div className="customer-detail">
                          <p>
                            Placed Orders:{" "}
                            <a
                              href="#!"
                              className="btn btn-primary"
                              onClick={getOrdersCustomer}
                            >
                              View Order
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="person-contact customer-detail">
                          <h5>
                            <b>Shipping Address Detail</b>
                          </h5>
                          {dataHtmlShipping()}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="person-contact customer-detail">
                          <h5>
                            <b>Contact Person</b>
                          </h5>
                          {dataHtml()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  className="modal-update customer-order-details"
                  show={customerOrderModal}
                  onHide={closeCustomerModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="m-0" style={{ color: "black" }}>
                      Order Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="assign-vendor">
                    <table className="table-responsive">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Order ID</th>
                          <th>Order Date</th>
                          <th>Delivery Date</th>
                          <th>Product</th>
                          <th>Customised</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>GST (In %)</th>
                          {/* <th>GST Amount</th> */}
                          <th>Net Sales Value</th>
                          <th>Gross Sales Value</th>
                        </tr>
                      </thead>
                      {customerOrderDetailsHtml()}
                    </table>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default CustomerDetails;
