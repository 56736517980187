import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { formatDateTime, formatDate } from "../utils/dateTime";
import { Modal } from "react-bootstrap";
import * as myConstList from "./BaseUrl";
import { Fragment } from "react";
import Footer from "./Footer";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
const config = reactTableConfig("In Process Orders");
const baseUrl = myConstList.baseUrl;

function InprocessOrders(props) {
  const [record, setRecord] = useState([]);
  const [user, setUser] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [rejectError, setRejectError] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 1 };
  }, []);

  const {
    data,
    loading,
    error: orderError,
    refetch,
  } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  useEffect(() => {
    const user = localStorage.getItem("userType");
    setUser(user);
  }, []);

  const handleChangeReason = (event) => {
    let fieldValue = event.target.value;
    if (fieldValue.trim() === "") {
      setRejectMessage("");
      setRejectError("Please enter a valid reason");
      return;
    }

    setRejectMessage(fieldValue);
    setRejectError("");
  };

  const closeRejectModel = () => {
    setShowRejectModal(false);
    setRejectMessage("");
    setRejectError("");
    setOrderId(null);
  };

  const changeStatusProductAdmin = (value) => {
    setShowRejectModal(true);

    if (value) {
      setOrderId(value);
    }
  };

  const submitRejectOrder = async (e) => {
    e.preventDefault();

    if (rejectMessage.trim() === "") {
      setRejectError("Please enter a valid reason");
      return;
    }

    let data = {
      orderId,
      message: rejectMessage,
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      };

      const { data: resp } = await axios.post(
        baseUrl + "/frontapi/reject-inprocess-order",
        data,
        config
      );

      if (resp.status) {
        // productData();
        refetch();
        toast.dismiss();
        toast.success(resp.message);
        closeRejectModel();
      } else {
        toast.dismiss();
        toast.error(resp.message);
        closeRejectModel();
      }
    } catch (error) {
     
      toast.dismiss();
      toast.error(error.message);
      // closeRejectModel();
    }
  };

  function getSignalColor(expectedDeliveryDate) {
    const deliveryDateMoment = moment(expectedDeliveryDate);

    // Get the current date
    const currentDateMoment = moment();

    // Calculate the difference in days between the current date and the expected delivery date
    const daysUntilCurrentDate = deliveryDateMoment.diff(
      currentDateMoment,
      "days"
    );

    // Check if today's date is greater than the expected delivery date
    if (daysUntilCurrentDate < 0) {
      return "RED";
    }

    // Calculate the difference in days between the order date and the expected delivery date
    const daysUntilDelivery = deliveryDateMoment.diff(
      currentDateMoment,
      "days"
    );
    // GREEN signal if more than 2 days before delivery or if it's the current date
    if (daysUntilDelivery > 2) {
      return "#66FF00";
    }

    // YELLOW signal if 1 or 2 days before delivery
    if (
      daysUntilDelivery === 1 ||
      daysUntilDelivery === 2 ||
      daysUntilDelivery === 0
    ) {
      return "YELLOW";
    }
  }

  function numberOfDaysFromDelivery(orderDate, expectedDeliveryDate) {
    const orderDateObject = new Date(orderDate);
    const deliveryDateObject = new Date(expectedDeliveryDate);

    const orderDateMoment = moment(orderDateObject);
    const deliveryDateMoment = moment(deliveryDateObject);

    // Calculate the difference in days between the order date and the expected delivery date
    const daysUntilDelivery = deliveryDateMoment.diff(orderDateMoment, "days");

    return daysUntilDelivery;
  }

  const columnsAdmin = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },
    {
      key: "name",
      text: "Sales Person",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "assignName",
      text: "Assigned To",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>INR {record.amount}</Fragment>;
      },
    },
    {
      key: "estimated_delivery_date",
      text: "Expected Date of Delivery",
      className: "date-red",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            <span
              style={{
                color: getSignalColor(record.estimated_delivery_date),
              }}
            >
              {formatDate(record.estimated_delivery_date)}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "day",
      text: "Days",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            {numberOfDaysFromDelivery(
              record.createdAt,
              record.estimated_delivery_date
            )}
          </Fragment>
        );
      },
    },
    {
      key: "createdAt",
      text: "Order Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)}</Fragment>;
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/inprocess-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="form-control"
              data-toggle="tooltip"
              data-placement="top"
              title="Reject"
              onClick={(e) => changeStatusProductAdmin(record.id)}
            >
              <i className="mdi mdi-close-circle-outline"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },
    {
      key: "name",
      text: "Sales Person",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "assignName",
      text: "Assigned To",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>INR {record.amount}</Fragment>;
      },
    },
    {
      key: "estimated_delivery_date",
      text: "Expected Date of Delivery",
      className: "date-red",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            <span
              style={{
                color: getSignalColor(record.estimated_delivery_date),
              }}
            >
              {formatDate(record.estimated_delivery_date)}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "day",
      text: "Days",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            {numberOfDaysFromDelivery(
              record.createdAt,
              record.estimated_delivery_date
            )}
          </Fragment>
        );
      },
    },
    {
      key: "createdAt",
      text: "Order Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)}</Fragment>;
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/inprocess-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const columnsOps = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "customerName",
      text: "Customer",
      className: "customerName",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.prod_name === null ? "N/A" : record.prod_name}
          </Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },
    {
      key: "name",
      text: "Sales Person",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "assignName",
      text: "Assigned By",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            INR{" "}
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>INR {record.amount}</Fragment>;
      },
    },

    {
      key: "estimated_delivery_date",
      text: "Expected Date of Delivery",
      className: "date-red",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            <span
              style={{
                color: getSignalColor(record.estimated_delivery_date),
              }}
            >
              {formatDate(record.estimated_delivery_date)}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "day",
      text: "Days",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            {numberOfDaysFromDelivery(
              record.createdAt,
              record.estimated_delivery_date
            )}
          </Fragment>
        );
      },
    },

    {
      key: "createdAt",
      text: "Order Date",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)}</Fragment>;
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/inprocess-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const handleColorChange = (color) => {
    const filteredData =
      color === "All"
        ? data
        : data.filter(
            (record) => getSignalColor(record.estimated_delivery_date) === color
          );
    setRecord(filteredData);
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>INPROCESS ORDER</b>
              </h2>
              <div className="add-product-btn text-center">
                <button
                  className="btn btn-primary"
                  style={{
                    marginRight: "5px",
                  }}
                  onClick={() => handleColorChange("RED")}
                >
                  Red
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleColorChange("YELLOW")}
                >
                  Yellow
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleColorChange("#66FF00")}
                >
                  Green
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleColorChange("All")}
                >
                  All
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  {user === "oprationExecutive" ? (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={record}
                      columns={columnsOps}
                      loading={loading}
                    />
                  ) : user === "ADMIN" ? (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={record}
                      columns={columnsAdmin}
                      loading={loading}
                    />
                  ) : (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={record}
                      columns={columns}
                      loading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modal-update"
          show={showRejectModal}
          onHide={closeRejectModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              Confirm Order Rejection?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <div className="product-price">
                  <label>Rejection reason</label>
                  <input
                    type="text"
                    className="form-control"
                    name="reason"
                    placeholder="reason"
                    value={rejectMessage}
                    onChange={handleChangeReason}
                  />
                  <p style={{ color: "red" }}>{rejectError}</p>
                </div>
              </div>

              <div className="col-12">
                <div className="submit-btn">
                  <button
                    style={{ marginRight: "10px" }}
                    className="btn btn-primary"
                    onClick={submitRejectOrder}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={closeRejectModel}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Footer />

        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default InprocessOrders;
