import React, { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import { formatDateTime, formatDate, momentObj } from "../utils/dateTime";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import Select from "react-select";
import { selectStyleLight } from "../Hooks/SelectStyle";
import { addDays } from "date-fns";
import { DatepickerCustomRanges } from "../lib/DatePickerCustomRanges";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { reactTableConfig } from "../lib/reactTableConfig";
const config = reactTableConfig("All Sales");
const baseUrl = myConstList.baseUrl;

const AllSales = () => {
  const [record, setRecord] = useState([]); // to show record
  const [tempRecord, setTempRecord] = useState([]); // operation
  const [prodData, setProdData] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [catManagers, setCatManagers] = useState([]);
  const [prodId, setProdId] = useState("all");
  const [salesPerson, setSalesPerson] = useState("all");
  const [catManager, setCatManager] = useState("all");
  const [customer, setCustomer] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [state, setDateSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  useEffect(() => {
    submitSearch();
  }, [prodId, startDate, endDate, salesPerson, catManager, customer]);

  const handleOutsideClick = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      // Click occurred outside the date picker
      if (buttonRef.current && buttonRef.current.contains(e.target)) {
        // Click occurred on the button, do not close the date picker
        return;
      }
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPicker]);

  useEffect(() => {
    SalesData();
    productData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChanges = (date) => {
    setStartDate(date[0].startDate);
    setEndDate(date[0].endDate);
    setDateSelection(date);
  };

  const SalesData = () => {
    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/all-sales-data", SalesData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          const { managers, customers, salesPersons } = res.data;
          setSalesPersons(salesPersons);
          setCustomers(customers);
          setCatManagers(managers);
          setRecord(resp.data);
          setTempRecord(resp.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "id",
      text: "Order ID",
      className: "cust_id",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "sales_person",
      text: "Sales Person ",
      className: "sales_name",
      align: "left",
      sortable: true,
    },
    {
      key: "customer_name",
      text: "Customer",
      className: "sales_name",
      align: "left",
      sortable: true,
    },
    {
      key: "category_manager_name",
      text: "Category Manager",
      className: "sales_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.category_manager_name
              ? record.category_manager_name
              : "N/A"}
          </Fragment>
        );
      },
    },

    // {
    //   key: "quantity",
    //   text: "Quantity",
    //   className: "Quantity",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return <Fragment>{record.quantity} INR</Fragment>;
    //   },
    // },

    {
      key: "amount",
      text: "Net Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {parseFloat(
              Number(record.amount) - Number(record.gstAmount)
            ).toFixed(2)}{" "}
            INR
          </Fragment>
        );
      },
    },
    {
      key: "amount",
      text: "Gross Sales Value",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {parseFloat(Number(record.amount)).toFixed(2)} INR
          </Fragment>
        );
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.createdAt)} </Fragment>;
      },
    },
  ];

  const productData = () => {
    axios.post(baseUrl + "/frontapi/product-data", productData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        const sortByName = resp.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setProdData(sortByName);
      }
    });
  };

  const handleVendorChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedOption(selectedOption);
      setProdId("all");
      return;
    }
    setSelectedOption(selectedOption);
    setProdId(selectedOption.value);
  };

  const salesPersonHtml = () => {
    const saleshtml = [];
    salesPersons.map((value, i) => {
      return saleshtml.push(
        <option key={value.id} value={value.name}>
          {value.name}
        </option>
      );
    });
    return saleshtml;
  };

  const customersHtml = () => {
    const customerHtml = [];
    customers.map((value, i) => {
      return customerHtml.push(
        <option key={value.id} value={value.name}>
          {value.name}
        </option>
      );
    });
    return customerHtml;
  };

  const catManagersHtml = () => {
    const catManagerHtml = [];
    catManagers.map((value, i) => {
      return catManagerHtml.push(
        <option key={value.id} value={value.name}>
          {value.name}
        </option>
      );
    });
    return catManagerHtml;
  };

  const handleChangeAll = (e) => {
    let { name, value } = e.target;

    if (name === "prodId") {
      setProdId(value);
    }

    if (name === "startDate") {
      setStartDate(value);
    }

    if (name === "endDate") {
      setEndDate(value);
    }

    if (name === "salesPerson") {
      setSalesPerson(value);
    }

    if (name === "customers") {
      setCustomer(value);
    }

    if (name === "catManagers") {
      setCatManager(value);
    }
  };

  const submitSearch = (e) => {
    // e.preventDefault();
    if (!startDate && endDate) {
      showError("Start date is required");
      return;
    }

    if (startDate > endDate) {
      showError("Please enter a valid start date");
      return;
    }

    let filteredData = tempRecord;

    if (prodId && prodId !== "all") {
      filteredData = filteredData.filter(
        (value) => Number(value.product_id) === Number(prodId)
      );
    }

    if (salesPerson !== "all") {
      filteredData = filteredData.filter(
        (value) => value.sales_person === salesPerson
      );
    }

    if (customer !== "all") {
      filteredData = filteredData.filter(
        (value) => value.customer_name === customer
      );
    }

    if (catManager !== "all") {
      filteredData = filteredData.filter(
        (value) => value.category_manager_name === catManager
      );
    }

    if (startDate) {
      filteredData = filteredData.filter((value) => {
        const date = momentObj(value.createdAt);
        return date.isSameOrAfter(startDate, "day");
      });
    }

    if (endDate) {
      filteredData = filteredData.filter((value) => {
        const date = momentObj(value.createdAt);
        return date.isSameOrBefore(endDate, "day");
      });
    }

    setRecord(filteredData);
  };

  const showError = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between">
              <h2>
                <b>All Sales</b>
              </h2>
            </div>
            <div className="invent-form">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Products</label>
                    <Select
                      value={selectedOption}
                      onChange={handleVendorChange}
                      styles={selectStyleLight}
                      className="custom-select"
                      name="prodId"
                      isClearable={true}
                      noOptionsMessage={() => "No vendor found"}
                      options={prodData.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Sales Person</label>
                    <select
                      className="form-control"
                      name="salesPerson"
                      onChange={handleChangeAll}
                      value={salesPerson}
                    >
                      <option value="all">All</option>
                      {salesPersonHtml()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Customers</label>
                    <select
                      className="form-control"
                      name="customers"
                      onChange={handleChangeAll}
                      value={customer}
                    >
                      <option value="all">All</option>
                      {customersHtml()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Category Managers</label>
                    <select
                      className="form-control"
                      name="catManagers"
                      onChange={handleChangeAll}
                      value={catManager}
                    >
                      <option value="all">All</option>
                      {catManagersHtml()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Select Date Range</label>
                    <button
                      className="date-btn btn"
                      ref={buttonRef}
                      onClick={() => setShowPicker(!showPicker)}
                    >
                      {startDate ? "Order date: " : "Select Date"}
                      <span style={{ fontSize: "12px" }}>
                        {startDate &&
                          formatDate(startDate, "MMM D, YYYY") + " -"}{" "}
                        {endDate && formatDate(endDate, "MMM D, YYYY")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    className="table table-bordered table-striped"
                    config={config}
                    records={record}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
            {showPicker && (
              <div ref={datePickerRef} className=" date-picker-local">
                <DateRangePicker
                  onChange={(item) => handleDateChanges([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                  maxDate={new Date()}
                  months={2}
                  ranges={state}
                  rangeColors={["#333333", "#333333", "#333333"]}
                  direction="horizontal"
                  staticRanges={[
                    ...defaultStaticRanges,
                    ...DatepickerCustomRanges,
                  ]}
                />
              </div>
            )}
          </div>
        </div>

        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default AllSales;
