import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

const RemindersEdit = () => {
  const params = useParams();
  const [leadData, setLeadData] = useState([]);
  const [lead, setLead] = useState("");
  const [name, setName] = useState("");
  const [preReminderDate, setPreReminderDate] = useState("");
  const [reminderDate, setReminderDate] = useState("");

  // error //
  const [leadErr, setLeadErr] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [preReminderDateErr, setPreReminderDateErr] = useState("");
  const [reminderDateErr, setReminderDateErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReminderData();
    leadsDataApi();
  }, []);

  const getReminderData = async () => {
    await axios
      .post(baseUrl + "/frontapi/reminder-data-single", { id: atob(params.id) })
      .then((res) => {
        let resp = res.data;

        if (resp.status === true) {
          setReminderDate(resp.data[0].date);
          setPreReminderDate(resp.data[0].reminder_date);
          setName(resp.data[0].name);
          setLead(resp.data[0].leadId)
        }
      });
  };

  const leadsDataApi = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/leads-manager-data", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setLeadData(resp.data);
        }
      });
  };

  const leadHtml = () => {
    const leadHtml = [];
    var LData = leadData.sort(function (a, b) {
      var textA = a.id;
      var textB = b.id;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    LData.map((value, i) => {
      return leadHtml.push(<option value={value.id}>{value.id}</option>);
    });
    return leadHtml;
  };

  const handleChangeAll = async (event) => {
    let { name, value } = event.target;
    if (name === "lead") {
      setLead(value);
      if (!value) {
        setLeadErr("Please select lead id");
        return false;
      }
      setLeadErr("");
    }
    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }
      setNameErr("");
    }

    if (name === "preDate") {
      setPreReminderDate(value);
      setPreReminderDateErr("");
    }

    if (name === "date") {
      setReminderDate(value);
      setReminderDateErr("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!lead) {
      setLeadErr("Please select lead id");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    let error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!preReminderDate) {
      setPreReminderDateErr("Pre reminder date is required");
      return false;
    }

    if (!reminderDate) {
      setReminderDateErr("Reminder date is required");
      return false;
    }

    setLoading(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      id: atob(params.id),
      leadId: lead,
      name: name,
      reminderDate: reminderDate,
      preReminderDate: preReminderDate,
      userType: "SalesManager",
    };

    axios
      .post(baseUrl + "/frontapi/reminder-edit", data, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === false) {
          toast.error(resp.message);
          return;
        } else {
          toast.success(resp.message);
          setTimeout(function () {
            window.location = "/reminders";
          }, 2000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="Edit Reminder"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Lead ID</label>
              <select
                name="lead"
                placeholder="Lead ID"
                className="form-control"
                onChange={handleChangeAll}
                value={lead}
              >
                <option value="">Please Select</option>
                {leadHtml()}
              </select>

              <p style={{ color: "red" }}> {leadErr} </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                className="form-control"
                onChange={handleChangeAll}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Pre Reminder Date</label>
              <input
                type="datetime-local"
                name="preDate"
                placeholder="Date"
                className="form-control"
                onChange={handleChangeAll}
                value={preReminderDate}
              />
              <p style={{ color: "red" }}> {preReminderDateErr} </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Reminder Date</label>
              <input
                type="datetime-local"
                name="date"
                placeholder="Date"
                className="form-control"
                onChange={handleChangeAll}
                value={reminderDate}
              />
              <p style={{ color: "red" }}> {reminderDateErr} </p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup onClick={handleSubmit} link="/reminders" loading={loading} />
    </ContentLayout>
  );
};

export default RemindersEdit;
