import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import { Modal } from "react-bootstrap";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function CategoryManagerEdit() {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [catData, setCatData] = useState([]);
  const [id, setId] = useState(atob(useParams().id));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [formValues, setFormValues] = useState([{ categoryId: "" }]);
  // errors //
  const [formValuesError, setFormValuesError] = useState([{ categoryId: "" }]);
  const [type, setType] = useState("number");
  const [typePass, setTypePass] = useState("password");
  const [typePass1, setTypePass1] = useState("password");
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [newPassErr, setNewPassErr] = useState("");
  const [cPassError, setCPassError] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    categoryMangerSingle();
    categoryData();
  }, []);

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };
  const handleChangeName = async (event) => {
    let eventValue = event.target.value;

    if (event.target.name === "name") {
      setName(eventValue);
      if (!eventValue) {
        setNameErr("Please enter name");
        return false;
      }

      setNameErr("");
    }

    if (event.target.name === "email") {
      setEmail(eventValue);
      if (!eventValue) {
        setEmailErr("Please enter email");
        return false;
      }
      if (!eventValue.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }

      setEmailErr("");
    }

    if (event.target.name === "number") {
      setNumber(eventValue);
      if (!eventValue) {
        setNumErr("Please enter phone number");
        return false;
      }
      if (eventValue.length < 10 || eventValue.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }
  };

  const categoryMangerSingle = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/category-manager-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setId(resp.data.sqlRun[0].id);
          setName(resp.data.sqlRun[0].name);
          setEmail(resp.data.sqlRun[0].email);
          setNumber(resp.data.sqlRun[0].mobile_no);

          if (resp.data.sqlRun2.length > 0) {
            setFormValues(resp.data.sqlRun2);
            let html1 = [];
            resp.data.sqlRun2.forEach((element) => {
              let tempArr1 = [
                {
                  categoryId: "",
                },
              ];
              html1.push(tempArr1);
            });
            setFormValuesError(html1);
          } else {
            setFormValues([{}]);
          }
        }
      });
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    var CData = catData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const categoryData = () => {
    // const data = { id: id };
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";

    if (!name && !email && !number) {
      setNameErr("Please enter name");
      setEmailErr("Please enter email");
      setNumErr("Please enter phone number");
      return false;
    }

    if (!name) {
      setNameErr("Please enter name");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Please enter email");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Please enter phone number");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    let html = [];
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.categoryId || element.categoryId === "Please select") {
        formError[i].categoryId = "Please select category";
        setFormValuesError(formError);
        return false;
      }

      html.push(Number(element.categoryId));
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate category found");
      return false;
    }
    setLoading(true);
    let customerData = {
      id: id,
      name: name,
      email: email,
      categoryId: formValues,
      number: number,
    };
    axios
      .post(baseUrl + "/frontapi/category-manager-update", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Category Manager Updated Successfully");
          setTimeout(() => {
            window.location = "/category-manager";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangePass = (e) => {
    let { name, value } = e.target;
    if (name === "newPass") {
      setNewPass(value);
      if (!value) {
        setNewPassErr("This field is required");
        return false;
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!value.match(regex)) {
        setNewPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      } else {
        setNewPassErr("");
      }

      if (value !== confirmPass) {
        setCPassError("Confirm password does not match");
        return false;
      } else {
        setCPassError("");
      }

      setNewPassErr("");
    }

    if (name === "confirmPass") {
      setConfirmPass(value);
      if (!value) {
        setCPassError("This field is required");
        return false;
      }
      if (value !== newPass) {
        setCPassError("Confirm password does not match");
        return false;
      }

      setCPassError("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = () => {
    if (!newPass) {
      setNewPassErr("This field is required");
      return false;
    }
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!newPass.match(regex)) {
      setNewPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    let error = checkSpace("new password", newPass);
    if (error) {
      setNewPassErr(error);
      return false;
    }

    if (!confirmPass) {
      setCPassError("This field is required");
      return false;
    }

    error = checkSpace("confirm password", confirmPass);
    if (error) {
      setCPassError(error);
      return false;
    }

    if (confirmPass !== newPass) {
      setCPassError("Confirm password does not match");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const data = {
      userId: id,
      newPass: newPass,
      confirmPass: confirmPass,
    };

    axios.post(baseUrl + "/frontapi/changePass", data, config).then((res) => {
      const resp = res.data;
      toast.dismiss();

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setTimeout(() => {
          window.location.href = "/category-manager";
        }, 2000);
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPass("");
    setConfirmPass("");
    setNewPassErr("");
    setCPassError("");
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  const handleClick1 = () => {
    if (typePass1 === "password") {
      setTypePass1("text");
      return false;
    } else {
      setTypePass1("password");
    }
  };

  let addFormFields = () => {
    setFormValues([...formValues, { categoryId: "" }]);
    setFormValuesError([...formValuesError, { categoryId: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.name === "categoryId") {
      if (
        !newFormValues[i].categoryId ||
        newFormValues[i].categoryId === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "Please select category";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Modal show={modalIsOpen} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  {" "}
                  Change Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        className="form-control fs12"
                        placeholder="Enter New Password"
                        type={typePass}
                        name="newPass"
                        onChange={handleChangePass}
                        value={newPass}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick()}
                      >
                        {typePass === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{newPassErr}</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        placeholder="Enter Confirm Password"
                        className="form-control fs12"
                        type={typePass1}
                        name="confirmPass"
                        onChange={handleChangePass}
                        value={confirmPass}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick1()}
                      >
                        {typePass1 === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{cPassError}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="d-block">
                <div className="submit-btn-outer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <div className="section-heading">
              <h2>
                <b>Category Manager Update</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Name"
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}> {nameErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChangeName}
                            // disabled
                          />
                          <p style={{ color: "red" }}> {emailErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type={type}
                            name="number"
                            value={number}
                            onKeyDown={handleValidation}
                            maxLength={10}
                            placeholder="Mobile Number"
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}> {numErr} </p>
                        </div>
                      </div>
                      {formValues.map((element, index) => (
                        <>
                          <div className="col-md-6 col-12">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Category Name</label>
                                <select
                                  className="form-control"
                                  name="categoryId"
                                  placeholder="Category Name"
                                  onChange={(e) => handleChangeAttr(index, e)}
                                  value={element.categoryId || ""}
                                >
                                  <option>Please select</option>
                                  {categoryHtml()}
                                </select>
                                <p style={{ color: "red" }}>
                                  {" "}
                                  {formValuesError[index]?.categoryId}{" "}
                                </p>
                              </div>

                              <div className="add-row">
                                {index ? (
                                  <a
                                    href="#!"
                                    className="add-btn add"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a
                                  className="add-btn add"
                                  href="#!"
                                  onClick={() => addFormFields()}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="col-md-4 col-sm-6 col-12 align-self-end">
                        <div className="form-group">
                          <button
                            type="button"
                            // onClick={buyPopUp}
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#add-user-modal"
                            onClick={() => setModalIsOpen(true)}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/category-manager"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default CategoryManagerEdit;
