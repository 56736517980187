import React, { useState, useEffect, Fragment, useMemo } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./Footer";
import ReactDatatable from "@mkikets/react-datatable";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
import * as myConstList from "./BaseUrl";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Total Orders");

const VendorPendingOrders = () => {
  const [adRecords, setAdRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [refreshed, setRefreshed] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 0, orderType: "vendor" };
  }, []);

  const { data, loading, refetch } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setAdRecords(data);
      setRecords(data);
    }
  }, [data]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "order_id",
      text: "Order ID",
      className: "otype",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.id === null ? "N/A" : record.id}</Fragment>;
      },
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{record.name === null ? "N/A" : record.name}</Fragment>
        );
      },
    },
    {
      key: "against_order_id",
      text: "Against Order",
      className: "ao",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{record.against_order_id === null ? "NO" : "YES"}</Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity === null ? "N/A" : record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.price === null ? "N/A" : record.price} INR
          </Fragment>
        );
      },
    },
    {
      key: "total",
      text: "Total Price",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.total === null ? "N/A" : record.total + " INR"}
          </Fragment>
        );
      },
    },
    {
      key: "gst",
      text: "GST(%)",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.tax === null ? "N/A" : record.tax}</Fragment>;
      },
    },
    {
      key: "orderType",
      text: "Order Type",
      className: "otype",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "prod",
      text: "Image",
      className: "prodimg",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.product_file === null ? (
              "N/A"
            ) : (
              <a
                href={baseUrl + "/static/customizeImage/" + record.product_file}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                {!record.product_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                  "View File"
                ) : (
                  <img
                    src={
                      baseUrl + "/static/customizeImage/" + record.product_file
                    }
                    alt=""
                    className="img-fluid"
                  />
                )}
              </a>
            )}
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendors-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "status",
      text: "Status",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <select
              className="form-control"
              value={record.status}
              onChange={(e) => changeStatusProduct(e, record)}
            >
              <option>Select</option>
              <option value="1">In Process</option>
              <option value="2">Completed</option>
              <option value="3">Rejected</option>
            </select>
          </Fragment>
        );
      },
    },
  ];

  const adColumns = [
    {
      key: "id",
      text: "Sr No.",
      className: "",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "id",
      text: "Order ID",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{record.name === null ? "N/A" : record.name}</Fragment>
        );
      },
    },
    {
      key: "against_order_id",
      text: "Against Order",
      className: "ao",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>{record.against_order_id === null ? "NO" : "YES"}</Fragment>
        );
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity === null ? "N/A" : record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.price === null ? "N/A" : record.price} INR
          </Fragment>
        );
      },
    },

    {
      key: "total",
      text: "Total Price",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.total === null ? "N/A" : record.total + " INR"}
          </Fragment>
        );
      },
    },
    {
      key: "vendor_name",
      text: "Vendor Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "assigned_by",
      text: "Assigned By",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "ordrtype",
      text: "Order Type",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendor-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    // here  i want to show an input field
        
    {
      key: "detail",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="form-control"
              onClick={(e) => changeStatusProductAdmin(record)}
              data-toggle="tooltip"
              data-placement="top"
              title="Reject"
            >
              <i className="mdi mdi-close-circle-outline"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const updateStatus = (record) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/change-vendororder-status", record, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          // productData();
          refetch();
          if (record.status === "2") {
            setTimeout(() => {
              window.location.href = "/vendor-order-completed";
            }, 2000);
            return false;
          }
          if (record.status === "3") {
            setTimeout(() => {
              window.location.href = "/vendor-rejected-orders";
            }, 2000);
            return false;
          }

          setTimeout(() => {
            window.location.href = "/process-order";
          }, 2000);
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => updateStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const changeStatusProductAdmin = (value) => {
    let data = {
      orderId: value.id,
      status: "3",
      quantity: value.quantity,
      productId: value.product_id,
      against_order_id: value.against_order_id,
    };

    Conn(data);
  };

  const changeStatusProduct = (e, value) => {
    let data = {
      orderId: value.id,
      status: e.target.value,
      quantity: value.quantity,
      productId: value.product_id,
      against_order_id: value.against_order_id,
    };

    if (e.target.value === "Select" || !e.target.value) {
      return false;
    }

    if (e.target.value === "2") {
      if (value.orderType === "Customize") {
        if (value.sample_file_status === "1") {
          Conn(data);
          return false;
        }
        toast.dismiss();
        toast.error("Change status to inprocess, Sample file is not uploaded");
        return false;
      }
      Conn(data);
      return false;
    }

    if (e.target.value) {
      Conn(data);
      return false;
    }
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>VENDOR PENDING ORDERS</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  {localStorage.getItem("userType") === "Vendor" ? (
                    <ReactDatatable
                      className="table table-bordered table-striped  "
                      config={config}
                      records={records}
                      columns={columns}
                      loading={loading}
                    />
                  ) : (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={adRecords}
                      columns={adColumns}
                      loading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default VendorPendingOrders;
