import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { baseUrl } from "./BaseUrl";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { IconContext } from "react-icons";
const config = reactTableConfig("Campaign");

const Campaign = () => {
  const [record, setRecord] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    campaignData();
  }, []);

  const columns = [
    {
      key: "#",
      text: "Sr No.",
      className: "#",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Campaign ID",
      className: "id",
      align: "left",
      sortable: true,
    },

    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },

    {
      key: "startDate",
      text: "Start Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.startDate).format("lll")}</Fragment>;
      },
    },

    {
      key: "endDate",
      text: "End Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.endDate).format("lll")}</Fragment>;
      },
    },

    {
      key: "description",
      text: "Description",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {record.description} </Fragment>;
      },
    },
    {
      key: "image",
      text: "Image",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              type="button"
              onClick={() => {
                setDocumentData(JSON.parse(record.image));
                setShowModal(true);
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {moment(record.created_at).format("lll")} </Fragment>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "detail",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <IconContext.Provider
              value={{
                color: "white",
                size: "2em",
                style: { padding: "3px" },
              }}
            >
              <MdEdit
                onClick={() =>
                  (window.location.href = "/campaign-edit/" + btoa(record.id))
                }
                className="btn"
                style={{ background: "#00bf66" }}
              />
            </IconContext.Provider>

            <button
              className="btn btn-danger btn-sm img-fluid"
              onClick={() => {
                Conn(record.id, record.is_deleted);
              }}
            >
              Delete
            </button>

            {/* {record.is_deleted === "1" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enable
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disable
              </button>
            )} */}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }
    let data = {
      id: id,
      status: temp,
    };
    axios.post(baseUrl + "/frontapi/campaign-delete", data).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status === true) {
        toast.success(resp.message);
        campaignData();
      } else {
        toast.error(resp.message);
      }
    });
  };

  const campaignData = () => {
    axios
      .post(baseUrl + "/frontapi/campaign-data-admin", {
        userType: localStorage.getItem("userType"),
      })
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
        }
      });
  };

  const closeShowModel = () => {
    setDocumentData([]);
    setShowModal(false);
  };

  const imageHtml = () => {
    const html = [];
    documentData.map((value) => {
      return html.push(
        <div className="col-md-6 col-12">
          <div className="product-price">
            <img
              src={baseUrl + "/static/campaign/" + value.image}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <ContentLayout
        title="Campaign List"
        buttonTitle="Add Campaign"
        buttonUrl="/campaign-add"
        className="product-list-outer customer-list-outer"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />

        <Modal
          className="modal-update"
          show={showModal}
          onHide={closeShowModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              Images
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">{imageHtml()}</div>
          </Modal.Body>
        </Modal>
      </ContentLayout>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
};

export default Campaign;
