import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

const IssueTypeAdd = () => {
  const [issueType, setIssueType] = useState("");
  const [issueTypeErr, setIssueTypeErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeIssueType = async (event) => {
    let eventValue = event.target.value;

    setIssueType(eventValue);
    if (!eventValue) {
      setIssueTypeErr("Issue type is required");
      return false;
    }
    setIssueTypeErr("");
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!issueType) {
      setIssueTypeErr("Issue type is required");
      return false;
    }

    let error = checkSpace("issueType", issueType);

    if (error) {
      setIssueTypeErr(error);
      return false;
    }

    setLoading(true);
    let issueData = {
      issueType: issueType,
    };
    axios
      .post(baseUrl + "/frontapi/issue-type-add", issueData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(function () {
            window.location = "/issue-type";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="ADD Issue Type"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Issue Type</label>
              <input
                type="text"
                name="issueType"
                placeholder="Issue Type"
                className="form-control"
                onChange={handleChangeIssueType}
              />
              <p style={{ color: "red" }}>{issueTypeErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/issue-type"
        loading={loading}
      />
    </ContentLayout>
  );
};

export default IssueTypeAdd;
