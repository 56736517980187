import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function DesignationAdd() {
  const [designation, setDesignation] = useState("");
  const [designationErr, setDesignationErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeDesignation = async (event) => {
    let eventValue = event.target.value;

    if (!eventValue) {
      setDesignation(eventValue);

      setDesignationErr("Designation is required");
      return false;
    }

    setDesignationErr("");
    setDesignation(eventValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!designation) {
      setDesignationErr("Designation is required");
      return false;
    }
    setLoading(true);
    let designationData = {
      designation: designation,
    };
    axios
      .post(baseUrl + "/frontapi/designation-add", designationData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Designation Added Successfully");
          setTimeout(function () {
            window.location = "/designation";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="ADD Designation"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Designation</label>
              <input
                type="text"
                name="designation"
                placeholder="Designation"
                className="form-control"
                onChange={handleChangeDesignation}
              />
              <p style={{ color: "red" }}>{designationErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/designation"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default DesignationAdd;
