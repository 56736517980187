import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("All Sales");

const AllSalesCatMan = () => {
  const [vendors, setVendors] = useState([]);
  const [record, setRecord] = useState([]);
  const [tempRecords, setTempRecord] = useState([]);

  useEffect(() => {
    vendorOrdersPipeline();
    vendorData();
  }, []);

  const vendorOrdersPipeline = async () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    try {
      const response = await axios.post(
        baseUrl + "/frontapi/vendor-pipeline",
        {},
        config
      );
      const resp = response.data;
      if (resp.status) {
        setRecord(resp.data);
        setTempRecord(resp.data);
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  const calculateNetProfile = (price, quantity) => {
    price = Number(price);
    quantity = Number(quantity);

    const subtotal = price * quantity;
    return subtotal.toFixed(2);
  };

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Product",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "against_order_id",
      text: "Customer/Inventory",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.customer_name}</Fragment>;
      },
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.quantity}</Fragment>;
      },
    },
    {
      key: "createdAt",
      text: "Buying Price Per Unit",
      className: "doo",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price}</Fragment>;
      },
    },
    {
      key: "detail",
      text: "Net Buying Price",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {calculateNetProfile(record.price, record.quantity)}
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Expected Delivery Date",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.estimated_delivery_date
              ? moment(record.estimated_delivery_date).format("ll")
              : "N/A"}
          </Fragment>
        );
      },
    },
    {
      key: "detail",
      text: "Current Status",
      className: "Detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.status === "0" ? "Pending" : "Inprogress"}
          </Fragment>
        );
      },
    },
  ];

  const vendorData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios.post(baseUrl + "/frontapi/vendor-data", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        let sorted = resp.data
          .filter((item) => item.is_deleted !== "1")
          .sort((a, b) => a.name.localeCompare(b.name));
        setVendors(sorted);
      }
    });
  };

  const vendorHtml = () => {
    const vendorOptions = [];
    var PData = vendors.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    PData.map((value, i) => {
      return vendorOptions.push(<option value={value.id}>{value.name}</option>);
    });
    return vendorOptions;
  };

  const handleChangeAll = (e) => {
    let { value } = e.target;
    const filterData =
      value === "all"
        ? tempRecords
        : tempRecords.filter((record) => record.vendor_id === Number(value));

    setRecord(filterData);
  };

  return (
    <>
      <ContentLayout title="Vendor Pipeline">
        <div className="invent-form">
          <div className="row align-items-center">
            <div className="col-md-3 col-sm-6 col-12">
              <div className="form-group">
                <label>Vendors</label>
                <select
                  className="form-control"
                  name="vendorId"
                  onChange={handleChangeAll}
                  // value={prodId}
                >
                  <option value="all">All</option>
                  {vendorHtml()}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="product-list-outer customer-list-outer">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default AllSalesCatMan;
