import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import $ from "jquery";
import Footer from "./Footer";
import { selectStyleLight } from "../Hooks/SelectStyle";
import Select from "react-select";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function VendorAdd() {
  const [prodData, setProdData] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [website, setWebsite] = useState("");
  const [gst, setGst] = useState("");
  const [panNum, setPanNum] = useState("");
  const [address, setAddress] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [comments, setComments] = useState("");
  const [designationData, setDesignationData] = useState([]);

  // Errors for top container //
  const [type, setType] = useState("number");
  const [vendorNameError, setvendorNameError] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([
    {
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
    },
  ]);
  const [formValuesError, setFormValuesError] = useState([
    {
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      // selected: "",
    },
  ]);
  const [formValuesManufacturer, setFormValuesManufacturer] = useState([
    { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
  ]);

  const [formValuesManufacturerError, setFormValuesManufacturerError] =
    useState([
      { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
    ]);

  const [formValuesTrader, setFormValuesTrader] = useState([
    { tName: "", tCapacity: "", tLocation: "", tSPrice: "" },
  ]);

  const [formValuesTraderError, setFormValuesTraderError] = useState([
    { tName: "", tCapacity: "", tLocation: "", tSPrice: "" },
  ]);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  useEffect(() => {
    getProductData();
    getDesignationData();
  }, []);

  const getProductData = () => {
    axios.post(baseUrl + "/frontapi/product-data").then((res) => {
      const resp = res.data;
      const onlyActiveProducts = resp.data
        .filter((product) => product.isDeleted !== "0")
        .sort((a, b) => a.name.localeCompare(b.name));
      setProdData(onlyActiveProducts);
    });
  };

  const getDesignationData = () => {
    axios.post(baseUrl + "/frontapi/designation-data-deleted").then((res) => {
      const resp = res.data;
      setDesignationData(resp.data);
    });
  };

  const designationHtml = () => {
    const designationhtml = [];

    designationData.map((value, i) => {
      return designationhtml.push(
        <option value={value.designation}>{value.designation}</option>
      );
    });
    return designationhtml;
  };

  const handleChangeTop = (event) => {
    let { name, value } = event.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(event.target).blur();
    });

    if (name === "vendorName") {
      if (!value) {
        setVendorName(value);
        setvendorNameError("Please enter vendor name");
        return false;
      }
      setVendorName(value);
      setvendorNameError("");
    }

    if (name === "companyName") {
      if (!value) {
        setCompanyName(value);
        setCompNameError("Please enter company name");
        return false;
      }
      setCompanyName(value);
      setCompNameError("");
    }

    if (name === "email") {
      if (!value) {
        setEmail(value);
        setEmailError("Please enter email");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmail(value);
        setEmailError("Please enter valid email");
        return false;
      }
      setEmail(value);
      setEmailError("");
    }

    if (name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!value) {
        setPassword(value);
        setPasswordError("Please enter password");
        return false;
      }

      if (!value.match(regex)) {
        setPassword(value);
        setPasswordError(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }
      setPassword(value);
      setPasswordError("");
    }

    if (name === "pNumber") {
      if (!value) {
        setPhoneNum(value);
        setPhoneError("Please enter phone number");
        return false;
      }
      if (!/^[6-9]/.test(value)) {
        setPhoneNum(value);
        setPhoneError("Please enter valid phone number");
        return false;
      }
      if (value.length < 10 || value.length > 10) {
        setPhoneNum(value);
        setPhoneError("Phone number should be of 10 digits");
        return false;
      }

      setPhoneNum(value);
      setPhoneError("");
    }

    if (name === "website") {
      // if (!value) {
      //   setWebsite(value);
      //   setWebsiteError("Please enter website");
      //   return false;
      // }
      setWebsite(value);
      setWebsiteError("");
    }

    if (name === "comments") {
      setComments(value);
    }

    if (name === "gst") {
      let newValue = value.toUpperCase();
      if (!newValue) {
        setGst(newValue);
        setGstError("Please enter GST number");
        return false;
      }

      if (newValue === "URP") {
        setGst(newValue);
        setGstError("");
        return false;
      }
      if (
        !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(newValue)
      ) {
        setGst(newValue);
        setPanNum("");
        setGstError("Enter valid GST number");
        return false;
      } else {
        var modifiedText = newValue.substring(2, newValue.length - 3);
        setPanNum(modifiedText);
      }
      setGst(newValue);
      setGstError("");
    }

    if (name === "panNum") {
      if (!value) {
        setPanNum(value);
        setPanError("Please enter pan number");
        return false;
      }

      var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
      if (!reggg.test(value)) {
        setPanNum(value);
        setPanError("Please enter valid pan number");
        return false;
      }
      setPanNum(value);
      setPanError("");
    }

    if (name === "billingAddress") {
      if (!value) {
        setAddress(value);
        setAddressError("Please enter address");
        return false;
      }
      setAddress(value);
      setAddressError("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";
    if (
      !vendorName &&
      !companyName &&
      !email &&
      !password &&
      !phoneNum &&
      // !website &&
      !gst &&
      !panNum &&
      !address
    ) {
      setvendorNameError("Please enter vendor name");
      setCompNameError("Please enter company name");
      setEmailError("Please enter email");
      setPasswordError("Please enter password");
      setPhoneError("Please enter phone number");
      // setWebsiteError("Please enter website");
      setGstError("Please enter GST number");
      setPanError("Please enter pan number");
      setAddressError("Please enter address");
    }

    if (!vendorName) {
      setvendorNameError("Please enter vendor name");
      return false;
    }

    error = checkSpace("vendor name", vendorName);
    if (error) {
      setvendorNameError(error);
      return false;
    }

    if (!companyName) {
      setCompNameError("Please enter company name");
      return false;
    }

    error = checkSpace("company name", companyName);
    if (error) {
      setCompNameError(error);
      return false;
    }

    if (!email) {
      setEmailError("Please enter email");
      return false;
    }

    if (!email.match(emailReg)) {
      setEmailError("Please enter valid email");
      return false;
    }

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!password) {
      setPasswordError("Please enter password");
      return false;
    }

    if (!password.match(regex)) {
      setPasswordError(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    error = checkSpace("password", password);
    if (error) {
      setPasswordError(error);
      return false;
    }

    if (!phoneNum) {
      setPhoneError("Please enter phone number");
      return false;
    }
    if (phoneNum.length > 10) {
      setPhoneError("Phone Number should be of 10 digits");
      return false;
    }
    if (phoneNum.length < 10) {
      setPhoneError("Phone Number should be of 10 digits");
      return false;
    }

    if (!gst) {
      setGstError("Please enter GST number");
      return false;
    }

    if (gst !== "URP") {
      if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
        setGstError("Enter valid GST number");
        return false;
      }
    }

    if (!panNum) {
      setPanError("Please enter pan number");
      return false;
    }

    var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!reggg.test(panNum)) {
      setPanNum(panNum);
      setPanError("Please enter valid pan number");
      return false;
    }

    if (!address) {
      setAddressError("Please enter address");
      return false;
    }

    for (let i = 0; i < formValuesManufacturer.length; i++) {
      const element = formValuesManufacturer[i];
      let manufactureError = [...formValuesManufacturerError];

      if (!element.vendor_type) {
        manufactureError[i].vendor_type = "Please select vendor type";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mName) {
        manufactureError[i].mName = "Please select product name";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mCapacity) {
        manufactureError[i].mCapacity = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mLocation) {
        manufactureError[i].mLocation = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mSPrice) {
        manufactureError[i].mSPrice = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (element.mSPrice < 0 || element.mSPrice === "0") {
        manufactureError[i].mSPrice = "Price cannot be zero or negative value";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }
    }
    // }

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let contactError = [...formValuesError];

      if (!element.aName) {
        contactError[i].aName = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      error = checkSpace("name", element.aName);
      if (error) {
        contactError[i].aName = error;
        setFormValuesError(contactError);
        return false;
      }

      if (!element.aPhoneNo) {
        contactError[i].aPhoneNo = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      if (!/^[6-9]/.test(element.aPhoneNo)) {
        contactError[i].aPhoneNo = "Please enter valid phone number";
        setFormValuesError(contactError);
        return false;
      }

      if (element.aPhoneNo.length > 10) {
        contactError[i].aPhoneNo = "Phone Number should be of 10 digits";
        setFormValuesError(contactError);
        return false;
      }
      if (element.aPhoneNo.length < 10) {
        contactError[i].aPhoneNo = "Phone Number should be of 10 digits";
        setFormValuesError(contactError);
        return false;
      }

      if (!element.designation) {
        contactError[i].designation = "Please select designation";
        setFormValuesError(contactError);
        return false;
      }
      if (!element.aEmail) {
        contactError[i].aEmail = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      error = checkSpace("email", element.aEmail);
      if (error) {
        contactError[i].aEmail = error;
        setFormValuesError(contactError);
        return false;
      }

      if (!element.aEmail.match(emailReg)) {
        contactError[i].aEmail = "Please enter valid email";
        setFormValuesError(contactError);
        return false;
      }
    }

    let vendorData = {
      vendorName: vendorName,
      companyName: companyName,
      email: email,
      password: password,
      phoneNum: phoneNum,
      website: website,
      comments: comments,
      gst: gst,
      panNum: panNum,
      address: address,
      atribute: formValues,
      manufacturerAttribute: formValuesManufacturer,
    };

    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/vendor-add", vendorData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Vendor Add Successfully");
          setTimeout(function () {
            window.location = "/vendor";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(true));
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValues(newFormValues);

    if (e.target.name === "aName") {
      if (!newFormValues[i].aName) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "aPhoneNo") {
      if (!newFormValues[i].aPhoneNo) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }

      if (!/^[6-9]/.test(newFormValues[i].aPhoneNo)) {
        newFormValuesError[i][e.target.name] =
          "Please enter valid phone number";
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (
        newFormValues[i].aPhoneNo.length < 10 ||
        newFormValues[i].aPhoneNo.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Phone Number should be of 10 digits";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
    }

    if (e.target.name === "designation") {
      if (
        !newFormValues[i].designation ||
        newFormValues[i].designation === "Select"
      ) {
        newFormValuesError[i][e.target.name] = "Please select designation";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "aEmail") {
      if (!newFormValues[i].aEmail) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }

      if (!newFormValues[i].aEmail.match(emailReg)) {
        newFormValuesError[i][e.target.name] = "Please enter valid email";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    setFormValuesError(newFormValuesError);
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);

    setFormValuesError([...formValuesError, {}]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  // For MANUFACTURER

  const handleProductChange = (selectedOption, index) => {
    let newFormValuesManufacturer = [...formValuesManufacturer];
    newFormValuesManufacturer[index]["mName"] = selectedOption.value;
    setFormValuesManufacturer(newFormValuesManufacturer);
    // error //
    let newFormValuesManufactureError = [...formValuesManufacturerError];
    newFormValuesManufactureError[index]["mName"] = selectedOption.value;

    if (!newFormValuesManufacturer[index].mName) {
      newFormValuesManufactureError[index]["mName"] =
        "Please select product name";
      setFormValuesManufacturerError(newFormValuesManufactureError);
      return false;
    } else {
      newFormValuesManufactureError[index]["mName"] = "";
      setFormValuesManufacturerError(newFormValuesManufactureError);
      setFormValuesManufacturer(newFormValuesManufacturer);
    }
  };

  let handleChangeManufacturer = (i, e) => {
    let newFormValuesManufacturer = [...formValuesManufacturer];
    newFormValuesManufacturer[i][e.target.name] = e.target.value;
    setFormValuesManufacturer(newFormValuesManufacturer);
    // error //
    let newFormValuesManufactureError = [...formValuesManufacturerError];
    newFormValuesManufactureError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";

    if (e.target.name === "vendor_type") {
      if (!newFormValuesManufacturer[i].vendor_type) {
        newFormValuesManufactureError[i][e.target.name] =
          "Please select vendor type";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mCapacity") {
      if (!newFormValuesManufacturer[i].mCapacity) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mLocation") {
      if (!newFormValuesManufacturer[i].mLocation) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mSPrice") {
      if (!newFormValuesManufacturer[i].mSPrice) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }

      if (
        newFormValuesManufacturer[i].mSPrice < 0 ||
        newFormValuesManufacturer[i].mSPrice === "0"
      ) {
        newFormValuesManufactureError[i][e.target.name] =
          "Price cannot be zero or negative value";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }
    setFormValuesManufacturerError(newFormValuesManufactureError);
    setFormValuesManufacturer(newFormValuesManufacturer);
  };

  let addFormFieldsManufacturer = () => {
    setFormValuesManufacturer([
      ...formValuesManufacturer,
      { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
    ]);
    setFormValuesManufacturerError([
      ...formValuesManufacturerError,
      { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
    ]);
  };

  let removeFormFieldsManufacturer = (i) => {
    let newFormValues = [...formValuesManufacturer];
    newFormValues.splice(i, 1);
    setFormValuesManufacturer(newFormValues);

    let newFormValuesError = [...formValuesManufacturerError];
    newFormValuesError.splice(i, 1);
    setFormValuesManufacturerError(newFormValuesError);
  };

  // For TRADER

  let handleChangeTrader = (i, e) => {
    let newFormValuesTrader = [...formValuesTrader];
    newFormValuesTrader[i][e.target.name] = e.target.value;
    setFormValuesTrader(newFormValuesTrader);
    // error //
    let newFormValuesTraderError = [...formValuesTraderError];
    newFormValuesTraderError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";

    if (e.target.name === "tName") {
      if (!newFormValuesTrader[i].tName) {
        newFormValuesTraderError[i][e.target.name] =
          "Please select product name";
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tCapacity") {
      if (!newFormValuesTrader[i].tCapacity) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tLocation") {
      if (!newFormValuesTrader[i].tLocation) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tSPrice") {
      if (!newFormValuesTrader[i].tSPrice) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }

      if (
        newFormValuesTrader[i].tSPrice < 0 ||
        newFormValuesTrader[i].tSPrice === "0"
      ) {
        newFormValuesTraderError[i][e.target.name] =
          "Price cannot be zeo or negative value";
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }
    setFormValuesTraderError(newFormValuesTraderError);
  };

  let addFormFieldsTrader = () => {
    setFormValuesTrader([...formValuesTrader, {}]);

    setFormValuesTraderError([...formValuesTraderError, {}]);
  };

  let removeFormFieldsTrader = (i) => {
    let newFormValues = [...formValuesTrader];
    newFormValues.splice(i, 1);
    setFormValuesTrader(newFormValues);

    let newFormValuesError = [...formValuesTraderError];
    newFormValuesError.splice(i, 1);
    setFormValuesTraderError(newFormValuesError);
  };

  const clearForm = () => {
    setTimeout(() => {
      window.location.href = "/vendor";
    }, 1000);
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    if (prodData.length > 0) {
      prodData.map((value, i) => {
        return categoryhtml.push(
          <option value={value.id}>{value.name}</option>
        );
      });
    }
    return categoryhtml;
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD Vendor</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Vendor Name</label>
                          <input
                            type="text"
                            name="vendorName"
                            placeholder="Vendor Name"
                            value={vendorName}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {vendorNameError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={companyName}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {compNameError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {emailError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type={typePass}
                            name="password"
                            placeholder="Password"
                            value={password}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span
                            role="button"
                            className="password__show eye1"
                            onClick={() => handleClick()}
                          >
                            {typePass === "text" ? (
                              <i className="fa fa-eye"></i>
                            ) : (
                              <i className="fa fa-eye-slash"></i>
                            )}
                          </span>
                          <span>
                            <p style={{ color: "red" }}> {passwordError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type={type}
                            onKeyDown={handleValidation}
                            name="pNumber"
                            maxLength={10}
                            placeholder="Phone Number"
                            value={phoneNum}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {phoneError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Website</label>
                          <input
                            type="text"
                            name="website"
                            placeholder="Website"
                            value={website}
                            className="form-control"
                            onChange={handleChangeTop}
                          />{" "}
                          <span>
                            <p style={{ color: "red" }}> {websiteError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>GST Number</label>
                          <input
                            type="text"
                            name="gst"
                            placeholder="GST Number"
                            value={gst}
                            maxLength={15}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {gstError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>PAN Number</label>
                          <input
                            type="text"
                            name="panNum"
                            placeholder="PAN Number"
                            value={panNum}
                            maxLength={10}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {panError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            name="billingAddress"
                            placeholder="Address"
                            value={address}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {addressError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Vendor Comments</label>
                          <input
                            type="text"
                            name="comments"
                            placeholder="Vendor Comments"
                            value={comments}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                        </div>
                      </div>

                      {/* {manufactureListOpen === true && ( */}
                      <>
                        {formValuesManufacturer.map((element, index) => (
                          <>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Vendor Type</label>

                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    handleChangeManufacturer(index, e)
                                  }
                                  name="vendor_type"
                                  value={element.vendor_type || ""}
                                  // value={selectedItem}
                                >
                                  <option value="">Select</option>
                                  <option>Customization Vendor</option>
                                  <option>Goods Importer</option>
                                  <option>Goods Manufacturer</option>
                                  <option>Goods Trader</option>
                                  <option>Raw Material Manufacturer</option>
                                  <option>Raw Material Trader</option>
                                </select>
                                <span>
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {
                                      formValuesManufacturerError[index]
                                        .vendor_type
                                    }{" "}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Product Name</label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  onChange={(option) =>
                                    handleProductChange(option, index)
                                  }
                                  isSearchable={true}
                                  isClearable={true}
                                  styles={selectStyleLight}
                                  // value={element.productId || ""}
                                  // name="vendorId"
                                  noOptionsMessage={() => "No vendor found"}
                                  name={`mName`}
                                  placeholder="Select Product"
                                  options={
                                    prodData &&
                                    prodData?.map((option) => ({
                                      value: option.id,
                                      label: option.name,
                                    }))
                                  }
                                />
                                {/* <select
                                  className="form-control"
                                  name="mName"
                                  value={element.mName || ""}
                                  onChange={(e) =>
                                    handleChangeManufacturer(index, e)
                                  }
                                >
                                  <option value="">Please select</option>
                                  {categoryHtml()}
                                </select> */}
                                <span>
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {
                                      formValuesManufacturerError[index].mName
                                    }{" "}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Capacity</label>
                                <input
                                  type="text"
                                  name="mCapacity"
                                  placeholder="Capacity"
                                  className="form-control"
                                  value={element.mCapacity || ""}
                                  onChange={(e) =>
                                    handleChangeManufacturer(index, e)
                                  }
                                />
                                <span>
                                  <p style={{ color: "red" }}>
                                    {
                                      formValuesManufacturerError[index]
                                        .mCapacity
                                    }
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Location</label>
                                <input
                                  type="text"
                                  name="mLocation"
                                  placeholder="Location"
                                  className="form-control"
                                  value={element.mLocation || ""}
                                  onChange={(e) =>
                                    handleChangeManufacturer(index, e)
                                  }
                                />
                                <span>
                                  <p style={{ color: "red" }}>
                                    {
                                      formValuesManufacturerError[index]
                                        .mLocation
                                    }
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="add-row-group d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                  <label>Selling Price</label>
                                  <input
                                    type="number"
                                    name="mSPrice"
                                    placeholder="Selling Price"
                                    onKeyDown={handleValidation}
                                    className="form-control"
                                    value={element.mSPrice || ""}
                                    onChange={(e) =>
                                      handleChangeManufacturer(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {
                                        formValuesManufacturerError[index]
                                          .mSPrice
                                      }
                                    </p>
                                  </span>
                                </div>
                                <div className="add-row">
                                  {index ? (
                                    <a
                                      href="#!"
                                      onClick={() =>
                                        removeFormFieldsManufacturer(index)
                                      }
                                    >
                                      -
                                    </a>
                                  ) : null}
                                  <a
                                    href="#!"
                                    onClick={() => addFormFieldsManufacturer()}
                                  >
                                    +
                                  </a>
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Add Product
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>

                      <div className="col-12">
                        <div className="contact-person-heading">
                          <h5>Contact Person</h5>
                        </div>
                      </div>
                      {formValues.map((element, index) => (
                        <>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="aName"
                                placeholder="Name"
                                className="form-control"
                                value={element.aName || ""}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index].aName}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Phone Number</label>
                              <input
                                type={type}
                                name="aPhoneNo"
                                onKeyDown={handleValidation}
                                placeholder="Phone Number"
                                maxLength={10}
                                className="form-control"
                                value={element.aPhoneNo || ""}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index].aPhoneNo}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Designation</label>
                              <select
                                className="form-control"
                                name="designation"
                                value={element.designation || ""}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">Select</option>
                                <option>Accounts Manager</option>
                                <option>Director</option>
                                <option>Factory Manager</option>
                                <option>Machine Man</option>
                                <option>Partner</option>
                                <option>Proprietor</option>
                                <option>Store Manager</option>
                                {designationHtml()}
                              </select>
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index].designation}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="aEmail"
                                  placeholder="Email"
                                  className="form-control"
                                  value={element.aEmail || ""}
                                  onChange={(e) => handleChange(index, e)}
                                />
                                <span>
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].aEmail}
                                  </p>
                                </span>
                              </div>
                              <div className="add-row">
                                {index ? (
                                  <a
                                    href="#!"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a href="#!" onClick={() => addFormFields()}>
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/vendor"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default VendorAdd;
