import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function UnitsEdit() {
  const [id, setId] = useState(useParams().id);
  const [units, setUnits] = useState("");
  const [unitsErr, setUnitsErr] = useState("");
  const [loading, setLoading] = useState(false);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    unitsData();
  }, []);

  const handleChangeUnits = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue) {
      setUnits(eventValue);
      setUnitsErr("Units is required");
      return false;
    }

    setUnitsErr("");
    setUnits(eventValue);
  };

  const unitsData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/units-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setId(resp.data.sqlRun[0].id);
        setUnits(resp.data.sqlRun[0].units);
      }
    });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!units) {
      setUnitsErr("Units is required");
      return false;
    }

    let error = checkSpace("units", units);
    if (error) {
      setUnitsErr(error);
      return false;
    }

    setLoading(true);
    let unitsData = {
      id: id,
      units: units,
    };
    axios
      .post(baseUrl + "/frontapi/units-edit", unitsData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Unit updated successfully");
          setTimeout(function () {
            window.location = "/units";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="UNIT EDIT"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Unit</label>
              <input
                type="text"
                name="units"
                placeholder="UNIT"
                className="form-control"
                onChange={handleChangeUnits}
                value={units}
              />
              <p style={{ color: "red" }}>{unitsErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup onClick={handleSubmit} link="/units" loading={loading} />
    </ContentLayout>
  );
}
export default UnitsEdit;
