import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import axios from "axios";

const baseUrl = myConstList.baseUrl;
function Login(props) {
  if (!localStorage.jwtToken) {
  } else {
    window.location.href = "/dashboard";
  }
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [ip, setIp] = useState("");
  const [typePass, setTypePass] = useState("password");

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
    getData();
  }, []);

  async function getData() {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setIp(data.ip);
    localStorage.setItem("ip", data.ip ? data.ip : "");
  }

  const handleChangeEmail = async (event) => {
    let eventValue = event.target.value;
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    setEmail(eventValue);
    if (!eventValue) {
      setEmail(eventValue);
      setEmailErr("Please enter email address");
      return false;
    }

    if (!eventValue.match(emailReg)) {
      setEmail(eventValue);
      setEmailErr("Please enter valid email address");
      return false;
    }

    setEmailErr("");
    setEmail(eventValue);
  };
  const handlePassword = async (event) => {
    let eventValue = event.target.value;
    setPassword(eventValue);

    if (!eventValue) {
      setPassword(eventValue);
      setPassErr("Please enter password");
      return false;
    }
    setPassword(eventValue);
    setPassErr("");
  };

  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    event.preventDefault();

    if (!Email && !password) {
      setEmailErr("Please enter email address");
      setPassErr("Please enter password");
      return false;
    }

    if (!Email) {
      setEmailErr("Please enter email address");
      return false;
    }

    if (!Email.match(emailReg)) {
      setEmailErr("Please enter valid email address");
      return false;
    }

    if (!password) {
      setPassErr("Please enter password");
      return false;
    }

    let LoginData = {
      email: Email,
      password: password,
      ip: ip,
    };
    axios.post(baseUrl + "/frontapi/login-admin", LoginData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        localStorage.setItem("email", Email);
        localStorage.setItem("password", password);
        // localStorage.setItem("checkbox", isChecked);
        let token = resp.token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", resp.data[0].userType);
        toast.success("Login Successfully");
        setTimeout(function () {
          window.location = "/dashboard";
        }, 3000);
        return false;
      }
    });
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <div className="login d-flex nm-aic nm-vh-md-100">
      <div className="overlay" />
      <div className="nm-tm-wr">
        <div className="container">
          <form>
            <div className="nm-hr nm-up-rl-3">
              <h2>Login</h2>
            </div>
            <div className="form-group nm-gp">
              <span className="nm-gp-pp">
                {/* <i className="fa-user" /> */}
                <iconify-icon icon="ph:user-duotone"></iconify-icon>
              </span>
              <input
                type="text"
                className="form-control"
                id="inputUsername"
                tabIndex={1}
                placeholder="Email"
                required=""
                onChange={handleChangeEmail}
              />
              <p style={{ color: "red" }}>{emailErr}</p>
            </div>
            <div className="form-group nm-gp">
              <span className="nm-gp-pp">
                <iconify-icon icon="material-symbols:lock"></iconify-icon>
              </span>
              <input
                type={typePass}
                className="form-control"
                id="inputPassword"
                tabIndex={2}
                placeholder="Password"
                required=""
                onChange={handlePassword}
              />{" "}
              <span
                role="button"
                className="password__show eye1"
                style={{
                  position: "absolute",
                  top: "10px",
                }}
                onClick={() => handleClick()}
              >
                {typePass === "text" ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </span>
              <p style={{ color: "red" }}>{passErr}</p>
            </div>
            <div className="form-group nm-gp">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                />
                <label
                  className="form-check-label nm-check"
                  htmlFor="rememberMe"
                >
                  Keep me logged in
                </label>
              </div>
            </div>
            <div className="row nm-aic nm-mb-1">
              <div className="col-sm-6 nm-mb-1 nm-mb-sm-0">
                <button
                  type="submit"
                  className="btn btn-primary nm-hvr nm-btn-2"
                  onClick={handleSubmit}
                >
                  Log In
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default Login;
