import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { reactTableConfig } from "../lib/reactTableConfig";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
const baseUrl = myConstList.baseUrl;

const config = reactTableConfig("Customers");

const YTDSales = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    SalesData();
  }, []);

  const SalesData = () => {
    axios.post(baseUrl + "/frontapi/all-sales-data", SalesData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        filterDaData(resp.data);
        // setRecord(resp.data);
      }
    });
  };

  const filterDaData = (records) => {
    let html = [];
    for (let i = 0; i < records.length; i++) {
      const element = records[i];
      let created_date = element.createdAt;

      // Parse the date using moment
      const date = moment(created_date);

      // Get the month as a numeric value (0 for January, 1 for February, and so on)
      const monthNumber = date.month();
      const year = date.year();

      const currentDate = moment();
      const currentYear = currentDate.year();

      if (currentYear >= year && monthNumber >= 3) {
        html.push(element);
      }

      setRecord(html);
    }
  };

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Sales Person Name",
      className: "sales_name",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.amount} INR</Fragment>;
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.createdAt).format("lll")} </Fragment>;
      },
    },
  ];

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>YTD Sales</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default YTDSales;
