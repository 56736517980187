import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function LeadSourceEdit() {
  const [id, setId] = useState(useParams().id);

  const [leadSource, setLeadSource] = useState("");
  const [leadSourceErr, setLeadSourceErr] = useState("");

  const [loading, setLoading] = useState(false);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    LeadSourceData();
  }, []);

  const handleChange = async (event) => {
    let { name, value } = event.target;

    if (name === "leadSource") {
      setLeadSource(value);
      if (!value) {
        setLeadSourceErr("Lead source is required");
        return false;
      }
      setLeadSourceErr("");
    }
  };

  const LeadSourceData = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/lead-source-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setId(resp.data.sqlRun[0].id);
          setLeadSource(resp.data.sqlRun[0].leadSource);
        }
      });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!leadSource) {
      setLeadSourceErr("Lead source is required");
      return false;
    }

    let error = checkSpace("lead source", leadSource);

    if (error) {
      setLeadSourceErr(error);
      return false;
    }
    setLoading(true);
    let LeadSourceData = {
      id: id,
      leadSource: leadSource,
    };
    axios
      .post(baseUrl + "/frontapi/lead-source-edit", LeadSourceData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Lead source updated successfully");
          setTimeout(function () {
            window.location = "/lead-source";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="Lead Source Edit"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Lead Source</label>
              <input
                type="text"
                name="leadSource"
                placeholder="Lead Source"
                className="form-control"
                onChange={handleChange}
                value={leadSource}
              />
              <p style={{ color: "red" }}>{leadSourceErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/lead-source"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default LeadSourceEdit;
