import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { MdDelete, MdEdit } from "react-icons/md";
import { IconContext } from "react-icons";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Issue Type");

const IssueType = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    issueData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "issueType",
      text: "Issue Type",
      className: "issueType",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <IconContext.Provider
              value={{
                color: "white",
                size: "2.5em",
                style: { padding: "3px" },
              }}
            >
              <MdEdit
                onClick={() =>
                  (window.location.href = "/issue-type-edit/" + btoa(record.id))
                }
                className="btn"
                style={{ background: "#00bf66" }}
              />

              <MdDelete
                className="btn"
                style={{ background: "#f95c6b" }}
                onClick={() => Conn(record.id)}
              />
            </IconContext.Provider>
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const issueData = () => {
    axios.get(baseUrl + "/frontapi/issue-type-data", issueData).then((res) => {
      var resp = res.data;
      if (resp.status == true) {
        setRecord(resp.data);
      }
    });
  };

  const deleted = (id) => {
    let params = {
      id: id,
    };
    axios.post(baseUrl + "/frontapi/issue-type-delete", params).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status === false) {
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        toast.success(resp.message);
        issueData();
        // setRefreshed(true);
      }
    });
  };

  return (
    <ContentLayout
      title="Issue Type List"
      buttonTitle="Add Issue Type"
      buttonUrl="/issue-type-add"
      className="product-list-outer customer-list-outer"
    >
      <ReactDatatable
        // className="table table-bordered table-striped table-responsive"
        config={config}
        records={record}
        columns={columns}
      />
    </ContentLayout>
  );
};

export default IssueType;
