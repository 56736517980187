import React, { useState, useEffect } from "react";
import axios from "axios";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

const ManufactureDetails = () => {
  const Params = useParams();
  const orderType = atob(Params.orderType);
  const theOrderId = atob(Params.orderId);
  const [tableData, setTableData] = useState([]);
  const [rawMaterialData, setRawMaterialData] = useState([]);

  useEffect(() => {
    orderData();
    RawData();
  }, []);

  const orderData = async () => {
    const data = {
      againstOrderId: theOrderId,
      orderType: orderType,
    };
    await axios
      .post(baseUrl + "/frontapi/getManufactureDetail", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTableData(resp.data);
        }
      });
  };

  const RawData = async () => {
    const data = {
      orderId: theOrderId,
    };
    await axios
      .post(baseUrl + "/frontapi/getRawMaterialDetail", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setRawMaterialData(resp.data);
        }
      });
  };

  const manufactureHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Vendor Order ID: <b>{value.id}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name: <b>{value.prod_name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Assigned to: <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Ordered Quantity:{" "}
                <b>
                  {value.quantity} {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Produced Quantity:{" "}
                <b>
                  {value.manufactured_quantity
                    ? `${value.manufactured_quantity} ${value.units}`
                    : "N/A"}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding ">
            <div className="person-detail">
              <p>
                Price: <b>{value.price} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                GST: <b>{value.tax}%</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Total: <b>{value.total} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding ">
            <div className="person-detail">
              <p>
                Status : {value.status === "1" ? <b>InProcess</b> : ""}
                {value.status === "0" ? <b>Pending</b> : ""}
                {value.status === "2" ? <b>Completed</b> : ""}
                {value.status === "3" ? <b>Rejected</b> : ""}
              </p>
            </div>
          </div>
          {tableData.length > 1 && <hr style={{ color: "white" }} />}
        </div>
      );
    });
    return html;
  };
  const rawHtml = () => {
    const html = [];
    rawMaterialData.map((value, i) => {
      return html.push(
        <div className="row">
          {value.vendor_order_status === "3" ? (
            <>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Product Name : <b>{value.att[0].prod_name}</b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Holded Quantity :{" "}
                    <b>
                      {value.locked_qty} {value.units}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Status : <b>No Order Placed</b>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Product Name : <b>{value.att[0].prod_name}</b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Assigned to:{" "}
                    <b>
                      {value.att[0].name == null ? "N/A" : value.att[0].name}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Quantity:{" "}
                    <b>
                      {value.locked_qty} {value.units}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Price: <b>{value.att[0].price} INR</b>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    GST: <b>{value.att[0].tax}%</b>
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Total: <b>{value.att[0].total} INR</b>
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Status :
                    {value.vendor_order_status === "1" ? <b>InProcess</b> : ""}
                    {value.vendor_order_status === "0" ? <b>Pending</b> : ""}
                    {value.vendor_order_status === "2" ? <b>Completed</b> : ""}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>
                    {" "}
                    {orderType === "buy" ? "BUY" : ""}{" "}
                    {orderType === "orderGiven" ? "MANUFACTURE" : ""} DETAIL
                  </b>
                </h2>
              </div>
              <div className=" customer-detail-outer">
                <div className="order-detail-outer-middle pending-list-outer">
                  <div className="col-12">
                    <div className="person-contact customer-detail">
                      <h3>
                        {" "}
                        {orderType === "buy" ? "Buy" : ""}{" "}
                        {orderType === "orderGiven" ? "Manufacture" : ""} Order
                        Detail
                      </h3>
                      {manufactureHtml()}
                    </div>
                  </div>
                </div>
              </div>

              {orderType === "orderGiven" && (
                <div className=" customer-detail-outer">
                  <div className="order-detail-outer-middle pending-list-outer">
                    <div className="col-12">
                      <div className="person-contact customer-detail">
                        <h5>
                          <h3 className="text-black">Raw Material Details</h3>
                        </h5>
                        {rawHtml()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default ManufactureDetails;
