import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
const baseUrl = myConstList.baseUrl;

function CatManAddProducts(props) {
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [attrData, setAttrData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [productName, setProductName] = useState("");
  const [unitsData, setUnitsData] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [tax, setTax] = useState("");
  const [imgCollection, setImgCollection] = useState([]);
  const [image, setImage] = useState([]);
  const [formValues, setFormValues] = useState([{ aName: "", aValue: "" }]);
  const [inventoryValues, setInventoryValues] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
      material_units: "",
    },
  ]);

  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [productDefination, setProductDefination] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [productType, setProductType] = useState("");
  const [gstData, setGstData] = useState([]);
  const [avaliableQty, setAvaliableQty] = useState("");
  const [isMaterialUse, setIsMaterialUse] = useState(false);
  const [salePrice, setSalePrice] = useState("");

  // errors //
  const [nameErr, setNameErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [lowStockErr, setLowStockErr] = useState("");
  const [prodPriceErr, setProdPriceErr] = useState("");
  const [salesPriceErr, setSalesPriceErr] = useState("");
  const [hsnErr, setHsnErr] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const [units, setUnits] = useState("");
  const [unitsError, setUnitsError] = useState("");

  const [formValuesError, setFormValuesError] = useState([
    { aName: "", aValue: "" },
  ]);

  const [inventoryValuesError, setInventoryValuesError] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
      material_units: "",
    },
  ]);

  useEffect(() => {
    categoryData();
    attributeData();
    getMaterialData();
    GetgstData();
    GetUnitsData();
  }, []);

  const GetgstData = () => {
    axios.post(baseUrl + "/frontapi/gst-data-deleted", gstData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setGstData(resp.data);
      }
    });
  };

  const GetUnitsData = () => {
    axios.post(baseUrl + "/frontapi/units-data-deleted", {}).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setUnitsData(resp.data);
      }
    });
  };

  const handleChangeDesc = (e) => {
    let { value } = e.target;
    if (!value) {
      setDescErr("Please enter description");
      setDescription(value);
      return false;
    }
    setDescErr("");
    setDescription(value);
  };
  const handleChangeLowStock = (e) => {
    let { value } = e.target;
    if (!value) {
      setLowStockErr("Please enter low Stock Threshold");
      setLowStockThreshold(value);
      return false;
    }
    setLowStockErr("");
    setLowStockThreshold(value);
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = async (event) => {
    let { value, name } = event.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });

    if (name === "productName") {
      if (!value) {
        setProductName(value);
        setNameErr("Please enter product name");
        return false;
      }

      setNameErr("");
    }
    if (name === "price") {
      setPrice(value);
      if (!value) {
        setProdPriceErr("Please enter product price");
        return false;
      }
      // var deciRegex = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

      if (value < 0 || value == 0) {
        setProdPriceErr("Product price cannot be zero or negative");
        return false;
      }

      if (salePrice !== "") {
        if (value > Number(salePrice)) {
          setProdPriceErr("Product price cannot be higher than sales price");
          return false;
        }
      }

      setProdPriceErr("");
    }

    if (name === "hsnCode") {
      setHsnCode(value);
      if (!value) {
        setHsnErr("Please enter product HSN Code");
        return false;
      }

      setHsnErr("");
    }

    if (name === "tax") {
      setTax(value);
      // if (!value || value === "") {
      //   toast.dismiss();
      //   toast.error("Please select tax");
      //   setTax(value);
      //   return false;
      // }
    }

    if (name === "avaliableQty") {
      setAvaliableQty(value);
      if (!value) {
        setQuantityErr("Please enter available quantity");
        return false;
      }

      let regexx = "^[0-9]*$";

      if (!value.match(regexx)) {
        setQuantityErr("Available quantity should be only numeric value");
        return false;
      }

      setQuantityErr("");
    }

    if (name === "salePrice") {
      setSalePrice(value);
      if (!value) {
        setSalesPriceErr("Please enter product sales price");
        return false;
      }

      if (value < 0 || value == 0) {
        setSalesPriceErr("Product price cannot be zero or negative");
        return false;
      }

      if (price !== "") {
        if (value < Number(price)) {
          setProdPriceErr("Product price cannot be higher than sales price");
          return false;
        } else {
          setProdPriceErr("");
          setSalesPriceErr("");
        }
      }

      setSalesPriceErr("");
    }
  };

  const subCategoryHtml = () => {
    const html = [];
    var SCData = subCatData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SCData.map((value, i) => {
      return html.push(<option value={value.id}>{value.name}</option>);
    });
    return html;
  };

  const categoryHtml = () => {
    const categoryhtml = [];

    catData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const categoryData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/category-data-deleted-CatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setCatData(resp.data);
          // setCategoryId(resp.data[0].id);
          subCategoryData(resp.data[0].id);
        }
      });
  };

  const subCategoryData = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/frontapi/sub-category-by-catid", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          setSubCatData([]);
          return false;
        }
        if (resp.status === true) {
          setSubCatData(resp.data);
        }
      });
  };

  const attributeHtml = () => {
    const attributeHtml = [];
    var aData = attrData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    aData.map(function (value, i) {
      return attributeHtml.push(<option value={value.id}>{value.name}</option>);
    });
    return attributeHtml;
  };

  const materialHtml = () => {
    var materialHtml = [];

    var mData = materialData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    mData.map(function (value, i) {
      return materialHtml.push(
        <option value={value.name}>{value.name}</option>
      );
    });
    return materialHtml;
  };

  const getMaterialData = () => {
    axios
      .post(baseUrl + "/frontapi/material-data-raw", getMaterialData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setMaterialData(resp.data);
        }
      });
  };

  const attributeData = () => {
    axios
      .post(baseUrl + "/frontapi/attribute-data-deleted", attributeData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setAttrData(resp.data);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let regexx = "^[0-9]*$";
    var formData = new FormData();

    if (imgCollection.length > 0) {
      for (let i = 0; i < imgCollection.length; i++) {
        const element = imgCollection[i][0];

        if (!element.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          toast.dismiss();
          toast.error("Please select valid image jpg,jpeg,png,gif");
          return false;
        }
        if (element.size >= 3e6) {
          toast.dismiss();
          toast.error("Product image size should not be more than 3MB");
          return false;
        }
      }
    }

    if (
      !productName &&
      !description &&
      !lowStockThreshold &&
      !price &&
      !salePrice &&
      !hsnCode &&
      !avaliableQty &&
      !units
    ) {
      setNameErr("Please enter product name");
      setDescErr("Please enter product description");
      setLowStockErr(" Please enter low stock threshold");
      setProdPriceErr("Please enter product price");
      setSalesPriceErr("Please enter sales price");
      setHsnErr("Please enter HSN code");
      setQuantityErr("Please enter available quantity");
      setUnitsError("Please select quantity units");
      return false;
    }

    if (!productName) {
      setNameErr("Please enter product name");
      return false;
    }
    if (!categoryId || categoryId === "") {
      toast.dismiss();
      toast.error("Please select category");
      return false;
    }
    if (!subCategoryId || subCategoryId === "") {
      toast.dismiss();
      toast.error("Please select sub category");
      return false;
    }

    if (!description) {
      setDescErr(" Please enter product description");
      return false;
    }

    if (!productDefination || productDefination === "") {
      toast.dismiss();
      toast.error(" Please select product defination");
      return false;
    }

    if (!lowStockThreshold) {
      setLowStockErr(" Please enter low stock threshold");
      return false;
    }

    if (!price) {
      setProdPriceErr(" Please enter product price");
      return false;
    }

    if (price < 0 || parseFloat(price) === 0) {
      setSalesPriceErr("Ptoduct price cannot be zero or negative value");
      return false;
    }

    if (!salePrice) {
      setSalesPriceErr(" Please enter sales price");
      return false;
    }

    if (salePrice < 0 || parseFloat(salePrice) === 0) {
      setSalesPriceErr("Sales price cannot be zero or negative value");
      return false;
    }

    if (Number(price) > Number(salePrice)) {
      setProdPriceErr("Product price cannot be higher than sales price");
      return false;
    }
    if (Number(salePrice) < Number(price)) {
      setProdPriceErr("Product price cannot be higher than sales price");
      return false;
    }
    if (!hsnCode) {
      setHsnErr(" Please enter HSN code");
      return false;
    }

    if (!avaliableQty) {
      setQuantityErr(" Please enter available quantity");
      return false;
    }

    if (!avaliableQty.match(regexx)) {
      setQuantityErr("Available quantity should be only numeric value");
      return false;
    }

    if (!units || units === "") {
      toast.dismiss();
      toast.error("Please select quantity units");
      return false;
    }

    if (!productType || productType === "") {
      toast.dismiss();
      toast.error(" Please select product type");
      return false;
    }

    if (!tax || tax === "") {
      toast.dismiss();
      toast.error(" Please select tax");
      return false;
    }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.aName || element.aName === "") {
        toast.dismiss();
        toast.error(" Please select attribute name");

        // formError[i].aName = "Please select attribute name";
        // setFormValuesError(formError);
        return false;
      }

      if (!element.aValue) {
        formError[i].aValue = "This field is required";
        setFormValuesError(formError);
        return false;
      }
      html.push(element.aName);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate attribute name found");
      return false;
    }

    // if (isMaterialUse === true) {
    //   for (let i = 0; i < inventoryValues.length; i++) {
    //     const element = inventoryValues[i];
    //     let inventoryError = [...inventoryValuesError];
    //     var itemType = element.material_type;
    //     var itemQuantity = element.material_quantity;
    //     if (!itemType) {
    //       inventoryError[i].material_type = "Please select raw material";
    //       setInventoryValuesError(inventoryError);
    //       return false;
    //     }
    //     if (!itemQuantity) {
    //       inventoryError[i].material_quantity = "This field is required";
    //       setInventoryValuesError(inventoryError);
    //       return false;
    //     }
    //   }
    // }

    for (const key of Object.keys(imgCollection)) {
      formData.append("imgCollection", imgCollection[key][0]);
    }
    formData.append("productName", productName);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("salePrice", salePrice);
    formData.append("hsnCode", hsnCode);
    formData.append("tax", tax);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryId", subCategoryId);
    formData.append("avaliableQty", avaliableQty);
    formData.append("atribute", JSON.stringify(formValues));
    formData.append("materials", JSON.stringify(inventoryValues));
    formData.append("prod_def", productDefination);
    formData.append("prod_type", productType);
    formData.append("lowStockThreshold", lowStockThreshold);
    formData.append("units", units);

    axios.post(baseUrl + "/frontapi/product-add", formData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        toast.dismiss();
        toast.success("Product added successfully");
        setTimeout(function () {
          window.location = "/products-CatMan";
        }, 3000);
        return false;
      }
    });
  };

  let addFormFields = () => {
    setFormValues([...formValues, { aName: "", aValue: "" }]);
    setFormValuesError([...formValuesError, { aName: "", aValue: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValues(newFormValues);

    if (e.target.name === "aName") {
      if (
        !newFormValues[i].aName ||
        newFormValues[i].aName === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "";

        // newFormValuesError[i][e.target.name] = "Please select attribute name";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "aValue") {
      if (!newFormValues[i].aValue) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  // Raw Material Form

  let addFormFieldsMaturials = () => {
    setInventoryValues([...inventoryValues, {}]);
    setInventoryValuesError([...inventoryValuesError, {}]);
  };

  let removeFormFieldsMaturial = (i) => {
    let newFormValues = [...inventoryValues];
    newFormValues.splice(i, 1);
    setInventoryValues(newFormValues);

    let newFormValuesError = [...inventoryValuesError];
    newFormValuesError.splice(i, 1);
    setInventoryValuesError(newFormValuesError);
  };

  let handleChangeMaturial = (i, e) => {
    let newFormValues = [...inventoryValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...inventoryValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setInventoryValues(newFormValues);

    if (e.target.name === "material_type") {
      if (
        !newFormValues[i].material_type ||
        newFormValues[i].material_type === ""
      ) {
        // newFormValuesError[i][e.target.name] = "Please select raw material";
        setInventoryValues([{}]);
        setInventoryValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setInventoryValuesError(newFormValuesError);
        const data = {
          byName: "yes",
          materialName: valv.material_type,
        };

        axios
          .post(baseUrl + "/frontapi/material-data-raw", data)
          .then((res) => {
            var resp = res.data;

            if (resp.status === true) {
              valv.material_available_quantity = resp.data[0].avaliable_qty;
              valv.material_id = resp.data[0].id;
              valv.material_units = resp.data[0].units;
            }
          });
      }
    }

    if (e.target.name === "material_quantity") {
      if (
        !newFormValues[i].material_quantity ||
        newFormValues[i].material_quantity === ""
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setInventoryValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setInventoryValuesError(newFormValuesError);
      }
    }
    setInventoryValuesError(newFormValuesError);
    setInventoryValues(newFormValues);
  };

  const onFileChange = async (event) => {
    let ImagesArray = Object.entries(event.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );

    // if (!event.target.files[0]) {
    //   toast.error("Please select product image");
    //   return false;
    // }

    setImage([...image, ...ImagesArray]);
    setImgCollection([...imgCollection, event.target.files]);
  };

  const showImages = () => {
    const html = [];

    if (image.length > 0) {
      image.map((element, index) => {
        return html.push(
          <div className="imageSize" key={element}>
            <img className="uploaded-images" src={element} alt="" />
            <span
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(index)}
            >
              X
            </span>
          </div>
        );
      });
    }
    return html;
  };

  const handleChangeCategory = async (event) => {
    let { name, value } = event.target;

    if (name === "categoryId") {
      if (!value || value === "") {
        toast.dismiss();
        // toast.error("Please select category");
        setSubCategoryId("");
        setCategoryId(value);
        setSubCatData([]);
        return false;
      }
      setSubCategoryId("");
      setCategoryId(value);
      subCategoryData(value);
    }

    if (name === "subCategoryId") {
      if (!value || value === "") {
        toast.dismiss();
        // toast.error("Please select sub category");
        setSubCategoryId(value);
        return false;
      }
      setSubCategoryId(value);
    }
  };

  const handleChangeProductDefination = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue || eventValue === "") {
      toast.dismiss();
      // toast.error("Please select product defination");
      setProductDefination(eventValue);
      return false;
    }
    toast.dismiss();
    setProductDefination(eventValue);
  };

  const handleChangeUnits = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue || eventValue === "") {
      setUnits(eventValue);
      toast.dismiss();
      // toast.error("Please select quantity units");
      return false;
    }

    setUnitsError("");
    setUnits(eventValue);
  };

  const handleChangeProductType = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue || eventValue === "") {
      toast.dismiss();
      // toast.error("Please select product type");
      setInventoryValues([{}]);
      setInventoryValuesError([{}]);
      setIsMaterialUse(false);
      setProductType(eventValue);
      return false;
    }

    if (eventValue === "finished") {
      setInventoryValues([{}]);
      setProductType(eventValue);
      setIsMaterialUse(true);
      return false;
    }
    if (eventValue === "raw" || eventValue === "imported") {
      setInventoryValues([{}]);
      setInventoryValuesError([{}]);
      setProductType(eventValue);
      setIsMaterialUse(false);
      return false;
    }
    setIsMaterialUse(false);
    setProductType(eventValue);
  };

  const removeItem = (element) => {
    const s = image.filter((item, index) => index !== element);
    const indexToRemove = element; // The index of the file to remove
    let filesArray = Array.from(imgCollection); // Convert FileList to an array
    filesArray.splice(indexToRemove, 1); // Remove the file from the array
    setImgCollection(filesArray);
    setImage(s);
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD PRODUCT</b>
              </h2>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form enctype="multipart/form-data">
                    <div className="row">
                      <div className="form-group mb-0">
                        <label>
                          Product Image
                          {/* (Select Multiple Photos At Once) */}
                        </label>
                      </div>
                      <div className="form-group file-upload position-relative">
                        <div className="">
                          <input
                            className="profile_input"
                            type="file"
                            name="bunner"
                            // multiple
                            accept="image/*"
                            onChange={onFileChange}
                          />
                        </div>
                      </div>

                      <div id="uploadedImages">{showImages()}</div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Name</label>
                          <input
                            type="text"
                            name="productName"
                            placeholder="Product Name"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {nameErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <select
                            className="form-control"
                            name="categoryId"
                            onChange={handleChangeCategory}
                            value={categoryId}
                            // disabled
                          >
                            <option value="">Please select</option>
                            {categoryHtml()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Sub Category</label>
                          <select
                            className="form-control"
                            name="subCategoryId"
                            onChange={handleChangeCategory}
                            value={subCategoryId}
                          >
                            <option value="">Please select</option>
                            {subCategoryHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Defination</label>
                          <select
                            className="form-control"
                            name="productDefination"
                            onChange={handleChangeProductDefination}
                            value={productDefination}
                          >
                            <option value="">Please select</option>
                            <option value="inventory">Inventory</option>
                            <option value="make_to_order">Make to Order</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Low Stock Threshold</label>
                          <input
                            type={"text"}
                            className="form-control"
                            onChange={handleChangeLowStock}
                            placeholder="Low Stock Threshold"
                            name="lowStock"
                            value={lowStockThreshold}
                          />
                          <p style={{ color: "red" }}> {lowStockErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Price</label>
                          <input
                            type="number"
                            onKeyDown={handleValidation}
                            name="price"
                            placeholder="Product Price"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {prodPriceErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Sale Price</label>
                          <input
                            type="number"
                            onKeyDown={handleValidation}
                            name="salePrice"
                            placeholder="Sale Price"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {salesPriceErr} </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>HSN Code</label>
                          <input
                            type="text"
                            name="hsnCode"
                            placeholder="HSN Code"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {hsnErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Available Quantity</label>
                          <input
                            type="number"
                            onKeyDown={handleValidation}
                            name="avaliableQty"
                            placeholder="Available Quantity"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {quantityErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Quantity Units</label>
                          <select
                            className="form-control"
                            name="units"
                            onChange={handleChangeUnits}
                            value={units}
                          >
                            <option value={""}>Select Units</option>
                            {unitsData.map((item, i) => {
                              return (
                                <option value={item.units}>{item.units}</option>
                              );
                            })}
                          </select>
                          <p style={{ color: "red" }}>{unitsError}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Type</label>
                          <select
                            className="form-control"
                            name="productType"
                            onChange={handleChangeProductType}
                            value={productType}
                          >
                            <option value="">Please select</option>
                            <option value="raw">Raw</option>
                            <option value="finished">Finished</option>
                            <option value="imported">Imported</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Tax</label>
                          <select
                            className="form-control"
                            name="tax"
                            onChange={handleChange}
                            value={tax}
                          >
                            <option value={""}>Select Tax</option>
                            {gstData.map((item, i) => {
                              return (
                                <option value={item.gst}>{item.gst}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            placeholder="Enter Description"
                            className="form-control resize"
                            name="description"
                            onChange={handleChangeDesc}
                            value={description}
                          ></textarea>
                          <p style={{ color: "red" }}> {descErr} </p>
                        </div>
                      </div>

                      {formValues.map((element, index) => (
                        <>
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>Attribute Name</label>

                              <select
                                className="form-control"
                                name="aName"
                                value={element.aName || ""}
                                onChange={(e) => handleChangeAttr(index, e)}
                              >
                                <option>Please select</option>
                                {attributeHtml()}
                              </select>
                              <p style={{ color: "red" }}>
                                {" "}
                                {formValuesError[index].aName}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Attribute Description</label>
                                <input
                                  type="text"
                                  name="aValue"
                                  placeholder="Attribute Description"
                                  className="form-control"
                                  value={element.aValue || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red", margin: "0" }}>
                                  {" "}
                                  {formValuesError[index].aValue}{" "}
                                </p>
                              </div>
                              <div className="add-row">
                                {index ? (
                                  <a
                                    className="remove"
                                    href="#!"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a
                                  className="add-btn add"
                                  href="#!"
                                  onClick={() => addFormFields()}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      {isMaterialUse === true && (
                        <>
                          {inventoryValues.map((element, index) => (
                            <>
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Raw Material</label>
                                  <select
                                    className="form-control"
                                    name="material_type"
                                    onChange={(e) =>
                                      handleChangeMaturial(index, e)
                                    }
                                    value={element.material_type || ""}
                                  >
                                    <option value="">Please select</option>
                                    {materialHtml()}
                                  </select>
                                  <p style={{ color: "red" }}>
                                    {inventoryValuesError[index].material_type}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-3 col-12">
                                <div className="form-group">
                                  <label>Raw Material Quantity</label>
                                  <input
                                    className="form-control"
                                    name="material_quantity"
                                    placeholder="Raw Material Quantity"
                                    type={"number"}
                                    onKeyDown={handleValidation}
                                    value={element.material_quantity || ""}
                                    onChange={(e) =>
                                      handleChangeMaturial(index, e)
                                    }
                                  />
                                  <p style={{ color: "red", margin: "0" }}>
                                    {
                                      inventoryValuesError[index]
                                        .material_quantity
                                    }
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-3 col-12">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group">
                                    <label>Raw Material Unit</label>
                                    <input
                                      className="form-control"
                                      name="material_units"
                                      type="text"
                                      placeholder="Material Quantity"
                                      disabled
                                      value={element.material_units || ""}
                                    />
                                  </div>

                                  <div className="add-row">
                                    <a
                                      className="add-btn add"
                                      href="#!"
                                      onClick={() => addFormFieldsMaturials()}
                                    >
                                      +
                                    </a>
                                    {index ? (
                                      <a
                                        className="remove btn btn-primary"
                                        href="#!"
                                        onClick={() =>
                                          removeFormFieldsMaturial(index)
                                        }
                                      >
                                        -
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </form>
                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      {" "}
                      Save{" "}
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => (window.location.href = "/products")}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default CatManAddProducts;
