import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function VentureEdit() {
  const [id, setId] = useState(useParams().id);
  const [industryData, setIndustryData] = useState([]);
  const [industryId, setIndustryId] = useState("");
  const [industryIdErr, setIndustryIdErr] = useState("");
  const [venture, setVenture] = useState("");
  const [ventureErr, setVentureErr] = useState("");

  const [loading, setLoading] = useState(false);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    getIndustry();
    ventureData();
  }, []);

  const handleChange = async (event) => {
    let { name, value } = event.target;

    if (name === "industryId") {
      setIndustryId(value);
      if (!value) {
        setIndustryIdErr("Please select industry");
        return false;
      }
      setIndustryIdErr("");
    }

    if (name === "venture") {
      setVenture(value);
      if (!value) {
        setVentureErr("Venture is required");
        return false;
      }
      setVentureErr("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const ventureData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/venture-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setId(resp.data.sqlRun[0].id);
        setIndustryId(resp.data.sqlRun[0].industryId);
        setVenture(resp.data.sqlRun[0].venture);
      }
    });
  };

  const getIndustry = () => {
    axios
      .post(baseUrl + "/frontapi/industry-data-deleted", getIndustry)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status == true) {
          setIndustryData(resp.data);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!industryId) {
      setIndustryIdErr("Please select industry");
      return false;
    }

    if (!venture) {
      setVentureErr("Venture is required");
      return false;
    }


    let error = checkSpace("venture", venture);

    if (error) {
      setVentureErr(error);
      return false;
    }


    setLoading(true);
    let ventureData = {
      id: id,
      venture: venture,
      industryId: industryId,
    };
    axios
      .post(baseUrl + "/frontapi/venture-edit", ventureData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Venture updated successfully");
          setTimeout(function () {
            window.location = "/venture";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="Venture Edit"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Industry</label>

              <select
                className="form-control"
                onChange={handleChange}
                name="industryId"
                value={industryId}
              >
                <option value="">Select Industry</option>
                {industryData.map((item, i) => {
                  return <option value={item.id}>{item.industry}</option>;
                })}
              </select>
              <p style={{ color: "red" }}>{industryIdErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Venture</label>
              <input
                type="text"
                name="venture"
                placeholder="Venture"
                className="form-control"
                onChange={handleChange}
                value={venture}
              />
              <p style={{ color: "red" }}>{ventureErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup onClick={handleSubmit} link="/venture" loading={loading} />
    </ContentLayout>
  );
}
export default VentureEdit;
