import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function IndustryEdit(props) {
  const [id, setId] = useState(useParams().id);
  const [industry, setIndustry] = useState("");
  const [industryErr, setIndustryErr] = useState("");
  const [loading, setLoading] = useState(false);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    industryData();
  }, []);

  const handleChangeIndustry = async (event) => {
    let eventValue = event.target.value;
    setIndustry(eventValue);
    if (!eventValue) {
      setIndustry(eventValue);
      setIndustryErr("Industry is required");
      return false;
    }

    setIndustryErr("");
  };

  const industryData = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/industry-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setId(resp.data.sqlRun[0].id);
          setIndustry(resp.data.sqlRun[0].industry);
        }
      });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!industry) {
      setIndustryErr("Industry is required");
      return false;
    }

    let error = checkSpace("industry", industry);

    if (error) {
      setIndustryErr(error);
      return false;
    }

    setLoading(true);
    let industryData = {
      id: id,
      industry: industry,
    };
    axios
      .post(baseUrl + "/frontapi/industry-edit", industryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Industry updated successfully");
          setTimeout(function () {
            window.location = "/industry";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="Industry Edit"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Industry</label>
              <input
                type="text"
                name="industry"
                placeholder="Industry"
                className="form-control"
                onChange={handleChangeIndustry}
                value={industry}
              />
              <p style={{ color: "red" }}>{industryErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup onClick={handleSubmit} link="/industry" loading={loading} />
    </ContentLayout>
  );
}
export default IndustryEdit;
