import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function AttributeAdd() {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeName = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue) {
      setName(eventValue);
      setNameErr("Attribute name is required");
      return false;
    }
    setName(eventValue);
    setNameErr("");
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      setNameErr("Attribute name is required");
      return false;
    }

    let error = checkSpace("attribute name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    setLoading(true);
    let customerData = {
      name: name,
    };
    axios
      .post(baseUrl + "/frontapi/attribute-add", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Attribute Added Successfully");
          setTimeout(() => {
            window.location = "/attributes";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ContentLayout
        title="ADD Attribute"
        className="add-product-outer customer-detail-outer"
      >
        <form>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Attribute Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Attribute Name"
                  className="form-control"
                  onChange={handleChangeName}
                />
                <p style={{ color: "red" }}> {nameErr} </p>
              </div>
            </div>
          </div>
        </form>
        <ButtonGroup
          onClick={handleSubmit}
          link="/attributes"
          loading={loading}
        />
      </ContentLayout>
    </>
  );
}
export default AttributeAdd;
