import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import $ from "jquery";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

function CatManEditProducts() {
  const [productName, setProductName] = useState("");
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [gstData, setGstData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [id, setId] = useState(atob(useParams().id));
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [tax, setTax] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [attrData, setAttrData] = useState([]);
  const [availableQty, setAvailableQty] = useState("");
  const [productDefination, setProductDefination] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [productType, setProductType] = useState("");
  const [imgCollection, setImgCollection] = useState([]);
  const [image, setImage] = useState(null);
  const [units, setUnits] = useState("");
  const [unitsError, setUnitsError] = useState("");
  const [inventoryValues, setInventoryValues] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
      material_units: "",
    },
  ]);
  const [isMaterialUse, setIsMaterialUse] = useState(false);
  const [materialData, setMaterialData] = useState([]);
  const [formValues, setFormValues] = useState([{ name: "", value: "" }]);
  // errors //
  const [nameErr, setNameErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [lowStockErr, setLowStockErr] = useState("");
  const [prodPriceErr, setProdPriceErr] = useState("");
  const [salesPriceErr, setSalesPriceErr] = useState("");
  const [hsnErr, setHsnErr] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const [formValuesError, setFormValuesError] = useState([
    { name: "", value: "" },
  ]);
  const [inventoryValuesError, setInventoryValuesError] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
      material_units: "",
    },
  ]);

  const [image1, setImage1] = useState([]);

  const tempId = useParams();

  const Params = atob(tempId.id);

  useEffect(() => {
    categoryData();
    productData();
    getMaterialData();
    attributeData();
    GetgstData();
    GetUnitsData();
  }, []);

  const GetgstData = () => {
    axios.post(baseUrl + "/frontapi/gst-data-deleted", gstData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setGstData(resp.data);
      }
    });
  };

  const GetUnitsData = () => {
    axios.post(baseUrl + "/frontapi/units-data-deleted", {}).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setUnitsData(resp.data);
      }
    });
  };

  const productData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/product-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        if (resp.data.sqlRun2.length > 0) {
          setFormValues(resp.data.sqlRun2);
          let html = [];
          resp.data.sqlRun2.forEach((element) => {
            let tempArr = [{ name: "", value: "" }];
            html.push(tempArr);
          });
          setFormValuesError(html);
        } else {
          setFormValues(resp.data.sqlRun2);
        }
        setId(resp.data.sqlRun[0].id);
        setProductName(resp.data.sqlRun[0].name);
        setDescription(resp.data.sqlRun[0].description);
        setPrice(resp.data.sqlRun[0].price);
        setHsnCode(resp.data.sqlRun[0].hsnCode);
        setTax(resp.data.sqlRun[0].tax);
        setImage(resp.data.sqlRun[0].image);
        setCategoryId(resp.data.sqlRun[0].category_id);
        setLowStockThreshold(resp.data.sqlRun[0].lowStockThreshold);
        setSalePrice(resp.data.sqlRun[0].sale_price);
        setProductType(resp.data.sqlRun[0].prod_type);
        setProductDefination(resp.data.sqlRun[0].prod_def);
        setSubCategoryId(resp.data.sqlRun[0].sub_categories_id);
        setAvailableQty(String(resp.data.sqlRun[0].avaliable_qty));
        setUnits(resp.data.sqlRun[0].units);
        if (resp.data.sqlRun3.length > 0) {
          setInventoryValues(resp.data.sqlRun3);
          let html1 = [];
          resp.data.sqlRun3.forEach((element) => {
            let tempArr1 = [
              {
                material_type: "",
                material_quantity: "",
                material_id: "",
                material_available_quantity: "",
                material_units: "",
              },
            ];
            html1.push(tempArr1);
          });
          setInventoryValuesError(html1);
        } else {
          setInventoryValues([{}]);
        }

        subCategoryData(resp.data.sqlRun[0].category_id);
        const html = [];
        var oldImages = resp.data.sqlRun[0].image;
        if (oldImages) {
          var uploadIMages = oldImages.split(",");
          if (uploadIMages) {
            uploadIMages.forEach((element, index) => {
              if (element) {
                html.push(
                  <div className="col-md-3 col-12 imageSize">
                    <img
                      className="uploaded-images img-fluid"
                      src={baseUrl + "/static" + element}
                      alt=""
                    />
                  </div>
                );
              }
            });
            setImage(html);
          }
        }
        if (resp.data.sqlRun[0].prod_type === "finished") {
          setIsMaterialUse(true);
          return;
        }
      }
    });
  };
  const getMaterialData = () => {
    axios
      .post(baseUrl + "/frontapi/material-data-raw", getMaterialData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setMaterialData(resp.data);
        }
      });
  };
  const handleChangeDesc = (e) => {
    let value = e.target.value;
    if (!value) {
      setDescErr(" Please enter description");
      setDescription(value);
      return false;
    }
    setDescErr("");
    setDescription(value);
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = async (event) => {
    let fieldValue = event.target.value;
    let fieldName = event.target.name;
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });

    if (fieldName === "productName") {
      if (!fieldValue) {
        setNameErr(" Please enter product name");
        setProductName(fieldValue);
        return false;
      }
      setNameErr("");
      setProductName(fieldValue);
    }
    if (fieldName === "price") {
      if (!fieldValue) {
        setProdPriceErr(" Please enter product price");
        setPrice(fieldValue);
        return false;
      }

      if (fieldValue < 0 || fieldValue == 0) {
        setPrice(fieldValue);
        setProdPriceErr("Product price cannot be zero or negative");
        return false;
      }

      if (salePrice !== "") {
        if (fieldValue > Number(salePrice)) {
          setPrice(fieldValue);
          setProdPriceErr("Product price cannot be higher than sales price");
          return false;
        }
      }
      setProdPriceErr("");
      setPrice(fieldValue);
    }
    if (fieldName === "hsnCode") {
      if (!fieldValue) {
        setHsnErr(" Please enter product HSN Code");
        setHsnCode(fieldValue);
        return false;
      }
      setHsnErr("");
      setHsnCode(fieldValue);
    }

    if (fieldName === "tax") {
      if (!fieldValue || fieldValue === "") {
        toast.dismiss();
        // toast.error("Please select tax");
        setTax(fieldValue);
        return false;
      }
      setTax(fieldValue);
    }
    if (fieldName === "avaliableQty") {
      if (!fieldValue) {
        setQuantityErr(" Please enter available quantity");
        setAvailableQty(fieldValue);
        return false;
      }

      setQuantityErr("");
      setAvailableQty(fieldValue);
    }
    if (fieldName === "salePrice") {
      if (!fieldValue) {
        setSalesPriceErr(" Please enter product sales price");
        setSalePrice(fieldValue);
        return false;
      }

      if (fieldValue < 0 || fieldValue == 0) {
        setSalePrice(fieldValue);
        setSalesPriceErr("Product price cannot be zero or negative");
        return false;
      }
      if (price !== "") {
        if (fieldValue < Number(price)) {
          setProdPriceErr("Product price cannot be higher than sales price");
          setSalePrice(fieldValue);
          return false;
        } else {
          setProdPriceErr("");
          setSalesPriceErr("");
          setSalePrice(fieldValue);
        }
      }
      setSalesPriceErr("");
      setSalePrice(fieldValue);
    }
    if (fieldName === "categoryId") {
      if (!fieldValue || fieldValue === "") {
        toast.dismiss();
        // toast.error("Please select category");
        setCategoryId(fieldValue);
        setSubCategoryId("");
        setSubCatData([]);
        return false;
      }
      setSubCategoryId("");
      setCategoryId(fieldValue);
      subCategoryData(fieldValue);
    }
    if (fieldName === "subCategoryId") {
      if (!fieldValue || fieldValue === "") {
        toast.dismiss();
        // toast.error("Please select sub category");
        setSubCategoryId(fieldValue);
        return false;
      }
      setSubCategoryId(fieldValue);
    }
  };

  const subCategoryData = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/frontapi/sub-category-by-catid", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          setSubCatData([]);
          return false;
        }
        if (resp.status === true) {
          setSubCatData(resp.data);
        }
      });
  };
  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          setCatData([]);
          return;
        }
        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };
  const attributeData = () => {
    axios
      .post(baseUrl + "/frontapi/attribute-data-deleted", attributeData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setAttrData(resp.data);
        }
      });
  };
  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }

    let tempImage = [];

    if (image) {
      for (let i = 0; i < image.length; i++) {
        const element = image[i];

        if (element.isNew === true) {
          // return;
        } else {
          var temp = element.props.children.props.src.replace(
            baseUrl + "/static",
            ""
          );

          tempImage.push(temp);
        }
      }
    }

    event.preventDefault();
    var formData = new FormData();

    if (imgCollection.length > 0) {
      for (let i = 0; i < imgCollection.length; i++) {
        const element = imgCollection[i][0];
        if (!element.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          toast.dismiss();
          toast.error("Please select valid image jpg,jpeg,png,gif");
          return false;
        }
        if (element.size >= 3e6) {
          toast.dismiss();
          toast.error("Product image size should not be more than 3MB");
          return false;
        }
      }
    }
    if (
      !productName &&
      !description &&
      !lowStockThreshold &&
      !price &&
      !salePrice &&
      !hsnCode
      // !availableQty
    ) {
      setNameErr(" Please enter product name");
      setDescErr(" Please enter product description");
      setLowStockErr("Please enter low stock threshold");
      setProdPriceErr(" Please enter product price");
      setSalesPriceErr(" Please enter sales price");
      setHsnErr(" Please enter HSN code");
      // setQuantityErr(" Please enter available quantity");
      return false;
    }
    if (!productName) {
      setNameErr(" Please enter product name");
      return false;
    }
    if (!categoryId || categoryId === "") {
      toast.dismiss();
      toast.error(" Please select category");
      return false;
    }

    if (!subCategoryId || subCategoryId === "") {
      toast.dismiss();
      toast.error("Please select sub category");
      return false;
    }
    if (!description) {
      setDescErr(" Please enter product description");
      return false;
    }
    if (!productDefination || productDefination === "") {
      toast.dismiss();
      toast.error(" Please select product defination");
      return false;
    }
    if (!lowStockThreshold) {
      setLowStockErr(" Please enter low stock threshold");
      return false;
    }

    if (!price) {
      setProdPriceErr(" Please enter product price");
      return false;
    }

    if (price < 0 || parseFloat(price) === 0) {
      setSalesPriceErr("Ptoduct price cannot be zero or negative value");
      return false;
    }

    if (!salePrice) {
      setSalesPriceErr(" Please enter sales price");
      return false;
    }

    if (salePrice < 0 || parseFloat(salePrice) === 0) {
      setSalesPriceErr("Sales price cannot be zero or negative value");
      return false;
    }

    if (Number(price) > Number(salePrice)) {
      setProdPriceErr("Product price cannot be higher than sales price");
      return false;
    }
    if (Number(salePrice) < Number(price)) {
      setProdPriceErr("Product price cannot be higher than sales price");
      return false;
    }
    if (!hsnCode) {
      setHsnErr(" Please enter HSN code");
      return false;
    }

    // if (!availableQty) {
    //   setQuantityErr(" Please enter available quantity");
    //   return false;
    // }

    if (!units || units === "") {
      toast.dismiss();
      toast.error("Please select quantity units");
      return false;
    }
    if (!productType || productType === "") {
      toast.dismiss();
      toast.error(" Please select product type");
      return false;
    }
    if (!tax || tax === "") {
      toast.dismiss();
      toast.error("Please select tax");
      return false;
    }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.name || element.name === "") {
        toast.dismiss();
        toast.error("Please select attribute name");

        // formError[i].name = "Please select attribute name";
        // setFormValuesError(formError);
        return false;
      }
      if (!element.value) {
        formError[i].value = "This field is required";
        setFormValuesError(formError);
        return false;
      }
      html.push(element.name);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate attribute name found");
      return false;
    }
    // if (isMaterialUse === true) {
    //   for (let i = 0; i < inventoryValues.length; i++) {
    //     const element = inventoryValues[i];
    //     let inventoryError = [...inventoryValuesError];
    //     var itemType = element.material_type;
    //     var itemQuantity = element.material_quantity;
    //     if (!itemType) {
    //       inventoryError[i].material_type = "Please select raw material";
    //       setInventoryValuesError(inventoryError);
    //       return false;
    //     }
    //     if (!itemQuantity) {
    //       inventoryError[i].material_quantity = "This field is required";
    //       setInventoryValuesError(inventoryError);
    //       return false;
    //     }
    //   }
    // }

    // return;

    for (const key of Object.keys(imgCollection)) {
      formData.append("imgCollection", imgCollection[key][0]);
    }

    formData.append("id", id);
    formData.append("productName", productName);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("salePrice", salePrice);
    formData.append("hsnCode", hsnCode);
    formData.append("tax", tax);
    formData.append("atribute", JSON.stringify(formValues));
    formData.append("materials", JSON.stringify(inventoryValues));
    // formData.append("avaliableQty", availableQty);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryId", subCategoryId);
    formData.append("prod_def", productDefination);
    formData.append("prod_type", productType);
    formData.append("lowStockThreshold", lowStockThreshold);
    formData.append("units", units);
    formData.append("dbImage", tempImage);

    axios.post(baseUrl + "/frontapi/product-edit", formData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        toast.success("Product updated successfully");
        setTimeout(function () {
          window.location = "/products-CatMan";
        }, 3000);
        return false;
      }
    });
  };

  const onFileChange1 = async (event) => {
    let newImagesArray = Object.entries(event.target.files).map((e) => {
      return {
        url: URL.createObjectURL(e[1]),
        isNew: true, // Add a property to differentiate between new and existing images
      };
    });

    // if (!event.target.files[0]) {
    //   toast.error("Please select product image");
    //   return false;
    // }
    setImage1([...image1, ...newImagesArray]);
    if (image) {
      setImage([...image]);
    }
    setImgCollection([...imgCollection, event.target.files]);
  };

  const showImages = () => {
    const html = [];

    if (image) {
      if (image.length > 0) {
        image.map((element, index) => {
          return html.push(
            <div className="imageSize" key={index}>
              <img
                className="uploaded-images"
                src={
                  element.isNew ? element.url : element.props.children.props.src
                }
                alt=""
              />
              <span
                className="text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => removeItem(index)}
              >
                X
              </span>
            </div>
          );
        });
      }
    }
    return html;
  };

  const showImages1 = () => {
    const html = [];

    if (image1) {
      if (image1.length > 0) {
        image1.map((element, index) => {
          return html.push(
            <div className="imageSize" key={index}>
              <img
                className="uploaded-images"
                src={
                  // ""
                  element.isNew ? element.url : ""
                }
                alt=""
              />
              <span
                className="text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => removeItem1(index)}
              >
                X
              </span>
            </div>
          );
        });
      }
    }
    return html;
  };

  const removeItem = (element) => {
    const s = image.filter((item, index) => index !== element);

    setImage(s);
  };

  const removeItem1 = (element) => {
    const s = image1.filter((item, index) => index !== element);
    setImage1(s);

    let filesArray = Array.from(imgCollection); // Convert FileList to an array
    const indexToRemove = element; // The index of the file to remove
    filesArray.splice(indexToRemove, 1); // Remove the file from the array
    setImgCollection(filesArray);
  };

  // const onFileChange = async (event) => {
  //   const html = [];
  //   setImgCollection(event.target.files);
  //   var uploadIMages = event.target.files;
  //   if (uploadIMages) {
  //     uploadIMages.forEach((element, index) => {
  //       if (element) {
  //         // setImage(URL.createObjectURL(element));
  //         html.push(
  //           <div className="imageSize">
  //             <img
  //               className="uploaded-images"
  //               src={URL.createObjectURL(element)}
  //               alt=""
  //             />
  //             <span
  //               className="text-danger"
  //               style={{ cursor: "pointer" }}
  //               onClick={() => removeItem(index)}
  //             >
  //               X
  //             </span>
  //           </div>
  //         );
  //       }
  //     });
  //     setImage(html);
  //   }
  // };
  const attributeHtml = () => {
    const attributeHtml = [];
    var aData = attrData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    aData.map(function (value, i) {
      return attributeHtml.push(<option value={value.id}>{value.name}</option>);
    });
    return attributeHtml;
  };

  // attribute ////
  let addFormFields = () => {
    setFormValues([...formValues, { name: "", value: "" }]);
    setFormValuesError([...formValuesError, { name: "", value: "" }]);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };
  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValues(newFormValues);
    if (e.target.name === "name") {
      if (!newFormValues[i].name || newFormValues[i].name === "Please select") {
        newFormValuesError[i][e.target.name] = "";

        // newFormValuesError[i][e.target.name] = "Please select attribute name";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
    if (e.target.name === "value") {
      if (!newFormValues[i].value) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };
  ///////// end attribute
  /// handle change raw material
  let addFormFieldsMaturials = () => {
    setInventoryValues([...inventoryValues, {}]);
    setInventoryValuesError([...inventoryValuesError, {}]);
  };
  let removeFormFieldsMaturial = (i) => {
    let newFormValues = [...inventoryValues];
    newFormValues.splice(i, 1);
    setInventoryValues(newFormValues);
    let newFormValuesError = [...inventoryValuesError];
    newFormValuesError.splice(i, 1);
    setInventoryValuesError(newFormValuesError);
  };
  let handleChangeMaturial = (i, e) => {
    let newFormValues = [...inventoryValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...inventoryValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setInventoryValues(newFormValues);

    if (e.target.name === "material_type") {
      if (
        !newFormValues[i].material_type ||
        newFormValues[i].material_type === ""
      ) {
        // newFormValuesError[i][e.target.name] = "Please select raw material";
        setInventoryValues([{}]);
        setInventoryValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setInventoryValuesError(newFormValuesError);
        const data = {
          byName: "yes",
          materialName: valv.material_type,
        };

        axios
          .post(baseUrl + "/frontapi/material-data-raw", data)
          .then((res) => {
            var resp = res.data;

            if (resp.status === true) {
              valv.material_available_quantity = resp.data[0].avaliable_qty;
              valv.material_id = resp.data[0].id;
              valv.material_units = resp.data[0].units;
            }
          });
      }
    }

    if (e.target.name === "material_quantity") {
      if (
        !newFormValues[i].material_quantity ||
        newFormValues[i].material_quantity === ""
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setInventoryValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setInventoryValuesError(newFormValuesError);
      }
    }
    setInventoryValuesError(newFormValuesError);
    setInventoryValues(newFormValues);
  };
  /////
  const handleChangeProductDefination = async (event) => {
    let eventValue = event.target.value;
    if (!eventValue || eventValue === "") {
      toast.dismiss();
      // toast.error("Please select product defination");
      setProductDefination(eventValue);
      return false;
    }
    toast.dismiss();
    setProductDefination(eventValue);
  };
  const handleChangeLowStock = (e) => {
    let { value } = e.target;
    if (!value) {
      setLowStockErr(" Please enter low Stock Threshold");
      setLowStockThreshold(value);
      return false;
    }
    setLowStockErr("");
    setLowStockThreshold(value);
  };

  const handleChangeUnits = async (event) => {
    let eventValue = event.target.value;
    setUnits(eventValue);
    if (!eventValue || eventValue === "") {
      toast.dismiss();
      // toast.error("Please select quantity units");
      return false;
    }

    setUnitsError("");
  };

  const handleChangeProductType = async (event) => {
    let eventValue = event.target.value;
    setProductType(eventValue);

    if (!eventValue || eventValue === "") {
      toast.dismiss();
      // toast.error("Please select product type");
      setInventoryValues([{}]);
      setIsMaterialUse(false);
      return false;
    }
    if (eventValue === "finished") {
      setInventoryValues([{}]);
      setIsMaterialUse(true);
      return false;
    }
    if (eventValue === "raw" || eventValue === "imported") {
      setInventoryValues([{}]);
      setIsMaterialUse(false);
      return false;
    }
    setIsMaterialUse(false);
  };

  const materialHtml = () => {
    var materialHtml = [];

    var mData = materialData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    mData.map(function (value, i) {
      return materialHtml.push(
        <option value={value.name}>{value.name}</option>
      );
    });
    return materialHtml;
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    var CData = catData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const subCategoryHtml = () => {
    const html = [];
    var sCData = subCatData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    sCData.map((value, i) => {
      return html.push(<option value={value.id}>{value.name}</option>);
    });
    return html;
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>EDIT PRODUCT</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="add-product-outer customer-detail-outer">
                  <form enctype="multipart/form-data">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-0">
                          <label>Product Image</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group file-upload position-relative">
                          <div className="">
                            <input
                              className="profile_input"
                              type="file"
                              name="bunner"
                              // multiple
                              accept="image/*"
                              onChange={onFileChange1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div id="uploadedImages">
                          <div className="row">{showImages()}</div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div id="uploadedImages">
                          {" "}
                          Newly uploaded
                          <div className="row">{showImages1()}</div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Name</label>
                          <input
                            type="text"
                            name="productName"
                            placeholder="Product Name"
                            className="form-control"
                            onChange={handleChange}
                            value={productName}
                          />
                          <p style={{ color: "red" }}> {nameErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="categoryId"
                            value={categoryId}
                            disabled="true"
                          >
                            <option value={""}>Select Category</option>
                            {categoryHtml()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Sub Category</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="subCategoryId"
                            value={subCategoryId}
                            disabled="true"
                          >
                            <option value="">Select Category</option>
                            {subCategoryHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Defination</label>
                          <select
                            className="form-control"
                            name="productDefination"
                            onChange={handleChangeProductDefination}
                            value={productDefination}
                          >
                            <option value="">Please select</option>
                            <option value="inventory">Inventory</option>
                            <option value="make_to_order">Make to Order</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Low Stock Threshold</label>
                          <input
                            type={"text"}
                            className="form-control"
                            onChange={handleChangeLowStock}
                            placeholder="Low Stock Threshold"
                            name="lowStock"
                            value={lowStockThreshold}
                          />
                          <p style={{ color: "red" }}> {lowStockErr} </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Price</label>
                          <input
                            type="number"
                            name="price"
                            onKeyDown={handleValidation}
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Product Price"
                            value={price}
                          />
                          <p style={{ color: "red" }}> {prodPriceErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Sale Price</label>
                          <input
                            type="number"
                            name="salePrice"
                            onKeyDown={handleValidation}
                            value={salePrice}
                            className="form-control"
                            placeholder="Sale Price"
                            onChange={handleChange}
                          />
                          <p style={{ color: "red" }}> {salesPriceErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>HSN Code</label>
                          <input
                            type="text"
                            name="hsnCode"
                            placeholder="HSN Code"
                            className="form-control"
                            onChange={handleChange}
                            value={hsnCode}
                          />
                          <p style={{ color: "red" }}> {hsnErr} </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Available Quantity</label>
                          <input
                            type="number"
                            name="avaliableQty"
                            onKeyDown={handleValidation}
                            placeholder="Available Quantity"
                            value={availableQty}
                            className="form-control"
                            onChange={handleChange}
                            disabled
                          />
                          <p style={{ color: "red" }}> {quantityErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Quantity Units</label>

                          <select
                            className="form-control"
                            name="units"
                            onChange={handleChangeUnits}
                            value={units}
                            disabled
                          >
                            <option value={""}>Select Units</option>
                            {unitsData.map((item, i) => {
                              return (
                                <option value={item.units}>{item.units}</option>
                              );
                            })}
                          </select>
                          <p style={{ color: "red" }}>{unitsError}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Type</label>
                          <select
                            className="form-control"
                            name="productType"
                            onChange={handleChangeProductType}
                            value={productType}
                            disabled
                          >
                            <option value="">Please select</option>
                            <option value="raw">Raw</option>
                            <option value="finished">Finished</option>
                            <option value="imported">Imported</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Tax</label>
                          <select
                            className="form-control"
                            name="tax"
                            onChange={handleChange}
                            value={tax}
                          >
                            <option value={""}>Select Tax</option>
                            {gstData.map((item, i) => {
                              return (
                                <option value={item.gst}>{item.gst}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            placeholder="Enter Description"
                            className="form-control resize"
                            name="description"
                            value={description}
                            onChange={handleChangeDesc}
                          ></textarea>
                          <p style={{ color: "red" }}> {descErr} </p>
                        </div>
                      </div>
                      {formValues.map((element, index) => (
                        <>
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>Attribute Name</label>
                              <select
                                className="form-control"
                                name="name"
                                placeholder="Attribute Name"
                                onChange={(e) => handleChangeAttr(index, e)}
                                value={element.name || ""}
                              >
                                <option>Please select</option>
                                {attributeHtml()}
                              </select>
                              <p style={{ color: "red" }}>
                                {" "}
                                {formValuesError[index]?.name}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Attribute Value</label>
                                <input
                                  type="text"
                                  name="value"
                                  placeholder="Attribute Value"
                                  className="form-control"
                                  value={element.value || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {" "}
                                  {formValuesError[index]?.value}{" "}
                                </p>
                              </div>
                              <div className="add-row">
                                {index ? (
                                  <a
                                    href="#!"
                                    className="add-btn add"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a
                                  className="add-btn add"
                                  href="#!"
                                  onClick={() => addFormFields()}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      {isMaterialUse === true && (
                        <>
                          {inventoryValues.map((element, index) => (
                            <>
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Raw Material</label>
                                  <select
                                    className="form-control"
                                    name="material_type"
                                    onChange={(e) =>
                                      handleChangeMaturial(index, e)
                                    }
                                    value={element.material_type || ""}
                                  >
                                    <option value="">Please select</option>
                                    {materialHtml()}
                                  </select>
                                  <p style={{ color: "red" }}>
                                    {inventoryValuesError[index]?.material_type}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-3 col-12">
                                <div className="form-group">
                                  <label>Raw Material Quantity</label>
                                  <input
                                    className="form-control"
                                    name="material_quantity"
                                    type={"number"}
                                    onKeyDown={handleValidation}
                                    placeholder="Material Quantity"
                                    onChange={(e) =>
                                      handleChangeMaturial(index, e)
                                    }
                                    value={element.material_quantity || ""}
                                  />
                                  <p style={{ color: "red", margin: "0" }}>
                                    {
                                      inventoryValuesError[index]
                                        ?.material_quantity
                                    }
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-3 col-12">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group">
                                    <label>Raw Material Unit</label>
                                    <input
                                      className="form-control"
                                      name="material_units"
                                      type="text"
                                      placeholder="Material Quantity"
                                      disabled
                                      value={element.material_units || ""}
                                    />
                                  </div>

                                  <div className="add-row">
                                    <a
                                      className="add-btn add"
                                      href="#!"
                                      onClick={() => addFormFieldsMaturials()}
                                    >
                                      +
                                    </a>
                                    {index ? (
                                      <a
                                        className="add-btn add"
                                        href="#!"
                                        onClick={() =>
                                          removeFormFieldsMaturial(index)
                                        }
                                      >
                                        -
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </form>
                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      {" "}
                      Save{" "}
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        window.location.href = "/products-CatMan";
                      }}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default CatManEditProducts;
