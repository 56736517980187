import React from "react";
import { Link } from "react-router-dom";

const images = {
  hand: "assets/images/menu-icon-light5.png",
  cart: "assets/images/menu-icon-light4.png",
  customer: "assets/images/menu-icon-light2.png",
  box: "assets/images/menu-icon-light1.png",
};

export const DashboardCard = ({ title, type, cls, data, url }) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
      <div className="card card-animate">
        <Link to={url}>
          <div className={`card-body card-${cls}`}>
            <div className="d-flex justify-content-between">
              <div>
                <p className="fw-medium mb-0">{title}</p>
                <h2 className="mt-4 ff-secondary fw-semibold">
                  <span className="counter-value" data-target="33.48">
                    {data}
                  </span>
                </h2>
              </div>
              <div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={`avatar-title bg-${cls} rounded-circle fs-2`}
                  >
                    <img src={images[type]} alt="" className="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
