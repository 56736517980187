import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import $ from "jquery";
import Footer from "./Footer";
const baseUrl = myConstList.baseUrl;

function VendorEdit() {
  const [id, setId] = useState(atob(useParams().id));
  const [newPass, setNewPass] = useState("");
  const [prodData, setProdData] = useState([]);
  const [confirmPass, setConfirmPass] = useState("");
  const [name, setName] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [website, setWebsite] = useState("");
  const [gst, setGst] = useState("");
  const [panNum, setPanNum] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [typePass, setTypePass] = useState("password");
  const [typePass1, setTypePass1] = useState("password");
  const [comments, setComments] = useState("");
  const [designationData, setDesignationData] = useState([]);

  // Errors for top container //
  const [vendorNameError, setvendorNameError] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  const [addressError, setAddressError] = useState("");

  const [formValues, setFormValues] = useState([
    {
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
    },
  ]);
  const [formValuesError, setFormValuesError] = useState([
    {
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      // selected: "",
    },
  ]);
  const [formValuesManufacturer, setFormValuesManufacturer] = useState([
    {
      vendor_type: "",
      prodName: "",
      mCapacity: "",
      mLocation: "",
      mSPrice: "",
    },
  ]);

  const [formValuesManufacturerError, setFormValuesManufacturerError] =
    useState([
      {
        vendor_type: "",
        prodName: "",
        mCapacity: "",
        mLocation: "",
        mSPrice: "",
      },
    ]);

  const [formValuesTrader, setFormValuesTrader] = useState([
    { prodName: "", tCapacity: "", tLocation: "", tSPrice: "" },
  ]);

  const [formValuesTraderError, setFormValuesTraderError] = useState([
    { prodName: "", tCapacity: "", tLocation: "", tSPrice: "" },
  ]);

  const [newPassErr, setNewPassErr] = useState("");
  const [cPassError, setCPassError] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    getProductData();
    getDesignationData();
  }, []);

  const getProductData = () => {
    axios.post(baseUrl + "/frontapi/product-data").then((res) => {
      const resp = res.data;
      setProdData(resp.data);
    });
  };

  const getDesignationData = () => {
    axios.post(baseUrl + "/frontapi/designation-data-deleted").then((res) => {
      const resp = res.data;
      setDesignationData(resp.data);
    });
  };

  const designationHtml = () => {
    const designationhtml = [];

    designationData.map((value, i) => {
      return designationhtml.push(
        <option value={value.designation}>{value.designation}</option>
      );
    });
    return designationhtml;
  };

  const handleChangeTop = (event) => {
    let { name, value } = event.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(event.target).blur();
    });
    if (name === "vendorName") {
      if (!value) {
        setName(value);
        setvendorNameError("Please enter vendor name");
        return false;
      }
      setName(value);
      setvendorNameError("");
    }

    if (name === "companyName") {
      if (!value) {
        setCompanyName(value);
        setCompNameError("Please enter company name");
        return false;
      }
      setCompanyName(value);
      setCompNameError("");
    }

    if (name === "email") {
      if (!value) {
        setEmail(value);
        setEmailError("Please enter email");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmail(value);
        setEmailError("Please enter valid email");
        return false;
      }
      setEmail(value);
      setEmailError("");
    }

    if (name === "pNumber") {
      if (!value) {
        setPhoneNum(value);
        setPhoneError("Please enter phone number");
        return false;
      }
      if (value.length < 10 || value.length > 10) {
        setPhoneNum(value);
        setPhoneError("Phone Number should be of 10 digits");
        return false;
      }

      setPhoneNum(value);
      setPhoneError("");
    }

    if (name === "website") {
      // if (!value) {
      //   setWebsite(value);
      //   setWebsiteError("Please enter website");
      //   return false;
      // }
      setWebsite(value);
      setWebsiteError("");
    }

    if (name === "comments") {
      setComments(value);
    }

    if (name === "gst") {
      let newValue = value.toUpperCase();
      if (!newValue) {
        setGst(newValue);
        setGstError("Please enter GST number");
        return false;
      }

      if (newValue === "URP") {
        setGst(newValue);
        setGstError("");
        return false;
      }

      if (
        !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(newValue)
      ) {
        setGst(newValue);
        setPanNum("");
        setGstError("Enter valid GST number");
        return false;
      } else {
        var modifiedText = newValue.substring(2, newValue.length - 3);
        setPanNum(modifiedText);
      }

      setGst(newValue);
      setGstError("");
    }

    if (name === "panNum") {
      if (!value) {
        setPanNum(value);
        setPanError("Please enter pan number");
        return false;
      }
      var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
      if (!reggg.test(value)) {
        setPanNum(value);
        setPanError("Please enter valid pan number");
        return false;
      }
      setPanNum(value);
      setPanError("");
    }

    if (name === "billingAddress") {
      if (!value) {
        setAddress(value);
        setAddressError("Please enter address");
        return false;
      }
      setAddress(value);
      setAddressError("");
    }
  };

  useEffect(() => {
    customerData();
  }, []);
  const customerData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/vendor-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        if (resp.data.sqlRun2.length > 0) {
          setFormValues(resp.data.sqlRun2);

          let html = [];

          resp.data.sqlRun2.forEach((element) => {
            let tempArr = [
              {
                aName: "",
                aEmail: "",
                aPhoneNo: "",
                designation: "",
              },
            ];
            html.push(tempArr);
          });
          setFormValuesError(html);
        }
        if (resp.data.sqlRun3.length > 0) {
          setFormValuesManufacturer(resp.data.sqlRun3);
          let html1 = [];

          resp.data.sqlRun3.forEach((element) => {
            let tempArr = [
              {
                vendor_type: "",
                prodName: "",
                mCapacity: "",
                mLocation: "",
                mSPrice: "",
              },
            ];
            html1.push(tempArr);
          });
          setFormValuesManufacturerError(html1);
        }

        setId(resp.data.sqlRun[0].id);
        setName(resp.data.sqlRun[0].name);
        setCompanyName(resp.data.sqlRun[0].companyName);
        setEmail(resp.data.sqlRun[0].email);
        setPhoneNum(resp.data.sqlRun[0].mobile_no);
        setComments(resp.data.sqlRun[0].vendorComments);
        setWebsite(resp.data.sqlRun[0].website);
        setGst(resp.data.sqlRun[0].gst);
        setPanNum(resp.data.sqlRun[0].panNumber);
        setAddress(resp.data.sqlRun[0].address);
      }
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";

    if (event.key === "Enter") {
    }

    if (
      !name &&
      !companyName &&
      !email &&
      !phoneNum &&
      // !website &&
      !gst &&
      !panNum &&
      !address
    ) {
      setvendorNameError("Please enter vendor name");
      setCompNameError("Please enter company name");
      setEmailError("Please enter email");
      setPhoneError("Please enter phone number");
      // setWebsiteError("Please enter website");
      setGstError("Please enter GST number");
      setPanError("Please enter pan number");
      setAddressError("Please enter address");
    }

    // all together wali validation lagegi //

    // top

    if (!name) {
      setvendorNameError("Please enter vendor name");
      return false;
    }

    error = checkSpace("vendor name", name);
    if (error) {
      setvendorNameError(error);
      return false;
    }

    if (!companyName) {
      setCompNameError("Please enter company name");
      return false;
    }

    error = checkSpace("company name", companyName);
    if (error) {
      setCompNameError(error);
      return false;
    }

    if (!email) {
      setEmailError("Please enter email");
      return false;
    }

    if (!email.match(emailReg)) {
      setEmailError("Please enter valid email");
      return false;
    }

    if (!phoneNum) {
      setPhoneError("Please enter phone number");
      return false;
    }

    if (phoneNum.length > 10) {
      setPhoneError("Phone Number should be of 10 digits");
      return false;
    }

    if (phoneNum.length < 10) {
      setPhoneError("Phone Number should be of 10 digits");
      return false;
    }

    if (!gst) {
      setGstError("Please enter GST number");
      return false;
    }

    if (gst !== "URP") {
      if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
        setGstError("Enter valid GST number");
        return false;
      }
    }

    if (!panNum) {
      setPanError("Please enter pan number");
      return false;
    }

    var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!reggg.test(panNum)) {
      setPanNum(panNum);
      setPanError("Please enter valid pan number");
      return false;
    }

    if (!address) {
      setAddressError("Please enter address");
      return false;
    }

    for (let i = 0; i < formValuesManufacturer.length; i++) {
      const element = formValuesManufacturer[i];
      let manufactureError = [...formValuesManufacturerError];

      if (!element.vendor_type) {
        manufactureError[i].vendor_type = "Please select vendor type";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.prodName) {
        manufactureError[i].prodName = "Please select product name";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mCapacity) {
        manufactureError[i].mCapacity = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mLocation) {
        manufactureError[i].mLocation = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (!element.mSPrice) {
        manufactureError[i].mSPrice = "This field is required";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }

      if (element.mSPrice < 0 || element.mSPrice === "0") {
        manufactureError[i].mSPrice = "Price cannot be zero or negative value";
        setFormValuesManufacturerError(manufactureError);
        return false;
      }
    }
    // }

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let contactError = [...formValuesError];

      if (!element.aName) {
        contactError[i].aName = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      error = checkSpace("name", element.aName);
      if (error) {
        contactError[i].aName = error;
        setFormValuesError(contactError);
        return false;
      }

      if (!element.aPhoneNo) {
        contactError[i].aPhoneNo = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      if (element.aPhoneNo.length > 10) {
        contactError[i].aPhoneNo = "Phone Number should be of 10 digits";
        setFormValuesError(contactError);
        return false;
      }
      if (element.aPhoneNo.length < 10) {
        contactError[i].aPhoneNo = "Phone Number should be of 10 digits";
        setFormValuesError(contactError);
        return false;
      }

      if (!element.designation) {
        contactError[i].designation = "Please select designation";
        setFormValuesError(contactError);
        return false;
      }
      if (!element.aEmail) {
        contactError[i].aEmail = "This field is required";
        setFormValuesError(contactError);
        return false;
      }

      error = checkSpace("email", element.aEmail);
      if (error) {
        contactError[i].aEmail = error;
        setFormValuesError(contactError);
        return false;
      }

      if (!element.aEmail.match(emailReg)) {
        contactError[i].aEmail = "Please enter valid email";
        setFormValuesError(contactError);

        return false;
      }
    }
    setLoading(true);
    let vendorData = {
      id: id,
      name: name,
      companyName: companyName,
      email: email,
      phoneNum: phoneNum,
      website: website,
      gst: gst,
      comments: comments,
      panNum: panNum,
      address: address,
      atribute: formValues,
      manufacturerAttribute: formValuesManufacturer,
    };
    axios
      .post(baseUrl + "/frontapi/vendor-edit", vendorData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status == true) {
          toast.success("Vendor updated successfully");
          setTimeout(function () {
            window.location = "/vendor";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangePass = (e) => {
    let { name, value } = e.target;
    if (name === "newPass") {
      if (!value) {
        setNewPass(value);
        setNewPassErr("This field is required");
        return false;
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!value.match(regex)) {
        setNewPass(value);
        setNewPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      } else {
        setNewPassErr("");
        setNewPass(value);
      }
      if (value !== confirmPass) {
        setNewPass(value);
        setCPassError("Confirm password does not match");
        return false;
      } else {
        setCPassError("");
        setNewPass(value);
      }
      setNewPassErr("");
      setNewPass(value);
    }

    if (name === "confirmPass") {
      if (!value) {
        setConfirmPass(value);
        setCPassError("This field is required");
        return false;
      }
      if (value !== newPass) {
        setConfirmPass(value);
        setCPassError("Confirm password does not match");
        return false;
      }
      setCPassError("");
      setConfirmPass(value);
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = () => {
    if (!newPass) {
      setNewPassErr("This field is required");
      return false;
    }
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!newPass.match(regex)) {
      setNewPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    let error = checkSpace("new password", newPass);
    if (error) {
      setNewPassErr(error);
      return false;
    }

    if (!confirmPass) {
      setCPassError("This field is required");
      return false;
    }

    error = checkSpace("confirm password", confirmPass);
    if (error) {
      setCPassError(error);
      return false;
    }

    if (confirmPass !== newPass) {
      setCPassError("Confirm password does not match");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const data = {
      userId: id,
      newPass: newPass,
      confirmPass: confirmPass,
    };

    axios.post(baseUrl + "/frontapi/changePass", data, config).then((res) => {
      const resp = res.data;
      toast.dismiss();

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setTimeout(() => {
          window.location.href = "/vendor";
        }, 2000);
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPass("");
    setConfirmPass("");
    setNewPassErr("");
    setCPassError("");
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValues(newFormValues);

    if (e.target.name === "aName") {
      if (!newFormValues[i].aName) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "aPhoneNo") {
      if (!newFormValues[i].aPhoneNo) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
      if (
        newFormValues[i].aPhoneNo.length < 10 ||
        newFormValues[i].aPhoneNo.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Phone Number should be of 10 digits";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
    }

    if (e.target.name === "designation") {
      if (
        !newFormValues[i].designation ||
        newFormValues[i].designation === "Select"
      ) {
        newFormValuesError[i][e.target.name] = "Please select designation";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "aEmail") {
      if (!newFormValues[i].aEmail) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }

      if (!newFormValues[i].aEmail.match(emailReg)) {
        newFormValuesError[i][e.target.name] = "Please enter valid email";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    setFormValuesError(newFormValuesError);
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);

    setFormValuesError([...formValuesError, {}]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  // For MANUFACTURER

  let handleChangeManufacturer = (i, e) => {
    let newFormValuesManufacturer = [...formValuesManufacturer];
    newFormValuesManufacturer[i][e.target.name] = e.target.value;
    setFormValuesManufacturer(newFormValuesManufacturer);

    // error //
    let newFormValuesManufactureError = [...formValuesManufacturerError];
    newFormValuesManufactureError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";

    if (e.target.name === "vendor_type") {
      if (!newFormValuesManufacturer[i].vendor_type) {
        newFormValuesManufactureError[i][e.target.name] =
          "Please select vendor type";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "prodName") {
      if (!newFormValuesManufacturer[i].prodName) {
        newFormValuesManufactureError[i][e.target.name] =
          "Please select product name";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mCapacity") {
      if (!newFormValuesManufacturer[i].mCapacity) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mLocation") {
      if (!newFormValuesManufacturer[i].mLocation) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }

    if (e.target.name === "mSPrice") {
      if (!newFormValuesManufacturer[i].mSPrice) {
        newFormValuesManufactureError[i][e.target.name] = tempErr;
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }

      if (
        newFormValuesManufacturer[i].mSPrice < 0 ||
        newFormValuesManufacturer[i].mSPrice === "0"
      ) {
        newFormValuesManufactureError[i][e.target.name] =
          "Price cannot be zero or negative value";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        return false;
      } else {
        newFormValuesManufactureError[i][e.target.name] = "";
        setFormValuesManufacturerError(newFormValuesManufactureError);
        setFormValuesManufacturer(newFormValuesManufacturer);
      }
    }
    setFormValuesManufacturerError(newFormValuesManufactureError);
    setFormValuesManufacturer(newFormValuesManufacturer);
  };

  let addFormFieldsManufacturer = () => {
    setFormValuesManufacturer([
      ...formValuesManufacturer,
      { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
    ]);
    setFormValuesManufacturerError([
      ...formValuesManufacturerError,
      { vendor_type: "", mName: "", mCapacity: "", mLocation: "", mSPrice: "" },
    ]);
  };

  let removeFormFieldsManufacturer = (i) => {
    let newFormValues = [...formValuesManufacturer];
    newFormValues.splice(i, 1);
    setFormValuesManufacturer(newFormValues);

    let newFormValuesError = [...formValuesManufacturerError];
    newFormValuesError.splice(i, 1);
    setFormValuesManufacturerError(newFormValuesError);
  };

  // For TRADER

  let handleChangeTrader = (i, e) => {
    let newFormValuesTrader = [...formValuesTrader];
    newFormValuesTrader[i][e.target.name] = e.target.value;
    setFormValuesTrader(newFormValuesTrader);

    // error //
    let newFormValuesTraderError = [...formValuesTraderError];
    newFormValuesTraderError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";

    if (e.target.name === "prodName") {
      if (!newFormValuesTrader[i].prodName) {
        newFormValuesTraderError[i][e.target.name] =
          "Please select product name";
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tCapacity") {
      if (!newFormValuesTrader[i].tCapacity) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tLocation") {
      if (!newFormValuesTrader[i].tLocation) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }

    if (e.target.name === "tSPrice") {
      if (!newFormValuesTrader[i].tSPrice) {
        newFormValuesTraderError[i][e.target.name] = tempErr;
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }

      if (
        newFormValuesTrader[i].tSPrice < 0 ||
        newFormValuesTrader[i].tSPrice === "0"
      ) {
        newFormValuesTraderError[i][e.target.name] =
          "Price cannot be zeo or negative value";
        setFormValuesTraderError(newFormValuesTraderError);
        return false;
      } else {
        newFormValuesTraderError[i][e.target.name] = "";
        setFormValuesTraderError(newFormValuesTraderError);
      }
    }
    setFormValuesTraderError(newFormValuesTraderError);
  };

  let addFormFieldsTrader = () => {
    setFormValuesTrader([...formValuesTrader, {}]);

    setFormValuesTraderError([...formValuesTraderError, {}]);
  };

  let removeFormFieldsTrader = (i) => {
    let newFormValues = [...formValuesTrader];
    newFormValues.splice(i, 1);
    setFormValuesTrader(newFormValues);

    let newFormValuesError = [...formValuesTraderError];
    newFormValuesError.splice(i, 1);
    setFormValuesTraderError(newFormValuesError);
  };

  const clearForm = () => {
    window.location.href = "/vendor";
  };

  const categoryHtml = () => {
    const categoryhtml = [];

    prodData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  const handleClick1 = () => {
    if (typePass1 === "password") {
      setTypePass1("text");
      return false;
    } else {
      setTypePass1("password");
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Modal show={modalIsOpen} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Change Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        className="form-control fs12"
                        placeholder="Enter New Password"
                        type={typePass}
                        name="newPass"
                        onChange={handleChangePass}
                        value={newPass}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick()}
                      >
                        {typePass === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{newPassErr}</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        className="form-control fs12"
                        placeholder="Enter Confirm Password"
                        type={typePass1}
                        name="confirmPass"
                        onChange={handleChangePass}
                        value={confirmPass}
                      />{" "}
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick1()}
                      >
                        {typePass1 === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{cPassError}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="d-block">
                <div className="submit-btn-outer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <div className="section-heading">
              <h2>
                <b>Edit Vendor</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Vendor Name</label>
                          <input
                            type="text"
                            name="vendorName"
                            value={name}
                            placeholder="Vendor Name"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {vendorNameError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            value={companyName}
                            placeholder="Company Name"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {compNameError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChangeTop}
                            // disabled="false"
                          />
                          <span>
                            <p style={{ color: "red" }}> {emailError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type={"number"}
                            onKeyDown={handleValidation}
                            maxLength={10}
                            name="pNumber"
                            value={phoneNum}
                            placeholder="Phone Number"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {phoneError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Website</label>
                          <input
                            type="text"
                            name="website"
                            value={website}
                            placeholder="Website"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {websiteError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>GST Number</label>
                          <input
                            type="text"
                            name="gst"
                            placeholder="GST Number"
                            value={gst}
                            maxLength={15}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {gstError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>PAN Number</label>
                          <input
                            type="text"
                            name="panNum"
                            value={panNum}
                            maxLength={10}
                            placeholder="PAN Number"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {panError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label> Address</label>
                          <input
                            type="text"
                            name="address"
                            value={address}
                            placeholder="Address"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {addressError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Vendor Comments</label>
                          <input
                            type="text"
                            name="comments"
                            placeholder="Vendor Comments"
                            value={comments}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                        </div>
                      </div>

                      {formValuesManufacturer.map((element, index) => (
                        <>
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Vendor Type</label>
                              <select
                                className="form-control"
                                value={element.vendor_type || ""}
                                onChange={(e) =>
                                  handleChangeManufacturer(index, e)
                                }
                                name="vendor_type"
                              >
                                <option value={""}>Select</option>
                                <option>Raw Material Manufacturer</option>
                                <option>Raw Material Trader</option>
                                <option>Goods Trader</option>
                                <option>Goods Manufacturer</option>
                                <option>Goods Importer</option>
                              </select>
                              <span>
                                <p style={{ color: "red" }}>
                                  {
                                    formValuesManufacturerError[index]
                                      ?.vendor_type
                                  }
                                </p>
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Product Name</label>
                              <select
                                className="form-control"
                                name="prodName"
                                value={element.prodName || ""}
                                onChange={(e) =>
                                  handleChangeManufacturer(index, e)
                                }
                              >
                                <option value="">Please select</option>
                                {categoryHtml()}
                              </select>
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesManufacturerError[index]?.prodName}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Capacity</label>
                              <input
                                type="text"
                                name="mCapacity"
                                placeholder="Capacity"
                                className="form-control"
                                value={element.mCapacity || ""}
                                onChange={(e) =>
                                  handleChangeManufacturer(index, e)
                                }
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {
                                    formValuesManufacturerError[index]
                                      ?.mCapacity
                                  }
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Location</label>
                              <input
                                type="text"
                                name="mLocation"
                                placeholder="Location"
                                className="form-control"
                                value={element.mLocation || ""}
                                onChange={(e) =>
                                  handleChangeManufacturer(index, e)
                                }
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {" "}
                                  {
                                    formValuesManufacturerError[index]
                                      ?.mLocation
                                  }{" "}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Price</label>
                                <input
                                  type="number"
                                  name="mSPrice"
                                  onKeyDown={handleValidation}
                                  placeholder="Price"
                                  className="form-control"
                                  value={element.mSPrice || ""}
                                  onChange={(e) =>
                                    handleChangeManufacturer(index, e)
                                  }
                                />
                                <span>
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {
                                      formValuesManufacturerError[index]
                                        ?.mSPrice
                                    }{" "}
                                  </p>
                                </span>
                              </div>
                              <div className="add-row">
                                {index ? (
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      removeFormFieldsManufacturer(index)
                                    }
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => addFormFieldsManufacturer()}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <button
                            type="button"
                            // onClick={buyPopUp}
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#add-user-modal"
                            onClick={() => setModalIsOpen(true)}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="contact-person-heading">
                          <h5>Contact Person</h5>
                        </div>
                      </div>

                      {/* {buyPopUp} */}
                      {formValues.map((element, index) => (
                        <>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="aName"
                                placeholder="Name"
                                className="form-control"
                                value={element.aName || ""}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index]?.aName}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Phone Number</label>
                              <input
                                type={"number"}
                                onKeyDown={handleValidation}
                                maxLength={10}
                                name="aPhoneNo"
                                placeholder="Phone Number"
                                className="form-control"
                                value={element.aPhoneNo || ""}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index]?.aPhoneNo}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="form-group">
                              <label>Designation</label>
                              <select
                                className="form-control"
                                name="designation"
                                value={element.designation || ""}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">Select</option>
                                <option>Proprietor</option>
                                <option>Partner</option>
                                <option>Director</option>
                                <option>Store Manager</option>
                                <option>Accounts Manager</option>
                                <option>Factory Manager</option>
                                <option>Machine Man</option>
                                {designationHtml()}
                              </select>
                              <span>
                                <p style={{ color: "red" }}>
                                  {formValuesError[index]?.designation}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 col-12 order-1">
                            <div className="add-row-group d-flex align-items-center justify-content-between">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="aEmail"
                                  placeholder="Email"
                                  className="form-control"
                                  value={element.aEmail || ""}
                                  onChange={(e) => handleChange(index, e)}
                                />
                                <span>
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index]?.aEmail}
                                  </p>
                                </span>
                              </div>

                              <div className="add-row">
                                {index ? (
                                  <a
                                    href="#!"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    -
                                  </a>
                                ) : null}
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => addFormFields()}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/vendor"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default VendorEdit;
