import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { reactTableConfig } from "../lib/reactTableConfig";
const config = reactTableConfig("ub Categories");

const SubCategory = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    getSubCategoryData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "cat_name",
      text: "Category Name",
      className: "price",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Sub Category Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "id",
      align: "left",
      sortable: true,
    },

    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href = "/sub-category-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>

            {record.is_deleted == "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const getSubCategoryData = () => {
    axios.post(baseUrl + "/frontapi/sub-category-data").then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setRecord(resp.data);
      }
    });
  };

  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }
    let data = {
      id: id,
      status: temp,
    };
    axios.post(baseUrl + "/frontapi/remove-sub-category", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.success(resp.message);
        getSubCategoryData();
        return false;
      }

      toast.error(resp.message);
    });
  };

  return (
    <ContentLayout
      title="Sub Category List"
      buttonTitle="Add Sub Category"
      buttonUrl="/sub-category-add"
      className="product-list-outer customer-list-outer"
    >
      <ReactDatatable
        className="table table-bordered table-striped "
        config={config}
        records={record}
        columns={columns}
      />
    </ContentLayout>
  );
};

export default SubCategory;
