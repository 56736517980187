import moment from "moment";
import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

const NotificationDetail = () => {
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    orderData();
  }, []);

  const orderData = async () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    let data = {
      userType: localStorage.getItem("userType"),
      email: localStorage.getItem("email"),
    };
    await axios
      .post(baseUrl + "/frontapi/getNotifications", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setNotificationData(resp.data.result);
        }
      });
  };

  const materialListHtml = () => {
    const html = [];
    notificationData.map((item, i) => {
      return html.push(
        <li key={i}>
          <div className="product-quantity">
            <p className="notify-text">
              <b>{item.message}</b>
            </p>
            <p className="notify-date">
              <b>{moment(item.created_at).format("lll")}</b>
            </p>
          </div>
        </li>
      );
    });
    return html;
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Notifications</b>
              </h2>
            </div>
            <div className="manufacture-main-outer">
              <div className="add-product-outer manufacture-right">
                <div className="product-full-detail customer-detail-outer">
                  <ul className="nav">{materialListHtml()}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NotificationDetail;
