import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { baseUrl } from "./BaseUrl";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { selectStyleLight } from "../Hooks/SelectStyle";
import moment from "moment";
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IconContext } from "react-icons";
const config = reactTableConfig("Campaign");

const CampaignManager = () => {
  const [id, setId] = useState("");
  const [record, setRecord] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [custData, setCustData] = useState([]);
  const [custId, setCustId] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalCustomer, setShowModalCustomer] = useState(false);
  const [custErr, setCustErr] = useState("");
  const [whatsappDisable, setWhatsappDisable] = useState(false);
  const [emailDisable, setEmailDisable] = useState(false);

  useEffect(() => {
    customerData();
    campaignData();
  }, []);

  const columns = [
    {
      key: "#",
      text: "Sr No.",
      className: "#",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Campaign ID",
      className: "id",
      align: "left",
      sortable: true,
    },

    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },

    {
      key: "startDate",
      text: "Start Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.startDate).format("lll")}</Fragment>;
      },
    },

    {
      key: "endDate",
      text: "End Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.endDate).format("lll")}</Fragment>;
      },
    },

    {
      key: "description",
      text: "Description",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {record.description} </Fragment>;
      },
    },
    {
      key: "image",
      text: "Image",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              type="button"
              onClick={() => {
                setDocumentData(JSON.parse(record.image));
                setShowModal(true);
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {moment(record.created_at).format("lll")} </Fragment>;
      },
    },

    {
      key: "action",
      text: "Action",
      className: "detail",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = new Date(); // Assuming you have a date object
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so we add 1
        const day = String(date.getDate()).padStart(2, "0");
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const currentDate = `${year}-${month}-${day}-${hours}-${minutes}`;

        const enteredDate = new Date(record.endDate); // Assuming you have a date object
        const enteredYear = enteredDate.getFullYear();
        const enteredMonth = String(enteredDate.getMonth() + 1).padStart(
          2,
          "0"
        ); // Month starts from 0, so we add 1
        const enteredDay = String(enteredDate.getDate()).padStart(2, "0");
        const enteredHours = enteredDate.getHours();
        const enteredMinutes = enteredDate.getMinutes();

        const enteredFormattedDate = `${enteredYear}-${enteredMonth}-${enteredDay}-${enteredHours}-${enteredMinutes}`;

        return (
          <Fragment>
            {currentDate > enteredFormattedDate ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => {
                  setId(record.id);
                  setCustId("");
                  setCustErr("");
                  setShowModalCustomer(true);
                }}
                className="btn btn-primary"
              >
                Share
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const customerData = () => {
    const data = {
      userType: localStorage.getItem("userType"),
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios.post(baseUrl + "/frontapi/get-customer", data, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        var Data = resp.data.sort(function (a, b) {
          var textA = a.name;
          var textB = b.name;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        setCustData(Data);
      }
    });
  };

  const campaignData = () => {
    axios
      .post(baseUrl + "/frontapi/campaign-data-sales", {
        userType: localStorage.getItem("userType"),
      })
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
          setFilterData(resp.data);
        }
      });
  };

  const closeShowModel = () => {
    setDocumentData([]);
    setShowModal(false);
  };

  const closeShareModel = () => {
    setId("");
    setCustId("");
    setCustErr("");
    setWhatsappDisable(false);
    setEmailDisable(false);
    setShowModalCustomer(false);
  };

  const handleChangeCustomer = (selectedOption) => {
    let html = [];

    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        const element = selectedOption[i];

        if (element.value === "all") {
          custData.map((value, i) => {
            return html.push({ value: value.id, label: value.name });
          });

          setCustId(html);
          return false;
        }
      }
    }

    setCustErr("");
    setCustId(selectedOption);
  };

  const handleChangeFilter = (e) => {
    let value = e.target.value;
    setFilterValue(value);

    if (value === "") {
      setFilterData(record);
      return false;
    } else {
      const date = new Date(); // Assuming you have a date object
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so we add 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = date.getHours();
      const minutes = date.getMinutes();

      const currentDate = `${year}-${month}-${day}-${hours}-${minutes}`;

      if (value === "Past") {
        const filteredData = record.filter((item) => {
          const enteredDate = new Date(item.endDate); // Assuming you have a date object
          const enteredYear = enteredDate.getFullYear();
          const enteredMonth = String(enteredDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Month starts from 0, so we add 1
          const enteredDay = String(enteredDate.getDate()).padStart(2, "0");
          const enteredHours = enteredDate.getHours();
          const enteredMinutes = enteredDate.getMinutes();

          const enteredFormattedDate = `${enteredYear}-${enteredMonth}-${enteredDay}-${enteredHours}-${enteredMinutes}`;

          return currentDate > enteredFormattedDate;
        });
        setFilterData(filteredData);
      }

      if (value === "Upcoming") {
        const filteredData = record.filter((item) => {
          const enteredDate = new Date(item.startDate); // Assuming you have a date object
          const enteredYear = enteredDate.getFullYear();
          const enteredMonth = String(enteredDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Month starts from 0, so we add 1
          const enteredDay = String(enteredDate.getDate()).padStart(2, "0");
          const enteredHours = enteredDate.getHours();
          const enteredMinutes = enteredDate.getMinutes();

          const enteredFormattedDate = `${enteredYear}-${enteredMonth}-${enteredDay}-${enteredHours}-${enteredMinutes}`;

          return currentDate < enteredFormattedDate;
        });
        setFilterData(filteredData);
      }

      if (value === "Running") {
        const filteredData = record.filter((item) => {
          const startDate = new Date(item.startDate); // Assuming you have a date object
          const startYear = startDate.getFullYear();
          const startMonth = String(startDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so we add 1
          const startDay = String(startDate.getDate()).padStart(2, "0");
          const startHours = startDate.getHours();
          const startMinutes = startDate.getMinutes();

          const startFormattedDate = `${startYear}-${startMonth}-${startDay}-${startHours}-${startMinutes}`;

          const endDate = new Date(item.endDate); // Assuming you have a date object
          const endYear = endDate.getFullYear();
          const endMonth = String(endDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so we add 1
          const endDay = String(endDate.getDate()).padStart(2, "0");
          const endHours = endDate.getHours();
          const endMinutes = endDate.getMinutes();

          const endFormattedDate = `${endYear}-${endMonth}-${endDay}-${endHours}-${endMinutes}`;

          return (
            startFormattedDate <= currentDate && currentDate < endFormattedDate
          );
        });

        setFilterData(filteredData);
      }
    }
  };

  const customOptionsHtml = () => {
    let html = [];

    if (custData && custData.length > 0) {
      html.push({ value: "all", label: "Select All" });

      custData.map((value, i) => {
        return html.push({ value: value.id, label: value.name });
      });
    }

    return html;
  };

  const sendWhatsApp = async () => {
    if (!id) {
      toast.dismiss();
      toast.error("Id is required, please try again");
      return false;
    }

    if (!custId || custId.length === 0) {
      setCustErr("Email is required");
      return false;
    }

    setWhatsappDisable(true);
    const data = {
      id: id,
      customer: custId,
    };

    await axios
      .post(baseUrl + "/frontapi/campaign-whatsapp", data)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status) {
          toast.success(resp.message);
          setTimeout(() => {
            setWhatsappDisable(false);
          }, 3000);
        } else {
          setWhatsappDisable(true);
          toast.error(resp.message);
        }
      });
  };

  const sendEmail = async () => {
    if (!id) {
      toast.dismiss();
      toast.error("Id is required, please try again");
      return false;
    }

    if (!custId || custId.length === 0) {
      setCustErr("Email is required");
      return false;
    }

    setEmailDisable(true);

    const data = {
      id: id,
      customer: custId,
    };

    await axios.post(baseUrl + "/frontapi/campaign-email", data).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status) {
        toast.success(resp.message);
        setTimeout(() => {
          setEmailDisable(false);
        }, 3000);
      } else {
        setEmailDisable(true);
        toast.error(resp.message);
      }
    });
  };

  const imageHtml = () => {
    const html = [];
    documentData.map((value) => {
      return html.push(
        <div className="col-md-6 col-12">
          <div className="product-price">
            <img
              src={baseUrl + "/static/campaign/" + value.image}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      );
    });
    return html;
  };

  const filterComponent = () => {
    return (
      <div className="add-product-btn text-center">
        <h4>Filter By Date</h4>
        <select
          className="form-control"
          value={filterValue}
          onChange={handleChangeFilter}
        >
          <option value="">All</option>
          <option value="Past">Past</option>
          <option value="Running">Running</option>
          <option value="Upcoming">Upcoming</option>
        </select>
      </div>
    );
  };

  return (
    <>
      <ContentLayout
        title="Campaign List"
        className="product-list-outer customer-list-outer"
        ExtraComponent={filterComponent}
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={filterData}
          columns={columns}
        />

        <Modal
          className="modal-update"
          show={showModal}
          onHide={closeShowModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              Images
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">{imageHtml()}</div>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal-update"
          show={showModalCustomer}
          onHide={closeShareModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              Share Campaign
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            {/* <div className="row align-items-center"> */}
            <div className="col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <label>Customers</label>
                <Select
                  value={custId}
                  onChange={handleChangeCustomer}
                  styles={selectStyleLight}
                  className="custom-select"
                  name="custId"
                  isMulti
                  isClearable={true}
                  noOptionsMessage={() => "No customer found"}
                  options={customOptionsHtml()}
                />

                <p style={{ color: "red" }}>{custErr}</p>
              </div>
              {/* </div> */}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="btn-group">
              <IconContext.Provider
                value={{
                  color: "white",
                  // size: "0.5em",
                  // style: { padding: "3px" },
                }}
              >
                <button
                  className="btn"
                  style={{ background: "#65B741", marginRight: "4px" }}
                  onClick={sendWhatsApp}
                  disabled={whatsappDisable}
                >
                  <FaWhatsapp />
                </button>

                <button
                  className="btn"
                  style={{ background: "#008DDA", marginRight: "4px" }}
                  onClick={sendEmail}
                  disabled={emailDisable}
                >
                  <MdEmail />
                </button>

                <button className="btn btn-primary" onClick={closeShareModel}>
                  Cancel
                </button>
              </IconContext.Provider>
            </div>
          </Modal.Footer>
        </Modal>
      </ContentLayout>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
};

export default CampaignManager;
