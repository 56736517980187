import React, { useState, useEffect, Fragment, useMemo } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import { formatDateTime } from "../utils/dateTime";
import moment from "moment";
import * as myConstList from "./BaseUrl";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Vendor Completed Order");

const VendorOrderCompleted = () => {
  const [record, setRecord] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 2, orderType: "vendor" };
  }, []);

  const { data, loading } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total",
      text: "Total Price",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.total} INR</Fragment>;
      },
    },
    {
      key: "vendor_name",
      text: "Assigned To",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "orderType",
      text: "Order Type",
      className: "otype",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "counter",
      text: "Ordered Date",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.created_at)}</Fragment>;
      },
    },
    {
      key: "counter",
      text: "Finished Date",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.finishAt)}</Fragment>;
      },
    },

    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendor-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const AdminColumns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total",
      text: "Total Price",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.total} INR</Fragment>;
      },
    },
    {
      key: "vendor_name",
      text: "Assigned To",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "orderType",
      text: "Order Type",
      className: "otype",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.orderType === null ? "Normal" : ""}
            {record.orderType === "Customize" ? "Customize" : ""}
            {record.orderType === "buy" ? "Buy" : ""}
            {record.orderType === "manufacture" ? "Manufacture" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "counter",
      text: "Finished Date",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{formatDateTime(record.finishAt)}</Fragment>;
      },
    },

    {
      key: "invoiceNumber",
      text: "invoiceStatus",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.invoiceNumber === "" || record.invoiceNumber === null
              ? record.invoiceStatus
              : `Invoice_Number : ${record.invoiceNumber}`}
          </Fragment>
        );
      },
    },

    {
      key: "counter",
      text: "Total Time Taken",
      className: "counter",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {showTimerCounterHtml(record.finishAt, record.created_at)}
          </Fragment>
        );
      },
    },

    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendor-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  const showTimerCounterHtml = (expriryDateTime, startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD hh:mm:ss");
    var endDate = moment(expriryDateTime).format("YYYY-MM-DD hh:mm:ss");

    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();
    const secondsExpiry = moment(endDate, "YYYY-MM-DD HH:mm:ss").unix();

    var remainingSeconds = secondsExpiry - secondsStart;
    const duration = moment.duration(remainingSeconds, "seconds");

    // const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsRemaining = duration.seconds();

    return `${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`;

    // setRemaingTime(
    //   `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
    // );
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>VENDOR ORDERS COMPLETED</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  {localStorage.getItem("userType") === "ADMIN" ? (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={record}
                      columns={AdminColumns}
                      loading={loading}
                    />
                  ) : (
                    <ReactDatatable
                      className="table table-bordered table-striped "
                      config={config}
                      records={record}
                      columns={columns}
                      loading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />{" "}
      </div>
    </div>
  );
};

export default VendorOrderCompleted;
