import React from 'react';
// import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export const Button = ({ onClick, loading, children, className }) => {
  return (
    <button
      className={`btn btn-primary ${className ? className : ''}`}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        children
      )}
    </button>
  );
};

