import moment from "moment";

export const formatDate = (date, format = "MMM D, YYYY") =>
  date ? moment(date).format(format) : date;

export const formatDateTime = (date, format = "MMM D, YYYY, h:mm A") =>
  date ? moment(date).format(format) : date;

export const formatDateTimeForAPI = (date) =>
  date ? moment(date).utc().format() : date;

export const momentObj = (date) => (date ? moment(date) : date);
