export const selectStyle = {
  control: (base, state) => ({
    ...base,
    // color: '#ffffff',
    background: "#333333",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "none" : "none",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "none" : "none",
    },
    margin: "0",
    padding: "0",
  }),
  singleValue: (styles) => {
    return {
      ...styles,
      color: "#FFFFFF", // Set the color of the selected single value to white
    };
  },
  input: (styles) => ({
    ...styles,
    color: "#FFFFFF", // Set the color of the typed input text to white
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#333333",
      color: "#FFFFFF",
      padding: "2px 10px",
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    backgroundColor: "#999999", // Change background color for "No options" message
    color: "white", // Change text color for "No options" message
    padding: "2px 10px",
    // Add any other styles you want
  }),
};

export const selectStyleLight = {
  control: (base, state) => ({
    ...base,
    // color: '#ffffff',
    background: "#5a5a5a",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "none" : "none",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "none" : "none",
    },
    margin: "0",
    padding: "0",
  }),
  singleValue: (styles) => {
    return {
      ...styles,
      color: "#FFFFFF", // Set the color of the selected single value to white
    };
  },
  input: (styles) => ({
    ...styles,
    color: "#FFFFFF", // Set the color of the typed input text to white
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#333333",
      color: "#FFFFFF",
      padding: "2px 10px",
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    backgroundColor: "#999999", // Change background color for "No options" message
    color: "white", // Change text color for "No options" message
    padding: "2px 10px",
    // Add any other styles you want
  }),
};

export const selectStyleDark = {
  control: (base, state) => ({
    ...base,
    // color: '#ffffff',
    background: "#5a5a5a",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "none" : "none",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "none" : "none",
    },
    margin: "0",
    padding: "0",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#333333",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#333333",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#333333",
    ":hover": {
      backgroundColor: "#333333",
      color: "white",
    },
  }),

  // singleValue: (styles) => {
  //   return {
  //     ...styles,
  //     color: "#FFFFFF", // Set the color of the selected single value to white
  //   };
  // },
  input: (styles) => ({
    ...styles,
    color: "#FFFFFF", // Set the color of the typed input text to white
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#333333",
      color: "#FFFFFF",
      padding: "2px 10px",
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    backgroundColor: "#999999", // Change background color for "No options" message
    color: "white", // Change text color for "No options" message
    padding: "2px 10px",
    // Add any other styles you want
  }),
};
