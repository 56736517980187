import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { useEffect } from "react";
const baseUrl = myConstList.baseUrl;

function AddSalesPerson() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [salesManData, setSalesManData] = useState([]);
  const [salesManagerId, setSalesManagerId] = useState("");
  const [AllZoneData, setAllZoneData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [zone, setZone] = useState("");

  const [formValues, setFormValues] = useState([{ zone: "" }]);
  const [formValuesError, setFormValuesError] = useState([{ zone: "" }]);

  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [salesErr, setSalesErr] = useState("");
  const [zoneErr, setZoneErr] = useState("");
  const [loading, setLoading] = useState(false);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  };

  useEffect(() => {
    SalesManagerData();
    getZonesData();
  }, []);

  const SalesManagerData = () => {
    axios
      .post(baseUrl + "/frontapi/sales-manager-data-deleted", SalesManagerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setSalesManData(resp.data);
        }
      });
  };

  const getZonesData = () => {
    axios.post(baseUrl + "/frontapi/zone-data-deleted", {}).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setAllZoneData(resp.data);
      }
    });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const salesManagerHtml = () => {
    const salesManagerhtml = [];
    var SMData = salesManData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SMData.map((value, i) => {
      return salesManagerhtml.push(
        <option value={value.id}>{value.name}</option>
      );
    });
    return salesManagerhtml;
  };

  const zoneHtml = () => {
    const zonehtml = [];
    var ZNData = zoneData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    ZNData.map((value, i) => {
      return zonehtml.push(<option value={value.id}>{value.zone}</option>);
    });
    return zonehtml;
  };

  const handleChange = async (event) => {
    let { value, name } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }

      setNameErr("");
    }

    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailErr("Email is required");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }

      setEmailErr("");
    }

    if (name === "number") {
      setNumber(value);
      if (!value) {
        setNumErr("Mobile number is required");
        return false;
      }

      if (!/^[6-9]/.test(value)) {
        setNumErr("Please enter valid mobile number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }

    if (name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      setPassword(value);
      if (!value) {
        setPassErr("Password is required");
        return false;
      }

      if (!value.match(regex)) {
        setPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }
      setPassErr("");
    }

    if (name === "salesManagerId") {
      setSalesManagerId(value);
      setSalesErr("");
      setZoneData([]);
      setZone("");

      if (!value) {
        return;
      }

      // a function to get zone data by sales man
      zoneByManager(value);
    }

    if (name === "zone") {
      setZone(value);
      setZoneErr("");
    }
  };

  const zoneByManager = (managerId) => {
    const data = {
      id: managerId,
    };

    axios.post(baseUrl + "/frontapi/getZonesByManager", data).then((res) => {
      var resp = res.data;

      console.log("respppppp", resp);
      const convertedZone = JSON.parse(resp.data[0].zone);
      console.log("convertedZone", convertedZone);

      // Create a set of zones from the second array
      const secondArrayZones = new Set(
        convertedZone.map((item) => parseInt(item.zone, 10))
      );

      // Filter the first array to include only those objects whose ids are in the set
      const commonArray = AllZoneData.filter((item) =>
        secondArrayZones.has(item.id)
      );

      setZoneData(commonArray);
    });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = "";
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
    if (!name && !email && !number && !password) {
      setNameErr("Name is required");
      setEmailErr("Email is required");
      setNumErr("Mobile number is required");
      setPassErr("Password is required");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Email is required");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Mobile number is required");
      return false;
    }

    if (!/^[6-9]/.test(number)) {
      setNumErr("Please enter valid mobile number");
      return false;
    }

    if (number.length < 10 || number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    if (!password) {
      setPassErr("Password is required");
      return false;
    }

    if (!password.match(regex)) {
      setPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    error = checkSpace("password", password);
    if (error) {
      setPassErr(error);
      return false;
    }

    if (!salesManagerId || salesManagerId === "Please select") {
      setSalesErr("Please select sales manager");
      return false;
    }

    // if (!zone || zone === "Please select") {
    //   setZoneErr("Please select zone");
    //   return false;
    // }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.zone || element.zone === "Please Select") {
        formError[i].zone = "Please select zone";
        setFormValuesError(formError);
        return false;
      }

      html.push(element.zone);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate zone found");
      return false;
    }

    setLoading(true);

    let Data = {
      name: name,
      email: email,
      number: number,
      password: password,
      salesManagerId: salesManagerId,
      zoneData: JSON.stringify(formValues),
    };
    axios
      .post(baseUrl + "/frontapi/sales-person-add", Data)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === false) {
          toast.error(resp.message);
          clearWaitingQueue();
        }
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/sales-person";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(true));
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  let addFormFields = () => {
    setFormValues([...formValues, { zone: "" }]);
    setFormValuesError([...formValuesError, { zone: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeZone = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.name === "zone") {
      if (!newFormValues[i].zone || newFormValues[i].zone === "Please Select") {
        newFormValuesError[i][e.target.name] = "Please select zone";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  return (
    <ContentLayout
      title="ADD Sales Person"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{emailErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Mobile number</label>
              <input
                type="number"
                name="number"
                maxLength="10"
                onKeyDown={handleValidation}
                value={number}
                placeholder="Mobile number"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{numErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Password</label>
              <input
                type={typePass}
                name="password"
                placeholder="Password"
                className="form-control"
                onChange={handleChange}
              />
              <span
                role="button"
                className="password__show eye1"
                onClick={() => handleClick()}
              >
                {typePass === "text" ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </span>
              <p style={{ color: "red" }}>{passErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Sales Manager</label>
              <select
                className="form-control"
                name="salesManagerId"
                value={salesManagerId}
                onChange={handleChange}
              >
                <option value={""}>Please select</option>
                {salesManagerHtml()}
              </select>

              <p style={{ color: "red" }}>{salesErr}</p>
            </div>
          </div>

          <div className="row">
            {formValues.map((element, index) => (
              <>
                <div className="col-md-4 col-12">
                  <div className="add-row-group d-flex align-items-center justify-content-between">
                    <div className="form-group">
                      <label>Zone</label>
                      <select
                        className="form-control"
                        name="zone"
                        value={element.zone || ""}
                        onChange={(e) => handleChangeZone(index, e)}
                      >
                        <option>Please Select</option>
                        {zoneHtml()}
                      </select>
                      <p style={{ color: "red" }}>
                        {" "}
                        {formValuesError[index].zone}{" "}
                      </p>
                    </div>
                    <div className="add-row">
                      {index ? (
                        <a
                          className="remove"
                          href="#!"
                          onClick={() => removeFormFields(index)}
                        >
                          -
                        </a>
                      ) : null}
                      <a
                        className="add-btn add"
                        href="#!"
                        onClick={() => addFormFields()}
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

          {/* <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Zone</label>
              <select
                className="form-control"
                name="zone"
                value={zone}
                onChange={handleChange}
              >
                <option value={""}>Please select</option>
                {zoneHtml()}
              </select>

              <p style={{ color: "red" }}>{zoneErr}</p>
            </div>
          </div> */}
        </div>
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/sales-person"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default AddSalesPerson;
