import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import { Modal } from "react-bootstrap";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
const baseUrl = myConstList.baseUrl;

function SalesManagerEdit() {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [id, setId] = useState(atob(useParams().id));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [zone, setZone] = useState("");
  const [zoneData, setZoneData] = useState([]);
  const [formValues, setFormValues] = useState([{ zone: "" }]);
  const [formValuesError, setFormValuesError] = useState([{ zone: "" }]);

  // errors //
  const [typePass, setTypePass] = useState("password");
  const [typePass1, setTypePass1] = useState("password");
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [newPassErr, setNewPassErr] = useState("");
  const [cPassError, setCPassError] = useState("");
  const [zoneErr, setZoneErr] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    SalesMangerSingle();

    getZoneData();
  }, []);

  const getZoneData = () => {
    axios.post(baseUrl + "/frontapi/zone-data-deleted").then((res) => {
      const resp = res.data;
      setZoneData(resp.data);
    });
  };

  const zoneHtml = () => {
    const designationhtml = [];

    zoneData.map((value, i) => {
      return designationhtml.push(
        <option value={value.id}>{value.zone}</option>
      );
    });
    return designationhtml;
  };
  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };
  const handleChangeName = async (event) => {
    let { value, name } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Please enter name");
        return false;
      }

      setNameErr("");
    }
    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailErr("Please enter email");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }

      setEmailErr("");
    }

    if (name === "number") {
      setNumber(value);
      if (!value) {
        setNumErr("Please enter mobile number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }

    if (name === "zone") {
      setZone(value);

      if (!value) {
        setZoneErr("Please select zone");
        return false;
      }

      setZoneErr("");
    }
  };

  const SalesMangerSingle = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/sales-manager-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setId(resp.data[0].id);
          setName(resp.data[0].name);
          setEmail(resp.data[0].email);
          setNumber(resp.data[0].mobile_no);

          const zoneConverted = JSON.parse(resp.data[0].zone);

          if (zoneConverted.length > 0) {
            setFormValues(zoneConverted);
            let html = [];
            zoneConverted.forEach((element) => {
              let tempArr1 = [
                {
                  zone: "",
                },
              ];
              html.push(tempArr1);
            });
            setFormValuesError(html);
          } else {
            setFormValues([{}]);
          }

          // setZone(resp.data[0].zone);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";
    if (!name && !email && !number) {
      setNameErr("Please enter name");
      setEmailErr("Please enter email");
      setNumErr("Please enter mobile number");
      return false;
    }

    if (!name) {
      setNameErr("Please enter name");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Please enter email");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Please enter mobile number");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    // if (!zone) {
    //   setZoneErr("Please select zone");
    //   return false;
    // }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.zone || element.zone === "Please Select") {
        formError[i].zone = "Please select zone";
        setFormValuesError(formError);
        return false;
      }

      html.push(element.zone);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate zone found");
      return false;
    }

    setLoading(true);
    let data = {
      id: id,
      name: name,
      email: email,
      number: number,
      zoneData: JSON.stringify(formValues),
    };
    axios
      .post(baseUrl + "/frontapi/sales-manager-update", data)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location.href = "/sales-manager";
          }, 3000);
          return false;
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangePass = (e) => {
    let { name, value } = e.target;
    if (name === "newPass") {
      setNewPass(value);
      if (!value) {
        setNewPassErr("This field is required");
        return false;
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!value.match(regex)) {
        setNewPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      } else {
        setNewPassErr("");
      }
      if (value !== confirmPass) {
        setCPassError("Confirm password does not match");
        return false;
      } else {
        setCPassError("");
      }

      setNewPassErr("");
    }

    if (name === "confirmPass") {
      setConfirmPass(value);
      if (!value) {
        setCPassError("This field is required");
        return false;
      }
      if (value !== newPass) {
        setCPassError("Confirm password does not match");
        return false;
      }
      setCPassError("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = () => {
    if (!newPass) {
      setNewPassErr("This field is required");
      return false;
    }
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!newPass.match(regex)) {
      setNewPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    let error = checkSpace("new password", newPass);
    if (error) {
      setNewPassErr(error);
      return false;
    }

    if (!confirmPass) {
      setCPassError("This field is required");
      return false;
    }

    error = checkSpace("confirm password", confirmPass);
    if (error) {
      setCPassError(error);
      return false;
    }

    if (confirmPass !== newPass) {
      setCPassError("Confirm password does not match");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const data = {
      userId: id,
      newPass: newPass,
      confirmPass: confirmPass,
    };

    axios.post(baseUrl + "/frontapi/changePass", data, config).then((res) => {
      const resp = res.data;
      toast.dismiss();

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setTimeout(() => {
          window.location.href = "/sales-manager";
        }, 2000);
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPass("");
    setConfirmPass("");
    setNewPassErr("");
    setCPassError("");
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  const handleClick1 = () => {
    if (typePass1 === "password") {
      setTypePass1("text");
      return false;
    } else {
      setTypePass1("password");
    }
  };

  let addFormFields = () => {
    setFormValues([...formValues, { zone: "" }]);
    setFormValuesError([...formValuesError, { zone: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeZone = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.name === "zone") {
      if (!newFormValues[i].zone || newFormValues[i].zone === "Please Select") {
        newFormValuesError[i][e.target.name] = "Please select zone";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Modal show={modalIsOpen} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  {" "}
                  Change Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        className="form-control fs12"
                        placeholder="Enter New Password"
                        type={typePass}
                        name="newPass"
                        onChange={handleChangePass}
                        value={newPass}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick()}
                      >
                        {typePass === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{newPassErr}</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        placeholder="Enter Confirm Password"
                        className="form-control fs12"
                        type={typePass1}
                        name="confirmPass"
                        onChange={handleChangePass}
                        value={confirmPass}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick1()}
                      >
                        {typePass1 === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <p style={{ color: "red" }}>{cPassError}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="d-block">
                <div className="submit-btn-outer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <div className="section-heading">
              <h2>
                <b>Sales Manager Edit</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Name"
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}> {nameErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChangeName}
                            // disabled
                          />
                          <p style={{ color: "red" }}> {emailErr} </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="number"
                            name="number"
                            value={number}
                            onKeyDown={handleValidation}
                            maxLength={10}
                            placeholder="Mobile Number"
                            className="form-control"
                            onChange={handleChangeName}
                          />
                          <p style={{ color: "red" }}> {numErr} </p>
                        </div>
                      </div>

                      <div className="row">
                        {formValues.map((element, index) => (
                          <>
                            <div className="col-md-4 col-12">
                              <div className="add-row-group d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                  <label>Zone</label>
                                  <select
                                    className="form-control"
                                    name="zone"
                                    value={element.zone || ""}
                                    onChange={(e) => handleChangeZone(index, e)}
                                  >
                                    <option>Please Select</option>
                                    {zoneHtml()}
                                  </select>
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].zone}{" "}
                                  </p>
                                </div>
                                <div className="add-row">
                                  {index ? (
                                    <a
                                      className="remove"
                                      href="#!"
                                      onClick={() => removeFormFields(index)}
                                    >
                                      -
                                    </a>
                                  ) : null}
                                  <a
                                    className="add-btn add"
                                    href="#!"
                                    onClick={() => addFormFields()}
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>

                      {/* <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Zone</label>
                          <select
                            className="form-control"
                            name="zone"
                            value={zone}
                            onChange={handleChangeName}
                          >
                            <option value="">Please select</option>
                            {zoneHtml()}
                          </select>
                          <p style={{ color: "red" }}>{zoneErr}</p>
                        </div>
                      </div> */}

                      <div className="col-md-4 col-sm-6 col-12 align-self-end">
                        <div className="form-group">
                          <button
                            type="button"
                            // onClick={buyPopUp}
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#add-user-modal"
                            onClick={() => setModalIsOpen(true)}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/sales-manager"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default SalesManagerEdit;
