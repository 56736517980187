import React, { useState, useEffect } from "react";
import axios from "axios";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

const ViewManufacturingMaterials = () => {
  const [tableData, setTableData] = useState([]);
  const Params = useParams();
  const vendorRawMaterialId = atob(Params.id);

  useEffect(() => {
    orderData();
  }, []);

  const orderData = async () => {
    const data = {
      id: vendorRawMaterialId,
    };
    await axios
      .post(baseUrl + "/frontapi/get-vendor-raw-material", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTableData(resp.data);
        }
      });
  };

  const manufactureHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Vendor Order Id: <b>{value.id}</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name: <b>{value.prod_name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding ">
            <div className="person-detail">
              <p>
                Assigned to: <b>{value.assigned_to}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Quantity:{" "}
                <b>
                  {value.quantity} {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Price: <b>{value.price} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                GST: <b>{value.tax}%</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Total: <b>{value.total} INR</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Status: {value.status === "1" ? <b>InProcess</b> : ""}
                {value.status === "0" ? <b>Pending</b> : ""}
                {value.status === "2" ? <b>Completed</b> : ""}
                {value.status === "3" ? <b>Rejected</b> : ""}
              </p>
            </div>
          </div>
          {tableData.length > 1 && <hr style={{ color: "white" }} />}
        </div>
      );
    });
    return html;
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>BUY ORDER DETAIL FOR MANUFACTURING</b>
              </h2>
            </div>
            <div className=" customer-detail-outer">
              <div className="order-detail-outer-middle pending-list-outer">
                <div className="col-12">
                  <div className="person-contact customer-detail">
                    {manufactureHtml()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewManufacturingMaterials;
