import React from "react";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";

export const ButtonGroup = ({ onClick, link, loading }) => {
  const navigate = useNavigate();
  return (
    <div className="btn-group">
      <Button onClick={onClick} loading={loading}>Save</Button>
      <br />
      <Button onClick={() => navigate(link)}>Cancel</Button>
    </div>
  );
};
