import moment from "moment";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { data } from "jquery";
import Select from "react-select";
import { selectStyle } from "../Hooks/SelectStyle";
import { LoadingSpinnerButton } from "./Spinner";

const Manufacture = () => {
  const tempId = useParams();
  const orderId = atob(tempId.id);
  const subOrderIdParams = atob(tempId.subOrderId);
  const [customerName, setCustomerName] = useState("");
  const [salsePersonName, setSalsePersonName] = useState("");
  const [theOrderId, setTheOrderId] = useState("");
  const [theSubOrderId, setTheSubOrderId] = useState("");
  const [tax, setTax] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [materialData, setMaterialData] = useState([]);
  const [vendorListData, setVendorListData] = useState([]);
  const [materialPrice, setMaterialPrice] = useState("");
  const [materialQuantity, setMaterialQuantity] = useState("");
  const [showLockModal, setShowLockModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalWithoutGst, setTotalWithoutGst] = useState(0);
  const [materialName, setMaterialName] = useState("");
  const [specification, setSpecification] = useState("");

  const [lockQty, setLockQty] = useState("");
  const [availableQty, setAvailableQty] = useState("");
  const [msgGreen, setMsgGreen] = useState("");
  const [msgRed, setMsgRed] = useState("");
  const [materialId, setMaterialId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [productId, setProductId] = useState("");
  const [materialAdd, setMaterialAdd] = useState(false);
  const [quantityForManufacture, setQuantityForManufacture] = useState("");
  const [units, setUnits] = useState("");
  const [vendorNameError, setVendorNameError] = useState("");
  const [vendorPriceError, setVendorPriceError] = useState("");
  const [suggestedTotal, setSuggestedTotal] = useState(0);
  const [remainingQty, setRemainingQty] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  // temp states for buy and lock

  const [buyBtn, setBuyBtn] = useState(false);
  const [lockBtn, setlockBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const [productAvailableQty, setProductAvailableQty] = useState("");
  const [productMaterials_id, setProductMaterials_id] = useState("");
  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [inventoryValues, setInventoryValues] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
    },
  ]);

  const [nameChange, setNameChange] = useState("Hold");
  const [showVendor, setShowVendor] = useState(false);
  const [productName, setProductName] = useState("");
  const [submitMaterialData, setSubmitMaterialData] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    orderData(orderId);
    getMaterialDetails();
    getVendorList();
    getMaterialData();
  }, []);

  const getVendorList = () => {
    axios.get(baseUrl + "/frontapi/get-vendor-list").then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setVendorListData(resp.data);
      }
    });
  };

  const getMaterialData = (prodId) => {
    const data = {
      productId: prodId,
    };
    axios
      .post(baseUrl + "/frontapi/getMaterialsForManufacture", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRawMaterialData(resp.data);
        }
      });
  };

  const orderData = async (orderId) => {
    let data = {
      orderId: orderId,
      subOrderId: subOrderIdParams,
    };

    await axios
      .post(baseUrl + "/frontapi/order-single-vendor", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setDateOfOrder(resp.data[0].createAt);
          setSalsePersonName(resp.data[0].userName);
          setCustomerName(resp.data[0].salseName);
          setProductId(resp.data[0].product_Id);
          materialDataApi(resp.data[0].product_Id);
          setTheOrderId(resp.data[0].orderId);
          setTheSubOrderId(
            subOrderIdParams !== "undefined" ? resp.data[0].id : null
          );
          setUserId(resp.data[0].customer_id);
          setProductAvailableQty(resp.data[0].avaliable_qty);
          getMaterialData(resp.data[0].product_Id);
          setProductName(resp.data[0].productName);
          setProductPrice(resp.data[0].price);
          setQuantityForManufacture(resp.data[0].remaining_qty);
          setUnits(resp.data[0].units);
          setRemainingQty(resp.data[0].remaining_qty);
        }
      });
  };

  const getMaterialDetails = async (id) => {
    let data = {
      orderId: id,
      runThis: "yes",
    };
    await axios
      .post(baseUrl + "/frontapi/get-material-list", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setMaterialPrice(resp.data[0].price);
          setTax(resp.data[0].tax);
          setHsnCode(resp.data[0].hsnCode);
          setUnits(resp.data[0].units);

          let total = productPrice * quantityForManufacture;
          const taxValue = (total * resp.data[0].tax) / 100;
          const theValue = taxValue + total;

          setTotal(theValue);

          setTotalWithoutGst(total);
        }
      });
  };

  const materialDataApi = async (item) => {
    let data = {
      productId: item,
    };

    await axios.post(baseUrl + "/frontapi/get-materials", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setMaterialData(resp.data);
      }
    });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChangeLock = async (e) => {
    let value = e.target.value;
    var result = value - availableQty;
    var diff = Math.abs(result);

    if (!value || value == "0") {
      setMsgRed("");
      setMsgGreen("");
      setLockQty(value);
      return false;
    }

    if (value < 0) {
      setMsgRed("Quantity cannot be negative");
      setMsgGreen("");
      setLockQty(value);
      return false;
    }

    if (Number(availableQty) > Number(value)) {
      setMsgRed("");
      setMsgGreen("Hold your quantity");
      setLockQty(value);
      setNameChange("Hold");
      return false;
    }

    if (Number(value) > Number(availableQty)) {
      setMsgGreen("");
      setMsgRed("You need to buy " + diff + " no of quantity more");
      setLockQty(value);
      setNameChange("Buy");
      return false;
    }
    setLockQty(value);
  };

  const unholdQty = (item) => {
    setLoading(true);
    data = {
      orderId: theOrderId,
      subOrderId: theSubOrderId,
      material_id: item.material_id,
      avaliable_qty: item.material_available_quantity,
    };

    axios.post(baseUrl + "/frontapi/unlockQty", data).then((res) => {
      var resp = res.data;

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setLoading(false);
        materialDataApi(item.product_id);
        return;
      } else {
        setLoading(false);
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };
  const materialListHtml = () => {
    const html = [];
    materialData.map((item, i) => {
      return html.push(
        <div className="col-md-6 col-12">
          <li className="manufactur-list" key={i}>
            <div className="product-quantity d-flex flex-wrap align-items-center justify-content-between">
              <div className="col-sm-12 col-md-12">
                <p>
                  Type : <b>{item.material_type}</b>
                </p>
              </div>
              <div className="col-sm-6 col-md-6">
                <p>
                  Available Quantity : <b>{item.material_available_quantity}</b>
                </p>{" "}
              </div>
              <div className="col-sm-6 col-md-6">
                <p>
                  Material Required Per Unit : <b>{item.material_quantity}</b>
                </p>
              </div>
            </div>
            <div className="price-buy d-flex flex-wrap">
              <div className="col-md-4">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    openShowLockModel(item);
                  }}
                >
                  Hold Qty
                </button>
              </div>
              <div className="col-md-4">
                {loading ? (
                  <LoadingSpinnerButton />
                ) : (
                  <button
                    className="btn btn-primary btn-sm unhold"
                    onClick={() => {
                      unholdQty(item);
                    }}
                  >
                    Un Hold Qty
                  </button>
                )}
              </div>

              <div className="col-md-4">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    checkStatus(item);
                  }}
                >
                  Order Status
                </button>
              </div>
            </div>
          </li>
        </div>
      );
    });
    return html;
  };

  const checkStatus = (item) => {
    const data = {
      orderId: theOrderId,
      material_id: item.material_id,
      subOrderId: theSubOrderId,
    };

    axios.post(baseUrl + "/frontapi/checkLockOrderStatus", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.dismiss();
        if (resp.data[0].status === "0") {
          toast.error("Your order is Pending");
          return false;
        }
        if (resp.data[0].status === "1") {
          toast.error("Your order is in Inprocess");
          return false;
        }
        if (resp.data[0].status === "2") {
          toast.success("Your order is Completed");
          return false;
        }
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const submitLock = (e) => {
    e.preventDefault();

    if (!lockQty || lockQty == 0) {
      toast.dismiss();
      toast.error("Quantity is required");
      return false;
    }
    if (lockQty < 0) {
      toast.dismiss();
      toast.error("Quantity should not be zero or negative");
      return false;
    }

    setMsgGreen("");
    setMsgRed("");
    var availableQuantity = Number(availableQty);
    var actualLockedQty = availableQuantity - Number(lockQty);
    var total = materialPrice * Math.abs(actualLockedQty);
    var taxValue = (total * tax) / 100;
    var theValue = taxValue + total;
    setMaterialQuantity(Math.abs(actualLockedQty));
    setTotal(theValue);
    setTotalWithoutGst(total);

    if (availableQuantity >= Number(lockQty)) {
      setLoading(true);
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      };

      const data = {
        orderId: theOrderId,
        subOrderId: theSubOrderId,
        lockQty: lockQty,
        userId: userId,
        avaliableQty: productAvailableQty,
        productId: productId,
        materialId: materialId,
        material_available_quantity: availableQty,
        productMaterials_id: productMaterials_id,
      };

      axios.post(baseUrl + "/frontapi/lockqty", data, config).then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          toast.success(resp.message);
          materialDataApi(productId);
          setShowLockModal(false);
          closeShowLockModel();
          setLoading(false);
          return false;
        }
      });

      return false;
    } else {
      setlockBtn(false);
      setBuyBtn(true);
    }
  };

  let addFormFieldsMaturials = () => {
    setInventoryValues([...inventoryValues, {}]);
  };

  const materialHtml = () => {
    const materialHtml = [];
    rawMaterialData.map(function (value, i) {
      return materialHtml.push(
        <option value={value.name}>{value.name}</option>
      );
    });
    return materialHtml;
  };

  let removeFormFieldsMaturial = (i) => {
    let newFormValues = [...inventoryValues];
    newFormValues.splice(i, 1);
    setInventoryValues(newFormValues);
  };
  let handleChangeMaterial = (i, e) => {
    let newFormValues = [...inventoryValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;

    if (valv.material_type !== undefined) {
      const data = {
        byName: "yes",
        materialName: valv.material_type,
      };
      axios.post(baseUrl + "/frontapi/material-data-raw", data).then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          valv.material_available_quantity = resp.data[0].avaliable_qty;
          valv.material_id = resp.data[0].id;
        }
      });
    }
    setInventoryValues(newFormValues);
  };

  const handleChange = (e, i) => {
    if (e.target.name === "materialButton") {
      setMaterialAdd(!materialAdd);
    }
  };

  const handleVendorChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedOption(selectedOption);
      setVendorId(null);
      setVendorNameError("Please select vendor");
      return;
    }
    setSelectedOption(selectedOption);
    setVendorId(selectedOption.value);
    setVendorNameError("");
  };

  const handleSubmitMaterial = (e) => {
    e.preventDefault();
    if (!vendorId || vendorId === "Select Vendor") {
      setVendorNameError("Please select vendor");
      return false;
    }

    if (!materialPrice) {
      setVendorPriceError("Please enter price");
      return false;
    }

    if (!materialPrice || materialPrice == 0 || materialPrice < 0) {
      setVendorPriceError("Price cannot be zero or negative");
      return false;
    }

    if (!total || total == 0 || total < 0) {
      toast.dismiss();
      toast.error("Total cannot be zero or negative value");
      return false;
    }

    setLoading(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    var lockedQty = lockQty - materialQuantity;

    let data = {
      vendorId: vendorId,
      materialId: materialId,
      productId: productId,
      orderId: theOrderId,
      subOrderId: theSubOrderId,
      materialPrice: materialPrice,
      materialQuantity: materialQuantity,
      materialTotal: total,
      tax: tax,
      hsnCode: hsnCode,
      material_available_quantity: availableQty,
      lockQty: lockedQty,
      userId: userId,
      units: units,
      specification: specification,
    };

    axios
      .post(baseUrl + "/frontapi/addVendorMaterial", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          closeShowLockModel();
          setShowVendor(false);
          materialDataApi(productId);
          toast.success(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLockStatus = (item) => {
    const data = {
      orderId: theOrderId,
      material_id: item.material_id,
      subOrderId: theSubOrderId,
    };

    axios.post(baseUrl + "/frontapi/getLockStatus", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        // toast.success(resp.message);
        window.location.href =
          "/hold-qty-detail/" + btoa(item.id) + "/" + btoa(theOrderId);
        setShowLockModal(false);
        return false;
      } else {
        setShowLockModal(true);
      }
    });
  };

  const openShowLockModel = (item) => {
    setMaterialId(item.material_id);
    setMaterialName(item.material_type);
    getMaterialDetails(item.material_id);
    getLockStatus(item);
    setAvailableQty(item.material_available_quantity);
    setProductMaterials_id(item.id);
    setSuggestedTotal(item.material_quantity * remainingQty);
  };

  const closeShowLockModel = () => {
    setShowLockModal(false);
    setMaterialName("");
    setLockQty("");
    setAvailableQty("");
    setBuyBtn(false);
    setlockBtn(true);
    setMsgGreen("");
    setMsgRed("");
    setVendorId("");
    setVendorNameError("");
    setVendorPriceError("");
  };

  const closeShowVendorModel = () => {
    setShowVendor(false);
    setLockQty("");
    setVendorId("");
    setAvailableQty("");
    setBuyBtn(false);
    setlockBtn(true);
    setMsgGreen("");
    setMsgRed("");
    setProductPrice("");
    setVendorNameError("");
    setVendorPriceError("");
  };

  const handleChangeInput = (e) => {
    let { name, value } = e.target;
    let total;
    var regg = /^-\d*\.?\d+$/;
    if (name === "materialPrice") {
      if (!value) {
        setMaterialPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Please enter price");
        return false;
      }
      if (!value || value == 0 || value < 0) {
        setMaterialPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Price cannot be zero or negative");
        return false;
      }

      if (value.match(regg)) {
        setVendorPriceError("Please enter valid price");
        setMaterialPrice(value);
        return false;
      }
      setVendorPriceError("");
      setMaterialPrice(value);
      total = value * materialQuantity;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }
    if (name === "materialQuantity") {
      setMaterialQuantity(value);
      total = materialPrice * value;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }

    if (name === "specification") {
      setSpecification(value);
    }
  };

  const finalSubmt = () => {
    let data = {
      orderId: theOrderId,
      vendorId: vendorId,
      productId: productId,
      type: "manufacture",
      subOrderId: theSubOrderId,
    };
    axios
      .post(baseUrl + "/frontapi/add-manufacture-request", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setShowVendor(true);
          getMaterialDetails(productId);

          let total = productPrice * quantityForManufacture;
          const taxValue = (total * tax) / 100;
          const theValue = taxValue + total;

          setTotal(theValue);

          axios
            .post(baseUrl + "/frontapi/getMaterialDataByLock", data)
            .then((respo) => {
              var respon = respo.data;
              if (respon.status === true) {
                setSubmitMaterialData(respon.data);
              }
            });
          return;
        } else {
          setShowVendor(false);
          toast.error(resp.message);
        }
      });
  };

  const handeSubmitMaterial = (e) => {
    e.preventDefault();
    let data = {
      productId: productId,
      materialValues: JSON.stringify(inventoryValues),
    };

    axios.post(baseUrl + "/frontapi/add-material", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.success(resp.message);
        materialDataApi(productId);
        getMaterialData(productId);
        setMaterialAdd(false);
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const rawMaterialHtml = () => {
    let html = [];
    if (submitMaterialData.length > 0) {
      submitMaterialData.map((value, i) => {
        return html.push(
          <div className="col-6">
            <h4 style={{ color: "#757575" }}> {value.material_type} </h4>
            <div className="form-group place-vendor-inner">
              <label> Locked Qty: {value.locked_qty}</label>
            </div>
          </div>
        );
      });
    }
    return html;
  };

  const handleChangeSubmitVendor = (e) => {
    let { name, value } = e.target;
    let total;
    var regg = /^-\d*\.?\d+$/;
    if (name === "price") {
      if (!value) {
        setProductPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Please enter price");
        return false;
      }
      if (!value || value == 0 || value < 0) {
        setProductPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Price cannot be zero or negative");
        return false;
      }

      if (value.match(regg)) {
        setVendorPriceError("Please enter valid price");
        setProductPrice(value);
        return false;
      }
      setVendorPriceError("");
      setProductPrice(value);

      total = value * quantityForManufacture;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }
    if (name === "quantity") {
      setMaterialQuantity(value);
      total = productPrice * value;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }
  };

  const handleSubmitVendor = (e) => {
    e.preventDefault();

    if (!vendorId || vendorId === "Select Vendor") {
      setVendorNameError("Please select vendor");
      return false;
    }

    if (!productPrice) {
      setVendorPriceError("Please enter price");
      return false;
    }

    if (productPrice == 0 || productPrice < 0) {
      setVendorPriceError("Price cannot be zero or negative");
      return false;
    }

    if (!total || total == 0 || total < 0) {
      toast.dismiss();
      toast.error("Total cannot be zero or negative value");
      return false;
    }
    setLoading(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      price: productPrice,
      quantity: quantityForManufacture,
      totalPrice: total,
      productId: productId,
      vendorId: vendorId,
      tax: tax,
      hsnCode: hsnCode,
      normalOrder: "yes",
      orderId: theOrderId,
      subOrderId: theSubOrderId,
      units: units,
      specification: specification,
    };

    axios
      .post(baseUrl + "/frontapi/addVendorMaterial", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          window.location.href = "/inprocess-order-details/" + btoa(theOrderId);
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="manager-heading-outer section-heading">
              <h2>
                <b>Manufacturer</b>
              </h2>
            </div>
            <div className="order-detail-outer-top">
              <div className="row">
                <div className="col-md-3 col-12">
                  <div className="detail-inner">
                    <p>
                      Order Id : <b>{theOrderId}</b>
                    </p>
                  </div>
                </div>
                {subOrderIdParams !== "undefined" && (
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Sub Order Id : <b>{subOrderIdParams}</b>
                      </p>
                    </div>
                  </div>
                )}
                <div className="col-md-3 col-12">
                  <div className="detail-inner">
                    <p>
                      Date of Order :{" "}
                      <b>{moment(dateOfOrder).format("DD/MM/YYYY")}</b>
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="detail-inner">
                    <p>
                      Customer Name :{" "}
                      <b>
                        {customerName == null ? salsePersonName : customerName}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="manufacture-main-outer">
              <div className="add-product-outer manufacture-right">
                <div className="product-full-detail customer-detail-outer">
                  <div className="order-detail-outer-top">
                    <div
                      className="detail-inner"
                      style={{ backgroundColor: "#7F8487" }}
                    >
                      <h2>
                        <b>Raw Materials</b>
                      </h2>
                      <ul className="nav">{materialListHtml()}</ul>

                      <button
                        className="btn btn-primary"
                        onClick={handleChange}
                        name="materialButton"
                      >
                        Add Raw Material
                      </button>

                      {materialAdd === true && (
                        <>
                          {inventoryValues.map((element, index) => (
                            <div className="material-required add-product-outer customer-detail-outer">
                              <div
                                className="row align-items-center"
                                key={index}
                              >
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>RAW MATERIAL REQUIRED</label>
                                    <select
                                      className="form-control"
                                      name="material_type"
                                      placeholder="Raw Material"
                                      onChange={(e) =>
                                        handleChangeMaterial(index, e)
                                      }
                                      value={element.material_type || ""}
                                    >
                                      <option value="">Please select</option>
                                      {materialHtml()}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div className="add-row-group d-flex align-items-center justify-content-between">
                                    <div className="form-group">
                                      <label>
                                        RAW MATERIAL QUANTITY REQUIRED
                                      </label>
                                      <input
                                        className="form-control"
                                        name="material_quantity"
                                        placeholder="Quantity"
                                        onChange={(e) =>
                                          handleChangeMaterial(index, e)
                                        }
                                        value={element.material_quantity || ""}
                                      />
                                    </div>
                                    <div className="add-row">
                                      <a
                                        className="add-btn add"
                                        href="#!"
                                        onClick={() => addFormFieldsMaturials()}
                                      >
                                        +
                                      </a>
                                      {index ? (
                                        <a
                                          className="add-btn add"
                                          href="#!"
                                          onClick={() =>
                                            removeFormFieldsMaturial(index)
                                          }
                                        >
                                          -
                                        </a>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="col-12">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary"
                                onClick={handeSubmitMaterial}
                              >
                                Add Raw Materials
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={handleChange}
                                name="materialButton"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="submit-btn">
                    <button
                      className="btn btn-primary final Submit"
                      onClick={finalSubmt}
                    >
                      Proceed To Manufacture
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              className="modal-update place-order-vendor-popup"
              show={showLockModal}
              onHide={closeShowLockModel}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0" style={{ color: "#757575" }}>
                  {materialName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="assign-vendor">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group place-vendor-inner">
                      <label>
                        Suggested Required Quantity:{" "}
                        {parseFloat(suggestedTotal).toFixed(2)}{" "}
                      </label>
                    </div>
                  </div>
                  {buyBtn === false ? (
                    <div className="col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Total Required Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Quantity"
                          name="lockqty"
                          value={lockQty}
                          onChange={handleChangeLock}
                          onKeyDown={handleValidation}
                        />
                      </div>
                      <span style={{ color: "red" }}> {msgRed}</span>
                      <span style={{ color: "green" }}> {msgGreen}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {lockBtn && (
                    <>
                      <div className="col-12">
                        <div className="placeorder-btn jj">
                          {loading ? (
                            <LoadingSpinnerButton />
                          ) : (
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={submitLock}
                            >
                              {nameChange} Quantity
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {buyBtn && (
                    <>
                      <div className="col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Vendor:</label>
                          <Select
                            value={selectedOption}
                            onChange={handleVendorChange}
                            styles={selectStyle}
                            name="vendorId"
                            isClearable={true}
                            noOptionsMessage={() => "No vendor found"}
                            options={vendorListData.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                          />
                          <p style={{ color: "red" }}>{vendorNameError}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Price:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Price"
                            name="materialPrice"
                            value={materialPrice}
                            onKeyDown={handleValidation}
                            onChange={handleChangeInput}
                          />
                          <p style={{ color: "red" }}>{vendorPriceError}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Quantity:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            name="materialQuantity"
                            disabled
                            value={materialQuantity}
                            onKeyDown={handleValidation}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Units</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Units"
                            name="units"
                            value={units}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Tax:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Tax"
                            disabled
                            name="tax"
                            value={tax}
                            onKeyDown={handleValidation}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>HSN Code:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="HSN Code"
                            disabled
                            name="hsnCode"
                            value={hsnCode}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group place-vendor-inner">
                          <label>Specification:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Specification"
                            name="specification"
                            value={specification}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Total Price(Without GST):
                            <b> {parseFloat(totalWithoutGst).toFixed(2)} INR</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Total Price(With GST):{" "}
                            <b> {parseFloat(total).toFixed(2)} INR</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="placeorder-btn">
                          {loading ? (
                            <LoadingSpinnerButton />
                          ) : (
                            <button
                              className="btn btn-primary laga do 1"
                              onClick={handleSubmitMaterial}
                            >
                              Placeorders
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Modal>

            {/* After SUBMIT Place order to Vendor for Finish product  */}

            <Modal
              className="modal-update place-order-vendor-popup"
              show={showVendor}
              onHide={closeShowVendorModel}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0" style={{ color: "#757575" }}>
                  {productName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="assign-vendor">
                <div className="row align-items-center">
                  {rawMaterialHtml()}

                  <div className="col-6">
                    <div className="form-group place-vendor-inner">
                      <label>Vendor:</label>
                      <Select
                        value={selectedOption}
                        onChange={handleVendorChange}
                        styles={selectStyle}
                        name="vendorId"
                        isClearable={true}
                        noOptionsMessage={() => "No vendor found"}
                        options={vendorListData.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                      />

                      <p style={{ color: "red" }}>{vendorNameError}</p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>Price:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        name="price"
                        value={productPrice}
                        onKeyDown={handleValidation}
                        onChange={handleChangeSubmitVendor}
                      />
                      <p style={{ color: "red" }}>{vendorPriceError}</p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>Quantity:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Quantity"
                        name="quantity"
                        value={quantityForManufacture}
                        disabled
                        onKeyDown={handleValidation}
                        onChange={handleChangeSubmitVendor}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>Quantity Units</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Units"
                        name="units"
                        value={units}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>Tax:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Tax"
                        disabled
                        name="tax"
                        value={tax}
                        onKeyDown={handleValidation}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>HSN Code:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="HSN Code"
                        disabled
                        name="hsnCode"
                        value={hsnCode}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group place-vendor-inner">
                      <label>Specification:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Specification"
                        name="specification"
                        value={specification}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="product-total-price">
                      <p className="m-0">
                        Total Price(Without GST):
                        <b> {parseFloat(totalWithoutGst).toFixed(2)} INR</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="product-total-price">
                      <p className="m-0">
                        Total Price(With GST):{" "}
                        <b> {parseFloat(total).toFixed(2)} INR</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="placeorder-btn">
                      {loading ? (
                        <LoadingSpinnerButton />
                      ) : (
                        <button
                          className="btn btn-primary laga do"
                          onClick={handleSubmitVendor}
                        >
                          Place order
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Manufacture;
