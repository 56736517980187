import React, { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { formatDateTime, formatDate, momentObj } from "../utils/dateTime";
import { addDays } from "date-fns";
import { DatepickerCustomRanges } from "../lib/DatePickerCustomRanges";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaWhatsapp, FaEye } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { MdEmail, MdDelete, MdEdit, MdInfo, MdAlarm } from "react-icons/md";
import { IconContext } from "react-icons";
import { Country, State } from "country-state-city";
import $ from "jquery";
import { baseUrl } from "./BaseUrl";
import Footer from "./Footer";
const config = reactTableConfig("Leads");

function Leads() {
  // date selector
  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [state, setDateSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const [record, setRecord] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [statusValue, setStatusValue] = useState("");

  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const [midConvertModal, setMidConvertModal] = useState(false);
  const [dataForConvert, setDataForConvert] = useState([]);

  const [totalLead, setTotalLead] = useState(0);
  const [meetData, setMeetData] = useState([]);

  const [showReason, setShowReason] = useState(false);

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobNoError, setMobNoError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenWhatsApp, setIsModalOpenWhatsApp] = useState(false);

  // zone
  const [zoneData, setZoneData] = useState([]);
  const [zone, setZone] = useState("");
  const [zoneError, setZoneError] = useState("");

  // meeting
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [mode, setMode] = useState("");
  const [isSales, setIsSales] = useState("");
  const [seniorName, setSeniorName] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");

  const [modeErr, setModeErr] = useState("");
  const [isSalesErr, setIsSalesErr] = useState("");
  const [seniorNameErr, setSeniorNameErr] = useState("");
  const [meetingDateErr, setMeetingDateErr] = useState("");
  const [linkErr, setLinkErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");

  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisableEdit, setBtnDisableEdit] = useState(false);

  // meeting edit

  const [meetingObj, setMeetingObj] = useState([
    {
      leadId: "",
      mode: "",
      isSales: "",
      seniorName: "",
      date: "",
      link: "",
      description: "",
    },
  ]);

  const [meetingObjErr, setMeetingObjErr] = useState([
    {
      leadId: "",
      mode: "",
      isSales: "",
      seniorName: "",
      date: "",
      link: "",
      description: "",
    },
  ]);

  const [editModal, setEditModal] = useState(false);

  // reminder modal
  const [name, setName] = useState("");
  const [preReminderDate, setPreReminderDate] = useState("");
  const [reminderDate, setReminderDate] = useState("");
  const [reminderModal, setReminderModal] = useState(false);

  const [nameErr, setNameErr] = useState("");
  const [preReminderDateErr, setPreReminderDateErr] = useState("");
  const [reminderDateErr, setReminderDateErr] = useState("");
  const [remBtnDisable, setRemBtnDisable] = useState(false);

  // customer add
  const [btnDisableCustomer, setBtnDisableCustomer] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);

  const [userType, setUserType] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [compneyName, setCompneyName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [website, setWebsite] = useState("");
  const [gst, setGst] = useState("");
  const [pancard, setPancard] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincode, setPincode] = useState("");
  const [designationData, setDesignationData] = useState([]);
  const [formValuesUser, setFormValuesUser] = useState([
    {
      address: "",
      country: "",
      phone_no: "",
      city: "",
      zipcode: "",
      state: "",
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      selected: "",
    },
  ]);

  // Errors //

  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  const [selectedOptionsError, setSelectedOptionsError] = useState("");
  const [custNameError, setCustNameError] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [custEmailError, setCustEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [phone_noError, setPhone_noError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  const [formValuesUserError, setFormValuesUserError] = useState([
    {
      address: "",
      country: "",
      phone_no: "",
      city: "",
      zipcode: "",
      state: "",
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      selected: "",
    },
  ]);

  useEffect(() => {
    leadsData();
    getDesignationData();
    getZoneData();
  }, []);

  useEffect(() => {
    submitSearch();
  }, [statusValue, startDate, endDate]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPicker]);

  const handleOutsideClick = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      // Click occurred outside the date picker
      if (buttonRef.current && buttonRef.current.contains(e.target)) {
        // Click occurred on the button, do not close the date picker
        return;
      }
      setShowPicker(false);
    }
  };

  const getDesignationData = () => {
    axios.post(baseUrl + "/frontapi/designation-data-deleted").then((res) => {
      const resp = res.data;
      setDesignationData(resp.data);
    });
  };

  const handleDateChanges = (date) => {
    setStartDate(date[0].startDate);
    setEndDate(date[0].endDate);
    setDateSelection(date);
  };

  const columns = [
    {
      key: "#",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Lead ID",
      className: "id",
      align: "left",
      sortable: true,
    },

    {
      key: "name",
      text: "Customer Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },

    {
      key: "ventureName",
      text: "Venture Name",
      className: "ventureName",
      align: "left",
      sortable: true,
    },

    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "phone",
      text: "Phone No.",
      className: "phone",
      align: "left",
      sortable: true,
    },

    {
      key: "categoryId",
      text: "Category Name",
      className: "categoryName",
      align: "left",
      sortable: true,
      cell: (record) => {
        const data = JSON.parse(record.categoryId);
        const labels = data.map((item) => item.label);
        let result = labels.join(",");

        return <Fragment>{result}</Fragment>;
      },
    },

    {
      key: "assignedToName",
      text: "Assigned To",
      className: "assignedToName",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.assignedTo == record.salesManagerId
              ? "SELF"
              : record.assignedToName}
          </Fragment>
        );
      },
    },

    {
      key: "date",
      text: "Date ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {moment(record.created_at).format("lll")} </Fragment>;
      },
    },

    {
      key: "meet",
      text: "Meeting ",
      className: "meet",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            <select
              className="form-control"
              name="meetingId"
              value={record.status}
              onChange={(e) => handleChangeMeeting(e, record)}
            >
              <option value="">Please Select</option>
              <option value="add">Add</option>
              <option value="view">View</option>
              <option value="edit">Edit</option>
            </select>
          </Fragment>
        );
      },
    },

    {
      key: "status",
      text: "Status ",
      className: "status",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <select
              className="form-control"
              style={{ marginLeft: "4px" }}
              name="productStatus"
              value={record.status}
              onChange={(e) => handleSubmitStatus(e, record)}
            >
              <option value="0">Generated</option>
              <option value="1">Qualified</option>
              <option value="2">Negotiate</option>
              <option value="3">Converted</option>
              <option value="4">Not Converted</option>
            </select>
          </Fragment>
        );
      },
    },

    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <IconContext.Provider
              value={{
                color: "white",
                size: "2.5em",
                style: { padding: "3px" },
              }}
            >
              <FaEye
                onClick={() =>
                  (window.location.href = "/leads-view/" + btoa(record.id))
                }
                className="btn"
                style={{ background: "black" }}
              />

              <MdEdit
                onClick={() =>
                  (window.location.href = "/leads-edit/" + btoa(record.id))
                }
                className="btn"
                style={{ background: "#00bf66" }}
              />

              <MdAlarm
                className="btn"
                style={{ background: "#008DDA" }}
                title="Add Reminder"
                onClick={() => {
                  switchRemModal("open", record.id);
                }}
              />

              {/* <FaFilePdf
                className="btn"
                style={{ background: "#D20062" }}
                title="Convert to PDF"
                onClick={() => convertToPdf(record.id)}
              />

              <MdEmail
                className="btn"
                style={{ background: "#008DDA" }}
                title="Share Over Email"
                onClick={() => {
                  setIsModalOpen(true);
                  setEmail(record.email);
                  setId(record.id);
                  setEmailError("");
                }}
              />

              <FaWhatsapp
                className="btn"
                style={{ background: "#65B741", marginLeft: "4px" }}
                title="Share On WhatsApp"
                onClick={() => {
                  setIsModalOpenWhatsApp(true);
                  setMobNo(record.phoneNumber);
                  setId(record.id);
                  setMobNoError("");
                }}
              /> */}
              {record.isDeleted === "1" ? (
                <>
                  <MdInfo
                    className="btn"
                    style={{ background: "#1679AB" }}
                    onClick={() => switchDeleteModal(record, "show")}
                  />

                  <button
                    className="btn"
                    style={{ background: "#54B435" }}
                    onClick={() => activePopUp(record)}
                  >
                    Enable
                  </button>
                </>
              ) : (
                <button
                  className="btn"
                  style={{ background: "#f95c6b" }}
                  onClick={() => switchDeleteModal(record, "open")}
                >
                  Disable
                </button>
              )}
            </IconContext.Provider>

            {/* {record.isEstimation === "1" && (
              <>
                <button
                  style={{ marginRight: "5px" }}
                  className="btn btn-secondary btn-sm"
                  title="Download PDF"
                  onClick={() => convertToPdf(record.id)}
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>

                <button
                  style={{ marginRight: "5px" }}
                  className="btn btn-light btn-sm img-fluid"
                  title="Share Over Email"
                  onClick={() => {
                    setIsModalOpen(true);
                    setEmail(record.email);
                    setId(record.id);
                    setEmailError("");
                  }}
                >
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </button>

                <button
                  style={{ marginRight: "5px" }}
                  className="btn btn-success btn-sm img-fluid"
                  title="Share On WhatsApp"
                  onClick={() => {
                    setIsModalOpenWhatsApp(true);
                    setMobNo(record.phoneNumber);
                    setId(record.id);
                    setMobNoError("");
                  }}
                >
                  <i class="fa fa-whatsapp" aria-hidden="true"></i>
                </button>
              </>
            )} */}
          </Fragment>
        );
      },
    },
  ];

  const submitSearch = (e) => {
    // e.preventDefault();
    toast.dismiss();

    if (!startDate && endDate) {
      toast.error("Start date is required");
      return;
    }

    if (startDate > endDate) {
      toast.error("Please enter a valid start date");
      return;
    }

    let tempData = record;

    if (statusValue !== "") {
      tempData = tempData.filter((value) => {
        return value.status === statusValue;
      });
    }

    if (startDate) {
      tempData = tempData.filter((value) => {
        const date = momentObj(value.created_at);
        return date.isSameOrAfter(startDate, "day");
      });
    }

    if (endDate) {
      tempData = tempData.filter((value) => {
        const date = momentObj(value.created_at);
        return date.isSameOrBefore(endDate, "day");
      });
    }

    setFilterData(tempData);
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const changeStatus = (status, record) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      status: status,
      id: record.id,
    };

    axios
      .post(baseUrl + "/frontapi/change-lead-status", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          leadsData();
          return false;
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (statuss, data) => {
    let status = statuss;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(status, data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const convertToPdf = async (id) => {
    const data = {
      estimationId: id,
    };

    await axios
      .post(baseUrl + "/frontapi/generateLeadEstimation", data)
      .then((resp) => {
        const res = resp.data;
        if (res.status === true) {
          toast.dismiss();
          toast.success(res.message);

          setTimeout(() => {
            window.open(`${baseUrl}/static/leadPdf/${res.data}`, "_blank");
          }, 2000);
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      });
  };

  // WhatsApp Contents  //

  const sendWhatsApp = async (id) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    if (!mobNo) {
      setMobNoError("WhatsApp number is required");
      return false;
    }

    if (mobNo.length > 10) {
      setMobNoError("Please enter valid whatsapp number");
      return false;
    }

    const data = {
      id: id,
      mobile: mobNo,
    };

    await axios
      .post(baseUrl + "/frontapi/sendOverWhatsAppLead", data, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status) {
          toast.success(resp.message);
        } else {
          toast.error(resp.message);
        }
      });
  };

  // WhatsApp Contents Ended ///

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeModalWhatsApp = () => {
    setIsModalOpenWhatsApp(false);
  };

  const sendEmail = async (id) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }

    if (!email.match(emailReg)) {
      setEmailError("Please enter valid email");
      return false;
    }

    const data = {
      id: id,
      email: email,
    };
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "number") {
      setMobNo(value);

      if (!value) {
        setMobNoError("WhatsApp number is required");
        return false;
      }

      if (value.length > 10) {
        setMobNoError("Please enter valid whatsapp number");
        return false;
      }

      setMobNoError("");
    }

    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailError("Email is required");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmailError("Please enter valid email");
        return false;
      }

      setEmailError("");
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "." ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const leadsData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    const obj = {
      userType: localStorage.getItem("userType"),
    };

    axios
      .post(baseUrl + "/frontapi/leads-manager-data", obj, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setTotalLead(resp.data.length);
          setRecord(resp.data);
          setFilterData(resp.data);
        }
      });
  };

  const handleChangeStatus = (e) => {
    let value = e.target.value;
    setStatusValue(value);

    // if (value === "") {
    //   setFilterData(record);
    //   return false;
    // } else {
    //   const filteredData = record.filter((item) => {
    //     return item.status == value;
    //   });
    //   setFilterData(filteredData);
    // }
  };

  const switchDeleteModal = (record, value) => {
    setReasonError("");
    setReason("");
    if (value === "show") {
      setReason(record.reason);
      setShowReason(true);
      setIsModalOpenDelete(true);
      return;
    }

    if (value === "close") {
      setIsModalOpenDelete(false);
      setId("");
      setShowReason(false);
    }

    if (value === "open") {
      setId(record.id);
      setIsModalOpenDelete(true);
      setShowReason(false);
    }
  };

  const handleChangeReason = (e) => {
    let { value } = e.target;

    setReason(value);
    if (!value) {
      setReasonError("Reason is required");
      return false;
    }
    setReasonError("");
  };

  const disableLead = () => {
    let params = {
      id: id,
      reason: reason,
    };

    let error = checkSpace("reason", reason);

    if (!reason) {
      setReasonError("Reason is required");
      return false;
    }

    if (error) {
      setReasonError(error);
      return false;
    }

    axios.post(baseUrl + "/frontapi/leads-disable", params).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status === true) {
        toast.success(resp.message);
        switchDeleteModal([], "close");
        leadsData();
      } else {
        toast.error(resp.message);
      }
    });
  };

  const activePopUp = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => activeLead(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const activeLead = (record) => {
    let params = {
      id: record.id,
    };

    axios.post(baseUrl + "/frontapi/leads-active", params).then((res) => {
      var resp = res.data;
      toast.dismiss();
      if (resp.status === true) {
        toast.success(resp.message);
        leadsData();
      } else {
        toast.error(resp.message);
      }
    });
  };

  // Meeting

  const getMeetData = async (id) => {
    await axios
      .get(baseUrl + `/frontapi/meeting-lead-single/${id}`, {})
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        setMeetData(resp.data);
      });
  };

  const getMeetEditData = async (id) => {
    await axios
      .get(baseUrl + `/frontapi/meeting-lead-single/${id}`, {})
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        const meetDataObj = resp.data;

        if (resp.data.length > 0) {
          // switchEditModal("open","");
          setMeetingObj(meetDataObj);

          let html = [];
          meetDataObj.forEach((element) => {
            let tempArr = [
              {
                leadId: "",
                mode: "",
                isSales: "",
                seniorName: "",
                date: "",
                link: "",
                description: "",
              },
            ];
            html.push(tempArr);
          });
          setMeetingObjErr(html);
        } else {
          setMeetingObj(meetDataObj);
        }
      });
  };

  const MeetHtml = () => {
    const html = [];

    if (meetData.length > 0) {
      meetData.map((value, i) => {
        return html.push(
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Meeting ID: <b>{value.id}</b>
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Mode of Meeting: <b>{value.mode}</b>
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12 more-padding">
                <div className="person-detail">
                  <p>
                    Senior Involvement: <b>{value.isSales}</b>
                  </p>
                </div>
              </div>
              {value.isSales === "yes" && (
                <div className="col-md-6 col-sm-6 col-12 more-padding">
                  <div className="person-detail">
                    <p>
                      Senior Name: <b>{value.seniorName}</b>
                    </p>
                  </div>
                </div>
              )}
              <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Date & Time:
                    <b> {moment(value.date).format("lll")}</b>
                  </p>
                </div>
              </div>
              {value.mode === "online" && (
                <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
                  <div className="person-detail">
                    <p>
                      Link: <b>{value.link}</b>
                    </p>
                  </div>
                </div>
              )}
              <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
                <div className="person-detail">
                  <p>
                    Description: <b>{value.description}</b>
                  </p>
                </div>
              </div>
            </div>
            <br />
          </>
        );
      });
    } else {
      html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                <b>No Data</b>
              </p>
            </div>
          </div>
        </div>
      );
    }

    return html;
  };

  const handleChangeMeeting = (event, record) => {
    let value = event.target.value;

    setId(record.id);

    setAddModal(false);
    setViewModal(false);

    if (value === "add") {
      switchAddModal("open");
      setAddModal(true);
    }

    if (value === "view") {
      switchViewModal("open", record.id);
      setViewModal(true);
    }

    if (value === "edit") {
      switchEditModal("open", record.id);
      setEditModal(true);
    }
  };

  const switchEditModal = (value, id) => {
    const obj = [
      {
        leadId: "",
        mode: "",
        isSales: "",
        date: "",
        link: "",
        description: "",
      },
    ];

    setMeetingObj(obj);
    setMeetingObjErr(obj);

    if (value === "open") {
      getMeetEditData(id);
      setEditModal(true);
    }

    if (value === "close") {
      setEditModal(false);
    }
  };

  const switchViewModal = (value, id) => {
    if (value === "open") {
      getMeetData(id);
      setViewModal(true);
    }

    if (value === "close") {
      setViewModal(false);
    }
  };

  const switchAddModal = (value) => {
    setMode("");
    setIsSales("");
    setMeetingDate("");
    setLink("");
    setDescription("");

    setModeErr("");
    setIsSalesErr("");
    setMeetingDateErr("");
    setLinkErr("");
    setDescriptionErr("");

    setBtnDisable(false);

    if (value === "open") {
      setAddModal(true);
    }

    if (value === "close") {
      setAddModal(false);
    }
  };

  const handleChangeAll = (event) => {
    let { name, value } = event.target;

    if (name === "mode") {
      setMode(value);
      setLink("");
      if (!value) {
        setModeErr("Please select mode");
        return false;
      }
      setModeErr("");
    }

    if (name === "isSales") {
      setIsSales(value);
      setSeniorName("");
      if (!value) {
        setIsSalesErr("Please select senior involvement");
        return false;
      }
      setIsSalesErr("");
    }

    if (name === "seniorName") {
      setSeniorName(value);
      if (!value) {
        setSeniorNameErr("Please enter senior namee");
        return false;
      }
      setSeniorNameErr("");
    }

    if (name === "meetingDate") {
      setMeetingDate(value);
      setMeetingDateErr("");
    }

    if (name === "link") {
      setLink(value);
      if (!value) {
        setLinkErr("Link is required");
        return false;
      }
      setLinkErr("");
    }

    if (name === "description") {
      setDescription(value);
      if (!value) {
        setDescriptionErr("Link is required");
        return false;
      }
      setDescriptionErr("");
    }
  };

  const submitAdd = async (e) => {
    // e.preventDefault();
    let error = "";

    if (!mode) {
      setModeErr("Please select mode");
      return false;
    }

    if (!isSales) {
      setIsSalesErr("Please select senior involvement");
      return false;
    }

    if (isSales === "yes") {
      if (!seniorName) {
        setSeniorNameErr("Senior name is required");
        return false;
      }

      error = checkSpace("senior name", seniorName);
      if (error) {
        setSeniorNameErr(error);
        return false;
      }
    }

    if (!meetingDate) {
      setMeetingDateErr("Meeting date & time is required");
      return false;
    }

    if (mode === "online") {
      if (!link) {
        setLinkErr("Link is required");
        return false;
      }

      error = checkSpace("link", link);
      if (error) {
        setLinkErr(error);
        return false;
      }
    }

    if (!description) {
      setDescriptionErr("Description is required");
      return false;
    }

    error = checkSpace("description", description);
    if (error) {
      setDescriptionErr(error);
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      id: id,
      mode: mode,
      isSales: isSales,
      seniorName: seniorName,
      meetingDate: meetingDate,
      link: link,
      description: description,
    };

    setBtnDisable(true);

    await axios
      .post(baseUrl + "/frontapi/meeting-add", data, config)
      .then((res) => {
        var resp = res.data;

        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);

          switchAddModal("close");

          setTimeout(() => {
            setBtnDisable(false);
          }, 3000);
        } else {
          toast.error(resp.message);
          setBtnDisable(false);
        }
      })

      .finally(() => setBtnDisable(false));
  };

  const handleChangeEditMeet = (i, e) => {
    e.preventDefault();
    let newFormValues = [...meetingObj];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...meetingObjErr];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setMeetingObj(newFormValues);

    if (e.target.name === "date") {
      // if (!newFormValues[i].date) {
      //   newFormValuesError[i][e.target.name] = tempErr;
      //   setMeetingObjErr(newFormValuesError);
      //   return false;
      // } else {
      newFormValuesError[i][e.target.name] = "";
      setMeetingObjErr(newFormValuesError);
      // }
    }

    if (e.target.name === "mode") {
      valv.link = "";
      if (!newFormValues[i].mode) {
        newFormValuesError[i][e.target.name] = tempErr;
        setMeetingObjErr(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setMeetingObjErr(newFormValuesError);
      }
    }

    if (e.target.name === "isSales") {
      valv.seniorName = "";
      if (!newFormValues[i].isSales) {
        newFormValuesError[i][e.target.name] = tempErr;
        setMeetingObjErr(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setMeetingObjErr(newFormValuesError);
      }
    }

    if (e.target.name === "seniorName") {
      if (!newFormValues[i].seniorName) {
        newFormValuesError[i][e.target.name] = tempErr;
        setMeetingObjErr(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setMeetingObjErr(newFormValuesError);
      }
    }

    if (e.target.name === "link") {
      if (!newFormValues[i].link) {
        newFormValuesError[i][e.target.name] = tempErr;
        setMeetingObjErr(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setMeetingObjErr(newFormValuesError);
      }
    }
    if (e.target.name === "description") {
      if (!newFormValues[i].description) {
        newFormValuesError[i][e.target.name] = tempErr;
        setMeetingObjErr(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setMeetingObjErr(newFormValuesError);
      }
    }
  };

  const submitEdit = async (e) => {
    // e.preventDefault();

    if (!meetingObj || meetingObj.length === 0) {
      toast.dismiss();
      toast.error("No data found to update");
      return false;
    }

    for (let i = 0; i < meetingObj.length; i++) {
      const element = meetingObj[i];
      let formError = [...meetingObjErr];

      let error = "";

      if (!element.mode || element.mode === "") {
        formError[i].mode = "Please select mode";
        setMeetingObjErr(formError);
        return false;
      }

      if (!element.isSales || element.isSales === "") {
        formError[i].isSales = "Please select senior involvement";
        setMeetingObjErr(formError);
        return false;
      }

      if (element.isSales === "yes") {
        if (!element.seniorName || element.seniorName === "") {
          formError[i].seniorName = "Please enter senior name";
          setMeetingObjErr(formError);
          return false;
        }

        error = checkSpace("senior name", element.seniorName);
        if (error) {
          formError[i].seniorName = error;
          setMeetingObjErr(formError);
          return false;
        }
      }

      if (!element.date || element.date === "") {
        formError[i].date = "Please enter meeting date";
        setMeetingObjErr(formError);
        return false;
      }

      if (element.mode === "online") {
        if (!element.link || element.link === "") {
          formError[i].link = "Please enter link";
          setMeetingObjErr(formError);
          return false;
        }

        error = checkSpace("link", element.link);
        if (error) {
          formError[i].link = error;
          setMeetingObjErr(formError);
          return false;
        }
      }

      if (!element.description || element.description === "") {
        formError[i].link = "Please enter description";
        setMeetingObjErr(formError);
        return false;
      }

      error = checkSpace("description", element.description);
      if (error) {
        formError[i].description = error;
        setMeetingObjErr(formError);
        return false;
      }
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      mainData: JSON.stringify(meetingObj),
    };

    setBtnDisableEdit(true);

    await axios
      .put(baseUrl + `/frontapi/meeting-edit/${id}`, data, config)
      .then((res) => {
        var resp = res.data;

        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);

          switchEditModal("close", "");

          setTimeout(() => {
            setBtnDisableEdit(false);
          }, 3000);
        } else {
          toast.error(resp.message);
          setBtnDisableEdit(false);
        }
      })

      .finally(() => setBtnDisableEdit(false));
  };

  // reminder

  const handleChangeRem = async (event) => {
    let { name, value } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }
      setNameErr("");
    }
    if (name === "preDate") {
      setPreReminderDate(value);
      setPreReminderDateErr("");
    }

    if (name === "date") {
      setReminderDate(value);
      setReminderDateErr("");
    }
  };

  const handleSubmitRem = async (event) => {
    event.preventDefault();

    if (!id) {
      toast.dismiss();
      toast.error("Lead id is required, please try again");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    let error = checkSpace("name", name);

    if (error) {
      setNameErr(error);
      return false;
    }

    if (!preReminderDate) {
      setPreReminderDateErr("Pre reminder date is required");
      return false;
    }

    if (!reminderDate) {
      setReminderDateErr("Reminder date is required");
      return false;
    }

    setRemBtnDisable(true);

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      leadId: id,
      name: name,
      reminderDate: reminderDate,
      preReminderDate: preReminderDate,
      userType: "SalesPerson",
    };

    axios
      .post(baseUrl + "/frontapi/reminder-add", data, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === false) {
          toast.error(resp.message);
          setRemBtnDisable(false);
          return;
        } else {
          toast.success(resp.message);
          switchRemModal("close", "");
          return false;
        }
      })
      .finally(() => setRemBtnDisable(false));
  };

  const switchRemModal = (value, id) => {
    setName("");
    setNameErr("");
    setPreReminderDate("");
    setPreReminderDateErr("");
    setReminderDate("");
    setReminderDateErr("");

    if (value === "open") {
      setId(id);
      setReminderModal(true);
    }

    if (value === "close") {
      setReminderModal(false);
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleSubmitStatus = (e, data) => {
    const status = e.target.value;
    setId(data.id);
    setDataForConvert(data);
    if (status == "3") {
      if (data.customerId) {
        Conn(status, data);
      } else {
        setMidConvertModal(true);
        // switchCustomer("true", data);
      }
    } else {
      Conn(status, data);
    }
  };

  // Customer

  const switchCustomer = (status, data) => {
    // clear all the states with error

    setBtnDisableCustomer(false);
    setUserType(false);
    setSelectedOptions("");
    setCustomerName("");
    setCustEmail("");
    setCompneyName("");
    setPassword("");
    setPhoneNo("");
    setWebsite("");
    setGst("");
    setPancard("");
    setBillingAddress("");
    setCountry("");
    setCity("");
    setPhone_no("");
    setStateValue("");
    setPincode("");
    setFormValuesUser([
      {
        address: "",
        country: "",
        phone_no: "",
        city: "",
        zipcode: "",
        state: "",
        aName: "",
        aEmail: "",
        aPhoneNo: "",
        designation: "",
        selected: "",
      },
    ]);

    setGstError("");
    setPanError("");
    setSelectedOptionsError("");
    setCustNameError("");
    setCompNameError("");
    setCustEmailError("");
    setPasswordError("");
    setPhoneError("");
    setWebsiteError("");
    setCountryError("");
    setAddressError("");
    setStateError("");
    setCityError("");
    setPhone_noError("");
    setPincodeError("");

    setFormValuesUserError([
      {
        address: "",
        country: "",
        phone_no: "",
        city: "",
        zipcode: "",
        state: "",
        aName: "",
        aEmail: "",
        aPhoneNo: "",
        designation: "",
        selected: "",
      },
    ]);

    if (status === "true") {
      // open modal()
      getLeadDataId(data);
    } else {
      // close modal
      setShowCustomer(false);
    }
  };

  const getLeadDataId = async (data) => {
    const dataObj = {
      id: data.id,
    };

    setCustEmail("");
    setPassword("");
    await axios
      .post(baseUrl + "/frontapi/leads-single", dataObj)
      .then((res) => {
        var resp = res.data;

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }

        if (resp.status === true) {
          const leadsData = resp.data.sqlLeadsRun[0];

          setCustomerName(leadsData.name);
          setCustEmail(leadsData.email);
          setPhoneNo(leadsData.phone);

          setCountry(leadsData.country);
          setStateValue(leadsData.state);
          setCity(leadsData.city);
          setPincode(leadsData.pincode);

          setShowCustomer(true);
        }
      });
  };

  const handleChangeOptions = (e) => {
    let { name } = e.target;

    // if (name === "buisness") {
    //   setSelectedOptions(name);
    // }
    // if (name === "manufacturer") {
    //   setSelectedOptions(name);
    // }
    // if (name === "individual") {
    //   setSelectedOptions(name);
    // }

    if (name === "individual") {
      setUserType(false);
      setSelectedOptions(name);
      setSelectedOptionsError("");
      return false;
    }

    setSelectedOptions(name);
    setSelectedOptionsError("");
    setUserType(true);
    setCompneyName("");
    setGst("");
    setPancard("");
    setCompNameError("");
    setGstError("");
    setPanError("");
  };

  const handleChangeTop = (event) => {
    let { name, value } = event.target;
    $("input[type=number]").on("mousewheel", function (e) {
      $(event.target).blur();
    });

    if (name === "customerName") {
      setCustomerName(value);
      if (!value) {
        setCustNameError("Customer name is required");
        return false;
      }
      setCustNameError("");
    }

    if (name === "companyName") {
      setCompneyName(value);
      if (!value) {
        setCompNameError("Company name is required");
        return false;
      }
      setCompNameError("");
    }

    if (name === "email") {
      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      setCustEmail(value);
      if (!value) {
        setCustEmailError("Email is required");
        return false;
      }
      if (!value.match(emailReg)) {
        setCustEmailError("Please enter valid email");
        return false;
      }

      setCustEmailError("");
    }

    if (name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      setPassword(value);
      if (!value) {
        setPasswordError("Password is required");
        return false;
      }

      if (!value.match(regex)) {
        setPasswordError(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }
      setPasswordError("");
    }

    if (name === "pNumber") {
      setPhoneNo(value);
      if (!value) {
        setPhoneError("Phone No. is required");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setPhoneError("Phone number should be of 10 digits");
        return false;
      }

      setPhoneError("");
    }

    if (name === "website") {
      setWebsite(value);
      if (!value) {
        setWebsiteError("Website is required");
        return false;
      }
      setWebsiteError("");
    }

    if (name === "zone") {
      setZone(value);

      if (!value) {
        setZoneError("Please select zone");
        return false;
      }
      setZoneError("");
    }

    if (name === "gst") {
      let newValue = value.toUpperCase();
      setGst(newValue);
      if (!newValue) {
        setGstError("GST number is required");
        return false;
      } else {
        setGstError("");
      }

      if (newValue === "URP") {
        setPancard("URP");
        setPanError("");
        setGstError("");
        return false;
      }

      if (
        !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(newValue)
      ) {
        setPancard("");
        setGstError("Enter valid GST number");
        return false;
      } else {
        var modifiedText = newValue.substring(2, newValue.length - 3);
        setPancard(modifiedText);
        setPanError("");
      }

      // if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(newValue)) {
      //   setGst(newValue);
      //   setGstError("Enter valid GST number");
      //   return false;
      // }
      setGstError("");
    }

    if (name === "panNum") {
      let newValue = value.toUpperCase();
      setPancard(newValue);
      if (!newValue) {
        setPanError("Pan number is required");
        return false;
      }

      if (newValue === "URP") {
        setPancard("URP");
        setPanError("");
        return false;
      }

      var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
      if (!reggg.test(newValue)) {
        setPanError("Please enter correct pan card");
        return false;
      }

      setPanError("");
    }
  };

  const handleChangeBillingAddress = (event) => {
    let { name, value } = event.target;

    if (name === "country") {
      if (!value || value === "Select Country") {
        setCountry(value);
        setCountryError("Biling country is required");
        return false;
      }
      setCountry(value);
      setCountryError("");
      return false;
    }
    if (name === "billingAddress") {
      if (!value) {
        setBillingAddress(value);
        setAddressError("Billing address is required");
        return false;
      }
      setBillingAddress(value);
      setAddressError("");
      return false;
    }
    if (name === "state") {
      if (!value || value === "Select State") {
        setStateValue(value);
        setStateError("Billing state is required");
        return false;
      }
      setStateValue(value);
      setStateError("");
      return false;
    }
    if (name === "city") {
      if (!value) {
        setCity(value);
        setCityError("Billing city is required");
        return false;
      }
      setCity(value);
      setCityError("");
      return false;
    }
    if (name === "zipcode") {
      let valueNew = value.trim();
      let regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;

      if (!valueNew) {
        setPincode(valueNew);
        setPincodeError("Billing pincode is required");
        return false;
      }
      if (!valueNew.match(regex)) {
        setPincode(valueNew);
        setPincodeError("Please enter valid input");
        return false;
      }
      setPincode(valueNew);
      setPincodeError("");
      return false;
    }

    if (name === "phone_no") {
      setPhone_no(value);
      if (!value) {
        setPhone_noError("Billing phone no. is required");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setPhone_noError("Billing phone number should be of 10 digits");
        return false;
      }

      setPhone_noError("");
      return false;
    }
  };

  const countryDropDownHtml = () => {
    const countries = Country.getAllCountries();

    // Find the index of India in the country list
    const indiaIndex = countries.findIndex(
      (country) => country.isoCode === "IN"
    );

    // Move India to the beginning of the country list
    if (indiaIndex !== -1) {
      const india = countries.splice(indiaIndex, 1)[0];
      countries.unshift(india);
    }

    // Generate the HTML for country dropdown options
    const options = countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));

    return options;
  };

  const stateDropDownHtml = () => {
    const html = [];
    const states = State.getStatesOfCountry(country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  const countryShippingDropDownHtml = () => {
    const countries = Country.getAllCountries();

    // Find the index of India in the country list
    const indiaIndex = countries.findIndex(
      (country) => country.isoCode === "IN"
    );

    // Move India to the beginning of the country list
    if (indiaIndex !== -1) {
      const india = countries.splice(indiaIndex, 1)[0];
      countries.unshift(india);
    }

    // Generate the HTML for country dropdown options
    const options = countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));

    return options;
  };

  const stateShippingDropDownHtml = (i) => {
    const html = [];
    const states = State.getStatesOfCountry(formValuesUser[i].country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  const copyAddress = (i, e) => {
    e.preventDefault();

    let newFormValues = [...formValuesUser];
    // newFormValues[i][e.target.name] = e.target.value;

    const valv = newFormValues[i];

    valv.country = country;
    valv.address = billingAddress;
    valv.state = stateValue;
    valv.city = city;
    valv.zipcode = pincode;
    valv.phone_no = phone_no;

    newFormValues[i] = valv;

    setFormValuesUser(newFormValues);
  };

  /// Add user handle change ////

  const handleChangeAttr = (i, e) => {
    let newFormValues = [...formValuesUser];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValuesUser(newFormValues);

    // error //
    let newFormValuesError = [...formValuesUserError];
    newFormValuesError[i][e.target.name] = e.target.value;

    let tempErr = "This field is required";
    if (e.target.name === "country") {
      if (
        !newFormValues[i].country ||
        newFormValues[i].country === "Select Country"
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }
    }

    if (e.target.name === "address") {
      if (!newFormValues[i].address) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesUserError(newFormValuesError);
    }

    if (e.target.name === "state") {
      if (!newFormValues[i].state) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesUserError(newFormValuesError);
    }

    if (e.target.name === "city") {
      if (!newFormValues[i].city) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesUserError(newFormValuesError);
    }

    if (e.target.name === "zipcode") {
      if (!newFormValues[i].zipcode) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesUserError(newFormValuesError);
    }

    if (e.target.name === "phone_no") {
      if (!newFormValues[i].phone_no) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }

      if (
        newFormValues[i].phone_no.length < 10 ||
        newFormValues[i].phone_no.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Shipping phone number should be of 10 digits";
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }
    }

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (e.target.name === "aName") {
      if (!newFormValues[i].aName) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }
    }

    if (e.target.name === "aPhoneNo") {
      if (!newFormValues[i].aPhoneNo) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }
      if (
        newFormValues[i].aPhoneNo.length < 10 ||
        newFormValues[i].aPhoneNo.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Contact phone number should be of 10 digits";
        setFormValuesUserError(newFormValuesError);
        return false;
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesUserError(newFormValuesError);
    }

    if (e.target.name === "selected") {
      if (
        !newFormValues[i].selected ||
        newFormValues[i].selected === "Select"
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesUserError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesUserError(newFormValuesError);
      }
    }

    if (e.target.name === "aEmail") {
      // if (!newFormValues[i].aEmail) {
      //   newFormValuesError[i][e.target.name] = tempErr;
      //   setFormValuesUserError(newFormValuesError);
      //   return false;
      // } else {
      //   newFormValuesError[i][e.target.name] = "";
      //   setFormValuesUserError(newFormValuesError);
      // }

      if (newFormValues[i].aEmail) {
        if (!newFormValues[i].aEmail.match(emailReg)) {
          newFormValuesError[i][e.target.name] = "Please enter valid email";
          setFormValuesUserError(newFormValuesError);
          return false;
        } else {
          newFormValuesError[i][e.target.name] = "";
          setFormValuesUserError(newFormValuesError);
        }
      }
    }
    setFormValuesUserError(newFormValuesError);
  };

  const addFormFieldsUser = () => {
    setFormValuesUser([
      ...formValuesUser,
      {
        address: "",
        country: "",
        phone_no: "",
        city: "",
        zipcode: "",
        state: "",
        aName: "",
        aEmail: "",
        aPhoneNo: "",
        designation: "",
        selected: "",
      },
    ]);
    setFormValuesUserError([
      ...formValuesUserError,
      {
        address: "",
        country: "",
        phone_no: "",
        city: "",
        zipcode: "",
        state: "",
        aName: "",
        aEmail: "",
        aPhoneNo: "",
        designation: "",
        selected: "",
      },
    ]);
  };

  let removeFormFieldsUser = (i) => {
    let newFormValues = [...formValuesUser];
    newFormValues.splice(i, 1);
    setFormValuesUser(newFormValues);

    let newFormValuesError = [...formValuesUserError];
    newFormValuesError.splice(i, 1);
    setFormValuesUserError(newFormValuesError);
  };

  const designationHtml = () => {
    const designationhtml = [];

    designationData.map((value, i) => {
      return designationhtml.push(
        <option value={value.designation}>{value.designation}</option>
      );
    });
    return designationhtml;
  };

  const convertSubmit = (e) => {
    // e.preventDefault();

    if (
      !selectedOptions &&
      !customerName &&
      // !compneyName &&
      // !email &&
      !password &&
      !phoneNo &&
      // !website &&
      // !gst &&
      // !pancard &&
      (!country || country === "Select Country") &&
      !billingAddress &&
      (!stateValue || stateValue === "Select State") &&
      !city &&
      !pincode &&
      !phone_no
    ) {
      setSelectedOptionsError("Customer type is required");
      setCustNameError("Customer name is required");
      // setCompNameError("Company name is required");
      // setEmailError("Email is required");
      setPasswordError("Password is required");
      setPhoneError("Phone No. is required");
      // setWebsiteError("Website is required");
      // setGstError("GST number is required");
      // setPanError("Pan number is required");
      setCountryError("Biling country is required");
      setAddressError("Billing address is required");
      setStateError("Billing state is required");
      setCityError("Billing city is required");
      setPincodeError("Billing pincode is required");
      setPhone_noError("Billing phone no. is required");
    }
    // top

    if (!selectedOptions) {
      setSelectedOptionsError("Customer type is required");
      return false;
    }

    if (!customerName) {
      setCustNameError("Customer name is required");
      return false;
    }
    if (userType === true) {
      if (!compneyName) {
        setCompNameError("Company name is required");
        return false;
      }
    }

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    // if (!email) {
    //   setEmailError("Email is required");
    //   return false;
    // }
    // if (!email.match(emailReg)) {
    //   setEmailError("Please enter valid email");
    //   return false;
    // }

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!password) {
      setPasswordError("Password is required");
      return false;
    }

    if (!password.match(regex)) {
      setPasswordError(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    if (!phoneNo) {
      setPhoneError("Phone No. is required");
      return false;
    }
    if (phoneNo.length > 10) {
      setPhoneError("Phone No. should be of 10 digits");
      return false;
    }
    if (phoneNo.length < 10) {
      setPhoneError("Phone No. should be of 10 digits");
      return false;
    }

    // if (!website) {
    //   setWebsiteError("Website is required");
    //   return false;
    // }

    if (!zone) {
      setZoneError("Please select zone");
      return false;
    }

    if (userType === true) {
      if (!gst) {
        setGstError("GST is required");
        return false;
      }

      if (gst !== "URP") {
        if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
          setGstError("Enter valid GST number");
          return false;
        }
      }
    }

    // if (!gst) {
    //   setGstError("GST is required");
    //   return false;
    // }

    // if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
    //   setGstError("Enter valid GST number");
    //   return false;
    // }

    if (userType === true) {
      if (!pancard) {
        setPanError("Pan number is required");
        return false;
      }

      if (pancard !== "URP") {
        var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
        if (!reggg.test(pancard)) {
          setPanError("Please enter correct pan card");
          return false;
        }
      }
    }

    if (!country || country === "Select Country") {
      setCountryError("Billing country is required");
      return false;
    }

    if (!billingAddress) {
      setAddressError("Billing address is required");
      return false;
    }

    if (!stateValue || stateValue === "Select State") {
      setStateError("Billing state is required");
      return false;
    }

    if (!city) {
      setCityError("Billing city is required");
      return false;
    }

    // let valueNew = pincode.trim();
    let regexx = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;

    if (!pincode) {
      setPincodeError("Billing pincode is required");
      return false;
    }
    // if (!pincode.match(regexx)) {
    //   setPincodeError("Please enter valid input");
    //   return false;
    // }

    if (pincode.length > 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }
    if (pincode.length < 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }

    if (!phone_no) {
      setPhone_noError("Billing phone no. is required");
      return false;
    }

    if (phone_no.length > 10) {
      setPhone_noError("Phone No. should be of 10 digits");
      return false;
    }
    if (phone_no.length < 10) {
      setPhone_noError("Phone No. should be of 10 digits");
      return false;
    }

    for (let i = 0; i < formValuesUser.length; i++) {
      const element = formValuesUser[i];
      let addressError = [...formValuesUserError];

      let tempZipcode = element.zipcode;

      if (!element.country || element.country === "Select Country") {
        addressError[i].country = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }
      if (!element.address) {
        addressError[i].address = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }
      if (!element.state || element.state === "Select State") {
        addressError[i].state = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }
      if (!element.city) {
        addressError[i].city = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }
      if (!element.zipcode) {
        addressError[i].zipcode = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }

      // if (!tempZipcode.match(regexx)) {
      //   addressError[i].zipcode = "Please enter valid pincode";
      //   setFormValuesUserError(addressError);
      //   return false;
      // }

      if (tempZipcode.length > 6) {
        addressError[i].zipcode = "Pincode should be of 6 digits";
        setFormValuesUserError(addressError);
        return false;
      }
      if (tempZipcode.length < 6) {
        addressError[i].zipcode = "Pincode should be of 6 digits";
        setFormValuesUserError(addressError);
        return false;
      }

      if (!element.phone_no) {
        addressError[i].phone_no = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }

      if (element.phone_no.length > 10) {
        addressError[i].phone_no = "Phone No. should be of 10 digits";
        setFormValuesUserError(addressError);
        return false;
      }
      if (element.phone_no.length < 10) {
        addressError[i].phone_no = "Phone No. should be of 10 digits";
        setFormValuesUserError(addressError);
        return false;
      }

      if (!element.aName) {
        addressError[i].aName = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }

      if (!element.aPhoneNo) {
        addressError[i].aPhoneNo = "This field is required";
        setFormValuesUserError(addressError);
        return false;
      }

      if (element.aPhoneNo.length > 10) {
        addressError[i].aPhoneNo = "Contact phone No. should be of 10 digits";
        setFormValuesUserError(addressError);
        return false;
      }
      if (element.aPhoneNo.length < 10) {
        addressError[i].aPhoneNo = "Contact phone No. should be of 10 digits";
        setFormValuesUserError(addressError);
        return false;
      }

      if (!element.selected || element.selected === "Select") {
        addressError[i].selected = "This field is required";
        setFormValuesUserError(addressError);

        return false;
      }

      // if (!element.aEmail) {
      //   addressError[i].aEmail = "This field is required";
      //   setFormValuesUserError(addressError);

      //   return false;
      // }

      if (element.aEmail) {
        if (!element.aEmail.match(emailReg)) {
          addressError[i].aEmail = "Please enter valid email";
          setFormValuesUserError(addressError);

          return false;
        }
      }
    }

    setBtnDisableCustomer(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    let customerData = {
      selectedOptions: selectedOptions,
      customerName: customerName,
      companyName: compneyName,
      email: custEmail,
      phoneNo: phoneNo,
      website: website,
      leadType: "Lead",
      gst: gst,
      pancard: pancard,
      password: password,
      billingAddress: billingAddress,
      country: country,
      city: city,
      stateValue: stateValue,
      phone_no: phone_no,
      pincode: pincode,
      // atribute: formValues,
      address: formValuesUser,
      leadId: id,
      zone: zone,
      userType: localStorage.getItem("userType"),
      // address: formValuesAddress,
    };

    axios
      .post(baseUrl + "/frontapi/add-user-service-lead", customerData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          // getCustomer();
          toast.dismiss();
          toast.success(resp.message);
          switchCustomer("false", []);
          setCustomerName("");
          setCompneyName("");
          setEmail("");
          setPhoneNo("");
          setWebsite("");
          setGst("");
          setPancard("");
          setBillingAddress("");
          leadsData();
        } else {
          toast.dismiss();
          toast.error(resp.message);
          setBtnDisableCustomer(false);
        }
      });
  };

  const closeMidModal = () => {
    setMidConvertModal(false);
  };

  const midConvertFunction = (type) => {
    if (!dataForConvert && dataForConvert.length === 0) {
      toast.error("Please select converted again");
      return false;
    }

    if (type === "without") {
      // write code for converting status only
      Conn("3", dataForConvert);
    }

    if (type === "with") {
      switchCustomer("true", dataForConvert);
    }

    closeMidModal();
  };

  const getZoneData = () => {
    axios.post(baseUrl + "/frontapi/zone-data-deleted").then((res) => {
      const resp = res.data;
      setZoneData(resp.data);

      if (localStorage.getItem("userType") === "SalesManager") {
        zoneByManager(resp.data);
      }
    });
  };

  const ZoneHtml = () => {
    const zonehtml = [];

    zoneData.map((value, i) => {
      return zonehtml.push(<option value={value.id}>{value.zone}</option>);
    });
    return zonehtml;
  };

  const zoneByManager = (allData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/getZonesByManagerAuth", {}, config)
      .then((res) => {
        var resp = res.data;

        const convertedZone = JSON.parse(resp.data[0].zone);

        // Create a set of zones from the second array
        const secondArrayZones = new Set(
          convertedZone.map((item) => parseInt(item.zone, 10))
        );

        // Filter the first array to include only those objects whose ids are in the set
        const commonArray = allData.filter((item) =>
          secondArrayZones.has(item.id)
        );

        setZoneData(commonArray);
      });
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between">
              <h2>
                <b></b>
              </h2>
              <div className="add-product-btn text-center">
                <Link to={"/add-leads"} className="btn btn-primary">
                  Add Lead
                </Link>
              </div>
            </div>

            <div className="filter-box">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <label>Filter By Status</label>
                    <select
                      className="form-control"
                      value={statusValue}
                      onChange={handleChangeStatus}
                    >
                      <option value="">All</option>
                      <option value="0">Pending</option>
                      <option value="1">In Process</option>
                      <option value="4">Negotiate</option>
                      <option value="2">Converted</option>
                      <option value="3">Not Converted</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <label>Select Date Range</label>
                    <div className="btn-flex btn-rock">
                      <button
                        className="date-btn btn"
                        ref={buttonRef}
                        onClick={() => setShowPicker(!showPicker)}
                      >
                        {startDate ? "Lead date: " : "Select Date"}
                        <span style={{ fontSize: "12px" }}>
                          {startDate &&
                            formatDate(startDate, "MMM D, YYYY") + " -"}{" "}
                          {endDate && formatDate(endDate, "MMM D, YYYY")}
                        </span>
                      </button>

                      <button
                        className="date-btn btn"
                        onClick={() => {
                          setStartDate(null);
                          setEndDate(null);
                        }}
                      >
                        Clear Date
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    className="table table-bordered table-striped"
                    config={config}
                    records={filterData}
                    columns={columns}
                  />
                </div>
                <h3>Total Leads:-{totalLead}</h3>
              </div>
            </div>

            {showPicker && (
              <div ref={datePickerRef} className=" date-picker-local">
                <DateRangePicker
                  onChange={(item) => handleDateChanges([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                  maxDate={new Date()}
                  months={2}
                  ranges={state}
                  rangeColors={["#333333", "#333333", "#333333"]}
                  direction="horizontal"
                  staticRanges={[
                    ...defaultStaticRanges,
                    ...DatepickerCustomRanges,
                  ]}
                />
              </div>
            )}

            <ToastContainer
              limit={1}
              autoClose={3000}
              pauseOnHover={false}
              pauseOnFocusLoss={false}
              theme={"dark"}
            />

            <Modal
              className="modal-update"
              show={isModalOpenDelete}
              onHide={() => {
                switchDeleteModal([], "close");
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Disable Reason</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 main-col">
                    <div className="form-group">
                      <label>Reason</label>
                      <input
                        type="text"
                        name="reason"
                        className="form-control"
                        value={reason}
                        onChange={handleChangeReason}
                        placeholder="Reason"
                        disabled={showReason}
                      />
                      <p style={{ color: "red" }}>{reasonError}</p>
                    </div>

                    {showReason !== true && (
                      <button className="btn btn-success" onClick={disableLead}>
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="modal-update"
              show={isModalOpenWhatsApp}
              onHide={closeModalWhatsApp}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Send Over WhatsApp</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 main-col">
                    <div className="form-group">
                      <label>WhatsApp Number:</label>
                      <input
                        type="number"
                        name="number"
                        className="form-control"
                        value={mobNo}
                        onKeyDown={handleValidationDot}
                        onChange={handleChange}
                        placeholder="WhatsApp Number"
                      />
                      <p style={{ color: "red" }}>{mobNoError}</p>
                    </div>

                    <button
                      className="btn btn-success"
                      onClick={() => sendWhatsApp(id)}
                    >
                      SEND
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="modal-update"
              show={isModalOpen}
              onHide={closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Send Over Email</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 main-col">
                    <div className="form-group">
                      <label>Email:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        name="email"
                        placeholder="Email"
                      />
                      <p style={{ color: "red" }}>{emailError}</p>
                    </div>

                    <button
                      className="btn btn-success"
                      onClick={() => sendEmail(id)}
                    >
                      SEND
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* Add Meeting */}
            <Modal
              className="modal-update"
              show={addModal}
              onHide={() => {
                switchAddModal("close");
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Add Meeting</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Mode Of Meeting</label>
                      <select
                        className="form-control"
                        value={mode}
                        name="mode"
                        onChange={handleChangeAll}
                      >
                        <option value="">Please Select</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                      </select>
                      <p style={{ color: "red" }}>{modeErr}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Senior Involvement</label>
                      <select
                        className="form-control"
                        value={isSales}
                        name="isSales"
                        onChange={handleChangeAll}
                      >
                        <option value="">Please Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      <p style={{ color: "red" }}>{isSalesErr}</p>
                    </div>
                  </div>

                  {isSales === "yes" && (
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Senior Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={seniorName}
                          name="seniorName"
                          placeholder="Enter Senior Name"
                          onChange={handleChangeAll}
                        />

                        <p style={{ color: "red" }}>{seniorNameErr}</p>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Date & TIme</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={meetingDate}
                        name="meetingDate"
                        onChange={handleChangeAll}
                      />

                      <p style={{ color: "red" }}>{meetingDateErr}</p>
                    </div>
                  </div>

                  {mode === "online" && (
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Link</label>
                        <input
                          type="text"
                          className="form-control"
                          value={link}
                          name="link"
                          placeholder="Enter Link"
                          onChange={handleChangeAll}
                        />

                        <p style={{ color: "red" }}>{linkErr}</p>
                      </div>
                    </div>
                  )}

                  <div className="col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <input
                        type="text"
                        className="form-control"
                        value={description}
                        name="description"
                        placeholder="Enter Description"
                        onChange={handleChangeAll}
                      />

                      <p style={{ color: "red" }}>{descriptionErr}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={submitAdd}
                  disabled={btnDisable}
                >
                  Add Meeting
                </button>
              </Modal.Footer>
            </Modal>
            {/* End Add Meeting */}

            {/* View Meeting */}
            <Modal
              className="modal-update"
              show={viewModal}
              onHide={() => {
                switchViewModal("close", "");
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">View Meeting</Modal.Title>
              </Modal.Header>
              <Modal.Body>{MeetHtml()}</Modal.Body>
              <Modal.Footer>
                <h4>
                  Total Meetings:{" "}
                  {meetData && meetData.length === 0 ? 0 : meetData.length}
                </h4>
                <button
                  className="btn btn-danger"
                  onClick={() => switchViewModal("close", "")}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
            {/* End View Meeting */}

            {/* Edit Meeting */}
            <Modal
              className="modal-update"
              show={editModal}
              onHide={() => {
                switchEditModal("close", "");
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Edit Meeting</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {meetingObj.map((element, index) => (
                    <>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mode Of Meeting</label>
                          <select
                            className="form-control"
                            value={element.mode}
                            name="mode"
                            onChange={(e) => handleChangeEditMeet(index, e)}
                          >
                            <option value="">Please Select</option>
                            <option value="online">Online</option>
                            <option value="offline">Offline</option>
                          </select>
                          <p style={{ color: "red" }}>
                            {" "}
                            {meetingObjErr[index].mode}{" "}
                          </p>{" "}
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Senior Involvement</label>
                          <select
                            className="form-control"
                            value={element.isSales}
                            name="isSales"
                            onChange={(e) => handleChangeEditMeet(index, e)}
                          >
                            <option value="">Please Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          <p style={{ color: "red" }}>
                            {" "}
                            {meetingObjErr[index].isSales}{" "}
                          </p>{" "}
                        </div>
                      </div>

                      {element.isSales === "yes" && (
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Senior Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={element.seniorName}
                              name="seniorName"
                              placeholder="Enter Senior Name"
                              onChange={(e) => handleChangeEditMeet(index, e)}
                            />
                            <p style={{ color: "red" }}>
                              {" "}
                              {meetingObjErr[index].seniorName}{" "}
                            </p>{" "}
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Date & TIme</label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={element.date}
                            name="date"
                            onChange={(e) => handleChangeEditMeet(index, e)}
                          />
                          <p style={{ color: "red" }}>
                            {" "}
                            {meetingObjErr[index].date}{" "}
                          </p>{" "}
                        </div>
                      </div>

                      {element.mode === "online" && (
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Link</label>
                            <input
                              type="text"
                              className="form-control"
                              value={element.link}
                              name="link"
                              placeholder="Enter Link"
                              onChange={(e) => handleChangeEditMeet(index, e)}
                            />
                            <p style={{ color: "red" }}>
                              {" "}
                              {meetingObjErr[index].link}{" "}
                            </p>{" "}
                          </div>
                        </div>
                      )}

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            type="text"
                            className="form-control"
                            value={element.description}
                            name="description"
                            placeholder="Enter Description"
                            onChange={(e) => handleChangeEditMeet(index, e)}
                          />
                          <p style={{ color: "red" }}>
                            {" "}
                            {meetingObjErr[index].description}{" "}
                          </p>{" "}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={submitEdit}
                  disabled={btnDisableEdit}
                >
                  Update
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => switchEditModal("close", "")}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
            {/* End Edit Meeting */}

            {/* Reminder Meeting */}

            <Modal
              className="modal-update"
              show={reminderModal}
              onHide={() => {
                switchRemModal("close", "");
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">Add Reminder</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={name}
                        className="form-control"
                        onChange={handleChangeRem}
                      />
                      <p style={{ color: "red" }}>{nameErr}</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Pre Reminder Date</label>
                      <input
                        type="datetime-local"
                        name="preDate"
                        placeholder="Date"
                        className="form-control"
                        onChange={handleChangeRem}
                        value={preReminderDate}
                      />
                      <p style={{ color: "red" }}> {preReminderDateErr} </p>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Reminder Date</label>
                      <input
                        type="datetime-local"
                        name="date"
                        placeholder="Date"
                        className="form-control"
                        onChange={handleChangeRem}
                        value={reminderDate}
                      />
                      <p style={{ color: "red" }}> {reminderDateErr} </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-success"
                  onClick={handleSubmitRem}
                  disabled={remBtnDisable}
                >
                  Add
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => switchRemModal("close", "")}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>

            {/* End Reminder Meeting */}

            {/* Customer */}

            <Modal
              className="modal-update"
              show={showCustomer}
              onHide={() => switchCustomer("false", [])}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0"> Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className="add-user-form-main"> */}
                <form>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <input
                          type="radio"
                          name="buisness"
                          value={selectedOptions}
                          checked={selectedOptions === "buisness" && true}
                          id="buisness"
                          onChange={handleChangeOptions}
                        />
                        <label className="radio-label" htmlFor="buisness">
                          Business
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <input
                          type="radio"
                          value={selectedOptions}
                          name="manufacturer"
                          checked={selectedOptions === "manufacturer" && true}
                          id="manufacturer"
                          onChange={handleChangeOptions}
                        />
                        <label className="radio-label" htmlFor="manufacturer">
                          Manufacturer
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <input
                          type="radio"
                          name="individual"
                          value={selectedOptions}
                          checked={selectedOptions === "individual" && true}
                          id="individual"
                          onChange={handleChangeOptions}
                        />
                        <label className="radio-label" htmlFor="individual">
                          Individual
                        </label>
                      </div>
                    </div>
                    <p style={{ color: "red" }}>{selectedOptionsError}</p>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          name="customerName"
                          value={customerName}
                          placeholder="Customer Name"
                          className="form-control"
                          onChange={handleChangeTop}
                        />
                        <p style={{ color: "red" }}>{custNameError}</p>
                      </div>
                    </div>
                    {userType === true && (
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            value={compneyName}
                            placeholder="Company Name"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <p style={{ color: "red" }}>{compNameError}</p>
                        </div>
                      </div>
                    )}
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          value={custEmail}
                          placeholder="Email"
                          className="form-control"
                          onChange={handleChangeTop}
                          // onBlur={handleOnBlurEmail}
                        />
                        <p style={{ color: "red" }}>{custEmailError}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={password}
                          className="form-control"
                          onChange={handleChangeTop}
                        />
                        <p style={{ color: "red" }}>{passwordError}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type={"number"}
                          name="pNumber"
                          value={phoneNo}
                          onKeyDown={handleValidation}
                          placeholder="Phone No."
                          maxLength={10}
                          className="form-control"
                          onChange={handleChangeTop}
                        />
                        <p style={{ color: "red" }}>{phoneError}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Website</label>
                        <input
                          type="text"
                          name="website"
                          value={website}
                          placeholder="Website"
                          className="form-control"
                          onChange={handleChangeTop}
                          // onBlur={handleOnBlurWebsite}
                        />
                        <p style={{ color: "red" }}>{websiteError}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Zone</label>
                        <select
                          type="text"
                          name="zone"
                          value={zone}
                          placeholder="zone"
                          className="form-control"
                          onChange={handleChangeTop}
                        >
                          <option value={""}>Select Zone</option>
                          {ZoneHtml()}
                        </select>
                        <p style={{ color: "red" }}>{zoneError}</p>
                      </div>
                    </div>

                    {userType === true && (
                      <>
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label>GST</label>
                            <input
                              type="text"
                              name="gst"
                              value={gst}
                              maxLength={15}
                              placeholder="GST Number"
                              className="form-control"
                              onChange={handleChangeTop}
                              // onBlur={handleOnBlurGst}
                            />
                            <p style={{ color: "red" }}>{gstError}</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label>PAN Number</label>
                            <input
                              type="text"
                              name="panNum"
                              value={pancard}
                              placeholder="Pan Card Number"
                              className="form-control"
                              onChange={handleChangeTop}
                              // onBlur={handleOnBlurPanNum}
                            />
                            <p style={{ color: "red" }}>{panError}</p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="billing-shipping d-flex flex-wrap">
                      <div className="row">
                        <div className="col-12">
                          <h4> Billing Address</h4>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Country</label>
                            <select
                              type="text"
                              name="country"
                              value={country}
                              placeholder="Country"
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                            >
                              <option>Select Country</option>
                              {countryDropDownHtml()}
                            </select>
                            <p style={{ color: "red" }}>{countryError}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              name="billingAddress"
                              value={billingAddress}
                              placeholder="Address"
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                              // onBlur={handleOnBlurBillingAddress}
                            />
                            <p style={{ color: "red" }}>{addressError}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>State</label>
                            <select
                              type="text"
                              name="state"
                              value={stateValue}
                              placeholder="State"
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                            >
                              <option>Select State</option>
                              {stateDropDownHtml()}
                            </select>
                            <p style={{ color: "red" }}>{stateError}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              value={city}
                              placeholder="City"
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                            />
                            <p style={{ color: "red" }}>{cityError}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Pincode</label>
                            <input
                              type="number"
                              onKeyDown={handleValidation}
                              name="zipcode"
                              value={pincode}
                              placeholder="Pincode"
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                            />
                            <p style={{ color: "red" }}>{pincodeError}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Phone No.</label>
                            <input
                              type={"number"}
                              name="phone_no"
                              onKeyDown={handleValidation}
                              value={phone_no}
                              maxLength={10}
                              placeholder="Phone No."
                              className="form-control"
                              onChange={handleChangeBillingAddress}
                            />
                            <p style={{ color: "red" }}>{phone_noError}</p>
                          </div>
                        </div>
                      </div>

                      {formValuesUser.map((element, index) => (
                        <>
                          <div className="row">
                            <div className="col-12">
                              <h4>Shipping Address</h4>
                            </div>
                            <div>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => copyAddress(index, e)}
                              >
                                Copy Billing Address
                              </button>
                            </div>

                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  className="form-control"
                                  name="country"
                                  onChange={(e) => handleChangeAttr(index, e)}
                                  value={element.country || ""}
                                >
                                  <option>Select Country</option>
                                  {countryShippingDropDownHtml()}
                                </select>
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].country}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  placeholder="Address"
                                  className="form-control"
                                  value={element.address || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].address}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>State</label>
                                <select
                                  className="form-control"
                                  name="state"
                                  onChange={(e) => handleChangeAttr(index, e)}
                                  value={element.state || ""}
                                >
                                  <option>Select State</option>
                                  {stateShippingDropDownHtml(index)}
                                </select>
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].state}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  className="form-control"
                                  value={element.city || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].city}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Pincode</label>
                                <input
                                  className="form-control"
                                  type="number"
                                  onKeyDown={handleValidation}
                                  name="zipcode"
                                  placeholder="Pincode"
                                  onChange={(e) => handleChangeAttr(index, e)}
                                  value={element.zipcode || ""}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].zipcode}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Phone No.</label>
                                <input
                                  type={"number"}
                                  name="phone_no"
                                  onKeyDown={handleValidation}
                                  maxLength={10}
                                  placeholder="Phone No."
                                  className="form-control"
                                  value={element.phone_no || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].phone_no}
                                </p>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="contact-person-heading">
                                <h5>Contact Person</h5>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  type="text"
                                  name="aName"
                                  placeholder="Name"
                                  className="form-control"
                                  value={element.aName || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].aName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Phone No.</label>
                                <input
                                  type={"number"}
                                  onKeyDown={handleValidation}
                                  name="aPhoneNo"
                                  placeholder="Phone No."
                                  maxLength={10}
                                  className="form-control"
                                  value={element.aPhoneNo || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].aPhoneNo}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="form-group">
                                <label>Designation</label>
                                <select
                                  className="form-control"
                                  value={element.selected || ""}
                                  onChange={(e) => handleChangeAttr(index, e)}
                                  name="selected"
                                >
                                  <option>Select</option>
                                  <option>HR/Admin Manager</option>
                                  <option>Procurement Manager</option>
                                  <option>General Manager</option>
                                  <option>Owner/Partner/Director</option>
                                  <option>Store Manager</option>
                                  <option>Marketing Manager</option>
                                  {designationHtml()}
                                  <option>Other</option>
                                </select>
                                {element.selected === "Other" && (
                                  <input
                                    type="text"
                                    name="designation"
                                    placeholder="Other"
                                    className="form-control"
                                    value={element.designation || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                )}
                                <p style={{ color: "red" }}>
                                  {formValuesUserError[index].selected}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                              <div className="add-row-group d-flex align-items-center justify-content-between">
                                <div className="form-group">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    name="aEmail"
                                    placeholder="Email"
                                    className="form-control"
                                    value={element.aEmail || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesUserError[index].aEmail}
                                  </p>
                                </div>
                                <div className="add-row btn-group">
                                  {index ? (
                                    <a
                                      className="remove btn btn-primary"
                                      onClick={() =>
                                        removeFormFieldsUser(index)
                                      }
                                      href="#!"
                                    >
                                      -
                                    </a>
                                  ) : null}
                                  <a
                                    href="#!"
                                    onClick={() => addFormFieldsUser()}
                                    className="btn btn-primary"
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </form>

                <Modal.Footer>
                  <button
                    // type="button"
                    className="btn btn-success"
                    onClick={convertSubmit}
                    disabled={btnDisableCustomer}
                  >
                    Save
                  </button>
                  <button
                    // type="button"
                    className="btn btn-danger"
                    onClick={() => switchCustomer("false", [])}
                  >
                    Cancel
                  </button>
                </Modal.Footer>
                {/* </div> */}
              </Modal.Body>
            </Modal>

            {/* End Customer */}

            {/* Mid Covert Modal */}
            <Modal
              className="modal-update"
              show={midConvertModal}
              onHide={closeMidModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="m-0">
                  {" "}
                  Select Customer Option{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          midConvertFunction("without");
                        }}
                      >
                        Without Customer
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          midConvertFunction("with");
                        }}
                      >
                        With Customer
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <ToastContainer
              limit={1}
              autoClose={3000}
              pauseOnHover={false}
              pauseOnFocusLoss={false}
              theme={"dark"}
            />

            {/* End Mid Covert Modal */}
          </div>
        </div>

        <Footer />

        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default Leads;
