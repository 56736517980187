import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import Select from "react-select";
import { selectStyleDark } from "../Hooks/SelectStyle";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

function TrainingEdit() {
  const params = useParams();
  const [categoryId, setCategoryId] = useState("");
  const [prodId, setProdId] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");

  const [catData, setCatData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [dbDocs, setDbDocs] = useState([]);
  const [docs, setDocs] = useState([]);
  const [documentFile, setDocumentFile] = useState([]);
  const [loading, setLoading] = useState(false);

  // errors //
  const [categoryErr, setCategoryErr] = useState("");
  const [prodErr, setProdErr] = useState("");
  const [linkErr, setLinkErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [documentErr, setDocumentErr] = useState("");

  useEffect(() => {
    trainingDataSingle();
    categoryData();
  }, []);

  const categoryData = () => {
    axios.post(baseUrl + "/frontapi/category-data-deleted", {}).then((res) => {
      var resp = res.data;

      if (resp.status === true) {
        setCatData(resp.data);
      }
    });
  };

  const trainingDataSingle = () => {
    const data = {
      id: atob(params.id),
    };

    axios.post(baseUrl + "/frontapi/training-single", data).then((res) => {
      var resp = res.data;

      if (resp.status === true) {
        let mainData = resp.data[0];
        setCategoryId(JSON.parse(mainData.categoryId));
        setProdId(JSON.parse(mainData.productId));
        setDescription(mainData.description);
        setLink(mainData.link);
        setDbDocs(JSON.parse(mainData.document));
        productDataFunc(JSON.parse(mainData.categoryId));

      }
    });
  };

  const handleChangeAll = async (event) => {
    let { name, value } = event.target;
    if (name === "link") {
      setLink(value);
      setLinkErr("");
    }

    if (name === "description") {
      setDescription(value);
      if (!value) {
        setDescriptionErr("Please enter description");
        return false;
      }
      setDescriptionErr("");
    }

    if (name === "document") {
      let ImagesArray = Object.entries(event.target.files).map((e) =>
        URL.createObjectURL(e[1])
      );

      var temp_doc = event.target.files[0];

      if (!temp_doc) {
        setDocumentFile("");
        return;
      }
      
      if (temp_doc) {
        if (!temp_doc.name.match(/\.(jpg|jpeg|png|pdf|doc|webm|mp4)$/)) {
          setDocumentErr(
            "Please select valid document jpg,jpeg,png,pdf,doc,webm,mp4"
          );
          return false;
        }

        if (temp_doc.size >= 3e6) {
          setDocumentErr("Document size should not be more than 3MB");
          return false;
        }
      }

      setDocs([...docs, ...ImagesArray]);
      if (dbDocs) {
        setDbDocs([...dbDocs]);
      }

      setDocumentFile([...documentFile, event.target.files]);
      setDocumentErr("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = "";

    if (!categoryId || categoryId.length === 0) {
      setCategoryErr("Please select category");
      return false;
    }

    if (!prodId || prodId.length === 0) {
      setProdErr("Please select product");
      return false;
    }

    if (link && link.length > 0) {
      error = checkSpace("link", link);
      if (error) {
        setLinkErr(error);
        return false;
      }
    }

    if (!description) {
      setDescriptionErr("Please enter description");
      return false;
    }

    error = checkSpace("description", description);
    if (error) {
      setDescriptionErr(error);
      return false;
    }

    if (documentFile.length > 0) {
      for (let i = 0; i < documentFile.length; i++) {
        const element = documentFile[i][0];

        if (!element.name.match(/\.(jpg|jpeg|png|pdf|doc|webm|mp4)$/)) {
          setDocumentErr(
            "Please select valid document jpg,jpeg,png,pdf,doc,webm,mp4"
          );
          return false;
        }

        if (element.size >= 3e6) {
          setDocumentErr("Document size should not be more than 3MB");
          return false;
        }
      }
    }

    const formData = new FormData();
    formData.append("id", atob(params.id));
    for (const key of Object.keys(documentFile)) {
      formData.append("Document", documentFile[key][0]);
    }
    formData.append("dbDocs", JSON.stringify(dbDocs));
    formData.append("categoryId", JSON.stringify(categoryId));
    formData.append("productId", JSON.stringify(prodId));
    formData.append("link", link);
    formData.append("description", description);

    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/training-edit", formData)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/training";
          }, 3000);
          return false;
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeCategory = (selectedOption) => {
    setCategoryErr("");
    setCategoryId(selectedOption);

    setProdId("");

    if (selectedOption) {
      productDataFunc(selectedOption);
    } else {
      setProductData([]);
    }
  };

  const handleChangeProd = (selectedOption) => {
    setProdErr("");
    setProdId(selectedOption);
  };

  const productDataFunc = (selectedOption) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(
        baseUrl + "/frontapi/product-data-byCatId",
        { categoryId: selectedOption.value },
        config
      )
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setProductData(resp.data);
        }
      });
  };

  const showImages = () => {
    const html = [];

    if (dbDocs.length > 0) {
      dbDocs.map((element, index) => {
        return html.push(
          <div className="imageSize" key={element.doc}>
            <a
              href={baseUrl + "/static/training/" + element.doc}
              target="_blank"
              rel="noreferrer"
            >
              <b>{element.doc}</b>
            </a>

            {/* <b>{element.doc}</b> */}
            {/* <img className="uploaded-images" src={element} alt="" /> */}
            <span
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(index)}
            >
              X
            </span>
          </div>
        );
      });
    }
    return html;
  };

  const showImages1 = () => {
    const html = [];

    if (documentFile.length > 0) {
      documentFile.map((element, index) => {
        return html.push(
          <div className="imageSize" key={element}>
            <b>{element[0].name}</b>
            {/* <img className="uploaded-images" src={element} alt="" /> */}
            <span
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => removeItem1(index)}
            >
              X
            </span>
          </div>
        );
      });
    }
    return html;
  };

  const removeItem1 = (element) => {
    const s = docs.filter((item, index) => index !== element);
    const indexToRemove = element; // The index of the file to remove
    let filesArray = Array.from(documentFile); // Convert FileList to an array
    filesArray.splice(indexToRemove, 1); // Remove the file from the array
    setDocumentFile(filesArray);
    setDocs(s);
  };

  const removeItem = (element) => {
    const s = dbDocs.filter((item, index) => index !== element);

    setDbDocs(s);
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Edit Training</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <Select
                            value={categoryId}
                            onChange={handleChangeCategory}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="catId"
                            isClearable={true}
                            noOptionsMessage={() => "No category found"}
                            options={catData.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                          />
                          <p style={{ color: "red" }}>{categoryErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Name</label>
                          <Select
                            value={prodId}
                            onChange={handleChangeProd}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="prodId"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No products found"}
                            options={productData.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                          />
                          <p style={{ color: "red" }}>{prodErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Link (Optional)</label>
                          <input
                            type="text"
                            name="link"
                            placeholder="Link (Optional)"
                            value={link}
                            className="form-control"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{linkErr}</p>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={description}
                            className="form-control resize"
                            onChange={handleChangeAll}
                          />
                          <p style={{ color: "red" }}>{descriptionErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-5 col-12">
                        <div className="form-group">
                          <label>Document (Optional)</label>
                        </div>

                        <div className="form-group file-upload position-relative">
                          <input
                            className="profile_input"
                            type="file"
                            name="document"
                            placeholder=""
                            onChange={handleChangeAll}
                          />
                        </div>
                        <p style={{ color: "red" }}>{documentErr}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div id="uploadedImages">{showImages()}</div>
                    </div>

                    <div className="row">
                      <div id="uploadedImages">
                        {" "}
                        Newly Selected
                        <div className="row">{showImages1()}</div>
                      </div>
                    </div>
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/training"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default TrainingEdit;
