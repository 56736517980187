import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";

const baseUrl = myConstList.baseUrl;

function SalesPersonEdit() {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [id, setId] = useState(atob(useParams().id));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [salesManData, setSalesManData] = useState([]);
  const [salesManagerId, setSalesManagerId] = useState("");
  const [AllZoneData, setAllZoneData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [zone, setZone] = useState("");

  const [formValues, setFormValues] = useState([{ zone: "" }]);
  const [formValuesError, setFormValuesError] = useState([{ zone: "" }]);

  const [loading, setLoading] = useState(false);
  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [salesErr, setSalesErr] = useState("");
  const [zoneErr, setZoneErr] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [typePass1, setTypePass1] = useState("password");

  const [newPassErr, setNewPassErr] = useState("");
  const [cPassError, setCPassError] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    // getZonesData();
    SalesManagerData();
    salesPersonSingle();
  }, []);

  const getZonesData = (managerId) => {
    axios.post(baseUrl + "/frontapi/zone-data-deleted", {}).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setAllZoneData(resp.data);

        zoneByManager(managerId, resp.data);
      }
    });
  };

  const SalesManagerData = () => {
    axios
      .post(baseUrl + "/frontapi/sales-manager-data-deleted", SalesManagerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setSalesManData(resp.data);
        }
      });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = async (event) => {
    let { value, name } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }

      setNameErr("");
    }

    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailErr("Email is required");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }

      setEmailErr("");
    }

    if (name === "number") {
      setNumber(value);
      if (!value) {
        setNumErr("Mobile number is required");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }

    if (name === "salesManagerId") {
      setSalesManagerId(value);
      setSalesErr("");
      setZoneData([]);
      setZone("");

      if (!value) {
        return;
      }

      // a function to get zone data by sales man
      zoneByManager(value, AllZoneData);
    }

    if (name === "zone") {
      setZone(value);
      setZoneErr("");
    }
  };

  const salesPersonSingle = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/sales-person-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setId(resp.data.sqlRun[0].id);
        setName(resp.data.sqlRun[0].name);
        setEmail(resp.data.sqlRun[0].email);
        setNumber(resp.data.sqlRun[0].phoneNumber);
        setSalesManagerId(resp.data.sqlRun[0].salesManagerId);

        if (resp.data.sqlRun[0].SPZone) {
          const zoneConverted = JSON.parse(resp.data.sqlRun[0].SPZone);

          if (zoneConverted.length > 0) {
            setFormValues(zoneConverted);
            let html = [];
            zoneConverted.forEach((element) => {
              let tempArr1 = [
                {
                  zone: "",
                },
              ];
              html.push(tempArr1);
            });
            setFormValuesError(html);
          } else {
            setFormValues([{}]);
          }

          
          // setZone(resp.data.sqlRun[0].zone);
          // zoneByManager(resp.data.sqlRun[0].salesManagerId);
        }
        getZonesData(resp.data.sqlRun[0].salesManagerId);
      }
    });
  };

  const zoneByManager = (managerId, allData) => {
    // if (AllZoneData.length === 0) {

    const data = {
      id: managerId,
    };

    axios.post(baseUrl + "/frontapi/getZonesByManager", data).then((res) => {
      var resp = res.data;

      console.log("respppppp", resp);
      const convertedZone = JSON.parse(resp.data[0].zone);
      console.log("convertedZone", convertedZone);

      // Create a set of zones from the second array
      const secondArrayZones = new Set(
        convertedZone.map((item) => parseInt(item.zone, 10))
      );

      // Filter the first array to include only those objects whose ids are in the set
      const commonArray = allData.filter((item) =>
        secondArrayZones.has(item.id)
      );

      setZoneData(commonArray);
    });
    // }
    // else {
    //   getZonesData();
    //   zoneByManager(managerId);
    // }
  };

  const zoneHtml = () => {
    const zonehtml = [];
    var ZNData = zoneData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    ZNData.map((value, i) => {
      return zonehtml.push(<option value={value.id}>{value.zone}</option>);
    });
    return zonehtml;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = "";
    if (!name && !email && !number) {
      setNameErr("Name is required");
      setEmailErr("Email is required");
      setNumErr("Mobile number is required");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Email is required");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Mobile number is required");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    if (!salesManagerId || salesManagerId === "Please select") {
      setSalesErr("Please select sales manager");
      return false;
    }

    // if (!zone || zone === "Please select") {
    //   setZoneErr("Please select zone");
    //   return false;
    // }

    let html = [];

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let formError = [...formValuesError];
      if (!element.zone || element.zone === "Please Select") {
        formError[i].zone = "Please select zone";
        setFormValuesError(formError);
        return false;
      }

      html.push(element.zone);
    }

    function hasDuplicates(array) {
      for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return true; // if a duplicate is found, return true
          }
        }
      }
      // return false; // if no duplicates are found, return false
    }

    if (hasDuplicates(html) === true) {
      toast.dismiss();
      toast.error("Duplicate zone found");
      return false;
    }

    setLoading(true);

    let Data = {
      id: id,
      name: name,
      email: email,
      number: number,
      salesManagerId: salesManagerId,
      zoneData: JSON.stringify(formValues),
    };
    axios
      .post(baseUrl + "/frontapi/sales-person-update", Data)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(function () {
            window.location = "/sales-person";
          }, 3000);
          return false;
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const salesManagerHtml = () => {
    const salesManagerhtml = [];
    var SMData = salesManData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SMData.map((value, i) => {
      return salesManagerhtml.push(
        <option value={value.id}>{value.name}</option>
      );
    });
    return salesManagerhtml;
  };

  const handleChangePass = (e) => {
    let { name, value } = e.target;
    if (name === "newPass") {
      setNewPass(value);
      if (!value) {
        setNewPassErr("This field is required");
        return false;
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!value.match(regex)) {
        setNewPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      } else {
        setNewPassErr("");
      }

      if (value !== confirmPass) {
        setCPassError("Confirm password does not match");
        return false;
      } else {
        setCPassError("");
      }

      setNewPassErr("");
    }

    if (name === "confirmPass") {
      setConfirmPass(value);
      if (!value) {
        setCPassError("This field is required");
        return false;
      }
      if (value !== newPass) {
        setCPassError("Confirm password does not match");
        return false;
      }

      setCPassError("");
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const submit = () => {
    if (!newPass) {
      setNewPassErr("This field is required");
      return false;
    }
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!newPass.match(regex)) {
      setNewPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    let error = checkSpace("new password", newPass);
    if (error) {
      setNewPassErr(error);
      return false;
    }

    if (!confirmPass) {
      setCPassError("This field is required");
      return false;
    }

    error = checkSpace("confirm password", confirmPass);
    if (error) {
      setCPassError(error);
      return false;
    }

    if (confirmPass !== newPass) {
      setCPassError("Confirm password does not match");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const data = {
      userId: id,
      newPass: newPass,
      confirmPass: confirmPass,
      userType: "customer",
    };

    axios.post(baseUrl + "/frontapi/changePass", data, config).then((res) => {
      const resp = res.data;
      toast.dismiss();

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setTimeout(() => {
          window.location.href = "/sales-person";
        }, 2000);
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPass("");
    setConfirmPass("");
    setNewPassErr("");
    setCPassError("");
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  const handleClick1 = () => {
    if (typePass1 === "password") {
      setTypePass1("text");
      return false;
    } else {
      setTypePass1("password");
    }
  };

  let addFormFields = () => {
    setFormValues([...formValues, { zone: "" }]);
    setFormValuesError([...formValuesError, { zone: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);
  };

  let handleChangeZone = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.name === "zone") {
      if (!newFormValues[i].zone || newFormValues[i].zone === "Please Select") {
        newFormValuesError[i][e.target.name] = "Please select zone";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }
  };

  return (
    <ContentLayout
      title="Sales Person Update"
      className="add-product-outer customer-detail-outer"
    >
      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="form-group">
                <label>New Password</label>
                <input
                  className="form-control fs12"
                  placeholder="Enter New Password"
                  type={typePass}
                  name="newPass"
                  onChange={handleChangePass}
                  value={newPass}
                />
                <span
                  className="password__show eye1"
                  onClick={() => handleClick()}
                >
                  {typePass === "text" ? (
                    <i className="fa fa-eye"></i>
                  ) : (
                    <i className="fa fa-eye-slash"></i>
                  )}
                </span>
                <p style={{ color: "red" }}>{newPassErr}</p>
              </div>
            </div>
            <div className="col-sm-6 col-12">
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  placeholder="Enter Confirm Password"
                  className="form-control fs12"
                  type={typePass1}
                  name="confirmPass"
                  onChange={handleChangePass}
                  value={confirmPass}
                />
                <span
                  className="password__show eye1"
                  onClick={() => handleClick1()}
                >
                  {typePass1 === "text" ? (
                    <i className="fa fa-eye"></i>
                  ) : (
                    <i className="fa fa-eye-slash"></i>
                  )}
                </span>
                <p style={{ color: "red" }}>{cPassError}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="submit-btn-outer">
            <button type="submit" className="btn btn-primary" onClick={submit}>
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Name"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                className="form-control"
                disabled="true"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{emailErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Mobile Number</label>
              <input
                type="number"
                name="number"
                value={number}
                maxLength={10}
                onKeyDown={handleValidation}
                placeholder="Mobile Number"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{numErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Sales Manager</label>
              <select
                className="form-control"
                name="salesManagerId"
                value={salesManagerId}
                onChange={handleChange}
              >
                <option value="">Please select</option>
                {salesManagerHtml()}
              </select>

              <p style={{ color: "red" }}>{salesErr}</p>
            </div>
          </div>

          <div className="row">
            {formValues.map((element, index) => (
              <>
                <div className="col-md-4 col-12">
                  <div className="add-row-group d-flex align-items-center justify-content-between">
                    <div className="form-group">
                      <label>Zone</label>
                      <select
                        className="form-control"
                        name="zone"
                        value={element.zone || ""}
                        onChange={(e) => handleChangeZone(index, e)}
                      >
                        <option>Please Select</option>
                        {zoneHtml()}
                      </select>
                      <p style={{ color: "red" }}>
                        {" "}
                        {formValuesError[index].zone}{" "}
                      </p>
                    </div>
                    <div className="add-row">
                      {index ? (
                        <a
                          className="remove"
                          href="#!"
                          onClick={() => removeFormFields(index)}
                        >
                          -
                        </a>
                      ) : null}
                      <a
                        className="add-btn add"
                        href="#!"
                        onClick={() => addFormFields()}
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

          {/* <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Zone</label>
              <select
                className="form-control"
                name="zone"
                value={zone}
                onChange={handleChange}
              >
                <option value="">Please select</option>
                {zoneHtml()}
              </select>

              <p style={{ color: "red" }}>{zoneErr}</p>
            </div>
          </div> */}

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#add-user-modal"
                onClick={() => setModalIsOpen(true)}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/sales-person"
        loading={loading}
      />
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </ContentLayout>
  );
}
export default SalesPersonEdit;
