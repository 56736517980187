import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("All Sales");

const AllSalesCatMan = () => {
  const [record, setRecord] = useState([]); // to show record
  const [tempRecord, setTempRecord] = useState([]); // operation
  const [prodData, setProdData] = useState([]);
  const [prodId, setProdId] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    SalesData();
    productData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SalesData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/all-sales-data-catman", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
          setTempRecord(resp.data);
        }
      });
  };

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "id",
      text: "Order ID",
      className: "cust_id",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Sales Person Name",
      className: "sales_name",
      align: "left",
      sortable: true,
    },
    {
      key: "prod_name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "quantity",
      text: "Quantity",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.quantity} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price Per Unit",
      className: "name",
      align: "left",
      sortable: true,
    },

    // {
    //   key: "quantity",
    //   text: "Quantity",
    //   className: "Quantity",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return <Fragment>{record.quantity} INR</Fragment>;
    //   },
    // },

    {
      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.amount} INR</Fragment>;
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.createdAt).format("lll")} </Fragment>;
      },
    },
  ];

  const productData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setProdData(resp.data);
        }
      });
  };

  const productHtml = () => {
    const producthtml = [];
    var PData = prodData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    PData.map((value, i) => {
      return producthtml.push(<option value={value.id}>{value.name}</option>);
    });
    return producthtml;
  };

  const handleChangeAll = (e) => {
    let { name, value } = e.target;

    if (name === "prodId") {
      setProdId(value);
    }

    if (name === "startDate") {
      setStartDate(value);
    }

    if (name === "endDate") {
      setEndDate(value);
    }
  };

  const submitSearch = (e) => {
    e.preventDefault();
    // SalesData();
    if (!startDate && endDate) {
      toast.dismiss();
      toast.error("Start date is required");
      return false;
    }

    if (startDate > endDate) {
      toast.dismiss();
      toast.error("Please enter valid start date");
      return false;
    }

    if (!startDate && !endDate && (prodId == "all" || !prodId)) {
      SalesData();
    } else {
      let prodTypehtml = [];
      let dateTypeHtml = [];

      if (prodId !== "all") {
        for (let i = 0; i < tempRecord.length; i++) {
          const element = tempRecord[i];

          if (element.product_id == prodId) {
            prodTypehtml.push(element);
          }
        }

        if (endDate) {
          for (let j = 0; j < prodTypehtml.length; j++) {
            const value = prodTypehtml[j];

            // Your date string from the database
            const dateString = value.createdAt;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (
              outputDateFormatted >= startDate &&
              outputDateFormatted <= endDate
            ) {
              dateTypeHtml.push(value);
            }
          }

          setRecord(dateTypeHtml);
        } else if (startDate && !endDate) {
          for (let k = 0; k < prodTypehtml.length; k++) {
            const value = prodTypehtml[k];

            // Your date string from the database
            const dateString = value.createdAt;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (outputDateFormatted >= startDate) {
              dateTypeHtml.push(value);
            }
          }
          setRecord(dateTypeHtml);
        } else {
          setRecord(prodTypehtml);
        }
      } else {
        if (endDate) {
          for (let l = 0; l < tempRecord.length; l++) {
            const value = tempRecord[l];

            // Your date string from the database
            const dateString = value.createdAt;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (
              outputDateFormatted >= startDate &&
              outputDateFormatted <= endDate
            ) {
              dateTypeHtml.push(value);
            }
          }
          setRecord(dateTypeHtml);
        } else if (startDate && !endDate) {
          for (let j = 0; j < tempRecord.length; j++) {
            const value = tempRecord[j];

            // Your date string from the database
            const dateString = value.createdAt;

            // Parse the input date using moment
            const inputDate = moment(dateString);

            // Format the date in the desired output format (YYYY-MM-DD)
            const desiredFormat = "YYYY-MM-DD";
            const outputDateFormatted = inputDate.format(desiredFormat);

            if (outputDateFormatted >= startDate) {
              dateTypeHtml.push(value);
            }
          }
          setRecord(dateTypeHtml);
        }
      }
    }
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between">
              <h2>
                <b>All Sales</b>
              </h2>
            </div>

            <div className="invent-form">
              <div className="row align-items-end">
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Products</label>
                    <select
                      className="form-control"
                      name="prodId"
                      onChange={handleChangeAll}
                      value={prodId}
                    >
                      <option value="all">All</option>
                      {productHtml()}
                    </select>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Start Date</label>

                    <input
                      type="date"
                      name="startDate"
                      placeholder=" Start Date"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={startDate}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      placeholder=" End Date"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={endDate}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-3 col-sm-6 col-12">
                  <button className="btn btn-primary" onClick={submitSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default AllSalesCatMan;
