import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

function VendorOrderDetail() {
  const [orderId, setOrderId] = useState("");
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [againstOrderId, setAgainstOrderId] = useState(null);
  const [category, setCategory] = useState("");
  const [tableData, setTableData] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [rawMaterialDataManufacturing, setRawMaterialDataManufacturing] =
    useState([]);

  const [remainingTime, setRemainingTime] = useState("");
  const [remainingTimeFinished, setRemainingTimeFinished] = useState("");

  // modal states
  const [showBuyModel, setShowBuyModel] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [materialPrice, setMaterialPrice] = useState("");
  const [materialQuantity, setMaterialQuantity] = useState("");
  const [vendorSpecifications, setVendorSpecifications] = useState("");
  const [units, setUnits] = useState("");
  const [tax, setTax] = useState("");
  const [hsn, setHsn] = useState("");
  const [total, setTotal] = useState(0);
  const [vendorListData, setVendorListData] = useState([]);
  const [singleOrderId, setSingleOrderId] = useState("");
  const [singleProductId, setSingleProductId] = useState("");
  const [vendorTotalWithoutGst, setVendorTotalWithoutGst] = useState(0);
  const [vendorMaterialListId, setVendorMaterialListId] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  // lock modal
  const [lockQuantity, setLockQuantity] = useState("");
  const [showLockModel, setShowLockModel] = useState(false);
  const [btnDisableLock, setBtnDisableLock] = useState(false);

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    orderData(Params);
    getOrderDetails();
    setOrderId(Params);
    getVensdorList();
  }, []);

  const getCatData = (id) => {
    const data = {
      catId: id,
    };

    axios.post(baseUrl + "/frontapi/order-single-product", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCategory(resp.data.catData[0].name);
      }
    });
  };

  const RawData = async (id, status, vendorOrderId) => {
    const data = {
      orderId: id,
      status: status,
      vendorOrderId: vendorOrderId,
    };
    await axios
      .post(baseUrl + "/frontapi/getRawMaterialDetail", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          if (resp.type) {
            setRawMaterialDataManufacturing(resp.data);
          } else {
            setRawMaterialData(resp.data);
          }
        }
      });
  };

  const closeShowEditModel = () => {
    setShowBuyModel(false);
    setMaterialPrice("");
    setMaterialQuantity("");
    setVendorSpecifications("");
    setVendorId("");
    setTotal(0);
  };

  const closeShowLockModel = () => {
    setShowLockModel(false);
    setBtnDisableLock(false);
    setLockQuantity("");
  };

  const handleChangeLock = async (event) => {
    let value = event.target.value;
    setLockQuantity(value);
  };

  const handleSubmitLock = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      productId: singleProductId,
      quantity: lockQuantity,
      id: vendorMaterialListId,
    };

    setBtnDisableLock(true);

    axios
      .post(baseUrl + "/frontapi/lock-manufacture-order-vendor", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setBtnDisableLock(true);
          toast.success(resp.message);
          closeShowLockModel();
          orderData(Params);

          setTimeout(() => {
            setBtnDisableLock(false);
          }, 3000);
        } else {
          setBtnDisableLock(false);
          toast.error(resp.message);
        }
      });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = async (event, editor) => {
    let fieldValue = event.target.value;
    let fieldName = event.target.name;
    if (fieldName === "vendorId") {
      setVendorId(fieldValue);
    }
    let total;

    if (fieldName === "materialPrice") {
      setMaterialPrice(fieldValue);
      total = fieldValue * materialQuantity;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;

      setVendorTotalWithoutGst(total);
      setTotal(theValue);
    }
    if (fieldName === "materialQuantity") {
      setMaterialQuantity(fieldValue);

      total = fieldValue * materialPrice;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;

      setVendorTotalWithoutGst(total);
      setTotal(theValue);
    }
    if (fieldName === "specification") {
      setVendorSpecifications(fieldValue);
    }
  };

  const vendorListHtml = () => {
    const html = [];
    html.push(<option>Select Vendor</option>);
    vendorListData.map((item) => {
      return html.push(<option value={item.id}>{item.name}</option>);
    });
    return html;
  };

  const getVensdorList = () => {
    axios.get(baseUrl + "/frontapi/get-vendor-list").then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setVendorListData(resp.data);
      }
    });
  };

  const handleSubmitMaterial = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      vendorId: vendorId,
      singleOrderId: singleOrderId,
      singleProductId: singleProductId,
      materialPrice: materialPrice,
      materialQuantity: materialQuantity,
      materialTotal: total,
      tax: tax,
      hsnCode: hsn,
      units: units,
      type: "buy",
      specification: vendorSpecifications,
      vendorMaterialListId: vendorMaterialListId,
    };

    setBtnDisable(true);

    axios
      .post(baseUrl + "/frontapi/add-buy-product-manufacture", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setBtnDisable(true);
          setShowBuyModel(false);
          toast.success(resp.message);
          setVendorId("");
          setSingleOrderId("");
          setSingleProductId("");
          setMaterialPrice("");
          setMaterialQuantity("");
          setTotal("");

          orderData(Params);

          setTimeout(() => {
            setBtnDisable(false);
          }, 3000);
        } else {
          setBtnDisable(false);

          toast.error(resp.message);
        }
      });
  };

  const orderData = async (Params) => {
    const data = {
      id: Params,
      userType: "yes",
    };
    await axios.post(baseUrl + "/frontapi/order-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setTableData(resp.data);
        setAgainstOrderId(resp.data[0].against_order_id);
        setDateOfOrder(resp.data[0].created_at);
        getCatData(resp.data[0].category_id);
        getVendorName(resp.data[0].vendor_id);
        setOrderStatus(resp.data[0].status);

        if (resp.data[0].status === "2" || resp.data[0].status === "3") {
          showTimerCounterHtmlFinished(
            resp.data[0].finishAt,
            resp.data[0].created_at
          );
        } else {
          showTimerCounterHtml(resp.data[0].created_at);
        }
        setOrderType(resp.data[0].orderType);
        RawData(
          resp.data[0].against_order_id,
          resp.data[0].status,
          resp.data[0].id
        );
      }
    });
  };

  let getVendorName = async (id) => {
    let requestData = { id: id };
    await axios
      .post(baseUrl + "/frontapi/vendor-single", requestData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          return;
        }
        if (resp.status === true) {
          setVendorName(resp.data.sqlRun[0].name);
        }
      });
  };

  const getOrderDetails = () => {
    const data = {
      id: Params,
    };
    axios
      .post(baseUrl + "/frontapi/get-orderdetails-admin", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
        }
      });
  };

  const totalSumHtml = () => {
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.total);
    });
    return parseFloat(sum).toFixed(2);
  };

  const tableHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      var amt = value.price * value.quantity;
      var gstTotal = (amt / 100) * value.tax;
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.name}</th>
          <th>
            {value.quantity} {value.units}
          </th>
          <th>
            {value.manufactured_quantity ? value.manufactured_quantity : 0}{" "}
            {value.units}
          </th>
          <th>{value.price} INR</th>
          <th>{value.tax}%</th>
          <th>{parseFloat(gstTotal).toFixed(2)}</th>
          <th>
            {" "}
            {value.orderType === "Customize" ? (
              <>
                {value.product_file === null ||
                value.product_file === "NULL" ||
                value.product_file === "null" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl + "/static/customizeImage/" + value.product_file
                    }
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    {!value.product_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.product_file
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          <th>
            {!value.sample_file ? (
              "N/A"
            ) : (
              <>
                <a
                  href={baseUrl + "/static/sampleImage/" + value.sample_file}
                  target="_blank"
                  rel="noreferrer"
                  className=""
                >
                  {!value.sample_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                    "View File"
                  ) : (
                    <img
                      src={baseUrl + "/static/sampleImage/" + value.sample_file}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </a>
                {value.sample_file_status === "1" ? "Accepted" : ""}
                {value.sample_file_status === "2" ? "Rejected" : ""}
                {value.sample_file_status === "0" ||
                value.sample_file_status == null ? (
                  <div className="top-space form-group">
                    <select
                      className="form-control"
                      value={value.sample_file_status}
                      onChange={(e) => changeStatusProduct(e, value)}
                    >
                      <option>Select</option>
                      <option value="1">Accept</option>
                      <option value="2">Reject</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </th>

          <th>{parseFloat(value.total).toFixed(2)} INR</th>
        </tr>
      );
    });
    return html;
  };

  const changeStatusProduct = (e, value) => {
    let data = {
      orderId: value.id,
      status: e.target.value,
    };

    if (e.target.value === "Select") {
      return false;
    }

    Conn(data);
    return false;
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const changeStatus = (data) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/change-sampleFile-status", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          orderData();
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  const rawHtml = () => {
    const html = [];
    rawMaterialData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name : <b>{value.att[0].prod_name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Quantity : <b>{value.locked_qty}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const manufacturingRawHtml = () => {
    const html = [];
    rawMaterialDataManufacturing.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name : <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Locked Quantity :{" "}
                <b>
                  {value.locked_quantity} {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Required Quantity :{" "}
                <b>
                  {parseFloat(value.locked_quantity + value.remaining_quantity)}{" "}
                  {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Available Quantity :{" "}
                <b>
                  {parseFloat(value.available_quantity)} {value.units}
                </b>
              </p>
            </div>
          </div>
          {value.status == 1 ? (
            <div className="col-md-6 col-sm-6 col-12 more-padding">
              <div className="person-detail">
                <p>
                  Order :{" "}
                  <b>
                    {value.orderStatus == 0 ? (
                      <>
                        <button
                          className="btn btn-primary"
                          onClick={() => openModalLock(value)}
                        >
                          {" "}
                          Lock quantity
                        </button>

                        <button
                          className="btn btn-primary"
                          onClick={() => openModal(value)}
                        >
                          {" "}
                          Buy
                        </button>
                      </>
                    ) : value.orderStatus == 3 ? (
                      <>
                        <button
                          className="btn btn-primary"
                          onClick={() => openModalLock(value)}
                        >
                          {" "}
                          Lock quantity
                        </button>

                        <button
                          className="btn btn-primary"
                          onClick={() => openModal(value)}
                        >
                          {" "}
                          Buy
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            (window.location.href =
                              "/view-order-manufacture/" + btoa(value.id))
                          }
                        >
                          {" "}
                          View
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          (window.location.href =
                            "/view-order-manufacture/" + btoa(value.id))
                        }
                      >
                        {" "}
                        View
                      </button>
                    )}
                  </b>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
    return html;
  };

  const openModal = (value) => {
    setSingleOrderId(value.order_id);
    setSingleProductId(value.product_id);
    setMaterialQuantity(value.remaining_quantity);
    setTax(value.tax);
    setHsn(value.hsnCode);
    setMaterialPrice(value.sale_price);
    setUnits(value.units);
    setVendorMaterialListId(value.id);

    let total = value.sale_price * value.remaining_quantity;
    const taxValue = (total * tax) / 100;
    const theValue = taxValue + total;

    setVendorTotalWithoutGst(total);
    setTotal(theValue);

    setShowBuyModel(true);
  };

  const openModalLock = (value) => {
    setSingleOrderId(value.order_id);
    setSingleProductId(value.product_id);
    setMaterialQuantity(value.remaining_quantity);
    setVendorMaterialListId(value.id);

    setShowLockModel(true);
  };

  const showTimerCounterHtml = (startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();

    setInterval(async () => {
      var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
      const secondsCurrent = moment(currentDate, "YYYY-MM-DD HH:mm:ss").unix();
      var remainingSeconds = secondsCurrent - secondsStart;

      const duration = moment.duration(remainingSeconds, "seconds");
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const secondsRemaining = duration.seconds();

      setRemainingTime(
        `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
      );
    }, 1000);
  };

  const showTimerCounterHtmlFinished = (expriryDateTime, startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD hh:mm:ss");

    var endDate = moment(expriryDateTime).format("YYYY-MM-DD hh:mm:ss");

    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();
    const secondsExpiry = moment(endDate, "YYYY-MM-DD HH:mm:ss").unix();

    var remainingSeconds = secondsExpiry - secondsStart;

    const duration = moment.duration(remainingSeconds, "seconds");
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsRemaining = duration.seconds();

    setRemainingTimeFinished(
      `${days} days, ${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
    );
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>
                    {" "}
                    {orderStatus === "0" ? "PENDING" : ""}
                    {orderStatus === "1" ? "INPROCESS" : ""}
                    {orderStatus === "2" ? "COMPLETED" : ""}
                    {orderStatus === "3" ? "REJECTED" : ""}
                    {orderStatus === "4"
                      ? "Waiting For Manufacturing"
                      : ""}{" "}
                    ORDERS DETAIL
                  </b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id : <b>{orderId}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Date of Order:{" "}
                        <b>{moment(dateOfOrder).format("DD/MM/YYYY")}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Vendor Name: <b>{vendorName}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-inner">
                      <p>
                        Time Taken:{" "}
                        <b>
                          {remainingTime} {remainingTimeFinished}
                        </b>
                      </p>
                    </div>
                  </div>
                  {againstOrderId != null ? (
                    <div className="col-md-3 col-12">
                      <div className="detail-inner">
                        <p>
                          Against Order ID: <b>{againstOrderId}</b>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Order Details</h3>
                <div className="table-responsive">
                  <table className="w-100">
                    <tr>
                      <th>S.No</th>
                      <th>Products Name</th>
                      <th>Order Quantity</th>
                      <th>Delivered Quantity</th>
                      <th>Price</th>
                      <th>Gst</th>
                      <th>Gst Amount</th>
                      <th>Customize Image</th>
                      <th>Sample Image</th>
                      <th>Total Amount</th>
                    </tr>
                    {tableHtml()}
                    <tr align="right">
                      <td colSpan="10">
                        <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              {orderType === "manufacture" && (
                <div className=" customer-detail-outer">
                  <div className="order-detail-outer-middle pending-list-outer">
                    <div className="col-12">
                      <div className="person-contact customer-detail">
                        <h3>Raw Material Details</h3>
                        {rawHtml()}
                        {manufacturingRawHtml()}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Total Price : </small>
                        <b>INR {totalSumHtml()}-/-</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category : </small>
                        <b>
                          {category === null || !category ? "N/A" : category}
                        </b>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Buy Lock Modal */}
              <Modal
                className="modal-update place-order-vendor-popup buy-vendor"
                show={showLockModel}
                onHide={closeShowLockModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m-0">
                    Lock Product Quantity
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="assign-vendor">
                  <div className="row align-items-center">
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          onKeyDown={handleValidation}
                          placeholder="Quantity"
                          name="lockQuantity"
                          value={lockQuantity}
                          onChange={handleChangeLock}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="placeorder-btn">
                        <button
                          className="btn btn-primary laga do"
                          onClick={handleSubmitLock}
                          disabled={btnDisableLock}
                        >
                          Lock quantity
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* Buy Lock Modal */}

              {/* Buy Edit Modal */}
              <Modal
                className="modal-update place-order-vendor-popup buy-vendor"
                show={showBuyModel}
                onHide={closeShowEditModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m-0">Buy Product</Modal.Title>
                </Modal.Header>
                <Modal.Body className="assign-vendor">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="form-group place-vendor-inner">
                        <label>Vendor:</label>
                        <select
                          className="form-control"
                          value={vendorId}
                          name="vendorId"
                          onChange={handleChange}
                        >
                          {vendorListHtml()}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-6">
                      <div className="form-group place-vendor-inner">
                        <label>
                          Status: <b>{singleOrderStatus}</b>
                        </label>
                      </div>
                    </div> */}
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Price:</label>
                        <input
                          type="number"
                          className="form-control"
                          onKeyDown={handleValidation}
                          placeholder="Price"
                          name="materialPrice"
                          value={materialPrice}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Quantity:</label>
                        <input
                          type="number"
                          className="form-control"
                          onKeyDown={handleValidation}
                          placeholder="Quantity"
                          name="materialQuantity"
                          value={materialQuantity}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Units:</label>
                        <input
                          type="text"
                          placeholder="UNITS"
                          name="units"
                          value={units}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>TAX:</label>
                        <input
                          type="text"
                          placeholder="TAX"
                          name="tax"
                          value={tax}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>HSN Code:</label>
                        <input
                          type="text"
                          placeholder="HSN"
                          name="hsn"
                          value={hsn}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group place-vendor-inner">
                        <label>Specification:</label>
                        <input
                          type="type"
                          className="form-control"
                          placeholder="Specification"
                          name="specification"
                          value={vendorSpecifications}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Total Price (Inclusive Tax): <b>{total} INR</b>
                          </p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="product-total-price">
                          <p className="m-0">
                            Total Price (Exclusive Tax):{" "}
                            <b>{vendorTotalWithoutGst} INR</b>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6">
                      <div className="product-total-price">
                        <p className="m-0">
                          Total Price (Exclusive Tax): <b>{vendorTotalWithoutGst}</b>
                        </p>
                      </div>
                    </div> */}

                    <div className="col-12">
                      <div className="placeorder-btn">
                        <button
                          className="btn btn-primary laga do"
                          onClick={handleSubmitMaterial}
                          disabled={btnDisable}
                        >
                          Placeorder
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* Buy Edit Modal */}
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default VendorOrderDetail;
