import React, { useState, useEffect, Fragment, useCallback } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { Button } from "./Common/Elements/Button";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Inventory");

const AddInventory = () => {
  const [record, setRecord] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [prodId, setProdId] = useState(null);
  const [status, setStatus] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [remark, setRemark] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("userType");
    if (user !== "ADMIN") {
      window.location = "/dashboard";
    }
  }, []);

  useEffect(() => {
    fetchInventoryData();
    productData();
  }, []);

  const fetchInventoryData = useCallback(async () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    let {
      data: { status, data },
    } = await axios.post(baseUrl + "/frontapi/inventory-in-out", {}, config);
    if (status) {
      data = data.filter(
        (item) => item.type === "added" || item.type === "removed"
      );
      setRecord(data);
    } else {
      // toast.dismiss();
      // toast.error(message);
      return;
    }
  }, [record]);

  const productData = useCallback(() => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          const productData = resp.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setProdData(productData);
        }
      });
  }, []);

  const optionStyles = {
    control: (base, state) => ({
      ...base,

      background: "#5a5a5a",
      color: "red",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#5a5a5a" : "#5a5a5a",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#5a5a5a" : "#5a5a5a",
      },
      margin: "0",
      padding: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : "#3e3e3e",
        color: "#ffffff",
        padding: "2px 10px",
      };
    },
    input: (styles) => ({
      ...styles,
      color: "white", // Change the color of the search text
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change the color of the selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // Change the color of the placeholder text
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      backgroundColor: "#999999", // Change background color for "No options" message
      color: "white", // Change text color for "No options" message
      padding: "2px 10px",
      // Add any other styles you want
    }),
  };

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "quantity",
      text: "Quantity",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.quantity}</Fragment>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "status inventory-in-out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span
              style={{
                color: record.status.startsWith("in") ? "#66FF00" : "red",
              }}
            >
              {record.status}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.created_on).format("lll")} </Fragment>;
      },
    },
    {
      key: "remark",
      text: "Remark",
      className: "Quantity",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.remark} </Fragment>;
      },
    },
  ];

  const handleSelectChange = (selectedOption, event) => {
    const { name } = event;
    const newErrors = {};

    if (name === "prodId") {
      setProdId(selectedOption);
      if (
        selectedOption.value.trim() === "" ||
        selectedOption.value === undefined ||
        selectedOption.value === null
      ) {
        newErrors.productId = "Please select a product";
        setError(newErrors);
        return;
      }
      setError({});
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChangeAll = (e) => {
    let { name, value } = e.target;
    const newErrors = {};

    if (name === "status") {
      setStatus(value);
      if (value.trim() === "") {
        newErrors.status = "Status is required";
        setError(newErrors);
        return;
      }
      setError({});
    }

    if (name === "quantity") {
      setQuantity(value);
      if (Number(value.trim()) <= 0 || value.trim() === "") {
        newErrors.quantity = "Quantity must be a positive value";
        setError(newErrors);
        return;
      }
      setError({});
    }

    if (name === "remark") {
      setRemark(value);
      if (Number(value.trim() === "")) {
        newErrors.remark = "Please enter remark";
        setError(newErrors);
        return;
      }
      setError({});
    }
  };

  const submitAdd = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!prodId || prodId.value.trim() === "" || prodId.value === undefined) {
      newErrors.productId = "Please select a product";
    }

    if (status.trim() === "") {
      newErrors.status = "Status is required";
    }

    if (Number(quantity.trim()) <= 0 || quantity.trim() === "") {
      newErrors.quantity = "Quantity must be a positive value";
    }

    if (remark === "") {
      newErrors.remark = "Please enter remark";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
    } else {
      setLoading(true);

      let data = {
        productId: prodId.value,
        status,
        quantity,
        remark,
      };

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        };

        const { data: resp } = await axios.post(
          baseUrl + "/frontapi/add-inventory",
          data,
          config
        );

        if (resp.status) {
          fetchInventoryData();
          toast.dismiss();
          toast.success(resp.message);
          setQuantity("");
          setProdId(null);
          setStatus("");
          setRemark("");
        } else {
          toast.dismiss();
          toast.error(resp.message);
          fetchInventoryData();
        }
      } catch (error) {
        toast.dismiss();
        toast.error(error.message);
        fetchInventoryData();
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }

      setError({});
    }
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between mb-4">
              <h2>
                <b>Add Inventory</b>
              </h2>
            </div>
            <div className="invent-form">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Products</label>
                    <Select
                      className="basic-single custom-select"
                      classNamePrefix="select"
                      onChange={(selectedValue, event) =>
                        handleSelectChange(selectedValue, event)
                      }
                      value={prodId}
                      isSearchable={true}
                      styles={optionStyles}
                      // name="vendorId"
                      noOptionsMessage={() => "No product found"}
                      name={`prodId`}
                      placeholder="Select product"
                      isClearable={true}
                      // options={vendorDataList }

                      options={prodData.map((option) => ({
                        value: `${option.id}`,
                        label: option.name,
                      }))}
                    />
                    <p style={{ color: "red" }}> {error?.productId} </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={handleChangeAll}
                      value={status}
                    >
                      <option value={""}>Select</option>
                      <option value="in">In</option>
                      <option value="out">Out</option>
                    </select>
                    <p style={{ color: "red" }}> {error?.status} </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Quantity</label>
                    <input
                      type="number"
                      onKeyDown={handleValidation}
                      name="quantity"
                      placeholder="enter quantity"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={quantity}
                    />
                    <p style={{ color: "red" }}> {error?.quantity} </p>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Remark</label>
                    <input
                      type="text"
                      name="remark"
                      placeholder="enter remark"
                      className="form-control"
                      onChange={handleChangeAll}
                      value={remark}
                    />
                    <p style={{ color: "red" }}> {error?.remark} </p>
                  </div>
                </div>{" "}
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <Button onClick={submitAdd} loading={loading}>
                    Add{" "}
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};

export default AddInventory;
