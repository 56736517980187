import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "./BaseUrl";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";

const SubCategoryAdd = () => {
  const [subCatName, setSubCatName] = useState("");
  const [subShortName, setSubShortName] = useState("");
  const [catData, setCatData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameErr, setNameErr] = useState("");
  const [shortErr, setShortErr] = useState("");
  useEffect(() => {
    categoryData();
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      if (!value) {
        setSubCatName(value);
        setNameErr("Sub Category name is required");
        return false;
      }
      setNameErr("");
      setSubCatName(value);
    }
    if (name === "shortName") {
      if (!value) {
        setSubShortName(value);
        setShortErr("Short name is required");
        return false;
      }
      setShortErr("");
      setSubShortName(value);
    }
    if (name === "categoryId") {
      if (!value || value === "") {
        setCategoryId(value);
        toast.dismiss();
        toast.error("Please select category");
        return false;
      }
      setCategoryId(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!subCatName && !subShortName) {
      setNameErr("Sub Category name is required");
      setShortErr("Short name is required");
      return false;
    }

    if (!categoryId || categoryId === "") {
      toast.dismiss();
      toast.error("Please select category");
      return false;
    }

    if (!subCatName) {
      setNameErr("Sub Category name is required");
      return false;
    }

    if (!subShortName) {
      setShortErr("Short name is required");
      return false;
    }

    let data = {
      subCatName: subCatName,
      subShortName: subShortName,
      categoryId: categoryId,
    };
    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/add-sub-category", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location.href = "/sub-categories";
          }, 2000);
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setCatData(
            resp.data.sort(function (a, b) {
              var textA = a.name;
              var textB = b.name;
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
          );
        }
      });
  };

  return (
    <>
      <ContentLayout
        title="ADD Sub Category"
        className="add-product-outer customer-detail-outer"
      >
        <form>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Category</label>
                <select
                  className="form-control"
                  onChange={handleChange}
                  name="categoryId"
                >
                  <option value="">Select Category</option>
                  {catData.map((item, i) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control"
                  onChange={handleChange}
                  value={subCatName}
                />
                <p style={{ color: "red" }}>{nameErr}</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Short Name</label>
                <input
                  type="text"
                  name="shortName"
                  placeholder="Short Name"
                  maxLength="4"
                  className="form-control"
                  onChange={handleChange}
                  value={subShortName}
                />
                <p style={{ color: "red" }}>{shortErr}</p>
              </div>
            </div>
          </div>
        </form>
        <ButtonGroup
          onClick={handleSubmit}
          loading={loading}
          link="/sub-categories"
        />
      </ContentLayout>
    </>
  );
};

export default SubCategoryAdd;
