import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "./BaseUrl";
import $ from "jquery";

function Header({ unReadCounts = 0 }) {
  const [name, setName] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [isRead, setIsRead] = useState("");
  const [unReadCount, setUnReadCount] = useState();
  const [togglerEffect, setTogglerEffect] = useState(false);

  const collapseIcon = () => {
    if (!togglerEffect) {
      $(".main-content").addClass("active");
      $(".page-topbar").addClass("active");
      setTogglerEffect(!togglerEffect);
    } else {
      $(".main-content").removeClass("active");
      $(".page-topbar").removeClass("active");
      setTogglerEffect(!togglerEffect);
    }

    // $(document).ready(function () {
    //   $(".navbar-toggler").click(function () {
    //     $(".main-content").toggleClass("active");
    //   });
    // });
  };

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      window.location = "/";
    } else {
      if (localStorage.getItem("userType") !== "ADMIN") {
        // logout functionality
        const config = {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        };
        const data = {
          ip: localStorage.getItem("ip"),
        };
        axios
          .post(baseUrl + "/frontapi/getLoginData", data, config)
          .then((res) => {
            var resp = res.data;
            if (resp.status === true) {
              if (resp.data[0].is_logged === "0") {
                logout();
                return false;
              }
            }
          });
      }
    }

    getRights();
    getNotificationData();
  }, [unReadCounts]);

  const getRights = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios.post(baseUrl + "/frontapi/get-rightes", {}, config).then((res) => {
      var resp = res.data;

      setName(resp.data[0].name);
    });
  };
  
  const getNotificationData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      userType: localStorage.getItem("userType"),
      email: localStorage.getItem("email"),
      limit: "yes",
    };

    axios
      .post(baseUrl + "/frontapi/getNotifications", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setNotificationData(resp.data.result);
          setUnReadCount(resp.data.resultCount[0]["COUNT(id)"]);
          unRead(resp.data.result);
        }
      });
  };

  const logout = (e) => {
    // e.preventDefault();
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    const data = {
      ip: localStorage.getItem("ip"),
    };
    axios
      .post(baseUrl + "/frontapi/changeLoginData", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  const notificationHtml = () => {
    let html = [];

    if (notificationData.length > 0) {
      for (let i = 0; i < notificationData.length; i++) {
        const element = notificationData[i];
        html.push(
          <li className="dropdown-item" href="#!">
            {element.message}
          </li>
        );
      }
    }
    return html;
  };

  const runNotificationApi = () => {
    const data = {
      userType: localStorage.getItem("userType"),
      email: localStorage.getItem("email"),
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/updateNotification", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          getNotificationData();
        }
      });
  };

  const filterNotification = (element) => {
    return element.is_read == "0";
  };

  const unRead = (data) => {
    if (data.length > 0) {
      const check = data.filter(filterNotification);

      if (check.length > 0) {
        setIsRead("bg-danger");
      } else {
        setIsRead("");
      }
    }
  };

  return (
    <header id="page-topbar" className="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={collapseIcon}
            >
              <span className="navbar-toggler-icon">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <div className="dropdown d-md-none topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-search fs-22" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown header-item topbar-user notification-outer">
              <button
                type="button"
                className="btn btn-primary position-relative"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={runNotificationApi}
              >
                <i className="fa fa-bell" aria-hidden="true"></i>

                {isRead !== "" ? (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {unReadCount}
                  </span>
                ) : (
                  ""
                )}
              </button>

              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="notify-title">Notification</h6>
                {notificationHtml()}
                {notificationData.length > 0 && (
                  <a className="dropdown-item text-end" href="/notifications">
                    <span className="align-middle" data-key="t-logout">
                      View All
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className="dropdown ms-1 topbar-head-dropdown header-item">
              <div className="dropdown-menu dropdown-menu-end">
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="sp"
                  title="Spanish"
                >
                  <img
                    src="assets/images/flags/spain.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">Española</span>
                </a>
                {/* item*/}
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="gr"
                  title="German"
                >
                  <img
                    src="assets/images/flags/germany.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">Deutsche</span>
                </a>
                {/* item*/}
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="it"
                  title="Italian"
                >
                  <img
                    src="assets/images/flags/italy.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">Italiana</span>
                </a>
                {/* item*/}
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="ru"
                  title="Russian"
                >
                  <img
                    src="assets/images/flags/russia.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">русский</span>
                </a>
                {/* item*/}
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="ch"
                  title="Chinese"
                >
                  <img
                    src="assets/images/flags/china.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">中国人</span>
                </a>
                {/* item*/}
                <a
                  href="#!"
                  className="dropdown-item notify-item language"
                  data-lang="fr"
                  title="French"
                >
                  <img
                    src="assets/images/flags/french.svg"
                    alt="userImage"
                    className="me-2 rounded"
                    height={18}
                  />
                  <span className="align-middle">français</span>
                </a>
              </div>
            </div>
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="../../assets/images/1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      {/* Anna Adame */} {name}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {name}</h6>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={(e) => logout(e)}
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
