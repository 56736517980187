import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatDateTime } from "../utils/dateTime";

const baseUrl = myConstList.baseUrl;
function PendingOrderDetails(props) {
  const [orderId, setOrderId] = useState("");
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [catManager, setCatManager] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [salsePersonName, setSalsePersonName] = useState("");
  const [customerDataById, setCustomerDataById] = useState([]);
  const [vendorListData, setVendorListData] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [sData, setSData] = useState([]);
  const [specifications, setSpecifications] = useState("");
  const [remainingTime, setRemainingTime] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");

  //delivery status
  const [error, setError] = useState({});
  const [quantities, setQuantities] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [step, setStep] = useState("");
  const [totalOrderQuantity, setTotalOrderQuantity] = useState("");

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    orderData();
    getOrderDetails();
    setOrderId(Params);
    getCustomerDetailsAdmin();
    getCustomerShippingAddress();
    // getVendorsList();
  }, []);

  const getVendorsList = (catId) => {
    const data = {
      catId: catId,
    };

    axios
      .post(baseUrl + "/frontapi/opration-executive-data", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setVendorListData(resp.data);
        }
      });
  };

  const vendorListHtml = () => {
    const html = [];
    html.push(<option value={"self"}>Self</option>);
    vendorListData.map((item) => {
      return html.push(<option value={item.id}>{item.name}</option>);
    });
    return html;
  };

  const handleChangeDate = (e) => {
    let { value } = e.target;
    setEstimatedDate(value);
  };

  const onChangeVendorList = (e) => {
    let { value } = e.target;
    setVendorId(value);
  };

  const getCatData = (id) => {
    const data = {
      catId: id,
    };

    axios.post(baseUrl + "/frontapi/order-single-product", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCatManager(resp.data.catManData[0].name);
        setCategoryName(resp.data.catData[0].short_name);
        return false;
      }
    });
  };

  const orderData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/order-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setDateOfOrder(resp.data[0].createdAt);
        showTimerCounterHtml(resp.data[0].createdAt);
        setSalsePersonName(resp.data[0].salsePersonName);

        // getVendorsList(resp.data[0].assigned_to);
      }
    });
  };

  let orderStatusUpdate = async () => {
    if (!vendorId) {
      toast.dismiss();
      toast.error("Please select assigned to person");
      return false;
    }

    if (!deliveryStatus) {
      toast.dismiss();
      toast.error("Please select delivery status");
      return false;
    }

    if (deliveryStatus === "yes" && step === "" && step <= 0) {
      toast.dismiss();
      toast.error("Please add step for partials delivery");
      return false;
    }

    if ((deliveryStatus === "yes" && Number(step) <= 1) || Number(step) > 15) {
      toast.dismiss();
      toast.error("Quantity must be greater than 1 or max 15");
      return false;
    }

    const totalQuantity = quantities.reduce((acc, qty) => Number(acc + qty), 0);
    if (deliveryStatus === "yes" && totalQuantity !== totalOrderQuantity) {
      setIsValid(false);
      return;
    }

    const checkAnyZero = quantities.some((quantity) => quantity <= 0);
    if (deliveryStatus === "yes" && checkAnyZero) {
      setIsValid(false);
      return;
    }

    if (!estimatedDate || estimatedDate === 0) {
      toast.dismiss();
      toast.error("Please enter estimated delivery date");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    let requestData = {
      orderId: Params,
      status: 1,
      vendorId: vendorId,
      estimatedDate: estimatedDate,
      deliveryStatus,
      steps: step,
      subOrders: quantities,
    };

    await axios
      .post(baseUrl + "/frontapi/order-status-update", requestData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/inprocess-orders";
          }, 2000);
        }
      });
  };

  const getOrderDetails = () => {
    const data = {
      id: Params,
    };
    axios
      .post(baseUrl + "/frontapi/get-orderdetails-admin", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTableData(resp.data);
          setTotalOrderQuantity(Number(resp.data[0].quantity));
          setCustomerName(resp.data[0].customerName);
          getCatData(resp.data[0].category_id);
          getVendorsList(resp.data[0].category_id);
          getCustomerShippingAddress(
            resp.data[0].customer_id,
            resp.data[0].shipping_id
          );
          setSpecifications(resp.data[0].specifications);
        }
      });
  };

  const getCustomerDetailsAdmin = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/get-customer-admin", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCustomerDataById(resp.data);
      }
    });
  };

  const getCustomerShippingAddress = (id, addressId) => {
    const data = {
      id: id,
      addressId: addressId,
    };
    axios.post(baseUrl + "/frontapi/customer-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setSData(resp.data.sqlRun2);
      }
    });
  };

  const totalSumHtml = () => {
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.subTotal);
    });
    return parseFloat(sum).toFixed(2);
  };

  const tableHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.productName}</th>
          <th>
            {value.avaliable_qty} {value.units}
          </th>
          <th>
            {value.quantity} {value.units}
          </th>
          <th>{value.price} INR</th>
          <th>{value.gst}%</th>
          <th>{parseFloat(value.gstAmount).toFixed(2)} INR</th>
          <th>{parseFloat(value.subTotal).toFixed(2)} INR</th>
          <th>
            {value.isCustomize === "Y" ? (
              <>
                {value.customize_image == null ||
                value.customize_image === "null" ||
                value.customize_image === "NULL" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl +
                      "/static/customizeImage/" +
                      value.customize_image
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    {!value.customize_image.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.customize_image
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
        </tr>
      );
    });
    return html;
  };

  const specificationsHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.specifications}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const paymentModeHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.payment_mode}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const customerDetailsHtml = () => {
    const html = [];
    customerDataById.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav" key={value.name}>
            <li>
              Customer Name : <b>{value.name}</b>
            </li>
            <li>
              Company Name : <b>{value.companyName}</b>
            </li>
            <li>
              Email : <b>{value.email}</b>
            </li>
            <li>
              Pan Number : <b>{value.panNumber}</b>
            </li>
            <li>
              Phone Number : <b>{value.phoneNumber}</b>
            </li>
            <li>
              Billing Address : <b>{value.billingAddress}</b>
            </li>
            <li>
              Website : <b>{value.website}</b>
            </li>
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Shipping Address :</h5>
            {addressHtml()}
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Contact Person :</h5>
            {attributeCheck()}
          </ul>
        </div>
      );
    });
    return html;
  };
  const addressHtml = () => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-4 col-12">
              <li key={index}>
                Address: <b> {element.address}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Country: <b> {element.country}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                State: <b> {element.state}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                City: <b> {element.city}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Phone No: <b> {element.phone_no}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Pincode: <b> {element.zipcode}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };
  const attributeCheck = () => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-3 col-12">
              <li key={index}>
                Email: <b> {element.aEmail}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Name: <b> {element.aName}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Phone No: <b> {element.aPhoneNo}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Designation: <b> {element.selected}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };

  const showTimerCounterHtml = (startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();

    const intervalStart = setInterval(async () => {
      var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
      const secondsCurrent = moment(currentDate, "YYYY-MM-DD HH:mm:ss").unix();

      var remainingSeconds = secondsCurrent - secondsStart;

      const duration = moment.duration(remainingSeconds, "seconds");
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const secondsRemaining = duration.seconds();

      setRemainingTime(
        `${days} days, ${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
      );
    }, 1000);
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "." ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  function isDecimalNumber(input) {
    const decimalRegex = /^\d*$/;
    return input.match(decimalRegex);
  }

  const handlePartialsDelivery = (e) => {
    const { value, name } = e.target;
    const newErrors = {};
    if (name === "deliveryStatus") {
      setDeliveryStatus(value);
      if (value.trim() === "") {
        newErrors.deliveryStatusError = "Please select delivery status";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        setStep(null);
        return;
      }
      if (value === "no") {
        setIsValid(true);
        setQuantities([]);
        setStep(null);
      }
      setError({});
    }

    if (name === "step") {
      setStep(value);
      if (Number(value.trim()) <= 1 || value.trim() === "") {
        newErrors.stepError =
          "Quantity must be a positive value or greater than 1";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }

      let error = isDecimalNumber(value);

      if (!error) {
        newErrors.stepError = "Steps should be in number";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }

      if (Number(value.trim()) > 15) {
        newErrors.stepError = "Quantity must be greater than 1 or max 15";
        setError(newErrors);
        setIsValid(true);
        setQuantities([]);
        return;
      }
      setQuantities(Array(Number(value)).fill(0));
      setError({});
    }
  };

  const handleQuantityChange = (event, stepIndex) => {
    const newQuantities = [...quantities];
    const newValue = parseFloat(event.target.value);
    newQuantities[stepIndex] = newValue;

    // Validate input
    if (newValue <= 0 || isNaN(newValue)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }

    setQuantities(newQuantities);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>PENDING ORDER DETAIL</b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id: <b>{orderId}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-inner">
                      <p>
                        Date of Order: <b>{formatDateTime(dateOfOrder)}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-inner">
                      <p>
                        Customer Name:{" "}
                        <b>
                          {customerName == null
                            ? salsePersonName
                            : customerName}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-inner">
                      <p>
                        Time Taken: <b>{remainingTime}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Order Details</h3>
                <table className="w-100 table-responsive">
                  <tr>
                    <th>S.No</th>
                    <th>Products Name</th>
                    <th>Product Avaliable Quantity</th>
                    <th>Order Quantity</th>
                    <th>Price</th>
                    <th>Gst</th>
                    <th>Gst Amount</th>
                    <th>Total Amount</th>
                    <th>Customized Image</th>
                    {/* <th>Action</th> */}
                  </tr>
                  {tableHtml()}
                  <tr align="right">
                    <td colSpan="9">
                      <b>TOTAL PRICE INR {totalSumHtml()}</b>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Customer Details</h3>
                {customerDetailsHtml()}
              </div>

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  {specifications ? (
                    <div className="col-md-6 col-12">
                      <div className="detail-last-inner detail-inner">
                        <h3>Specification</h3>
                        {specificationsHtml()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h3>Payment Mode</h3>
                      {paymentModeHtml()}
                    </div>
                  </div>
                </div>
              </div>

              {/* {specifications ? (
                <div className="order-detail-outer-middle pending-list-outer">
                  <h3>Payment Mode</h3>
                  {paymentModeHtml()}
                </div>
              ) : (
                ""
              )} */}

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Sales Executive: </small>
                        <b>{salsePersonName}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category Manager: </small>
                        <b>
                          {catManager === null || !catManager
                            ? "N/A"
                            : catManager}
                        </b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Total Price: </small>
                        <b>INR {totalSumHtml()}-/-</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category: </small>
                        <b>
                          {categoryName == null || categoryName === ""
                            ? "N/A"
                            : categoryName}
                        </b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="assigned-outer detail-last-inner detail-inner text-start">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="product-price">
                            <label>Assigned:</label>
                            <select
                              className="form-control"
                              name="vendorId"
                              value={vendorId}
                              onChange={onChangeVendorList}
                            >
                              <option value={""}>Select</option>
                              {vendorListHtml()}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="product-price">
                            <label>Partials delivery Status:</label>
                            <select
                              className="form-control"
                              name="deliveryStatus"
                              value={deliveryStatus}
                              onChange={handlePartialsDelivery}
                            >
                              <option value={""}>Select</option>
                              <option value={"yes"}>Yes</option>
                              <option value={"no"}>No</option>
                            </select>
                            <p style={{ color: "red" }}>
                              {" "}
                              {error?.deliveryStatusError}{" "}
                            </p>
                          </div>
                        </div>

                        {deliveryStatus === "yes" && (
                          <div className="col-md-4 col-12">
                            <div className="product-price">
                              <label>
                                Total Ordered Quantity(Steps:) :{" "}
                                {totalOrderQuantity}
                              </label>
                              <input
                                placeholder="Steps"
                                type="number"
                                onKeyDown={handleValidationDot}
                                className="form-control"
                                name="step"
                                onChange={handlePartialsDelivery}
                                value={step}
                              />
                              <p style={{ color: "red" }}>
                                {" "}
                                {error?.stepError}{" "}
                              </p>
                            </div>
                          </div>
                        )}

                        {deliveryStatus === "yes" &&
                          quantities.length > 0 &&
                          quantities.map((quantity, index) => (
                            <div className="col-md-4 col-12">
                              <div className="product-price" key={index}>
                                <label htmlFor={`quantity-${index}`}>
                                  Add delivery quantity for {index + 1} order:
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  onKeyDown={handleValidation}
                                  id={`quantity-${index}`}
                                  value={quantity}
                                  onChange={(event) =>
                                    handleQuantityChange(event, index)
                                  }
                                />
                              </div>
                            </div>
                          ))}

                        {!isValid && (
                          <div
                            style={{ color: "red", textAlign: "left" }}
                            className="error-message col-md-12 col-12"
                          >
                            Please ensure that quantities are valid, total of
                            quantities must be equal to ordered quantity(
                            {totalOrderQuantity}).
                          </div>
                        )}
                        <div className="col-md-4 col-12">
                          <div className="product-price">
                            <label>Expected Delivery Date:</label>
                            <input
                              type="date"
                              name="estimatedDate"
                              placeholder=" Estimated Date"
                              className="form-control"
                              onChange={handleChangeDate}
                              value={estimatedDate}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="submit-btn-outer">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={orderStatusUpdate}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}
export default PendingOrderDetails;
