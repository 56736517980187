import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { baseUrl } from "./BaseUrl";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { IconContext } from "react-icons";
const config = reactTableConfig("Training");

const TrainingManager = () => {
  const [record, setRecord] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    TrainingData();
  }, []);

  const columns = [
    {
      key: "#",
      text: "Sr No.",
      className: "#",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Training ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "categoryId",
      text: "Category",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        const data = JSON.parse(record.categoryId);
        const labels = [data].map((item) => item.label);
        let result = labels.join(",");
        return <Fragment>{result}</Fragment>;
      },
    },
    {
      key: "product_name",
      text: "Product",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        const data = JSON.parse(record.productId);
        const labels = data.map((item) => item.label);
        let result = labels.join(",");
        return <Fragment>{result}</Fragment>;
      },
    },
    {
      key: "link",
      text: "Link",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.link ? `${record.link}` : `N/A`}</Fragment>;
      },
    },
    {
      key: "description",
      text: "Description",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {record.description} </Fragment>;
      },
    },
    {
      key: "document",
      text: "Document",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        let doc = JSON.parse(record.document);
        return (
          <Fragment>
            {doc.length > 0 ? (
              <button
                type="button"
                onClick={() => {
                  setDocumentData(JSON.parse(record.document));
                  setShowModal(true);
                }}
                className="btn btn-primary"
              >
                View
              </button>
            ) : (
              "N/A"
            )}
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {moment(record.created_at).format("lll")} </Fragment>;
      },
    },

    // {
    //   key: "Detail",
    //   text: "View Detail",
    //   className: "detail",
    //   align: "left",
    //   // sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <IconContext.Provider
    //           value={{
    //             color: "white",
    //             size: "2em",
    //             style: { padding: "3px" },
    //           }}
    //         >
    //           <MdEdit
    //             onClick={() =>
    //               (window.location.href = "/edit-training/" + btoa(record.id))
    //             }
    //             className="btn"
    //             style={{ background: "#00bf66" }}
    //           />
    //         </IconContext.Provider>

    //         {record.is_deleted == "0" ? (
    //           <button
    //             className="btn btn-success btn-sm img-fluid"
    //             onClick={() => {
    //               Conn(record.id, record.is_deleted);
    //             }}
    //           >
    //             Enabled
    //           </button>
    //         ) : (
    //           <button
    //             className="btn btn-danger btn-sm img-fluid"
    //             onClick={() => {
    //               Conn(record.id, record.is_deleted);
    //             }}
    //           >
    //             Disabled
    //           </button>
    //         )}

    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const TrainingData = () => {
    const data = {
      userType: localStorage.getItem("userType"),
    };

    axios.post(baseUrl + "/frontapi/training-data-admin", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setRecord(resp.data);
      }
    });
  };

  const closeShowModel = () => {
    setDocumentData([]);
    setShowModal(false);
  };

  const documentHtml = () => {
    const html = [];
    documentData.map((value, i) => {
      return html.push(
        <div className="col-md-6 col-12">
          <div className="product-price">
            <a
              href={baseUrl + "/static/training/" + value.doc}
              target="_blank"
              rel="noreferrer"
            >
              <b>{value.doc}</b>
            </a>
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <ContentLayout
        title="Training List"
        className="product-list-outer customer-list-outer"
        buttonTitle="Add Training"
        buttonUrl="/training-add"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />

        <Modal
          className="modal-update"
          show={showModal}
          onHide={closeShowModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              {" "}
              Document{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">{documentHtml()}</div>
          </Modal.Body>
        </Modal>
      </ContentLayout>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
};

export default TrainingManager;
