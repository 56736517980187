import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as myConstList from "./BaseUrl";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Products");

function CatManProducts() {
  const [record, setRecord] = useState([]);
  const [catData, setCatData] = useState([]);
  const [category, setCategory] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    productData();
    categoryData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "subShortName",
      text: "Product ID",
      className: "id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.id < 10
              ? record.subShortName + "000" + record.id
              : record.subShortName + "00" + record.id}
          </Fragment>
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "avaliable_qty",
      text: "Available Quantity",
      className: "qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.avaliable_qty} {record.units}
          </Fragment>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "price",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.price} INR</Fragment>;
      },
    },
    {
      key: "Detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/product-details-CatMan/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a
              className="btn-sm img-fluid"
              href={"/product-add-CatMan/" + btoa(record.id)}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
            </a>

            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href =
                  "/product-edit-CatMan/" + btoa(record.id);
              }}
            >
              Edit
            </button>
            {record.isDeleted === "1" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.isDeleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.isDeleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }

    let params = {
      id: id,
      status: temp,
    };
    axios.post(baseUrl + "/frontapi/product-delete", params).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return false;
      }
      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        productData();
      }
    });
  };

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    var CData = catData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const productData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
          setFilterData(resp.data);
        }
      });
  };

  const changeCategory = (e) => {
    let value = e.target.value;
    setCategory(value);

    if (value === "") {
      setFilterData(record);
      return false;
    } else {
      const filteredData = record.filter((item) => {
        return item.category_id == value;
      });
      setFilterData(filteredData);
    }
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>Product List</b>
              </h2>
              <div className="add-product-btn text-center">
                <h4>Filter By Category</h4>
                <select
                  className="form-control"
                  value={category}
                  onChange={changeCategory}
                >
                  <option value={""}>Select Category</option>
                  {categoryHtml()}
                </select>
              </div>{" "}
              <div className="add-product-btn text-center">
                <Link to={"/product-add-CatMan"} className="btn btn-primary">
                  Add Product
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer">
                  <ReactDatatable
                    config={config}
                    records={filterData}
                    columns={columns}
                    onPageChange={config}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default CatManProducts;
