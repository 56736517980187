import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import { Modal } from "react-bootstrap";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Category Manager");

function CategoryManager(props) {
  const [record, setRecord] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const [catManName, setCatManName] = useState("");

  useEffect(() => {
    CategoryManagerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshed]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "cust_id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Category Manager Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_no",
      text: "Phone No.",
      className: "phoneno",
      align: "left",
      sortable: true,
    },
    {
      key: "cName",
      text: "Category",
      className: "detail",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                openPopup(record.id);
              }}
            >
              View
            </button>
          </Fragment>
        );
      },
    },
    {
      key: "view",
      text: "Action",
      className: "detail",
      // align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid" 
              onClick={() => {
                window.location.href =
                  "/category-manager-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>

            {record.is_deleted == "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const CategoryManagerData = () => {
    axios
      .post(baseUrl + "/frontapi/category-manager-data", CategoryManagerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
        }
      });
  };
  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }
    let params = {
      id: id,
      status: temp,
    };
    axios
      .post(baseUrl + "/frontapi/category-manager-delete", params)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          CategoryManagerData();
        }
      });
  };

  const closeShowEditModel = () => {
    setShowVendorModal(false);
  };

  const openPopup = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };
    let params = {
      id: id,
      status: "Admin",
    };
    axios
      .post(baseUrl + "/frontapi/myCategories", params, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.dismiss();
          setShowVendorModal(true);
          setCategoryData(resp.data);
          setCatManName(resp.data[0].catManName);
          // categoryHtml(resp.data);
        }
      });
  };

  const categoryHtml = () => {
    let html = [];

    if (categoryData.length > 0) {
      for (let i = 0; i < categoryData.length; i++) {
        const element = categoryData[i];

        html.push(
          <div className="col-6" style={{ color: "#FFFFFF" }}>
            <div className="product-total-price">
              <p className="m-0">
                <b> {element.name} </b>
              </p>
            </div>
          </div>
        );
      }
      return html;
    }
  };

  return (
    <>
      <ContentLayout
        title="Category Manager List"
        buttonTitle="Add Category Manager"
        buttonUrl="/add-category-manager"
        className="product-list-outer customer-list-outer"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />
        <Modal
          className="modal-update"
          show={showVendorModal}
          onHide={closeShowEditModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              Category for {catManName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">{categoryHtml()}</div>
          </Modal.Body>
        </Modal>
      </ContentLayout>
    </>
  );
}
export default CategoryManager;
