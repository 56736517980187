import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import $ from "jquery";
import { Country, State } from "country-state-city";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

function CustomerAdd(props) {
  const navigate = useNavigate();

  const [customerName, setCutomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [website, setWebsite] = useState("");
  const [gst, setGst] = useState("");
  const [panNum, setPanNum] = useState("");
  const [leadType, setLeadType] = useState("");

  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincode, setPincode] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [userType, setUserType] = useState(false);
  const [designationData, setDesignationData] = useState([]);

  const [zoneData, setZoneData] = useState([]);
  const [zone, setZone] = useState("");

  const [btnDisable, setBtnDisable] = useState(false);

  // Errors for top container //
  const [custNameError, setCustNameError] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  // Errors for billing address //
  const [addressError, setAddressError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [phone_noError, setPhone_noError] = useState("");
  const [leadTypeError, setLeadTypeError] = useState("");
  const [zoneError, setZoneError] = useState("");

  const [loading, setLoading] = useState(false);
  const [formValuesAddress, setFormValuesAddress] = useState([
    {
      address: "",
      country: "",
      phone_no: "",
      city: "",
      zipcode: "",
      state: "",
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      selected: "",
    },
  ]);
  const [formValuesAddressError, setFormValuesAddressError] = useState([
    {
      address: "",
      country: "",
      phone_no: "",
      city: "",
      zipcode: "",
      state: "",
      aName: "",
      aEmail: "",
      aPhoneNo: "",
      designation: "",
      selected: "",
    },
  ]);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  useEffect(() => {
    getDesignationData();

    // if(localStorage.getItem("userType") === "SalesManager"){

    // }
    getZoneData();
  }, []);

  const getZoneData = () => {
    axios.post(baseUrl + "/frontapi/zone-data-deleted").then((res) => {
      const resp = res.data;
      setZoneData(resp.data);

      if (localStorage.getItem("userType") === "SalesManager") {
        zoneByManager(resp.data);
      }
    });
  };

  const ZoneHtml = () => {
    const zonehtml = [];

    zoneData.map((value, i) => {
      return zonehtml.push(<option value={value.id}>{value.zone}</option>);
    });
    return zonehtml;
  };

  const zoneByManager = (allData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/getZonesByManagerAuth", {}, config)
      .then((res) => {
        var resp = res.data;

        const convertedZone = JSON.parse(resp.data[0].zone);

        // Create a set of zones from the second array
        const secondArrayZones = new Set(
          convertedZone.map((item) => parseInt(item.zone, 10))
        );

        // Filter the first array to include only those objects whose ids are in the set
        const commonArray = allData.filter((item) =>
          secondArrayZones.has(item.id)
        );

        setZoneData(commonArray);
      });
  };

  const getDesignationData = () => {
    axios.post(baseUrl + "/frontapi/designation-data-deleted").then((res) => {
      const resp = res.data;
      setDesignationData(resp.data);
    });
  };

  const designationHtml = () => {
    const designationhtml = [];

    designationData.map((value, i) => {
      return designationhtml.push(
        <option value={value.designation}>{value.designation}</option>
      );
    });
    return designationhtml;
  };

  const handleChangeTop = (event) => {
    let { name, value } = event.target;

    $("input[type=number]").on("mousewheel", function (e) {
      $(event.target).blur();
    });
    if (name === "customerName") {
      if (!value) {
        setCutomerName(value);
        setCustNameError("Customer name is required");
        return false;
      }
      setCutomerName(value);
      setCustNameError("");
    }
    if (name === "companyName") {
      if (!value) {
        setCompanyName(value);
        setCompNameError("Company name is required");
        return false;
      }
      setCompanyName(value);
      setCompNameError("");
    }

    if (name === "email") {
      // if (!value) {
      //   setEmail(value);
      //   setEmailError("Email is required");
      //   return false;
      // }

      if (value) {
        if (!value.match(emailReg)) {
          setEmail(value);
          setEmailError("Please enter valid email");
          return false;
        }
        setEmail(value);
        setEmailError("");
      }
      setEmail(value);
      setEmailError("");
    }
    if (name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
      if (!value) {
        setPassword(value);
        setPasswordError("Password is required");
        return false;
      }
      if (!value.match(regex)) {
        setPassword(value);
        setPasswordError(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }
      setPassword(value);
      setPasswordError("");
    }
    if (name === "pNumber") {
      if (!value) {
        setPhoneNum(value);
        setPhoneError("Phone No. is required");
        return false;
      }

      if (!/^[6-9]/.test(value)) {
        setPhoneNum(value);
        setPhoneError("Please enter valid phone number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setPhoneNum(value);
        setPhoneError("Phone No. should be of 10 digits");
        return false;
      }

      setPhoneNum(value);
      setPhoneError("");
    }
    if (name === "website") {
      // if (!value) {
      //   setWebsite(value);
      //   setWebsiteError("Website is required");
      //   return false;
      // }
      setWebsite(value);
      setWebsiteError("");
    }
    if (name === "gst") {
      let newValue = value.toUpperCase();
      if (!newValue) {
        setGst(newValue);
        setGstError("GST number is required");
        return false;
      } else {
        setGst(newValue);
        setGstError("");
      }

      if (newValue === "URP") {
        setGst(newValue);
        setPanNum("URP");
        setPanError("");
        setGstError("");
        return false;
      }

      if (
        !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(newValue)
      ) {
        setGst(newValue);
        setPanNum("");
        setGstError("Enter valid GST number");
        return false;
      } else {
        var modifiedText = newValue.substring(2, newValue.length - 3);
        setPanNum(modifiedText);
        setPanError("");
      }
      setGst(newValue);
      setGstError("");
    }
    if (name === "panNum") {
      let newValue = value.toUpperCase();
      if (!newValue) {
        setPanNum(newValue);
        setPanError("Pan number is required");
        return false;
      }

      if (newValue === "URP") {
        setPanNum("URP");
        setPanError("");
        return false;
      }

      var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
      if (!reggg.test(newValue)) {
        setPanNum(newValue);
        setPanError("Please enter correct pan card");
        return false;
      }

      setPanNum(newValue);
      setPanError("");
    }

    if (name === "leadType") {
      setLeadType(value);

      if (!value) {
        setLeadTypeError("Please select potential customer");
        return false;
      }
      setLeadTypeError("");
    }

    if (name === "zone") {
      setZone(value);

      if (!value) {
        setZoneError("Please select zone");
        return false;
      }
      setZoneError("");
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "." ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChangeBillingAddress = (event) => {
    let { name, value } = event.target;
    if (name === "country") {
      if (!value || value === "Select Country") {
        setCountry(value);
        setCountryError("Billing country is required");
        return false;
      }
      setStateValue("");
      setCountry(value);
      setCountryError("");
      return false;
    }

    if (name === "state") {
      if (!value || value === "Select State") {
        setStateValue(value);
        setStateError("Billing state is required");
        return false;
      }
      setStateValue(value);
      setStateError("");
      return false;
    }

    if (name === "city") {
      if (!value) {
        setCity(value);
        setCityError("Billing city is required");
        return false;
      }
      setCity(value);
      setCityError("");
      return false;
    }

    if (name === "billingAddress") {
      if (!value) {
        setBillingAddress(value);
        setAddressError("Billing address is required");
        return false;
      }
      setBillingAddress(value);
      setAddressError("");
      return false;
    }

    if (name === "zipcode") {
      let valueNew = value.trim();
      let regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
      if (!valueNew) {
        setPincode(valueNew);
        setPincodeError("Billing pincode is required");
        return false;
      }

      if (!/^[1-9]\d*$/.test(valueNew)) {
        setPincode(valueNew);
        setPincodeError("Please enter valid input");
        return false;
      }

      if (!valueNew.match(regex)) {
        setPincode(valueNew);
        setPincodeError("Please enter valid input");
        return false;
      }
      setPincode(valueNew);
      setPincodeError("");
      return false;
    }

    if (name === "phone_no") {
      if (!value) {
        setPhone_no(value);
        setPhone_noError("Billing phone no. is required");
        return false;
      }

      if (!/^[6-9]/.test(value)) {
        setPhone_no(value);
        setPhone_noError("Please enter valid phone number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setPhone_no(value);
        setPhone_noError("Phone No. should be of 10 digits");
        return false;
      }

      setPhone_no(value);
      setPhone_noError("");
      return false;
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !selectedOptions &&
      !customerName &&
      // !companyName &&
      // !email &&
      !password &&
      !phoneNum &&
      // !website &&
      // !gst &&
      // !panNum &&
      (!country || country === "Select Country") &&
      !billingAddress &&
      (!stateValue || stateValue === "Select State") &&
      !city &&
      !pincode &&
      !phone_no
    ) {
      setCustNameError("Customer name is required");
      setPasswordError("Password is required");
      setPhoneError("Phone No. is required");
      setCountryError("Billing country is required");
      setAddressError("Billing address is required");
      setStateError("Billing state is required");
      setCityError("Billing city is required");
      setPincodeError("Billing pincode is required");
      setPhone_noError("Billing phone no. is required");
    }
    // all together wali validation lagegi //
    // top

    if (!selectedOptions) {
      toast.dismiss();
      toast.error("Customer type is required");
      return false;
    }

    let error = checkSpace("customer", customerName);

    if (!customerName) {
      setCustNameError("Customer name is required");
      return false;
    }

    if (error) {
      setCustNameError(error);
      return false;
    }

    if (userType === true) {
      if (!companyName) {
        setCompNameError("Company name is required");
        return false;
      }

      error = checkSpace("company name", companyName);
      if (error) {
        setCompNameError(error);
        return false;
      }
    }

    if (email) {
      if (!email.match(emailReg)) {
        setEmailError("Please enter valid email");
        return false;
      }
    }
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
    if (!password) {
      setPasswordError("Password is required");
      return false;
    }
    if (!password.match(regex)) {
      setPasswordError(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    error = checkSpace("password", password);

    if (error) {
      setPasswordError(error);
      return false;
    }

    if (!phoneNum) {
      setPhoneError("Phone No. is required");
      return false;
    }
    if (phoneNum.length > 10) {
      setPhoneError("Phone No. should be of 10 digits");
      return false;
    }
    if (phoneNum.length < 10) {
      setPhoneError("Phone No. should be of 10 digits");
      return false;
    }

    if (userType === true) {
      if (!gst) {
        setGstError("GST is required");
        return false;
      }

      if (gst !== "URP") {
        if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
          setGstError("Enter valid GST number");
          return false;
        }
      }
    }

    if (userType === true) {
      if (!panNum) {
        setPanError("Pan number is required");
        return false;
      }

      if (panNum !== "URP") {
        var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
        if (!reggg.test(panNum)) {
          setPanError("Please enter correct pan card");
          return false;
        }
      }
    }

    if (!leadType) {
      setLeadTypeError("Please select potential customer");
      return false;
    }

    // if (localStorage.getItem("userType") === "ADMIN") {
    if (!zone) {
      setZoneError("Please select zone");
      return false;
    }
    // }

    if (!country || country === "Select Country") {
      setCountryError("Billing country is required");
      return false;
    }

    if (!stateValue || stateValue === "Select State") {
      setStateError("Billing state is required");
      return false;
    }

    if (!city) {
      setCityError("Billing city is required");
      return false;
    }

    if (!billingAddress) {
      setAddressError("Billing address is required");
      return false;
    }

    // let valueNew = pincode.trim();
    let regexx = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!pincode) {
      setPincodeError("Billing pincode is required");
      return false;
    }

    // if (!pincode.match(regexx)) {
    //   setPincodeError("Please enter valid input");
    //   return false;
    // }

    // if (!/^[1-9]\d*$/.test(pincode)) {
    //   setPincodeError("Please enter valid input");
    //   return false;
    // }

    if (pincode.length > 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }
    if (pincode.length < 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }

    if (!phone_no) {
      setPhone_noError("Billing phone no. is required");
      return false;
    }
    if (phone_no.length > 10) {
      setPhone_noError("Phone No. should be of 10 digits");
      return false;
    }
    if (phone_no.length < 10) {
      setPhone_noError("Phone No. should be of 10 digits");
      return false;
    }

    for (let i = 0; i < formValuesAddress.length; i++) {
      const element = formValuesAddress[i];
      let addressError = [...formValuesAddressError];
      let tempZipcode = element.zipcode.trim();
      if (!element.country || element.country === "Select Country") {
        addressError[i].country = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.state || element.state === "Select State") {
        addressError[i].state = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.city) {
        addressError[i].city = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      error = checkSpace("city", element.city);

      if (error) {
        addressError[i].city = error;
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.address) {
        addressError[i].address = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      error = checkSpace("address", element.address);

      if (error) {
        addressError[i].address = error;
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.zipcode) {
        addressError[i].zipcode = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      // if (!/^[1-9]\d*$/.test(tempZipcode)) {
      //   addressError[i].zipcode = "Please enter valid pincode";
      //   setFormValuesAddressError(addressError);
      //   return false;
      // }

      // if (!tempZipcode.match(regexx)) {
      //   addressError[i].zipcode = "Please enter valid pincode";
      //   setFormValuesAddressError(addressError);
      //   return false;
      // }

      if (tempZipcode.length > 6) {
        addressError[i].zipcode = "Pincode should be of 6 digits";
        setFormValuesAddressError(addressError);
        return false;
      }
      if (tempZipcode.length < 6) {
        addressError[i].zipcode = "Pincode should be of 6 digits";
        setFormValuesAddressError(addressError);
        return false;
      }
      if (!element.phone_no) {
        addressError[i].phone_no = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }
      if (element.phone_no.length > 10) {
        addressError[i].phone_no = "Phone No. should be of 10 digits";
        setFormValuesAddressError(addressError);
        return false;
      }
      if (element.phone_no.length < 10) {
        addressError[i].phone_no = "Phone No. should be of 10 digits";
        setFormValuesAddressError(addressError);
        return false;
      }
      if (!element.aName) {
        addressError[i].aName = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      error = checkSpace("name", element.aName);

      if (error) {
        addressError[i].aName = error;
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.aPhoneNo) {
        addressError[i].aPhoneNo = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      if (element.aPhoneNo.length > 10) {
        addressError[i].aPhoneNo = "Contact phone No. should be of 10 digits";
        setFormValuesAddressError(addressError);
        return false;
      }

      if (element.aPhoneNo.length < 10) {
        addressError[i].aPhoneNo = "Contact phone No. should be of 10 digits";
        setFormValuesAddressError(addressError);
        return false;
      }

      if (!element.selected || element.selected === "Select") {
        addressError[i].selected = "This field is required";
        setFormValuesAddressError(addressError);
        return false;
      }

      // if (!element.aEmail) {
      //   addressError[i].aEmail = "This field is required";
      //   setFormValuesAddressError(addressError);
      //   return false;
      // }

      if (element.aEmail.length > 0) {
        error = checkSpace("email", element.aEmail);

        if (error) {
          addressError[i].aEmail = error;
          setFormValuesAddressError(addressError);
          return false;
        }

        if (!element.aEmail.match(emailReg)) {
          addressError[i].aEmail = "Please enter valid email";
          setFormValuesAddressError(addressError);
          return false;
        }
      }
    }

    setBtnDisable(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    let customerData = {
      customerType: selectedOptions,
      customerName: customerName,
      companyName: companyName,
      email: email,
      password: password,
      phoneNum: phoneNum,
      leadType: leadType,
      zone: zone,
      website: website,
      gst: gst,
      panNum: panNum,
      country: country,
      billingAddress: billingAddress,
      state: stateValue,
      city: city,
      pincode: pincode,
      phone_no: phone_no,
      userType: localStorage.getItem("userType"),
      // atribute: formValues,
      address: formValuesAddress,
    };

    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/customer-add", customerData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          setBtnDisable(false);
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setBtnDisable(true);
          toast.success("Customer Add Successfully");
          setTimeout(() => {
            window.location = "/customer";
          }, 3000);
          return false;
        }
      })
      .finally(() => {
        setBtnDisable(false);
        setLoading(false);
      });
  };

  const copyAddress = (i, e) => {
    e.preventDefault();

    let newFormValues = [...formValuesAddress];
    // newFormValues[i][e.target.name] = e.target.value;

    const valv = newFormValues[i];

    valv.country = country;
    valv.address = billingAddress;
    valv.state = stateValue;
    valv.city = city;
    valv.zipcode = pincode;
    valv.phone_no = phone_no;

    newFormValues[i] = valv;

    setFormValuesAddress(newFormValues);
  };

  let handleChangeAddress = (i, e) => {
    let newFormValues = [...formValuesAddress];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesAddressError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValuesAddress(newFormValues);
    if (e.target.name === "country") {
      newFormValues[i].state = "";
      if (
        !newFormValues[i].country ||
        newFormValues[i].country === "Select Country"
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
    }
    if (e.target.name === "state") {
      if (!newFormValues[i].state) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesAddressError(newFormValuesError);
    }
    if (e.target.name === "city") {
      if (!newFormValues[i].city) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesAddressError(newFormValuesError);
    }
    if (e.target.name === "address") {
      if (!newFormValues[i].address) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      }
      newFormValuesError[i][e.target.name] = "";
      setFormValuesAddressError(newFormValuesError);
    }
    if (e.target.name === "zipcode") {
      if (!newFormValues[i].zipcode) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      }

      if (!/^[1-9]\d*$/.test(newFormValues[i].zipcode)) {
        newFormValuesError[i][e.target.name] = "Please enter valid input";
        setFormValuesAddressError(newFormValuesError);
        return false;
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesAddressError(newFormValuesError);
    }
    if (e.target.name === "phone_no") {
      if (!newFormValues[i].phone_no) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }

      if (!/^[6-9]/.test(newFormValues[i].phone_no)) {
        newFormValuesError[i][e.target.name] =
          "Please enter valid phone number";
        setFormValuesAddressError(newFormValuesError);
        return false;
      }

      if (
        newFormValues[i].phone_no.length < 10 ||
        newFormValues[i].phone_no.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Phone No. should be of 10 digits";
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
    }

    if (e.target.name === "aName") {
      if (!newFormValues[i].aName) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
    }
    if (e.target.name === "aPhoneNo") {
      if (!newFormValues[i].aPhoneNo) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }

      if (!/^[6-9]/.test(newFormValues[i].aPhoneNo)) {
        newFormValuesError[i][e.target.name] =
          "Please enter valid phone number";
        setFormValuesAddressError(newFormValuesError);
        return false;
      }

      if (
        newFormValues[i].aPhoneNo.length < 10 ||
        newFormValues[i].aPhoneNo.length > 10
      ) {
        newFormValuesError[i][e.target.name] =
          "Phone No. should be of 10 digits";
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesAddressError(newFormValuesError);
    }
    if (e.target.name === "selected") {
      if (
        !newFormValues[i].selected ||
        newFormValues[i].selected === "Select"
      ) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
    }
    if (e.target.name === "aEmail") {
      if (!newFormValues[i].aEmail) {
        // newFormValuesError[i][e.target.name] = tempErr;
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
      if (!newFormValues[i].aEmail.match(emailReg)) {
        newFormValuesError[i][e.target.name] = "Please enter valid email";
        setFormValuesAddressError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesAddressError(newFormValuesError);
      }
    }
    setFormValuesAddressError(newFormValuesError);
  };
  let addFormFieldsAddress = () => {
    setFormValuesAddress([...formValuesAddress, {}]);
    setFormValuesAddressError([...formValuesAddressError, {}]);
  };
  let removeFormFieldsAddress = (i) => {
    let newFormValues = [...formValuesAddress];
    newFormValues.splice(i, 1);
    setFormValuesAddress(newFormValues);
    let newFormValuesError = [...formValuesAddressError];
    newFormValuesError.splice(i, 1);
    setFormValuesAddressError(newFormValuesError);
  };
  const handleChangeOptions = (e) => {
    let { name } = e.target;
    // if (name === "Business") {
    //   setUserType(true);
    //   setCompNameError("");
    //   setGstError("");
    //   setPanError("");
    //   setSelectedOptions(name);
    //   setSelectedOptionsError("");
    // }
    // if (name === "manufacturer") {
    //   setUserType(true);
    //   setCompNameError("");
    //   setGstError("");
    //   setPanError("");
    //   setSelectedOptions(name);
    //   setSelectedOptionsError("");
    // }

    if (name === "individual") {
      setUserType(false);
      setSelectedOptions(name);
      return false;
    }
    setSelectedOptions(name);
    setUserType(true);
    setCompanyName("");
    setGst("");
    setPanNum("");
    setCompNameError("");
    setGstError("");
    setPanError("");
  };
  // const generatePassword = (e) => {
  //   e.preventDefault();
  //   var chars =
  //     "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  //   var passwordLength = 12;
  //   var password = "";
  //   for (var i = 0; i <= passwordLength; i++) {
  //     var randomNumber = Math.floor(Math.random() * chars.length);
  //     password += chars.substring(randomNumber, randomNumber + 1);
  //   }
  //   setPassword(password);
  // };

  const countryDropDownHtml = () => {
    const countries = Country.getAllCountries();

    // Find the index of India in the country list
    const indiaIndex = countries.findIndex(
      (country) => country.isoCode === "IN"
    );

    // Move India to the beginning of the country list
    if (indiaIndex !== -1) {
      const india = countries.splice(indiaIndex, 1)[0];
      countries.unshift(india);
    }

    // Generate the HTML for country dropdown options
    const options = countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));

    return options;
  };

  // const countryDropDownHtml = () => {
  //   const html = [];
  //   const country = Country.getAllCountries();
  //   country.map((country) => {
  //     return html.push(
  //       <option key={country.name} value={country.isoCode}>
  //         {country.name}
  //       </option>
  //     );
  //   });
  //   return html;
  // };

  const stateDropDownHtml = () => {
    const html = [];
    const states = State.getStatesOfCountry(country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  const countryShippingDropDownHtml = () => {
    const html = [];
    const country = Country.getAllCountries();
    country.map((country) => {
      return html.push(
        <option key={country.name} value={country.isoCode}>
          {country.name}
        </option>
      );
    });
    return html;
  };

  const stateShippingDropDownHtml = (i) => {
    const html = [];
    const states = State.getStatesOfCountry(formValuesAddress[i].country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  const getBack = () => {
    window.location.href = "/customer";
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD CUSTOMER</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <label>Customer Type</label>
                      <div className="col-sm-4 col-12">
                        <div className="form-group">
                          <input
                            type="radio"
                            name="business"
                            value={selectedOptions}
                            checked={selectedOptions === "business" && true}
                            id="business"
                            onChange={handleChangeOptions}
                          />
                          <label className="radio-label" htmlFor="business">
                            Business
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-4 col-12">
                        <div className="form-group">
                          <input
                            type="radio"
                            value={selectedOptions}
                            name="manufacturer"
                            checked={selectedOptions === "manufacturer" && true}
                            id="manufacturer"
                            onChange={handleChangeOptions}
                          />
                          <label className="radio-label" htmlFor="manufacturer">
                            Manufacturer
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-4 col-12">
                        <div className="form-group">
                          <input
                            type="radio"
                            name="individual"
                            value={selectedOptions}
                            checked={selectedOptions === "individual" && true}
                            id="individual"
                            onChange={handleChangeOptions}
                          />
                          <label className="radio-label" htmlFor="individual">
                            Individual
                          </label>
                        </div>
                      </div>
                      {/* <span>
                        <p style={{ color: "red" }}> {selectedOptionsError} </p>
                      </span> */}
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            name="customerName"
                            placeholder="Customer Name"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {custNameError} </p>
                          </span>
                        </div>
                      </div>

                      {userType === true && (
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Company Name</label>
                            <input
                              type="text"
                              name="companyName"
                              placeholder="Company Name"
                              className="form-control"
                              onChange={handleChangeTop}
                            />
                            <span>
                              <p style={{ color: "red" }}> {compNameError} </p>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {emailError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type={typePass}
                            name="password"
                            placeholder="Password"
                            value={password}
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span
                            role="button"
                            className="password__show eye1"
                            onClick={() => handleClick()}
                          >
                            {typePass === "text" ? (
                              <i className="fa fa-eye"></i>
                            ) : (
                              <i className="fa fa-eye-slash"></i>
                            )}
                          </span>
                          <span>
                            <p style={{ color: "red" }}> {passwordError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="number"
                            name="pNumber"
                            placeholder="Phone Number"
                            className="form-control"
                            onKeyDown={handleValidationDot}
                            maxLength={10}
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {phoneError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Website</label>
                          <input
                            type="text"
                            name="website"
                            placeholder="Website"
                            className="form-control"
                            onChange={handleChangeTop}
                          />
                          <span>
                            <p style={{ color: "red" }}> {websiteError} </p>
                          </span>
                        </div>
                      </div>

                      {userType === true && (
                        <>
                          <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                              <label>GST Number</label>
                              <input
                                type="text"
                                name="gst"
                                value={gst}
                                placeholder="GST Number"
                                maxLength={15}
                                className="form-control"
                                onChange={handleChangeTop}
                              />
                              <span>
                                <p style={{ color: "red" }}>{gstError} </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                              <label>PAN Number</label>
                              <input
                                type="text"
                                name="panNum"
                                placeholder="PAN Number"
                                value={panNum}
                                className="form-control"
                                onChange={handleChangeTop}
                              />
                              <span>
                                <p style={{ color: "red" }}> {panError} </p>
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Potential Customer</label>
                          <select
                            type="text"
                            name="leadType"
                            value={leadType}
                            placeholder="leadType"
                            className="form-control"
                            onChange={handleChangeTop}
                          >
                            <option value={""}>Select Type</option>
                            <option value={"Lead"}>Lead</option>
                            <option value={"Normal"}>Normal</option>
                          </select>
                          <p style={{ color: "red" }}>{leadTypeError}</p>
                        </div>
                      </div>

                      {/* {localStorage.getItem("userType") === "ADMIN" && ( */}
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Zone</label>
                          <select
                            type="text"
                            name="zone"
                            value={zone}
                            placeholder="zone"
                            className="form-control"
                            onChange={handleChangeTop}
                          >
                            <option value={""}>Select Zone</option>
                            {ZoneHtml()}
                          </select>
                          <p style={{ color: "red" }}>{zoneError}</p>
                        </div>
                      </div>
                      {/* )} */}

                      <div className="address-heading">
                        <h5> Billing Address</h5>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            type="text"
                            name="country"
                            value={country}
                            placeholder="Country"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          >
                            <option>Select Country</option>
                            {countryDropDownHtml()}
                          </select>
                          <span>
                            <p style={{ color: "red" }}> {countryError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            type="text"
                            name="state"
                            value={stateValue}
                            placeholder="State"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          >
                            <option>Select State</option>
                            {stateDropDownHtml()}
                          </select>
                          <span>
                            <p style={{ color: "red" }}> {stateError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            value={city}
                            placeholder="City"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          />
                          <span>
                            <p style={{ color: "red" }}> {cityError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            name="billingAddress"
                            value={billingAddress}
                            placeholder="Address"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          />
                          <span>
                            <p style={{ color: "red" }}> {addressError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Pincode</label>
                          <input
                            type={"number"}
                            name="zipcode"
                            onKeyDown={handleValidationDot}
                            value={pincode}
                            maxLength={6}
                            placeholder="Pincode"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          />
                          <span>
                            <p style={{ color: "red" }}> {pincodeError} </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="number"
                            name="phone_no"
                            value={phone_no}
                            maxLength={10}
                            onKeyDown={handleValidationDot}
                            placeholder="Phone Number"
                            className="form-control"
                            onChange={handleChangeBillingAddress}
                          />
                          <span>
                            <p style={{ color: "red" }}> {phone_noError} </p>
                          </span>
                        </div>
                      </div>

                      <div className="address-heading">
                        <h5> Shipping Address</h5>
                      </div>
                      {formValuesAddress.map((element, index) => (
                        <>
                          <div className="col-12">
                            <div className="row">
                              <div className="copy-address">
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => copyAddress(index, e)}
                                >
                                  Copy Billing Address
                                </button>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>Country</label>
                                  <select
                                    className="form-control"
                                    name="country"
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                    value={element.country || ""}
                                  >
                                    <option>Select Country</option>
                                    {countryShippingDropDownHtml()}
                                  </select>
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].country
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>State</label>
                                  <select
                                    className="form-control"
                                    name="state"
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                    value={element.state || ""}
                                  >
                                    <option>Select State</option>
                                    {stateShippingDropDownHtml(index)}
                                  </select>
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesAddressError[index].state}{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>City</label>
                                  <input
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    className="form-control"
                                    value={element.city || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesAddressError[index].city}{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    placeholder="Address"
                                    value={element.address || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].address
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>Pincode</label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    onKeyDown={handleValidationDot}
                                    name="zipcode"
                                    placeholder="Pincode"
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                    value={element.zipcode || ""}
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].zipcode
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12 ">
                                <div className="form-group">
                                  <label>Phone Number</label>
                                  <input
                                    type="number"
                                    name="phone_no"
                                    onKeyDown={handleValidationDot}
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Phone Number"
                                    value={element.phone_no || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].phone_no
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="contact-person-heading">
                                  <h5>Contact Person</h5>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6 col-12 order-1">
                                <div className="form-group">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    name="aName"
                                    className="form-control"
                                    placeholder="Name"
                                    value={element.aName || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesAddressError[index].aName}{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6 col-12 order-1">
                                <div className="form-group">
                                  <label>Phone Number</label>
                                  <input
                                    type="number"
                                    name="aPhoneNo"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Phone Number"
                                    value={element.aPhoneNo || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                  />
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].aPhoneNo
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6 col-12 order-1">
                                <div className="form-group">
                                  <label>Designation</label>
                                  <select
                                    className="form-control"
                                    value={element.selected || ""}
                                    onChange={(e) =>
                                      handleChangeAddress(index, e)
                                    }
                                    name="selected"
                                  >
                                    <option>Select</option>
                                    <option>HR/Admin Manager</option>
                                    <option>Procurement Manager</option>
                                    <option>General Manager</option>
                                    <option>Owner/Partner/Director</option>
                                    <option>Store Manager</option>
                                    <option>Marketing Manager</option>
                                    {designationHtml()}
                                    <option>Other</option>
                                  </select>
                                  {element.selected === "Other" && (
                                    <input
                                      type="text"
                                      name="designation"
                                      placeholder=""
                                      className="form-control"
                                      value={element.designation || ""}
                                      onChange={(e) =>
                                        handleChangeAddress(index, e)
                                      }
                                    />
                                  )}{" "}
                                  <span>
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {
                                        formValuesAddressError[index].selected
                                      }{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6 col-12 order-1">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group">
                                    <label>Email</label>
                                    <input
                                      type="email"
                                      name="aEmail"
                                      className="form-control"
                                      placeholder="Email"
                                      value={element.aEmail || ""}
                                      onChange={(e) =>
                                        handleChangeAddress(index, e)
                                      }
                                    />
                                    <span>
                                      <p style={{ color: "red" }}>
                                        {" "}
                                        {
                                          formValuesAddressError[index].aEmail
                                        }{" "}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="add-row">
                                    {index ? (
                                      <a
                                        href="#!"
                                        onClick={() =>
                                          removeFormFieldsAddress(index)
                                        }
                                      >
                                        -
                                      </a>
                                    ) : null}
                                    <a
                                      href="#!"
                                      onClick={() => addFormFieldsAddress()}
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </form>

                  <div className="btn-group">
                    <Button onClick={handleSubmit} loading={loading}>
                      Save
                    </Button>
                    <br />
                    <Button onClick={() => navigate("/customer")}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default CustomerAdd;
