import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function AddOprationExecutive() {
  const [name, setName] = useState("");
  const [catData, setCatData] = useState([]);
  const [catManData, setCatManData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryManId, setCategoryManId] = useState("");

  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [typePass, setTypePass] = useState("password");
  const [showCategory, setShowCategory] = useState(false);
  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [loading, setLoading] = useState(false);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  useEffect(() => {
    categoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-manager-data", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setCatManData(resp.data);
        }
      });
  };

  const categoryManagerHtml = () => {
    const categoryhtml = [];
    var CData = catManData.sort(function (a, b) {
      var textA = a.email;
      var textB = b.email;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.email}</option>);
    });
    return categoryhtml;
  };

  const handleChangeCategoryManager = async (event) => {
    let eventValue = event.target.value;

    setCategoryManId(eventValue);
    if (!eventValue || eventValue === "") {
      setShowCategory(false);
      toast.dismiss();
      toast.error("Please select category manager");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    const data = {
      id: eventValue,
      status: "addOPS",
    };

    axios
      .post(baseUrl + "/frontapi/category-data-deleted-CatId", data, config)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setShowCategory(true);
          setCatData(resp.data);
        }
      });
  };

  const categoryHtml = () => {
    const categoryhtml = [];
    var CData = catData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CData.map((value, i) => {
      return categoryhtml.push(<option value={value.id}>{value.name}</option>);
    });
    return categoryhtml;
  };

  const handleChangeCategory = async (event) => {
    let eventValue = event.target.value;
    setCategoryId(eventValue);
    if (!eventValue || eventValue === "") {
      toast.dismiss();
      toast.error("Please select category");
      return false;
    }
  };

  const handleChange = async (event) => {
    let { value, name } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }

      setNameErr("");
    }

    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailErr("Email is required");
        return false;
      }
      if (!value.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }

      setEmailErr("");
    }

    if (name === "number") {
      setNumber(value);
      if (!value) {
        setNumErr("Mobile number is required");
        return false;
      }

      if (!/^[6-9]/.test(value)) {
        setNumErr("Please enter valid mobile number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }

    if (name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      setPassword(value);
      if (!value) {
        setPassErr("Password is required");
        return false;
      }

      if (!value.match(regex)) {
        setPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }

      setPassErr("");
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "." ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = "";
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
    if (!name && !email && !number && !password) {
      setNameErr("Name is required");
      setEmailErr("Email is required");
      setNumErr("Mobile number is required");
      setPassErr("Password is required");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (!email) {
      setEmailErr("Email is required");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Mobile number is required");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    if (!categoryManId || categoryManId === "") {
      toast.dismiss();
      toast.error("Please select category manager");
      return false;
    }

    if (!categoryId || categoryId === "") {
      toast.dismiss();
      toast.error("Please select category");
      return false;
    }

    if (!password) {
      setPassErr("Password is required");
      return false;
    }

    if (!password.match(regex)) {
      setPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    error = checkSpace("password", password);
    if (error) {
      setPassErr(error);
      return false;
    }

    setLoading(true);
    let customerData = {
      name: name,
      email: email,
      categoryManId: categoryManId,
      categoryId: categoryId,
      number: number,
      password: password,
    };
    axios
      .post(baseUrl + "/frontapi/opration-executive-add", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Opration Executive Added Successfully");
          setTimeout(function () {
            window.location = "/operation-executive";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <ContentLayout
      title="ADD Operation Executive"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Name"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{emailErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Mobile Number</label>
              <input
                type="number"
                name="number"
                onKeyDown={handleValidationDot}
                placeholder="Mobile Number"
                value={number}
                maxLength="10"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{numErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Category Manager </label>
              <select
                className="form-control"
                name="categoryManId"
                onChange={handleChangeCategoryManager}
              >
                <option value={""}>Please select</option>
                {categoryManagerHtml()}
              </select>
            </div>
          </div>

          {showCategory && (
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label>Category</label>
                <select
                  className="form-control"
                  name="categoryId"
                  onChange={handleChangeCategory}
                >
                  <option value={""}>Please select</option>
                  {categoryHtml()}
                </select>
              </div>
            </div>
          )}

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Password</label>
              <input
                type={typePass}
                name="password"
                value={password}
                placeholder="Password"
                className="form-control"
                onChange={handleChange}
              />
              <span
                role="button"
                className="password__show eye1"
                onClick={() => handleClick()}
              >
                {typePass === "text" ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </span>
              <p style={{ color: "red" }}>{passErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/operation-executive"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default AddOprationExecutive;
