import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { baseUrl } from "./BaseUrl";

function GstAdd() {
  const [gst, setGst] = useState("");
  const [type, setType] = useState("number");
  const [gstErr, setGstErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeGst = async (event) => {
    let eventValue = event.target.value;

    if (eventValue !== 0) {
      if (eventValue == "") {
        setGst(eventValue);
        setType("number");
        setGstErr("GST is required");
        return false;
      }
    }

    setType("number");
    setGstErr("");
    setGst(eventValue);
  };

  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }
    event.preventDefault();

    if (gst !== 0) {
      if (gst == "") {
        setGstErr("GST is required");
        return false;
      }
    }
    setLoading(true);
    const customerData = {
      gst: gst,
    };
    axios
      .post(baseUrl + "/frontapi/gst-add", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("GST Added Successfully");
          setTimeout(function () {
            window.location = "/gst";
          }, 3000);
          return false;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  return (
    <ContentLayout
      title={"ADD GST"}
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>GST (%)</label>
              <input
                type={type}
                name="gst"
                maxLength={"2"}
                onKeyDown={handleValidation}
                placeholder="GST %"
                className="form-control"
                onChange={handleChangeGst}
              />
              <p style={{ color: "red" }}>{gstErr}</p>
            </div>
          </div>
        </div>
      </form>
      <ButtonGroup onClick={handleSubmit} loading={loading} link="/gst" />
    </ContentLayout>
  );
}
export default GstAdd;
