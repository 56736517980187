import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { ContentLayout } from "./Common/Layout/ContentLayout";
const baseUrl = myConstList.baseUrl;

function AddSalesPersonByManager() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [typePass, setTypePass] = useState("password");
  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [loading, setLoading] = useState(false);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = async (event) => {
    let eventValue = event.target.value;
    if (event.target.name === "name") {
      if (!eventValue) {
        setName(eventValue);
        setNameErr("Name is required");
        return false;
      }
      setName(eventValue);
      setNameErr("");
    }
    if (event.target.name === "email") {
      if (!eventValue) {
        setEmail(eventValue);
        setEmailErr("Email is required");
        return false;
      }
      if (!eventValue.match(emailReg)) {
        setEmail(eventValue);
        setEmailErr("Please enter valid email");
        return false;
      }
      setEmailErr("");
      setEmail(eventValue);
    }
    if (event.target.name === "number") {
      if (!eventValue) {
        setNumber(eventValue);
        setNumErr("Mobile number is required");
        return false;
      }

      if (!/^[6-9]/.test(eventValue)) {
        setNumber(eventValue);
        setNumErr("Please enter valid mobile number");
        return false;
      }

      if (eventValue.length < 10 || eventValue.length > 10) {
        setNumber(eventValue);
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
      setNumber(eventValue);
    }
    if (event.target.name === "password") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

      if (!eventValue) {
        setPassword(eventValue);
        setPassErr("Password is required");
        return false;
      }

      if (!eventValue.match(regex)) {
        setPassword(eventValue);
        setPassErr(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      }
      setPassErr("");
      setPassword(eventValue);
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
    if (!name && !email && !number && !password) {
      setNameErr("Name is required");
      setEmailErr("Email is required");
      setNumErr("Mobile number is required");
      setPassErr("Password is required");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    if (!email) {
      setEmailErr("Email is required");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailErr("Please enter valid email");
      return false;
    }

    if (!number) {
      setNumErr("Mobile number is required");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile number should be of 10 digits");
      return false;
    }

    if (!password) {
      setPassErr("Password is required");
      return false;
    }

    if (!password.match(regex)) {
      setPassErr(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    let error = checkSpace("password", password);
    if (error) {
      setPassErr(error);
      return false;
    }

    setLoading(true);
    let customerData = {
      name: name,
      email: email,
      number: number,
      password: password,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(
        baseUrl + "/frontapi/sales-person-manager-add",
        customerData,
        config
      )
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === false) {
          toast.error(resp.message);
          clearWaitingQueue();
        }
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/sales-person";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(true));
  };

  const handleClick = () => {
    if (typePass === "password") {
      setTypePass("text");
      return false;
    } else {
      setTypePass("password");
    }
  };

  return (
    <ContentLayout
      title="ADD Sales Person"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{emailErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Mobile Number</label>
              <input
                type="number"
                name="number"
                maxLength="10"
                onKeyDown={handleValidation}
                value={number}
                placeholder="Mobile Number"
                className="form-control"
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{numErr}</p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Password</label>
              <input
                type={typePass}
                name="password"
                placeholder="Password"
                className="form-control"
                onChange={handleChange}
              />
              <span
                role="button"
                className="password__show eye1"
                onClick={() => handleClick()}
              >
                {typePass === "text" ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </span>
              <p style={{ color: "red" }}>{passErr}</p>
            </div>
          </div>
        </div>
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </form>
      <ButtonGroup
        onClick={handleSubmit}
        link="/sales-person"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default AddSalesPersonByManager;
