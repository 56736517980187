import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./Footer";
import { reactTableConfig } from "../lib/reactTableConfig";
const config = reactTableConfig("Category");
const baseUrl = myConstList.baseUrl;

function MyCategories(props) {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    categoryData();
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Category Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "id",
      align: "left",
      sortable: true,
    },
  ];

  const categoryData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/myCategories", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setRecord(resp.data);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading   d-flex justify-content-between">
              <h2>
                <b>Category List</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default MyCategories;
