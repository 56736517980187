import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { reactTableConfig } from "../lib/reactTableConfig";
import Footer from "./Footer";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Attributes");

function Attribute(props) {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    attributeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "Action",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-info btn-sm img-fluid"
              onClick={() => {
                window.location.href = "/attribute-edit/" + btoa(record.id);
              }}
            >
              Edit
            </button>
            {record.is_deleted == "0" ? (
              <button
                className="btn btn-success btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Enabled
              </button>
            ) : (
              <button
                className="btn btn-danger btn-sm img-fluid"
                onClick={() => {
                  Conn(record.id, record.is_deleted);
                }}
              >
                Disabled
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleted(getMethodDeleteId, status),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const attributeData = () => {
    axios
      .post(baseUrl + "/frontapi/attribute-data", attributeData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
        }
      });
  };
  const deleted = (id, status) => {
    let temp;
    if (status === "0") {
      temp = "1";
    } else {
      temp = "0";
    }

    let params = {
      id: id,
      status: temp,
    };

    axios.post(baseUrl + "/frontapi/attribute-delete", params).then((res) => {
      var resp = res.data;

      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        attributeData();
        return;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading  d-flex justify-content-between">
              <h2>
                <b>Attribute List</b>
              </h2>
              <div className="add-product-btn text-center">
                <Link to={"/attribute-add"} className="btn btn-primary">
                  Add Attribute
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
}
export default Attribute;
