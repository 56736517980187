import { Outlet } from "react-router-dom";
import Header from "./Header";
import Navbar from "./Navbar";
import axios from "axios";
import React, { useState } from "react";
import { Suspense } from "react";
import { LoadingSpinner } from "./Spinner";
import { baseUrl } from "./BaseUrl";

export default function RootLayout() {
  const [unReadCount, setUnReadCount] = useState("");

  const getNotificationData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      userType: localStorage.getItem("userType"),
      email: localStorage.getItem("email"),
      limit: "yes",
    };

    axios
      .post(baseUrl + "/frontapi/getNotifications", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setUnReadCount(resp.data.resultCount[0]["COUNT(id)"]);
          //   unRead(resp.data.result);
        }
      });
  };

  return (
    <div>
      <Header unReadCounts={unReadCount} />
      <Navbar getNotificationData={getNotificationData} />

      <main>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}
