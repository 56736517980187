import React, { useState, useEffect } from "react";
import axios from "axios";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

const HoldedQtyDetail = () => {
  const [tableData, setTableData] = useState([]);
  const [lockedQty, setLockedQty] = useState("");
  const [vendorData, setVendorData] = useState([]);
  // const [unit, setUnit] = useState("");
  const [unit1, setUnit1] = useState("");
  const [vendorNewData, setVendorNewData] = useState([]);

  const Params = useParams();
  const theOrderId = atob(Params.orderId);

  useEffect(() => {
    orderData();
  }, []);

  const orderData = async () => {
    const data = {
      id: atob(Params.id),
      orderId: theOrderId,
    };
    await axios
      .post(baseUrl + "/frontapi/getProductMaterialById", data)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setTableData(resp.data.result);
          setLockedQty(resp.data.result1[0].locked_qty);
          setVendorData(resp.data.result2[0]);
          setVendorNewData(resp.data.result2.filter((data) => data));
          // setUnit(resp.data.result2[0].units);
          setUnit1(resp.data.result[0].material_units);
        }
      });
  };

  const manufactureHtml = (vendor) => {
    const html = [];
    vendor.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Vendor Order ID: <b>{value.id}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name: <b>{tableData[i].material_type}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Assigned to: <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Quantity:{" "}
                <b>
                  {value.quantity} {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Price: <b>{value.price} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                GST: <b>{value.tax}%</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Total: <b>{value.total} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Status: {value.status === "1" ? <b>InProcess</b> : ""}
                {value.status === "0" ? <b>Pending</b> : ""}
                {value.status === "2" ? <b>Completed</b> : ""}
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>HOLD DETAIL</b>
              </h2>
            </div>
            {!vendorData && (
              <div className=" customer-detail-outer">
                <div className="order-detail-outer-middle pending-list-outer">
                  <div className="col-12">
                    <div className="person-contact customer-detail">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-12 more-padding border-right">
                          <div className="person-detail">
                            <p>
                              Product Name : <b>{tableData[0].material_type}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 more-padding ">
                          <div className="person-detail">
                            <p>
                              Holded Quantity :{" "}
                              <b>
                                {lockedQty} {unit1}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {vendorData && (
              <div className=" customer-detail-outer">
                <div className="order-detail-outer-middle pending-list-outer">
                  {/* <div className="col-12">
                    <h2>
                      Holded Quantity: {lockedQty} {unit} 
                    </h2>
                    <div className="person-contact customer-detail">
                      <h5>
                        <h3>Order Detail</h3>
                      </h5>
                      {manufactureHtml()}
                    </div>
                  </div> */}
                  {vendorNewData &&
                    vendorNewData.map((vendor) => (
                      <div className="col-12">
                        <h2>
                          Hold Quantity: {vendor.quantity} {vendor.unit}
                        </h2>
                        <div className="person-contact customer-detail">
                          <h5>
                            <h3>Order Detail</h3>
                          </h5>
                          {manufactureHtml([vendor])}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default HoldedQtyDetail;
