import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Footer from "../../Footer";

export function ContentLayout({children, title, className, buttonUrl, buttonTitle, ExtraComponent}) {
  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>{title}</b>
              </h2>
              {ExtraComponent && <ExtraComponent />}              
             {buttonTitle && <div className="add-product-btn text-center">
                <Link to={buttonUrl} className="btn btn-primary">
                  {buttonTitle}
                </Link>
              </div>}
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className={className}>
                  {children}                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}

