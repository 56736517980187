import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDatatable from "@mkikets/react-datatable";
import { reactTableConfig } from "../lib/reactTableConfig";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { baseUrl } from "./BaseUrl";
import { Modal } from "react-bootstrap";
import moment from "moment";
const config = reactTableConfig("Support");

const Support = () => {
  const [record, setRecord] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    supportData();
  }, []);

  const columns = [
    {
      key: "#",
      text: "Sr No.",
      className: "#",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Support ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "idType",
      text: "ID Type",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            {record.idType === "order"
              ? `Order ID- ${record.orderId}`
              : `Lead ID- ${record.leadId}`}{" "}
          </Fragment>
        );
      },
    },
    {
      key: "issue",
      text: "Issue Type",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {" "}
            {record.issueType === "Others"
              ? `${record.issueType} - ${record.otherDetail}`
              : `${record.issueName}`}{" "}
          </Fragment>
        );
      },
    },
    {
      key: "description",
      text: "Description",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {record.description} </Fragment>;
      },
    },
    {
      key: "document",
      text: "Document",
      className: "pn",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              type="button"
              onClick={() => {
                setDocumentData(JSON.parse(record.document));
                setShowModal(true);
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment> {moment(record.created_at).format("lll")} </Fragment>;
      },
    },
    {
      key: "status",
      text: "Status ",
      className: "phoneno",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.status === "2" ? (
              <span style={{ color: "green" }}>Resolved</span>
            ) : (
              <select
                className="form-control"
                style={{ marginLeft: "4px" }}
                name="productStatus"
                value={record.status}
                onChange={(e) => Conn(e, record)}
              >
                <option value="0">Pending</option>
                <option value="1">In Process</option>
                <option value="2">Resolved</option>
              </select>
            )}
          </Fragment>
        );
      },
    },
  ];

  const changeStatus = (status, record) => {
    if (status < record.status) {
      toast.dismiss();
      toast.error("Status cannot be reversed");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    let data = {
      status: status,
      id: record.id,
    };

    axios
      .post(baseUrl + "/frontapi/change-support-status", data, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === true) {
          toast.success(resp.message);
          supportData();
          return false;
        } else {
          toast.error(resp.message);
        }
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (e, data) => {
    let status = e.target.value;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(status, data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const supportData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(
        baseUrl + "/frontapi/support-data-admin",
        { userType: localStorage.getItem("userType") },
        config
      )
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRecord(resp.data);
        }
      });
  };

  const closeShowModel = () => {
    setDocumentData([]);
    setShowModal(false);
  };

  const documentHtml = () => {
    const html = [];
    documentData.map((value, i) => {
      return html.push(
        <div className="col-md-6 col-12">
          <div className="product-price">
            <a
              href={baseUrl + "/static/support/" + value.doc}
              target="_blank"
              rel="noreferrer"
            >
              <b>{value.doc}</b>
            </a>
          </div>
        </div>
      );
    });
    return html;
  };

  return (
    <>
      <ContentLayout
        title="Support List"
        className="product-list-outer customer-list-outer"
      >
        <ReactDatatable
          className="table table-bordered table-striped "
          config={config}
          records={record}
          columns={columns}
        />

        <Modal
          className="modal-update"
          show={showModal}
          onHide={closeShowModel}
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              {" "}
              Document{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row align-items-center">{documentHtml()}</div>
          </Modal.Body>
        </Modal>
      </ContentLayout>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
};

export default Support;
