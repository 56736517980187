import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import moment from "moment";
const baseUrl = myConstList.baseUrl;

function OrderCompletedDetails(props) {
  const [orderId, setOrderId] = useState("");
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [salsePersonName, setSalsePersonName] = useState("");
  const [customerDataById, setCustomerDataById] = useState([]);
  const [catManager, setCatManager] = useState("");
  const [sData, setSData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [specifications, setSpecifications] = useState(null);

  const [remaingTime, setRemaingTime] = useState("");

  //suborder data
  const [subOrdersTable, setSubOrdersTable] = useState([]);
  const [partialDeliveryStatus, setPartialDeliveryStatus] = useState("no");

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    orderData();
    getOrderDetails();
    setOrderId(Params);
    getCustomerDetailsAdmin();
  }, []);

  const getOrderDetails = () => {
    const data = {
      id: Params,
    };
    axios
      .post(baseUrl + "/frontapi/get-orderdetails-admin", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTableData(resp.data);
          setOrderStatus(resp.data[0].order_status);
          setCustomerName(resp.data[0].customerName);
          setPartialDeliveryStatus(resp.data[0].partial_delivery_status);
          setSubOrdersTable(resp.subOrders);
          getCatData(resp.data[0].category_id);
          getCustomerShippingAddress(
            resp.data[0].customer_id,
            resp.data[0].shipping_id
          );
          setSpecifications(resp.data[0].specifications);
        }
      });
  };

  const getCustomerShippingAddress = (id, addressId) => {
    const data = {
      id: id,
      addressId: addressId,
    };
    axios.post(baseUrl + "/frontapi/customer-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setSData(resp.data.sqlRun2);
      }
    });
  };

  const getCatData = (id) => {
    const data = {
      catId: id,
    };

    axios.post(baseUrl + "/frontapi/order-single-product", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCatManager(resp.data.catManData[0].name);
      }
    });
  };

  const getCustomerDetailsAdmin = () => {
    const data = {
      id: Params,
    };
    axios.post(baseUrl + "/frontapi/get-customer-admin", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setCustomerDataById(resp.data);
        getCustomerShippingAddress(resp.data[0].id);
      }
    });
  };

  const orderData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(baseUrl + "/frontapi/order-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setDateOfOrder(resp.data[0].createdAt);
        showTimerCounterHtml(resp.data[0].finishAt, resp.data[0].createdAt);
        setSalsePersonName(resp.data[0].salsePersonName);
      }
    });
  };

  const tableHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.productName}</th>
          <th>
            {value.quantity} {value.units}
          </th>
          <th>
            {value.delivered_quantity} {value.units}
          </th>
          <th>{value.price} INR</th>
          <th>{value.gst + "%"}</th>
          <th>{parseFloat(value.gstAmount).toFixed(2)} INR</th>
          <th>{parseFloat(value.subTotal).toFixed(2)} INR</th>
          {value.order_status === "6" ? (
            <>
              {value.manufactureOrBuy != null &&
              value.manufactureOrBuy !== "no" ? (
                <th>
                  {" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => getNewValues(value)}
                  >
                    View Request
                  </button>
                </th>
              ) : (
                <th>{`${value.locked_available_qty} quantity locked for this order`}</th>
              )}
            </>
          ) : (
            <th>N/A</th>
          )}
          <th>{value.isCustomize === "Y" ? "Yes" : "No"}</th>
          <th>
            {value.isCustomize === "Y" ? (
              <>
                {value.customize_image == null ||
                value.customize_image === "NULL" ||
                value.customize_image === "null" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl +
                      "/static/customizeImage/" +
                      value.customize_image
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    {!value.customize_image.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.customize_image
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          <th>
            {value.isCustomize === "Y" ? (
              <>
                {value.is_assigned === "N" ? (
                  "N/A"
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => viewAssignValues(value)}
                  >
                    View
                  </button>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          {/* <th>{value.order_status === "6" && "Completed"}</th> */}
        </tr>
      );
    });
    return html;
  };

  const tableSubHtml = () => {
    const html = [];
    subOrdersTable.map((value, i) => {
      return html.push(
        <tr key={i}>
          <th>{i + 1}</th>
          <th>{value.productName}</th>
          <th>
            {value.quantity} {value.units}
          </th>
          <th>{value.price} INR</th>
          <th>{value.gst + "%"}</th>
          <th>{parseFloat(value.gstAmount).toFixed(2)} INR</th>
          <th>{parseFloat(value.subTotal).toFixed(2)} INR</th>
          {value.order_status === "6" ? (
            <>
              {value.manufactureOrBuy != null &&
              value.manufactureOrBuy !== "no" ? (
                <th>
                  {" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => getNewValues(value)}
                  >
                    View Request
                  </button>
                </th>
              ) : (
                <th>{`${value.locked_available_qty} quantity locked for this order`}</th>
              )}
            </>
          ) : (
            <th>N/A</th>
          )}
          <th>{value.isCustomize === "Y" ? "Yes" : "No"}</th>
          <th>
            {value.isCustomize === "Y" ? (
              <>
                {value.customize_image == null ||
                value.customize_image === "NULL" ||
                value.customize_image === "null" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl +
                      "/static/customizeImage/" +
                      value.customize_image
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    {!value.customize_image.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.customize_image
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          <th>
            {value.isCustomize === "Y" ? (
              <>
                {value.is_assigned === "N" ? (
                  "N/A"
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => viewAssignValues(value)}
                  >
                    View
                  </button>
                )}
              </>
            ) : (
              "N/A"
            )}
          </th>
          {/* <th>{value.order_status === "6" && "Completed"}</th> */}
        </tr>
      );
    });
    return html;
  };

  const getNewValues = (item) => {
    window.location.href =
      "/manufacture-details/" +
      btoa(Params) +
      "/" +
      btoa(item.manufactureOrBuy);
  };

  const viewAssignValues = (item) => {
    window.location.href = "/assign-vendor-details/" + btoa(item.orderId);
  };

  const totalSumHtml = () => {
    let sum = 0;
    tableData.map((value) => {
      return (sum += value.subTotal);
    });
    return parseFloat(sum).toFixed(2);
  };

  const specificationsHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.specifications}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const paymentModeHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav justify-content-center" key={value.name}>
            <li>
              <b>{value.payment_mode}</b>
            </li>
          </ul>
        </div>
      );
    });
    return html;
  };

  const customerDetailsHtml = () => {
    const html = [];
    customerDataById.map((value, i) => {
      return html.push(
        <div className="customer-detail-outer">
          <ul className="nav" key={i}>
            <li>
              Customer Name : <b>{value.name}</b>
            </li>
            <li>
              Company Name : <b>{value.companyName}</b>
            </li>
            <li>
              Email : <b>{value.email}</b>
            </li>
            <li>
              Pan Number : <b>{value.panNumber}</b>
            </li>
            <li>
              Phone Number : <b>{value.phoneNumber}</b>
            </li>
            <li>
              Billing Address : <b>{value.billingAddress}</b>
            </li>
            <li>
              Website : <b>{value.website}</b>
            </li>
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Shipping Address :</h5> {addressHtml()}
          </ul>

          <ul className="nav other-address">
            <h5 className="w-100">Contact Person :</h5> {attributeCheck(value)}
          </ul>
        </div>
      );
    });
    return html;
  };

  const addressHtml = () => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-4 col-12">
              <li key={index}>
                Address: <b> {element.address}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Country: <b> {element.country}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                State: <b> {element.state}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                City: <b> {element.city}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Phone No: <b> {element.phone_no}</b>
              </li>
            </div>
            <div className="col-md-4 col-12">
              <li>
                Pincode: <b> {element.zipcode}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };

  const attributeCheck = (value) => {
    if (sData.length > 0) {
      return sData.map((element, index) => {
        return (
          <>
            <div className="col-md-3 col-12">
              <li key={index}>
                Email: <b> {element.aEmail}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Name: <b> {element.aName}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Phone No: <b> {element.aPhoneNo}</b>
              </li>
            </div>
            <div className="col-md-3 col-12">
              <li>
                Designation: <b> {element.selected}</b>
              </li>
            </div>
          </>
        );
      });
    }
  };

  const showTimerCounterHtml = (expriryDateTime, startDate) => {
    var initialDate = moment(startDate).format("YYYY-MM-DD hh:mm:ss");
    var endDate = moment(expriryDateTime).format("YYYY-MM-DD hh:mm:ss");

    const secondsStart = moment(initialDate, "YYYY-MM-DD HH:mm:ss").unix();
    const secondsExpiry = moment(endDate, "YYYY-MM-DD HH:mm:ss").unix();

    var remainingSeconds = secondsExpiry - secondsStart;
    const duration = moment.duration(remainingSeconds, "seconds");

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsRemaining = duration.seconds();

    setRemaingTime(
      `${days} days,${hours} hours, ${minutes} minutes, ${secondsRemaining} seconds`
    );
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="manager-heading-outer section-heading">
                <h2>
                  <b>
                    {" "}
                    {orderStatus === "5" ? "REJECTED" : ""}
                    {orderStatus === "6" ? "COMPLETED" : ""} ORDERS DETAIL
                  </b>
                </h2>
              </div>
              <div className="order-detail-outer-top">
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-12">
                    <div className="detail-inner">
                      <p>
                        Order Id: <b>{orderId}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-12">
                    <div className="detail-inner">
                      <p>
                        Date of Order:{" "}
                        <b>{moment(dateOfOrder).format("DD/MM/YYYY")}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-12">
                    <div className="detail-inner">
                      <p>
                        Customer Name:{" "}
                        <b>
                          {customerName == null
                            ? salsePersonName
                            : customerName}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-12">
                    <div className="detail-inner">
                      <p>
                        Total Time Taken: <b>{remaingTime}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-details-status">
                <div className="row">
                  <div className="col-12">
                    <div className="order-detail-outer-middle pending-list-outer">
                      <h3>Order Details</h3>
                      <table className="w-100 table-responsive">
                        <tr>
                          <th>S.No</th>
                          <th>Products Name</th>
                          <th>Order Quantity</th>
                          <th>Delivered Quantity</th>
                          <th>Price</th>
                          <th>Gst</th>
                          <th>Gst Amount</th>
                          <th>Total Amount</th>
                          <th>Buy/Manufacture</th>
                          <th>Customized</th>
                          <th>Customized Image</th>
                          <th>Assigned Vendor</th>
                        </tr>
                        {tableHtml()}
                        <tr align="right">
                          <td colSpan="13">
                            <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {partialDeliveryStatus === "yes" && (
                <div className="order-details-status">
                  <div className="row">
                    <div className="col-12">
                      <div className="order-detail-outer-middle pending-list-outer">
                        <h3>Sub Order Details</h3>
                        <table className="w-100 table-responsive">
                          <tr>
                            <th>S.No</th>
                            <th>Products Name</th>
                            <th>Order Quantity</th>
                            <th>Price</th>
                            <th>Gst</th>
                            <th>Gst Amount</th>
                            <th>Total Amount</th>
                            <th>Buy/Manufacture</th>
                            <th>Customized</th>
                            <th>Customized Image</th>
                            <th>Assigned Vendor</th>
                          </tr>
                          {tableSubHtml()}
                          <tr align="right">
                            <td colSpan="13">
                              <b>TOTAL PRICE : </b>INR {totalSumHtml()}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="order-detail-outer-middle pending-list-outer">
                <h3>Customer Details</h3>
                {customerDetailsHtml()}
              </div>

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  {specifications ? (
                    <div className="col-md-6 col-12">
                      <div className="detail-last-inner detail-inner">
                        <h3>Specification</h3>
                        {specificationsHtml()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-6 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h3>Payment Mode</h3>
                      {paymentModeHtml()}
                    </div>
                  </div>
                </div>
              </div>

              {/* {specifications ? (
                <div className="order-detail-outer-middle pending-list-outer">
                  <h3>Specification</h3>
                  {specificationsHtml()}
                </div>
              ) : (
                ""
              )} */}

              <div className="order-detail-outer-top order-detail-outer-last">
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Sales Executive : </small>
                        <b>{customerName == null ? "N/A" : salsePersonName}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Category Manager : </small>
                        <b>
                          {catManager === null || !catManager
                            ? "N/A"
                            : catManager}
                        </b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="detail-last-inner detail-inner">
                      <h5 className="m-0">
                        <small>Total Price : </small>
                        <b>INR {totalSumHtml()}-/-</b>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}
export default OrderCompletedDetails;
