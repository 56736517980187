import React, { useState, useEffect, Fragment, useMemo } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import * as myConstList from "./BaseUrl";
import useOrderData from "../Hooks/OrderData";
import { reactTableConfig } from "../lib/reactTableConfig";
const baseUrl = myConstList.baseUrl;
const config = reactTableConfig("Total Orders");

const VendorRejectedOrders = () => {
  const [record, setRecord] = useState([]);
  const [refreshed, setRefreshed] = useState(false);

  // order data hook
  let initialRequestData = useMemo(() => {
    return { status: 3, orderType: "vendor" };
  }, []);

  const { data, loading } = useOrderData(initialRequestData);

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  const columns = [
    {
      key: "id",
      text: "Sr No.",
      className: "srno",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Order ID",
      className: "order_id",
      align: "left",
      sortable: true,
    },
    {
      key: "ad",
      text: "Product Image",
      className: "cust_name",
      align: "left",
      // sortable: true,
      cell: (record) => {
        var pimages;
        if (record.image == null || record.image.split === undefined) {
          pimages = "assets/images/no-image-procurit.png";
        } else {
          pimages = record.image.split(",");
        }
        return (
          <img
            src={
              record.image == null ? pimages : baseUrl + "/static" + pimages[0]
            }
            alt=""
            className="img-fluid"
          />
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total",
      text: "Total Price",
      className: "Total",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.total} INR</Fragment>;
      },
    },
    {
      key: "detail",
      text: "Detail",
      className: "detail",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href={"/vendor-order-details/" + btoa(record.id)}>
              <img
                src="assets/images/view-icon.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </Fragment>
        );
      },
    },
  ];

  return (
    <div id="layout-wrapper">
      {/* <Header />
      <Navbar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>VENDOR REJECTED ORDERS</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    className="table table-bordered table-striped "
                    config={config}
                    records={record}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer refreshed={refreshed} setRefreshed={setRefreshed} />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />{" "}
      </div>
    </div>
  );
};

export default VendorRejectedOrders;
