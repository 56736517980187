import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";
import { Button } from "./Common/Elements/Button";
import { Country, State } from "country-state-city";
import Select from "react-select";
import { selectStyleDark } from "../Hooks/SelectStyle";
const baseUrl = myConstList.baseUrl;

function AddLeads() {
  const noImage = "assets/images/no-image_estimation.png";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [catData, setCatData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [assignedTo, setAssignedTo] = useState("");

  const [custId, setCustId] = useState("");
  const [custData, setCustData] = useState([]);

  // estimation
  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [ventureName, setVentureName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [ventureType, setVentureType] = useState("");
  const [designation, setDesignation] = useState("");
  const [leadSource, setLeadSource] = useState("");

  const [industryNameData, setIndustryNameData] = useState([]);
  const [ventureTypeData, setVentureTypeData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincode, setPincode] = useState("");

  const [formValues, setFormValues] = useState([
    {
      image: [],
      customerName: "",
      prodName: "",
      prodId: "",
      salesPerson: "",
      quantity: 0,
      estimatedDate: "",
      price: 0,
      salesPrice: 0,
      customize: "",
      priceTotal: 0,
      priceTotalTax: 0,
      salesTotal: 0,
      salesTotalTax: 0,
      customizeImage: [],
      customizeName: "",
      isCustom: false,
      specification: "",
      tax: 0,
    },
  ]);

  //disable when select customer
  const [disableCust, setDisableCust] = useState(false);

  // errors //
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numErr, setNumErr] = useState("");
  const [assignedErr, setAssignedErr] = useState("");
  const [categoryErr, setCategoryErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");

  const [industryErr, setIndustryErr] = useState("");
  const [ventureTypeErr, setVentureTypeErr] = useState("");
  const [ventureNameErr, setVentureNameErr] = useState("");
  const [designationErr, setDesignationErr] = useState("");
  const [leadSourceErr, setLeadSourceErr] = useState("");

  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  // estimations errors

  const [formValuesError, setFormValuesError] = useState([
    {
      image: [],
      customerName: "",
      prodName: "",
      prodId: "",
      salesPerson: "",
      quantity: "",
      estimatedDate: "",
      price: "",
      salesPrice: "",
      customize: "",
      priceTotal: "",
      priceTotalTax: "",
      salesTotal: "",
      salesTotalTax: "",
      customizeImage: "",
      customizeName: "",
      isCustom: "",
      specification: "",
      tax: "",
    },
  ]);

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
  useEffect(() => {
    categoryData();
    salesPersonData();
    customerData();
    getIndustryData();
    getDesignationData();
    getLeadSourceData();
  }, []);

  const customerData = () => {
    const data = {
      userType: localStorage.getItem("userType"),
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios.post(baseUrl + "/frontapi/get-customer", data, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        setCustData(resp.data);
      }
    });
  };

  const customerHtml = () => {
    const customerHtml = [];
    var CustomerData = custData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    CustomerData.map((value, i) => {
      return customerHtml.push(<option value={value.id}>{value.name}</option>);
    });
    return customerHtml;
  };

  const categoryData = () => {
    axios
      .post(baseUrl + "/frontapi/category-data-deleted", categoryData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setCatData(resp.data);
        }
      });
  };

  const getIndustryData = () => {
    axios
      .post(baseUrl + "/frontapi/industry-data-deleted", getIndustryData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setIndustryNameData(resp.data);
        }
      });
  };

  const getVentureData = (selectedOption) => {
    let html = [];
    for (let i = 0; i < selectedOption.length; i++) {
      const element = selectedOption[i];

      axios
        .post(baseUrl + "/frontapi/venture-data-byIndustryId", {
          industryId: element.value,
        })
        .then((res) => {
          var resp = res.data;
          if (resp.status === true) {
            resp.data.map((compo) => {
              html.push(compo);
            });
            setVentureTypeData(html);
          }
        });
    }
  };

  const getDesignationData = () => {
    axios
      .post(baseUrl + "/frontapi/designation-data-deleted", getDesignationData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setDesignationData(resp.data);
        }
      });
  };

  const getLeadSourceData = () => {
    axios
      .post(baseUrl + "/frontapi/lead-source-data-deleted", getLeadSourceData)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          setLeadSourceData(resp.data);
        }
      });
  };

  const salesPersonData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    axios
      .post(
        baseUrl + "/frontapi/sales-person-manager-data-deleted",
        salesPersonData,
        config
      )
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setSalesData(resp.data);
        }
      });
  };

  const salesHtml = () => {
    const salesHtml = [];
    var SData = salesData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SData.map((value, i) => {
      return salesHtml.push(<option value={value.id}>{value.name}</option>);
    });
    return salesHtml;
  };

  const designationHtml = () => {
    const designationHtml = [];
    var DData = designationData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    DData.map((value, i) => {
      return designationHtml.push(
        <option value={value.id}>{value.designation}</option>
      );
    });
    return designationHtml;
  };

  const leadSourceHtml = () => {
    const leadSourceHtml = [];
    var LData = leadSourceData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    LData.map((value, i) => {
      return leadSourceHtml.push(
        <option value={value.id}>{value.leadSource}</option>
      );
    });
    return leadSourceHtml;
  };

  const prodHtml = () => {
    const producthtml = [];

    var ProdData = productData.sort(function (a, b) {
      var textA = a.name;
      var textB = b.name;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    ProdData.map((value, i) => {
      return producthtml.push(<option value={value.id}>{value.name}</option>);
    });
    return producthtml;
  };

  const getCustomerShippingAddress = (id) => {
    const data = {
      id: id,
    };
    axios.post(baseUrl + "/frontapi/customer-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        const custData = resp.data.sqlRun[0];

        setName(custData.name);
        setEmail(custData.email);
        setNumber(custData.phoneNumber);
        setVentureName(custData.companyName);
        setCountry(custData.country);
        setStateValue(custData.state);
        setCity(custData.city);
        setPincode(custData.pincode);
        setDisableCust(true);
      }
    });
  };

  const handleChangeEstimations = async (event) => {
    let { name, value } = event.target;

    if (name === "custId") {
      setCustId(value);
      if (!value) {
        setName("");
        setEmail("");
        setNumber("");
        setCountry("");
        setStateValue("");
        setCity("");
        setPincode("");
        setDisableCust(false);
      } else {
        getCustomerShippingAddress(value);
      }
    }

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Please enter customer name");
        return false;
      }
      setNameErr("");
    }

    if (name === "email") {
      setEmail(value);
      // if (!value) {
      //   setEmailErr("Please enter email");
      //   return false;
      // }
      if (value && value.length > 0) {
        if (!value.match(emailReg)) {
          setEmailErr("Please enter valid email");
          return false;
        }
      }
      setEmailErr("");
    }
    if (name === "number") {
      setNumber(value);
      if (!value) {
        setNumErr("Please enter mobile number");
        return false;
      }

      if (!/^[6-9]/.test(value)) {
        setNumErr("Please enter valid mobile number");
        return false;
      }

      if (value.length < 10 || value.length > 10) {
        setNumErr("Mobile number should be of 10 digits");
        return false;
      }

      setNumErr("");
    }

    if (name === "description") {
      setDescription(value);
      if (!value) {
        setDescriptionErr("Please enter description");
        return false;
      }
      setDescriptionErr("");
    }

    if (name === "categoryId") {
      setCategoryId(value);
      clearEstimation();
      if (!value) {
        setCategoryErr("Please select category");
        setProductData([]);
        return false;
      } else {
        // productDataFunc(value);
      }
      setCategoryErr("");
    }

    if (name === "assignedTo") {
      setAssignedTo(value);
      if (!value) {
        setAssignedErr("Please select assign to");
        return false;
      }
      setAssignedErr("");
    }

    if (name === "ventureName") {
      setVentureName(value);
      if (!value) {
        setVentureNameErr("Please enter venture name");
        return false;
      }
      setVentureNameErr("");
    }

    if (name === "designation") {
      setDesignation(value);
      // if (!value) {
      //   setDesignationErr("Please enter designation");
      //   return false;
      // }
      setDesignationErr("");
    }

    if (name === "leadSource") {
      setLeadSource(value);
      // if (!value) {
      //   setLeadSourceErr("Please select lead source");
      //   return false;
      // }
      setLeadSourceErr("");
    }

    if (name === "country") {
      if (!value || value === "Select Country") {
        setCountry(value);
        // setCountryError("Please select country");
        return false;
      }
      setStateValue("");
      setCountry(value);
      setCountryError("");
      return false;
    }

    if (name === "state") {
      if (!value || value === "Select State") {
        setStateValue(value);
        // setStateError("Please select state");
        return false;
      }
      setStateValue(value);
      setStateError("");
      return false;
    }

    if (name === "city") {
      if (!value) {
        setCity(value);
        setCityError("City is required");
        return false;
      }
      setCity(value);
      setCityError("");
      return false;
    }

    if (name === "zipcode") {
      let valueNew = value.trim();
      let regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
      if (!valueNew) {
        setPincode(valueNew);
        setPincodeError("Pincode is required");
        return false;
      }

      if (!/^[1-9]\d*$/.test(valueNew)) {
        setPincode(valueNew);
        setPincodeError("Please enter valid input");
        return false;
      }

      if (!valueNew.match(regex)) {
        setPincode(valueNew);
        setPincodeError("Please enter valid input");
        return false;
      }
      setPincode(valueNew);
      setPincodeError("");
      return false;
    }
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleValidationDot = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "." ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = "";

    if (!name) {
      setNameErr("Please enter customer name");
      return false;
    }

    error = checkSpace("name", name);
    if (error) {
      setNameErr(error);
      return false;
    }

    if (email.length > 0) {
      if (!email.match(emailReg)) {
        setEmailErr("Please enter valid email");
        return false;
      }
    }

    if (!number) {
      setNumErr("Please enter mobile number");
      return false;
    }

    if (number.length > 10) {
      setNumErr("Mobile No. should be of 10 digits");
      return false;
    }
    if (number.length < 10) {
      setNumErr("Mobile No. should be of 10 digits");
      return false;
    }

    if (!categoryId || categoryId.length === 0) {
      setCategoryErr("Please select category");
      return false;
    }

    if (!assignedTo || assignedTo === "Please select") {
      setAssignedErr("Please select assign to");
      return false;
    }

    if (!industryName || industryName.length === 0) {
      setIndustryErr("Please select industry type");
      return false;
    }

    if (!ventureType || ventureType.length === 0) {
      setVentureTypeErr("Please select venture type");
      return false;
    }

    if (!ventureName) {
      setVentureNameErr("Please enter venture name");
      return false;
    }

    error = checkSpace("venture name", ventureName);
    if (error) {
      setVentureNameErr(error);
      return false;
    }

    if (!country || country === "Select Country") {
      setCountryError("Please select country");
      return false;
    }

    if (!stateValue || stateValue === "Select State") {
      setStateError("Please select state");
      return false;
    }

    if (!city) {
      setCityError("City is required");
      return false;
    }

    error = checkSpace("City", city);
    if (error) {
      setCityError(error);
      return false;
    }

    let valueNew = pincode;

    if (!valueNew) {
      setPincodeError("Pincode is required");
      return false;
    }

    if (!/^[1-9]\d*$/.test(valueNew)) {
      setPincodeError("Please enter valid input");
      return false;
    }

    if (valueNew.length > 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }
    if (valueNew.length < 6) {
      setPincodeError("Pincode should be of 6 digits");
      return false;
    }

    if (!designation) {
      setDesignationErr("Please select designation");
      return false;
    }

    if (!leadSource || leadSource.length === 0) {
      setLeadSourceErr("Please select lead source");
      return false;
    }

    if (!description) {
      setDescriptionErr("Please enter description");
      return false;
    }

    error = checkSpace("description", description);
    if (error) {
      setDescriptionErr(error);
      return false;
    }

    if (isEstimationOpen) {
      for (let i = 0; i < formValues.length; i++) {
        const element = formValues[i];
        let formError = [...formValuesError];

        if (!element.prodId || element.prodId === "") {
          toast.dismiss();
          toast.error("Please select product name");
          return false;
        }

        if (!element.quantity || element.quantity === 0) {
          formError[i].quantity = "Please enter quantity";
          setFormValuesError(formError);

          return false;
        }

        if (element.quantity < 0 || element.quantity == 0) {
          formError[i].quantity = "Quantity cannot be zero or negative value";
          setFormValuesError(formError);
          return false;
        }

        // if (!element.estimatedDate || element.estimatedDate === 0) {
        //   formError[i].estimatedDate = "Please enter estimated delivery date";
        //   setFormValuesError(formError);
        //   return false;
        // }

        if (!element.salesPrice || element.salesPrice === 0) {
          formError[i].salesPrice = "Please enter sales price";
          setFormValuesError(formError);
          return false;
        }

        if (element.salesPrice < 0 || element.salesPrice == 0) {
          formError[i].salesPrice =
            "Sales price cannot be zero or negative value";
          setFormValuesError(formError);
          return false;
        }

        if (!element.customize || element.customize === "") {
          toast.dismiss();
          toast.error("Please select customization option");
          return false;
        }

        if (!element.customize || element.customize === "") {
          toast.dismiss();
          toast.error("Please select customization option");
          return false;
        }

        if (
          !totalAmount ||
          Number(totalAmount) === 0 ||
          Number(totalAmount) < 0
        ) {
          toast.dismiss();
          toast.error(
            "Order value cannot be zero or negative, please refresh and try again"
          );
          return false;
        }
      }

      if (parseFloat(discount) < 0) {
        toast.dismiss();
        toast.error("Discount % cannot be negative");
        return false;
      }
      if (parseFloat(discount) >= 100) {
        toast.dismiss();
        toast.error("Discount cannot be 100%");
        return false;
      }

      if (parseFloat(discountAmount) < 0) {
        toast.dismiss();
        toast.error("Discount amount cannot be negative");
        return false;
      }

      if (parseFloat(discountAmount) >= totalAmount) {
        toast.dismiss();
        toast.error("Discount amount cannot be more than the order value");
        return false;
      }
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    const obj = {
      custId: custId,
      name: name,
      email: email,
      number: number,
      description: description,
      categoryId: categoryId,
      assignedTo: assignedTo,
      isEstimation: isEstimationOpen,
      mainData: JSON.stringify(formValues),
      totalAmount: totalAmount,
      discount: discount,
      discountAmount: discountAmount,
      industryName: industryName,
      ventureType: ventureType,
      ventureName: ventureName,
      country: country,
      stateValue: stateValue,
      city: city,
      pincode: pincode,
      designation: designation,
      leadSource: leadSource,
    };

    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/leads-add", obj, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();

        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            window.location = "/leads";
          }, 3000);
          return false;
        } else {
          toast.error(resp.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const toggleEstimation = (e) => {
    e.preventDefault();
    if (isEstimationOpen === true) {
      setIsEstimationOpen(false);
    } else {
      setIsEstimationOpen(true);
    }
    clearEstimation();
  };

  const handleDiscount = (e) => {
    let { id, value } = e.target;

    let totalPrice = 0;
    let totalPriceNoGst = 0;
    let tax = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.salesTotalTax
        ? parseFloat(element.salesTotalTax)
        : 0;

      tax += element.tax ? element.tax : 0;
      totalPriceNoGst += element.salesTotal ? element.salesTotal : 0;
    }

    if (id === "discount") {
      setDiscount(value);
      if (value > 0 && totalPriceNoGst > 0) {
        let discount_amount = (totalPriceNoGst * value) / 100;

        if (discount_amount > totalPriceNoGst || value == 0) {
          setTotalAmount(totalPrice);
        } else {
          // let total_discounted_amount = totalPrice - discount_amount;

          let newTotal = 0;
          // let totalPriceNoGst1 = 0;
          for (let i = 0; i < formValues.length; i++) {
            const element = formValues[i];

            let totalPriceNoGst1 = element.salesTotal
              ? parseFloat(element.salesTotal)
              : 0;

            let discount_amount = (totalPriceNoGst1 * value) / 100;

            let total_discounted_amount = totalPriceNoGst1 - discount_amount;

            tax = element.tax ? element.tax : 0;

            newTotal +=
              total_discounted_amount + (total_discounted_amount * tax) / 100;
          }

          // let newTotal =
          //   total_discounted_amount + (total_discounted_amount * tax) / 100;

          setTotalAmount(newTotal.toFixed(2));
        }

        setDiscountAmount(parseFloat(discount_amount).toFixed(2));
      } else {
        setTotalAmount(totalPrice);
        setDiscountAmount(0);
        setDiscount(0);
      }
    }

    if (id === "discountAmount") {
      setDiscountAmount(value);
      if (value > 0 && totalPriceNoGst > 0) {
        let discount_percent = (100 * value) / totalPriceNoGst;

        if (value > totalPriceNoGst || value == 0) {
          setTotalAmount(totalPrice);
        } else {
          let newTotal = 0;

          for (let i = 0; i < formValues.length; i++) {
            const element = formValues[i];

            let totalPriceNoGst1 = element.salesTotal
              ? parseFloat(element.salesTotal)
              : 0;

            let discount_amount = (totalPriceNoGst1 * discount_percent) / 100;

            let total_discounted_amount = totalPriceNoGst1 - discount_amount;

            tax = element.tax ? element.tax : 0;

            newTotal +=
              total_discounted_amount + (total_discounted_amount * tax) / 100;
          }

          setTotalAmount(newTotal.toFixed(2));
        }

        setDiscount(parseFloat(discount_percent).toFixed(2));
      } else {
        setTotalAmount(totalPrice);
        setDiscountAmount(0);
        setDiscount(0);
      }
    }
  };

  const clearEstimation = () => {
    setFormValues([
      {
        image: [],
        customerName: "",
        prodName: "",
        prodId: "",
        salesPerson: "",
        quantity: 0,
        estimatedDate: "",
        price: 0,
        salesPrice: 0,
        customize: "",
        priceTotal: 0,
        priceTotalTax: 0,
        salesTotal: 0,
        salesTotalTax: 0,
        customizeImage: [],
        customizeName: "",
        isCustom: false,
        specification: "",
        tax: 0,
      },
    ]);

    setFormValuesError([
      {
        image: [],
        customerName: "",
        prodName: "",
        prodId: "",
        salesPerson: "",
        quantity: "",
        estimatedDate: "",
        price: "",
        salesPrice: "",
        customize: "",
        priceTotal: "",
        priceTotalTax: "",
        salesTotal: "",
        salesTotalTax: "",
        customizeImage: [],
        customizeName: "",
        isCustom: false,
        specification: "",
        tax: "",
      },
    ]);

    setTotalAmount(0);
    setDiscount(0);
    setDiscountAmount(0);

    // err
  };

  let handleChangeAll = (i, e) => {
    e.preventDefault();
    let newFormValues = [...formValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    // setFormValues(newFormValues);

    // if (e.target.name === "customerName") {
    //   if (!newFormValues[i].customerName) {
    //     newFormValuesError[i][e.target.name] = tempErr;
    //     setFormValuesError(newFormValuesError);
    //     return false;
    //   } else {
    //     newFormValuesError[i][e.target.name] = "";
    //     setFormValuesError(newFormValuesError);
    //   }
    // }

    if (e.target.name === "prodId") {
      setTotalAmount(0);
      setDiscount(0);
      setDiscountAmount(0);
      valv.salesPrice = 0;
      valv.quantity = 0;
      valv.salesTotal = 0;
      valv.salesTotalTax = 0;
      valv.priceTotal = 0;
      valv.priceTotalTax = 0;
      valv.tax = 0;
      valv.customize = "";
      valv.specification = "";
      if (
        !newFormValues[i].prodId ||
        newFormValues[i].prodId === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);

        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);

        const config = {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        };
        let params = {
          id: valv.prodId,
        };

        axios
          .post(baseUrl + "/frontapi/product-single", params, config)
          .then((res) => {
            var resp = res.data;
            if (resp.status === false) {
              return;
            }

            if (resp.status === true) {
              // setTax(resp.data.sqlRun[0].tax);
              valv.prodName = resp.data.sqlRun[0]
                ? resp.data.sqlRun[0].name
                : "";
              valv.tax = resp.data.sqlRun[0] ? resp.data.sqlRun[0].tax : "";
              valv.price = resp.data.sqlRun[0] ? resp.data.sqlRun[0].price : "";
              valv.salesPrice = resp.data.sqlRun[0]
                ? resp.data.sqlRun[0].sale_price
                : "";

              if (resp.data.sqlRun[0].image) {
                valv.image = resp.data.sqlRun[0]
                  ? // ? resp.data.sqlRun[0].image
                    resp.data.sqlRun[0].image.split(",", 1)
                  : "";
              } else {
                valv.image = "";
              }

              setFormValues(newFormValues);
            }
          });
      }
    }

    if (e.target.name === "quantity") {
      setTotalAmount(0);
      setDiscount(0);
      setDiscountAmount(0);
      if (!newFormValues[i].quantity) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;
        valv.priceTotal = 0;
        valv.priceTotalTax = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }
      //  else {
      //   newFormValuesError[i][e.target.name] = "";
      //   setFormValuesError(newFormValuesError);
      // }

      if (newFormValues[i].quantity < 0 || newFormValues[i].quantity == 0) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;
        valv.priceTotal = 0;
        valv.priceTotalTax = 0;
        newFormValuesError[i][e.target.name] =
          "Quantity cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }

      // amount(prodPrice)

      const amountPriceNoTax = newFormValues[i].quantity * valv.price;

      // amount(prodPrice) with tax

      const amountPrice = newFormValues[i].quantity * valv.price;
      const withTaxPrice = (amountPrice * valv.tax) / 100;
      const totalWithTaxPrice = amountPrice + withTaxPrice;

      valv.priceTotal = amountPriceNoTax.toFixed(2);
      valv.priceTotalTax = totalWithTaxPrice.toFixed(2);

      if (newFormValues[i].salesPrice < 0 || newFormValues[i].salesPrice == 0) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;

        return false;
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);

      // amount(sales)

      const amountSalesNoTax = newFormValues[i].quantity * valv.salesPrice;

      // amount(sales) with tax

      const amountSales = newFormValues[i].quantity * valv.salesPrice;
      const withTax = (amountSales * valv.tax) / 100;

      const totalWithTax = amountSales + withTax;

      valv.salesTotal = amountSalesNoTax.toFixed(2);

      valv.salesTotalTax = totalWithTax.toFixed(2);
    }

    if (e.target.name === "price") {
      if (!newFormValues[i].price) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      setTotalAmount(0);
      setDiscount(0);
      setDiscountAmount(0);

      if (newFormValues[i].price < 0 || newFormValues[i].price == 0) {
        newFormValuesError[i][e.target.name] =
          "Product price cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
      // amount(prodPrice)

      // const amountPriceNoTaxx = newFormValues[i].price * avaliableQty;
      // valv.priceTotal = amountPriceNoTaxx;
      // // amount(prodPrice) with tax

      // const amountPricee = newFormValues[i].price * avaliableQty;
      // const withTaxPricee = (amountPricee * valv.tax) / 100;
      // const totalWithTaxPricee = amountPricee + withTaxPricee;
      // valv.priceTotalTax = totalWithTaxPricee;
    }

    if (e.target.name === "salesPrice") {
      setTotalAmount(0);
      setDiscount(0);
      setDiscountAmount(0);
      if (!newFormValues[i].salesPrice) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].salesPrice < 0 || newFormValues[i].salesPrice == 0) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;
        newFormValuesError[i][e.target.name] =
          "Product sales price cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].quantity < 0 || newFormValues[i].quantity == 0) {
        valv.salesTotal = 0;
        valv.salesTotalTax = 0;
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
        return false;
      }

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);

      // amount(salesPrice)

      const amountPriceNoTaxx =
        newFormValues[i].salesPrice * newFormValues[i].quantity;
      valv.salesTotal = amountPriceNoTaxx.toFixed(2);

      // amount(salesPrice) with tax

      const amountPricee =
        newFormValues[i].salesPrice * newFormValues[i].quantity;
      const withTaxPricee = (amountPricee * valv.tax) / 100;
      const totalWithTaxPricee = amountPricee + withTaxPricee;

      valv.salesTotalTax = totalWithTaxPricee.toFixed(2);
    }

    if (e.target.name === "estimatedDate") {
      if (!newFormValues[i].estimatedDate) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "specification") {
      if (!newFormValues[i].specification) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
      }
    }

    if (e.target.name === "customize") {
      if (!newFormValues[i].customize) {
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);
        if (newFormValues[i].customize === "yes") {
          // valv.isCustom = true;
          return false;
        } else {
          // valv.isCustom = false;
        }
      }
    }

    if (e.target.name === "customizeImage") {
      var customizeImageTemp = e.target.files[0];

      var fileSize = customizeImageTemp.size;

      if (fileSize > 3e6) {
        newFormValuesError[i][e.target.name] =
          "Image size too large, Send it via email";
        setFormValuesError(newFormValuesError);

        return false;
      }

      if (!customizeImageTemp.name.match(/\.(jpg|jpeg|png)$/)) {
        newFormValuesError[i][e.target.name] =
          "Unsupported format send via email";
        setFormValuesError(newFormValuesError);

        return false;
      }
      valv.customizeImage = [e.target.files[0]];
      valv.customizeName = customizeImageTemp.name;
      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
    }

    setFormValues((prev) => prev);

    // calling func for totalAmount
    createTotalAmount();
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);
    setFormValuesError([...formValuesError, {}]);

    let totalPrice = 0;
    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.salesTotal ? parseFloat(element.salesTotal) : 0;
    }

    setTotalAmount(totalPrice);
    setDiscount(0);
    setDiscountAmount(0);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);

    let totalPrice = 0;
    for (let i = 0; i < newFormValues.length; i++) {
      const element = newFormValues[i];

      totalPrice += element.salesTotal ? parseFloat(element.salesTotal) : 0;
    }

    setTotalAmount(totalPrice);

    setDiscount(0);
    setDiscountAmount(0);
  };

  const createTotalAmount = () => {
    let totalPrice = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.salesTotalTax
        ? parseFloat(element.salesTotalTax)
        : 0;
    }
    setTotalAmount(totalPrice);
  };

  const totalPriceHTML = () => {
    const html = [];

    let totalPrice = 0;
    let totalPriceNoGst = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.salesTotalTax
        ? parseFloat(element.salesTotalTax)
        : 0;

      totalPriceNoGst += element.salesTotal ? element.salesTotal : 0;
    }

    html.push(
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
          <div className="form-group">
            <div className="person-detail">
              <p>
                Order Value (With GST):{" "}
                <b>{parseFloat(totalPrice).toFixed(2)} INR</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    );

    return html;
  };

  const handleChangeCategory = (selectedOption) => {
    clearEstimation();
    setCategoryErr("");

    setCategoryId(selectedOption);

    if (selectedOption.length > 0) {
      productDataFunc(selectedOption);
    } else {
      setProductData([]);
    }
  };

  const handleChangeIndustry = (selectedOption) => {
    // clearEstimation();
    setIndustryErr("");
    setVentureTypeErr("");
    setVentureType("");
    setIndustryName(selectedOption);

    if (selectedOption.length > 0) {
      getVentureData(selectedOption);
    } else {
      setVentureTypeData([]);
    }
  };

  const handleChangeVenture = (selectedOption) => {
    setVentureTypeErr("");
    setVentureType(selectedOption);
  };

  const productDataFunc = (selectedOption) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    };

    let html = [];

    selectedOption.map((element) => {
      axios
        .post(
          baseUrl + "/frontapi/product-data-byCatId",
          { categoryId: element.value },
          config
        )
        .then((res) => {
          var resp = res.data;
          if (resp.status === true) {
            resp.data.map((compo) => {
              html.push(compo);
            });
            setProductData(html);
          }
        });
    });
  };

  const countryDropDownHtml = () => {
    const countries = Country.getAllCountries();

    // Find the index of India in the country list
    const indiaIndex = countries.findIndex(
      (country) => country.isoCode === "IN"
    );

    // Move India to the beginning of the country list
    if (indiaIndex !== -1) {
      const india = countries.splice(indiaIndex, 1)[0];
      countries.unshift(india);
    }

    // Generate the HTML for country dropdown options
    const options = countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));

    return options;
  };

  const stateDropDownHtml = () => {
    const html = [];
    const states = State.getStatesOfCountry(country);
    states.map((state) => {
      return html.push(
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      );
    });
    return html;
  };

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>ADD Leads</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Customer Data</label>
                          <select
                            type="text"
                            name="custId"
                            value={custId}
                            placeholder="Customer"
                            className="form-control"
                            onChange={handleChangeEstimations}
                          >
                            <option value="">Select Customer</option>
                            {customerHtml()}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={name}
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          />
                          <p style={{ color: "red" }}>{nameErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                            autoComplete="off"
                          />
                          <p style={{ color: "red" }}>{emailErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="number"
                            name="number"
                            onKeyDown={handleValidationDot}
                            placeholder="Mobile Number"
                            maxLength={10}
                            value={number}
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          />
                          <p style={{ color: "red" }}>{numErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Category</label>
                          <Select
                            value={categoryId}
                            onChange={handleChangeCategory}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="catId"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No category found"}
                            options={catData.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                          />
                          <p style={{ color: "red" }}>{categoryErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Assign To</label>
                          <select
                            className="form-control"
                            name="assignedTo"
                            value={assignedTo}
                            onChange={handleChangeEstimations}
                          >
                            <option>Please select</option>
                            <option>Self</option>
                            {salesHtml()}
                          </select>
                          <p style={{ color: "red" }}>{assignedErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Industry Type</label>
                          <Select
                            value={industryName}
                            onChange={handleChangeIndustry}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="industryId"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No industry found"}
                            options={industryNameData.map((option) => ({
                              value: option.id,
                              label: option.industry,
                            }))}
                          />
                          <p style={{ color: "red" }}>{industryErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Venture Type</label>
                          <Select
                            value={ventureType}
                            onChange={handleChangeVenture}
                            styles={selectStyleDark}
                            className="custom-select"
                            name="ventureType"
                            isMulti
                            isClearable={true}
                            noOptionsMessage={() => "No venture type found"}
                            options={ventureTypeData.map((option) => ({
                              value: option.id,
                              label: option.venture,
                            }))}
                          />
                          <p style={{ color: "red" }}>{ventureTypeErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Venture Name</label>
                          <input
                            type="text"
                            name="ventureName"
                            placeholder="Venture Name"
                            value={ventureName}
                            className="form-control"
                            onChange={handleChangeEstimations}
                          />
                          <p style={{ color: "red" }}>{ventureNameErr}</p>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            type="text"
                            name="country"
                            value={country}
                            placeholder="Country"
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          >
                            <option>Select Country</option>
                            {countryDropDownHtml()}
                          </select>
                          <span>
                            <p style={{ color: "red" }}> {countryError} </p>
                          </span>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            type="text"
                            name="state"
                            value={stateValue}
                            placeholder="State"
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          >
                            <option>Select State</option>
                            {stateDropDownHtml()}
                          </select>
                          <span>
                            <p style={{ color: "red" }}> {stateError} </p>
                          </span>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            value={city}
                            placeholder="City"
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          />
                          <span>
                            <p style={{ color: "red" }}> {cityError} </p>
                          </span>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="form-group">
                          <label>Pincode</label>
                          <input
                            type="number"
                            name="zipcode"
                            onKeyDown={handleValidationDot}
                            value={pincode}
                            maxLength={6}
                            placeholder="Pincode"
                            className="form-control"
                            onChange={handleChangeEstimations}
                            disabled={disableCust}
                          />
                          <span>
                            <p style={{ color: "red" }}> {pincodeError} </p>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Designation</label>
                          <select
                            type="text"
                            name="designation"
                            placeholder="Designation"
                            className="form-control"
                            onChange={handleChangeEstimations}
                          >
                            <option value={""}>Select Designation</option>
                            {designationHtml()}
                          </select>
                          <p style={{ color: "red" }}>{designationErr}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Lead Source</label>
                          <select
                            type="text"
                            name="leadSource"
                            placeholder="Lead Source"
                            className="form-control"
                            onChange={handleChangeEstimations}
                          >
                            <option value={""}>Select Lead Source</option>
                            {leadSourceHtml()}
                          </select>
                          <p style={{ color: "red" }}>{leadSourceErr}</p>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={description}
                            className="form-control resize"
                            onChange={handleChangeEstimations}
                          />
                          <p style={{ color: "red" }}>{descriptionErr}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="btn-group"> */}
                    <button
                      className="btn btn-primary"
                      onClick={toggleEstimation}
                    >
                      {isEstimationOpen
                        ? "Cancel Estimation"
                        : "Create Estimation"}
                    </button>
                    {/* </div> */}

                    {isEstimationOpen && (
                      <>
                        <div className="section-heading">
                          <h3>
                            <b>Estimation Creation: </b>
                          </h3>
                        </div>
                        <div className="row">
                          {formValues.map((element, index) => {
                            return (
                              <>
                                {/* <div className="col-12">
                                <div className="form-group mb-0">
                                  <label>Product Image</label>
                                </div>
                              </div> */}

                                {/* <div className="col-12">
                                <div className="products-images-outer d-flex">
                                  <div className="product-img-left order-image">
                                    <img
                                      src={
                                        element?.image?.length == "0"
                                          ? noImage
                                          : baseUrl + "/static/" + element.image
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div> */}

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>Product Name</label>
                                    <select
                                      className="form-control"
                                      name="prodId"
                                      onChange={(e) =>
                                        handleChangeAll(index, e)
                                      }
                                      value={element.prodId || ""}
                                    >
                                      <option value="">Please select</option>
                                      {prodHtml()}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>Quantity</label>
                                    <input
                                      type="number"
                                      name="quantity"
                                      onKeyDown={handleValidation}
                                      placeholder="Quantity"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChangeAll(index, e)
                                      }
                                      value={element.quantity || ""}
                                    />
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesError[index].quantity}{" "}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Estimated Delivery Date</label>
                                  <input
                                    type="date"
                                    name="estimatedDate"
                                    placeholder=" Estimated Date"
                                    className="form-control"
                                    onChange={(e) => handleChangeAll(index, e)}
                                    value={element.estimatedDate || ""}
                                  />
                                  <p style={{ color: "red" }}>
                                    {" "}
                                    {formValuesError[index].estimatedDate}{" "}
                                  </p>
                                </div>
                              </div> */}

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>Tax(%)</label>
                                    <input
                                      type="number"
                                      name="tax"
                                      onKeyDown={handleValidation}
                                      placeholder="Tax"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChangeAll(index, e)
                                      }
                                      value={element.tax || ""}
                                      disabled
                                    />
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesError[index].tax}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>Sales Price</label>
                                    <input
                                      type="number"
                                      onKeyDown={handleValidation}
                                      name="salesPrice"
                                      placeholder="Sales Price"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChangeAll(index, e)
                                      }
                                      value={element.salesPrice || ""}
                                    />
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {formValuesError[index].salesPrice}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>Specifications</label>
                                    <input
                                      type="text"
                                      name="specification"
                                      placeholder="Specifications"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChangeAll(index, e)
                                      }
                                      value={element.specification || ""}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12">
                                  <div className="add-row-group d-flex align-items-center justify-content-between">
                                    <div className="form-group w-100">
                                      <label>Customization</label>
                                      <select
                                        className="form-control"
                                        name="customize"
                                        value={element.customize || ""}
                                        onChange={(e) =>
                                          handleChangeAll(index, e)
                                        }
                                      >
                                        <option value="">Please select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                        <option value="via">
                                          Sent via Email
                                        </option>
                                      </select>
                                    </div>

                                    <div className="add-row">
                                      {index ? (
                                        <a
                                          className="add-btn add"
                                          href="#!"
                                          onClick={() =>
                                            removeFormFields(index)
                                          }
                                        >
                                          -
                                        </a>
                                      ) : null}
                                      <a
                                        // className="add-btn add btn btn-primary"
                                        href="#!"
                                        onClick={() => addFormFields()}
                                      >
                                        +
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group mt-2 mb-5">
                                      <label>
                                        <b>
                                          Sales Price Total(exclusive tax):{" "}
                                          {element.salesTotal}
                                        </b>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group mt-2 mb-5">
                                      <label>
                                        <b>
                                          Sales Price Total(inclusive tax):{" "}
                                          {element.salesTotalTax}
                                        </b>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group mt-2 mb-5">
                                      <label>
                                        <b>
                                          Price Total(exclusive tax) :{" "}
                                          {element.priceTotal}
                                        </b>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group mt-2 mb-5">
                                      <label>
                                        <b>
                                          Price Total(inclusive tax) :{" "}
                                          {element.priceTotalTax}
                                        </b>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="form-group">
                              <label>Discount %</label>

                              <input
                                type="number"
                                className="form-control"
                                name="discount"
                                onKeyDown={handleValidation}
                                value={discount}
                                id="discount"
                                onChange={handleDiscount}
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-group">
                              <label>Discount Amount</label>

                              <input
                                type="number"
                                className="form-control"
                                name="discountAmount"
                                onKeyDown={handleValidation}
                                value={discountAmount}
                                id="discountAmount"
                                onChange={handleDiscount}
                              />
                            </div>
                          </div>
                        </div>

                        {totalAmount <= 0 ? (
                          <div>{totalPriceHTML()}</div>
                        ) : (
                          <div className="row">
                            <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
                              <div className="form-group">
                                <div className="person-detail">
                                  <p>
                                    Order Value (With GST):{" "}
                                    <b>
                                      {parseFloat(totalAmount).toFixed(2)} INR
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </form>
                  <ButtonGroup
                    onClick={handleSubmit}
                    link="/leads"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default AddLeads;
