import Spinner from "react-bootstrap/Spinner";

export function LoadingSpinnerButton() {
  return (
    <button className="btn btn-primary" disabled>
      <Spinner animation="border" role="status" size="sm">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </button>
  );
}

export function LoadingSpinner() {
  return (
    <Spinner animation="border" role="status" size="sm">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
