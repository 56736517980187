import { useState, useEffect } from "react";
import axios from "axios";
import * as myConstList from "../components/BaseUrl";
const baseUrl = myConstList.baseUrl;

const useOrderData = (initialRequestData) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [respData, setResponse] = useState(null);
  const fetchData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      };

      const response = await axios.post(
        baseUrl + "/frontapi/order-data",
        initialRequestData,
        config
      );
      const responseData = response.data;

      if (responseData.status === false) {
        setError(responseData.message);
      } else if (responseData.status === true) {
        setData(responseData.data);
        setResponse(responseData);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch when component mounts
  useEffect(() => {
    fetchData();
  }, [initialRequestData]);

  // Define a refetch function
  const refetch = () => {
    setLoading(true); // Set loading to true while refetching
    fetchData();
  };

  return { data, loading, error, refetch, respData };
};

export default useOrderData;
