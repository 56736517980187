import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectStyle } from "../Hooks/SelectStyle";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Footer from "./Footer";
import Select from "react-select";
import { Button } from "./Common/Elements/Button";
const baseUrl = myConstList.baseUrl;

function AddOrdersManufacturer(props) {
  const [vendorNameErr, setVendorNameErr] = useState("");
  const [specification, setSpecification] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [productQuantity, setProductQuantity] = useState("");
  const [produtData, setProductData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [vendorDataList, setVendorDataList] = useState([]);
  const [defaultVendor, setDefaultVendor] = useState(null);
  const [selectedOptionsArray, setSelectedOptionsArray] = useState([null]);
  const [pData, setPData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [units, setUnits] = useState("");
  const noImage = "assets/images/no-image-procurit.png";
  const [rowMaterialQuantity, setRowMaterialQuantity] = useState([]);
  const [rowMaterialQuantityErrors, setRowMaterialQuantityErrors] = useState(
    []
  );
  const [productName, setProductName] = useState("");
  const [materialId, setMaterialId] = useState("");

  const [materialQuantity, setMaterialQuantity] = useState("");

  const [vendorPriceError, setVendorPriceError] = useState("");
  const [buyNameErr, setBuyNameErr] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalWithoutGst, setTotalWithoutGst] = useState(0);
  const [materialPrice, setMaterialPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [tax, setTax] = useState("");
  const [total, setTotal] = useState(0);
  const [productId, setProductId] = useState(null);
  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [materialAdd, setMaterialAdd] = useState([]);
  const [inventoryValues, setInventoryValues] = useState([
    {
      material_type: "",
      material_quantity: "",
      material_id: "",
      material_available_quantity: "",
    },
  ]);

  const [formValues, setFormValues] = useState([
    {
      price: 0,
      tax: 0,
      quantity: 0,
      totalPrice: 0,
      withoutTotalPrice: 0,
      hsnCode: "",
      vendorId: "",
      productId: "",
      image: [],
      specification: "",
    },
  ]);
  const [formValuesError, setFormValuesError] = useState([
    {
      price: "",
      tax: "",
      hsnCode: "",
      quantity: "",
      totalPrice: "",
      withoutTotalPrice: "",
      vendorId: "",
      productId: "",
      image: [],
      specification: "",
    },
  ]);

  useEffect(() => {
    productData();
    vendorData();
    getMaterialData();
  }, [props]);

  const productData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/product-data-byCatId", {}, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          const finishedProducts = resp.data.filter(
            (product) =>
              product.prod_type === "finished" && product.isDeleted === "1"
          );
          setProductData(finishedProducts);
        }
      });
  };

  const submitData = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    rowMaterialQuantity.forEach((row, index) => {
      formValues[index].rowMaterials = row;
    });

    // return;
    const data = {
      attributes: JSON.stringify(formValues),
      units: units,
    };

    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];
      let addressError = [...formValuesError];
      if (!element.vendorId || element.vendorId === "Select Vendor") {
        addressError[i].vendorId = "This field is required";
        setFormValuesError(addressError);
        return false;
      } else {
        addressError[i].vendorId = "";
        setFormValuesError(addressError);
      }

      if (!element.productId || element.productId === "") {
        addressError[i].productId = "This field is required";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.quantity) {
        addressError[i].quantity = "This field is required";
        setFormValuesError(addressError);
        return false;
      }

      if (element.quantity <= 0) {
        addressError[i].quantity = "Quantity cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.price) {
        addressError[i].price = "This field is required";
        setFormValuesError(addressError);
        return false;
      }
      if (element.price <= 0) {
        addressError[i].price = "Price cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }

      if (!element.totalPrice) {
        addressError[i].totalPrice = "This field is required";
        setFormValuesError(addressError);
        return false;
      }
      if (element.totalPrice <= 0) {
        addressError[i].totalPrice = "Total price cannot be zero or negative";
        setFormValuesError(addressError);
        return false;
      }
    }

    let hasErrors = false;

    if (materialData && materialData.length > 0) {
      if (rowMaterialQuantity && rowMaterialQuantity.length > 0) {
        if (materialData[0].data.length !== rowMaterialQuantity[0].length) {
          toast.dismiss();
          toast.error("Please add raw material quantity");
          return;
        }
      } else {
        toast.dismiss();
        toast.error("Please add raw material quantity");
        return;
      }
    }

    const updatedErrors = rowMaterialQuantity.map(
      (productData, indexProduct) => {
        return productData.map((rowMaterial, indexRowMaterial) => {
          const quantityValue = rowMaterial?.quantity;
          const validationError = validateQuantity(quantityValue);

          if (quantityValue === "" || quantityValue <= 0 || validationError) {
            hasErrors = true;
            return validationError;
          } else {
            // No error
            return "";
          }
        });
      }
    );

    // If there are errors, update the error state and prevent submission
    if (hasErrors) {
      setRowMaterialQuantityErrors(updatedErrors);
      return;
    }
    setLoading(true);

    axios
      .post(baseUrl + "/frontapi/add-manufacture-order-vendor", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          setLoading(false);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2500);

          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
        setLoading(false);
      });
  };

  const vendorData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };
    axios.post(baseUrl + "/frontapi/vendor-data", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        return;
      }
      if (resp.status === true) {
        let sorted = resp.data
          .filter((item) => item.is_deleted !== "1")
          .sort((a, b) => a.name.localeCompare(b.name));

        setVendorDataList(sorted);
      }
    });
  };

  const materialDataApi = async (index, productId) => {
    const data = {
      productId,
    };

    try {
      const response = await axios.post(
        baseUrl + "/frontapi/get-materials",
        data
      );
      const resp = response.data;

      if (resp.status === true) {
        let newMaterialData = [...materialData];
        newMaterialData[index] = { data: resp.data };
        setMaterialData(newMaterialData);
      }
    } catch (error) {}
  };

  const handeSubmitMaterial = (e, currantProduct, index) => {
    e.preventDefault();
    const { productId } = currantProduct;
    let data = {
      productId,
      materialValues: JSON.stringify(inventoryValues),
    };

    axios.post(baseUrl + "/frontapi/add-material", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        toast.success(resp.message);
        materialDataApi(index, productId);
        getMaterialData(productId);
        setMaterialAdd((prev) => {
          const updated = [...prev].fill(false);
          updated[index] = !prev[index];
          return updated;
        });
        return false;
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  let handleChangeMaturial = (i, e) => {
    let newFormValues = [...inventoryValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;

    if (valv.material_type !== undefined) {
      const data = {
        byName: "yes",
        materialName: valv.material_type,
      };
      axios.post(baseUrl + "/frontapi/material-data-raw", data).then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          valv.material_available_quantity = resp.data[0].avaliable_qty;
          valv.material_id = resp.data[0].id;
        }
      });
    }
    setInventoryValues(newFormValues);
  };

  let addFormFieldsMaturials = () => {
    setInventoryValues([...inventoryValues, {}]);
  };

  let removeFormFieldsMaturial = (i) => {
    let newFormValues = [...inventoryValues];
    newFormValues.splice(i, 1);
    setInventoryValues(newFormValues);
  };

  const materialHtml = () => {
    const materialHtml = [];
    rawMaterialData.map(function (value, i) {
      return materialHtml.push(
        <option value={value.name}>{value.name}</option>
      );
    });
    return materialHtml;
  };

  const getMaterialData = (prodId) => {
    const data = {
      productId: prodId,
    };
    axios
      .post(baseUrl + "/frontapi/getMaterialsForManufacture", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          return;
        }
        if (resp.status === true) {
          setRawMaterialData(resp.data);
        }
      });
  };

  const optionStyles = {
    control: (base, state) => ({
      ...base,

      background: "#3e3e3e",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "white" : "white",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
      },
      margin: "0",
      padding: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : "#3e3e3e",
        color: "#333333",
        padding: "2px 10px",
      };
    },
    noOptionsMessage: (provided) => ({
      ...provided,
      backgroundColor: "#999999", // Change background color for "No options" message
      color: "red", // Change text color for "No options" message
      padding: "2px 10px",
      // Add any other styles you want
    }),
  };

  const handleVendor = (selectedOption, index) => {
    let newFormValues = [...formValues];

    const valv = newFormValues[index];
    valv["vendorId"] = selectedOption.value;

    const updatedSelectedOptionsArray = [...selectedOptionsArray];
    updatedSelectedOptionsArray[index] = selectedOption;
    setSelectedOptionsArray(updatedSelectedOptionsArray);

    const data = {
      id: valv.vendorId,
    };
    axios.post(baseUrl + "/frontapi/vendor-single", data).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setIsShow(true);
        setPData(resp.data.sqlRun3);
        setVendorType(resp.data.sqlRun[0].vendorType);
      }
    });
  };

  const handleProductChange = (selectedOption, index) => {
    let newFormValues = [...formValues];

    const valv = newFormValues[index];
    valv["productId"] = selectedOption.value;

    let newFormValuesError = [...formValuesError];
    newFormValuesError[index][`productId`] = selectedOption.value;

    if (!newFormValues[index].productId) {
      newFormValuesError[index][`productId`] = "Please select product";
      setFormValuesError(newFormValuesError);
      valv.quantity = 0;
      valv.totalPrice = 0;
      valv.price = 0;
      valv.tax = 0;
      valv.withoutTotalPrice = 0;
      valv.hsnCode = "";
      valv.image = "";

      return false;
    } else {
      newFormValuesError[index][`productId`] = "";
      setFormValuesError(newFormValuesError);

      const config = {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      };
      let pramas = {
        id: valv.productId,
      };
      axios
        .post(baseUrl + "/frontapi/product-single", pramas, config)
        .then((res) => {
          var resp = res.data;
          if (resp.status === false) {
            return;
          }
          if (resp.status === true) {
            valv.quantity = 0;
            valv.totalPrice = 0;
            valv.price = resp.data.sqlRun[0] ? resp.data.sqlRun[0].price : "";
            valv.tax = resp.data.sqlRun[0] ? resp.data.sqlRun[0].tax : "";
            valv.hsnCode = resp.data.sqlRun[0]
              ? resp.data.sqlRun[0].hsnCode
              : "";

            if (resp.data.sqlRun[0].image) {
              valv.image = resp.data.sqlRun[0]
                ? // ? resp.data.sqlRun[0].image
                  resp.data.sqlRun[0].image.split(",", 1)
                : "";
            } else {
              valv.image = [];
            }
            setFormValues(newFormValues);

            setUnits(resp.data.sqlRun[0].units);
          }
        });
      // get row material
      materialDataApi(index, newFormValues[index].productId);
    }
  };

  let handleChangeAttr = (i, e) => {
    let newFormValues = [...formValues];
    const valv = newFormValues[i];
    valv[e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...formValuesError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "This field is required";
    setFormValues(newFormValues);

    if (e.target.name === "quantity") {
      if (!newFormValues[i].quantity) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].quantity <= 0) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] =
          "Quantity cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setFormValuesError(newFormValuesError);

        let amount = valv.price * valv.quantity;
        let getGstAmnout = (amount / 100) * valv.tax;
        let totalAmount = amount + getGstAmnout;
        valv.totalPrice = totalAmount;

        valv.withoutTotalPrice = valv.price * valv.quantity;
      }
    }

    if (e.target.name === "price") {
      if (!newFormValues[i].price) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] = tempErr;
        setFormValuesError(newFormValuesError);
        return false;
      }

      if (newFormValues[i].price <= 0) {
        valv.totalPrice = 0;
        valv.withoutTotalPrice = 0;
        newFormValuesError[i][e.target.name] =
          "Price cannot be zero or negative";
        setFormValuesError(newFormValuesError);
        return false;
      }

      let amount = valv.price * valv.quantity;
      let getGstAmnout = (amount / 100) * valv.tax;
      let totalAmount = amount + getGstAmnout;
      valv.totalPrice = totalAmount;

      valv.withoutTotalPrice = valv.price * valv.quantity;

      newFormValuesError[i][e.target.name] = "";
      setFormValuesError(newFormValuesError);
    }

    // if (e.target.name === "specification") {
    //   setFormValuesError(newFormValuesError);
    // }

    setFormValuesError(newFormValuesError);
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    const updatedSelectedOptionsArray = [...selectedOptionsArray];

    setFormValues([
      ...formValues,
      { vendorId: selectedOptionsArray[selectedOptionsArray.length - 1].value },
    ]);
    setFormValuesError([...formValuesError, {}]);

    const getSelectedVendor = vendorDataList.filter(
      (vendor) => vendor.id === formValues[formValues.length - 1].vendorId
    );

    updatedSelectedOptionsArray[formValues.length - 1] = {
      vendorId: getSelectedVendor[0].id,
    };

    setDefaultVendor({
      value: getSelectedVendor[0].id,
      label: getSelectedVendor[0].name,
    });
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newFormValuesError = [...formValuesError];
    newFormValuesError.splice(i, 1);
    setFormValuesError(newFormValuesError);

    let newMaterialData = [...materialData];
    newMaterialData.splice(i, 1);
    setMaterialData(newMaterialData);

    let newRowMaterialQuantity = [...rowMaterialQuantity];
    newRowMaterialQuantity.splice(i, 1);
    setRowMaterialQuantity(newRowMaterialQuantity);
  };

  const prodHtml = () => {
    const html = [];

    pData.map(function (value, i) {
      return html.push(
        <div className="row">
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name : <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                {vendorType === "Raw Material Manufacturer" ||
                "Goods Manufacturer"
                  ? "Production"
                  : "Delivery"}{" "}
                Capacity : <b>{value.mCapacity}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                {vendorType === "Raw Material Manufacturer" ||
                "Goods Manufacturer"
                  ? "Manufacturing"
                  : "Warehouse"}{" "}
                Location : <b>{value.mLocation}</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                SellingPrice : <b>{value.mSPrice}</b>
              </p>
            </div>
          </div>
        </div>
      );
    });
    return html;
  };

  const totalPriceHTML = () => {
    const html = [];

    let totalPrice = 0;
    let totalPriceNoGst = 0;

    // if (formValues.length > 0) {
    for (let i = 0; i < formValues.length; i++) {
      const element = formValues[i];

      totalPrice += element.totalPrice ? element.totalPrice : 0;

      totalPriceNoGst += element.withoutTotalPrice
        ? element.withoutTotalPrice
        : 0;
    }
    html.push(
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12 more-padding border-right">
          <div className="person-detail">
            <p>
              Order Value (Without GST):{" "}
              <b>{parseFloat(totalPriceNoGst).toFixed(2)} INR</b>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 more-padding ">
          <div className="person-detail">
            <p>
              Order Value (With GST):{" "}
              <b>{parseFloat(totalPrice).toFixed(2)} INR</b>
            </p>
          </div>
        </div>
      </div>
    );

    return html;
  };

  const handleRowQuantityChange = (
    e,
    indexProduct,
    rowMaterialProductId,
    materialId,
    indexRowMaterial
  ) => {
    e.preventDefault();
    const updatedData = [...rowMaterialQuantity];
    const updatedErrors = [...rowMaterialQuantityErrors];

    const quantityValue = e.target.value;

    if (!updatedData[indexProduct]) {
      updatedData[indexProduct] = [];
      updatedErrors[indexProduct] = [];
    }
    const validationError = validateQuantity(quantityValue);

    if (!updatedData[indexProduct][indexRowMaterial]) {
      updatedData[indexProduct][indexRowMaterial] = {
        quantity: e.target.value,
        rowMaterialProductId,
        materialId,
      };
      updatedErrors[indexProduct][indexRowMaterial] = ""; // Clear the error
    } else {
      updatedData[indexProduct][indexRowMaterial].quantity = e.target.value;
      updatedErrors[indexProduct][indexRowMaterial] = validationError;
    }

    setRowMaterialQuantity(updatedData);
    setRowMaterialQuantityErrors(updatedErrors);
  };

  const validateQuantity = (value) => {
    if (value === "") {
      return "Quantity cannot be empty"; // Return an error message for empty value
    } else if (value <= 0) {
      return "Quantity cannot be negative or zero"; // Return an error message for negative value
    }
    return ""; // No error
  };

  const materialListHtml = (index) => {
    const html = [];
    materialData.length > 0 &&
      materialData[index]?.data.map((item, i) => {
        return html.push(
          <li className="manufactur-list" key={i}>
            <div className="product-quantity d-flex flex-wrap align-items-center justify-content-between">
              <div className="col-sm-12 col-md-12">
                <p>
                  Type : <b>{item.material_type}</b>
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p>
                  Available Quantity :
                  <b>
                    {item.material_available_quantity}
                    {item.material_available_quantity === "0" && (
                      <>
                        &nbsp;
                        {/* <button
                          onClick={() => handleShowModal(item)}
                          type="button"
                          className="btn btn-primary"
                        >
                          Buy
                        </button> */}
                      </>
                    )}
                  </b>
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p>
                  Per Unit Quantity : <b>{item.material_quantity}</b>
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group place-vendor-inner">
                  <label>
                    Suggested Required Quantity:{" "}
                    {(
                      Number(formValues[index].quantity) *
                      Number(item.material_quantity)
                    ).toFixed(2)}{" "}
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-md-12">
                <div className="col-12">
                  <div className="form-group1 place-vendor-inner">
                    <label>Total Required Quantity:</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Quantity"
                      name="lockqty"
                      value={
                        rowMaterialQuantity[index] &&
                        rowMaterialQuantity[index][i] &&
                        rowMaterialQuantity[index][i].quantity
                          ? rowMaterialQuantity[index][i].quantity
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleRowQuantityChange(
                          e,
                          index,
                          item.material_id,
                          item.id,
                          i
                        )
                      }
                    />
                  </div>
                  <span style={{ color: "red" }}>
                    {rowMaterialQuantityErrors[index] &&
                    rowMaterialQuantityErrors[index][i] &&
                    rowMaterialQuantityErrors[index][i]
                      ? rowMaterialQuantityErrors[index][i]
                      : ""}
                  </span>
                  {/* <span style={{ color: "red" }}> {allEmpty[index]}</span> */}
                </div>
              </div>
            </div>
          </li>
        );
      });
    return html;
  };

  const handleMaterialAddProduct = (e, index) => {
    e.preventDefault();
    setMaterialAdd((prev) => {
      const updated = [...prev].fill(false);
      updated[index] = !prev[index];
      return updated;
    });
    setInventoryValues([
      {
        material_type: "",
        material_quantity: "",
        material_id: "",
        material_available_quantity: "",
      },
    ]);
  };

  const handleShowModal = (item) => {
    setProductId(item.product_id);
    setMaterialId(item.material_id);
    setProductName(item.material_type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getMaterialDetails = async (item, diff) => {
    try {
      const response = await axios.post(
        baseUrl + "/frontapi/get-material-list",
        {
          orderId: materialId,
          runThis: "yes",
        }
      );

      const resp = response.data;

      if (resp.status === true) {
        setTax(resp.data[0].tax);
        setHsnCode(resp.data[0].hsnCode);
        setMaterialPrice(resp.data[0].price);

        let total = materialPrice * parseInt(materialQuantity);
        const taxValue = (total * tax) / 100;
        const theValue = taxValue + total;
        setTotal(theValue);
      }
    } catch (error) {
      console.error("Error fetching material details:", error);
    }
  };
  useEffect(() => {
    if (productId) {
      getMaterialDetails();
    }
  }, [productId]);
  useEffect(() => {}, [materialQuantity, total]);

  const handleVendorChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedOption(selectedOption);
      setVendorId(null);
      setBuyNameErr("Please select vendor");
      setVendorNameErr("Please select vendor");
      return;
    }
    setSelectedOption(selectedOption);
    setVendorId(selectedOption.value);
    setVendorNameErr("");
    setBuyNameErr("");
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChangeInput = (e) => {
    let { name, value } = e.target;
    let total;
    var regg = /^-\d*\.?\d+$/;
    if (name === "materialPrice") {
      if (!value) {
        setMaterialPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Please enter price");
        return false;
      }
      if (!value || value == 0 || value < 0) {
        setMaterialPrice(value);
        setTotal(0);
        setTotalWithoutGst(0);
        setVendorPriceError("Price cannot be zero or negative");
        return false;
      }

      if (value.match(regg)) {
        setVendorPriceError("Please enter valid price");
        setMaterialPrice(value);
        return false;
      }
      setVendorPriceError("");
      setMaterialPrice(value);
      total = value * materialQuantity;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }
    if (name === "materialQuantity") {
      setMaterialQuantity(value);
      total = materialPrice * value;
      const taxValue = (total * tax) / 100;
      const theValue = taxValue + total;
      setTotal(theValue);
      setTotalWithoutGst(total);
    }

    if (name === "specification") {
      setSpecification(value);
    }
  };
  const handleSubmitModal = (e) => {
    e.preventDefault();

    if (vendorId === " " || vendorId === undefined) {
      setVendorNameErr("Please Select vendor name");
      return false;
    }

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    const data = {
      materialId: materialId,
      vendorId: vendorId,
      materialQuantity: materialQuantity,
      normalOrder: "yes",
      materialPrice: materialPrice,
      specification: specification,
      totalPrice: total,
      tax: tax,
      rawMaterial: "",
    };

    axios
      .post(baseUrl + "/frontapi/addVendorMaterial1", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 800);

          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div id="layout-wrapper">
        {/* <Header />
        <Navbar /> */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className="m-0" style={{ color: "#757575" }}>
              {productName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="assign-vendor">
            <div className="row">
              <div className="col-12">
                <div className="product-price">
                  <label>Vendor:</label>
                  <Select
                    value={selectedOption}
                    onChange={handleVendorChange}
                    styles={selectStyle}
                    name="vendorId"
                    isClearable={true}
                    noOptionsMessage={() => "No vendor found"}
                    options={vendorDataList.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                  />

                  <p style={{ color: "red" }}>{vendorNameErr}</p>

                  {/* <select
                          className="form-control"
                          name="vendorId"
                          onChange={handleChange}
                          value={vendorId}
                        >
                          {vendorListHtml()}
                        </select> */}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner mb-2">
                  <label>Price:</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    onKeyDown={handleValidation}
                    name="materialPrice"
                    value={materialPrice}
                    onChange={handleChangeInput}
                  />
                  <p style={{ color: "red" }}>{vendorPriceError}</p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner mb-2">
                  <label>Quantity:</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Quantity"
                    onKeyDown={handleValidation}
                    name="materialQuantity"
                    value={materialQuantity}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner mb-2">
                  <label>Units</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Units"
                    name="units"
                    value={units}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner mb-2">
                  <label>Tax:</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Tax"
                    disabled
                    onKeyDown={handleValidation}
                    name="tax"
                    value={tax}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner">
                  <label>HSN Code:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="HSN Code"
                    disabled
                    name="hsnCode"
                    value={hsnCode}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group place-vendor-inner">
                  <label>Specification:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Specification"
                    // disabled
                    name="specification"
                    value={specification}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              {/* <div className="col-md-4 col-12">
                      <div className="product-price">
                        <label>Price:</label>
                        <input
                          type="number"
                          name="product_price"
                          value={price}                    onKeyDown={handleValidation}

                          onChange={handleChangeVendor}
                          className="form-control"
                          placeholder="Price"
                        />
                        <p style={{ color: "red" }}>{vendorPriceError}</p>
                      </div>
                    </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button type="submit" variant="primary" onClick={handleSubmitModal}>
              Placeorder
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="vertical-overlay" />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>ADD ORDER MANUFACTURE</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-12">
                  <div className="add-product-outer customer-detail-outer add-order-form">
                    {formValues.map((element, index) => (
                      <div className="add-product-outer">
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group mb-0">
                                <label>Product Image</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="products-images-outer d-flex">
                                {/* {imagesHtml(index)} */}
                                <div className="product-img-left order-image">
                                  <img
                                    // src={baseUrl + "/static/" + element.image}
                                    src={
                                      element.image &&
                                      element.image.length == "0"
                                        ? noImage
                                        : baseUrl + "/static/" + element.image
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                            <>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Vendor Name</label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                      handleVendor(option, index)
                                    }
                                    isSearchable={true}
                                    styles={optionStyles}
                                    // name="vendorId"
                                    noOptionsMessage={() => "No vendor found"}
                                    name={`vendorId-${index}`}
                                    placeholder="Select Vendor"
                                    defaultValue={defaultVendor}
                                    options={vendorDataList.map((option) => ({
                                      value: option.id,
                                      label: option.name,
                                    }))}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].vendorId}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Product Name</label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                      handleProductChange(option, index)
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={optionStyles}
                                    // value={element.productId || ""}
                                    // name="vendorId"
                                    noOptionsMessage={() => "No vendor found"}
                                    name={`productId-${index}`}
                                    placeholder="Select Product"
                                    options={produtData.map((option) => ({
                                      value: option.id,
                                      label: option.name,
                                    }))}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].productId}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>QUANTITY</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="quantity"
                                    onKeyDown={handleValidation}
                                    placeholder="Quantity"
                                    onWheel={(e) => e.target.blur()}
                                    value={element.quantity || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].quantity}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Price</label>
                                  <input
                                    type="number"
                                    name="price"
                                    onKeyDown={handleValidation}
                                    placeholder="Price"
                                    value={element.price || ""}
                                    className="form-control"
                                    onChange={(e) => handleChangeAttr(index, e)}
                                    // disabled="true"
                                  />

                                  <p style={{ color: "red" }}>
                                    {formValuesError[index].price}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>TAX</label>
                                  <input
                                    type="number"
                                    name="tax"
                                    onKeyDown={handleValidation}
                                    placeholder="Tax"
                                    value={element.tax || ""}
                                    className="form-control"
                                    onChange={(e) => handleChangeAttr(index, e)}
                                    disabled="true"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>HSN Code</label>
                                  <input
                                    type="text"
                                    name="hsnCode"
                                    placeholder="HSN Code"
                                    className="form-control"
                                    disabled="true"
                                    value={element.hsnCode || ""}
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>Without GST Total Price</label>
                                  <input
                                    type="number"
                                    className="form-control price-input"
                                    disabled="true"
                                    onKeyDown={handleValidation}
                                    placeholder="Total Price"
                                    name="totalPrice"
                                    value={
                                      parseFloat(
                                        Number(element.price) *
                                          Number(element.quantity)
                                      ).toFixed(2) || ""
                                    }
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <label className="inr-label position-absolute">
                                    INR
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="form-group">
                                  <label>With GSt Total Price</label>
                                  <input
                                    type="number"
                                    className="form-control price-input"
                                    disabled="true"
                                    onKeyDown={handleValidation}
                                    placeholder="Total Price"
                                    name="totalPrice"
                                    value={
                                      parseFloat(element.totalPrice).toFixed(
                                        2
                                      ) || ""
                                    }
                                    onChange={(e) => handleChangeAttr(index, e)}
                                  />
                                  <label className="inr-label position-absolute">
                                    INR
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <div className="add-row-group d-flex align-items-center justify-content-between">
                                  <div className="form-group position-relative">
                                    <label>Specification</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Specification"
                                      name="specification"
                                      value={element.specification || ""}
                                      onChange={(e) =>
                                        handleChangeAttr(index, e)
                                      }
                                    />
                                  </div>
                                  <div className="add-row">
                                    {index ? (
                                      <a
                                        href="#!"
                                        className="add-btn add"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        -
                                      </a>
                                    ) : null}
                                    <a
                                      href="#!"
                                      onClick={() => addFormFields()}
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="manufacture-main-outer">
                                <div className="add-product-outer manufacture-right">
                                  <div className="product-full-detail customer-detail-outer">
                                    <ul className="row-list">
                                      {materialListHtml(index)}
                                    </ul>

                                    {element.productId && (
                                      <button
                                        className="btn btn-primary mb-4"
                                        onClick={(e) =>
                                          handleMaterialAddProduct(e, index)
                                        }
                                        name="materialButton"
                                      >
                                        Add Material
                                      </button>
                                    )}

                                    {materialAdd[index] && (
                                      <>
                                        {inventoryValues.map(
                                          (inventory, index) => (
                                            <div className="">
                                              <div
                                                className="row align-items-center"
                                                key={index}
                                              >
                                                <div className="col-md-6 col-12">
                                                  <div className="form-group">
                                                    <label>
                                                      RAW MATERIAL REQUIRED
                                                    </label>
                                                    <select
                                                      className="form-control"
                                                      name="material_type"
                                                      placeholder="Raw Material"
                                                      onChange={(e) =>
                                                        handleChangeMaturial(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                      value={
                                                        inventory.material_type ||
                                                        ""
                                                      }
                                                    >
                                                      <option value="">
                                                        Please select
                                                      </option>
                                                      {materialHtml()}
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                  <div className="add-row-group d-flex align-items-center justify-content-between">
                                                    <div className="form-group">
                                                      <label>
                                                        RAW MATERIAL QUANTITY
                                                        REQUIRED
                                                      </label>
                                                      <input
                                                        className="form-control"
                                                        name="material_quantity"
                                                        placeholder="Quantity"
                                                        onChange={(e) =>
                                                          handleChangeMaturial(
                                                            index,
                                                            e
                                                          )
                                                        }
                                                        value={
                                                          inventory.material_quantity ||
                                                          ""
                                                        }
                                                      />
                                                    </div>
                                                    <div className="add-row">
                                                      <a
                                                        className="add-btn add"
                                                        href="#!"
                                                        onClick={() =>
                                                          addFormFieldsMaturials()
                                                        }
                                                      >
                                                        +
                                                      </a>
                                                      {index ? (
                                                        <a
                                                          className="add-btn add"
                                                          href="#!"
                                                          onClick={() =>
                                                            removeFormFieldsMaturial(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          -
                                                        </a>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        <div className="col-12">
                                          <div className="btn-group">
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) =>
                                                handeSubmitMaterial(
                                                  e,
                                                  element,
                                                  index
                                                )
                                              }
                                            >
                                              Add Materials
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) =>
                                                handleMaterialAddProduct(
                                                  e,
                                                  index
                                                )
                                              }
                                              name="materialButton"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </form>
                      </div>
                    ))}

                    <div>{totalPriceHTML()}</div>

                    <div className="btn-group mt-4">
                      <Button onClick={submitData} loading={loading}>
                        Place Order
                      </Button>
                    </div>
                    {isShow === true ? (
                      <div className="col-12">
                        <div className="person-contact customer-detail">
                          <h5>
                            <b>Product Reference</b>
                          </h5>
                          {prodHtml()}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
}
export default AddOrdersManufacturer;
