import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import { baseUrl } from "./BaseUrl";
import { useParams } from "react-router-dom";
import Footer from "./Footer";

function GstEdit() {
  const [id, setId] = useState(useParams().id);
  const [gst, setGst] = useState("");
  const [type, setType] = useState("number");
  const [gstErr, setGstErr] = useState("");

  const tempId = useParams();
  const Params = atob(tempId.id);

  useEffect(() => {
    gstData();
  }, []);

  const handleChangeGst = async (event) => {
    let eventValue = event.target.value;
    if (eventValue !== 0) {
      if (eventValue == "") {
        setGst(eventValue);
        setType("number");
        setGstErr("GST is required");
        return false;
      }
    }

    // if (eventValue.length >= 2) {
    //   setGst(eventValue);
    //   setType("text");
    //   return false;
    // }
    setGstErr("");
    setGst(eventValue);
    setType("number");
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const gstData = async () => {
    const data = {
      id: Params,
    };
    await axios.post(`${baseUrl}/frontapi/gst-single`, data).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status === true) {
        setId(resp.data.sqlRun[0].id);
        setGst(resp.data.sqlRun[0].gst);
      }
    });
  };
  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }
    event.preventDefault();

    if (gst !== 0) {
      if (gst == "") {
        setGstErr("GST is required");
        return false;
      }
    }

    // if (gst == 0) {
    //   setGstErr("GST is required");
    //   return false;
    // }

    // if (gst.length > 2) {
    //   setGstErr("GST should be of 2 digits only");
    //   return false;
    // }
    const gstData = {
      id: id,
      gst: gst,
    };
    axios.post(baseUrl + "/frontapi/gst-edit", gstData).then((res) => {
      var resp = res.data;
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
        return false;
      }
      if (resp.status === true) {
        toast.success("GST updated successfully");
        setTimeout(function () {
          window.location = "/gst";
        }, 3000);
        return false;
      }
    });
  };

  const clearInputs = () => {
    window.location.href = "/gst";
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>GST Category</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="add-product-outer customer-detail-outer">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>GST</label>
                          <input
                            type={type}
                            name="gst"
                            placeholder="GST %"
                            maxLength={2}
                            onKeyDown={handleValidation}
                            className="form-control"
                            onChange={handleChangeGst}
                            value={gst}
                          />
                          <p style={{ color: "red" }}>{gstErr}</p>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      {" "}
                      Save{" "}
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={clearInputs}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}
export default GstEdit;
