import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";
import { ContentLayout } from "./Common/Layout/ContentLayout";
import { ButtonGroup } from "./Common/Elements/ButtonGroup";

const baseUrl = myConstList.baseUrl;

function CategoryEdit(props) {
  // const [data, setData] = useState([]);
  const [id, setId] = useState(useParams().id);
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameErr, setNameErr] = useState("");
  const [shortErr, setShortErr] = useState("");
  const [emailError, setEmailError] = useState("");

  const emailReg =
    /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

  const tempID = useParams();
  const Params = atob(tempID.id);

  const handleChangeAll = async (event) => {
    let { name, value } = event.target;

    if (name === "name") {
      setName(value);
      if (!value) {
        setNameErr("Name is required");
        return false;
      }
      setNameErr("");
    }

    if (name === "shortName") {
      setShortName(value);
      if (!value) {
        setShortErr("Short name is required");
        return false;
      }

      if (value.length > 4) {
        setShortErr("Short name should not be more than 4 letters");
        return false;
      }

      setShortErr("");
    }

    if (name === "email") {
      setEmail(value);
      if (!value) {
        setEmailError("Email is required");
        return false;
      }

      if (!value.match(emailReg)) {
        setEmailError("Please enter valid email");
        return false;
      }
      setEmailError("");
    }
  };

  useEffect(() => {
    categoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const categoryData = async () => {
    const data = {
      id: Params,
    };
    await axios
      .post(baseUrl + "/frontapi/category-single", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          // setData(resp.data.sqlRun);
          setId(resp.data.sqlRun[0].id);
          setName(resp.data.sqlRun[0].name);
          setShortName(resp.data.sqlRun[0].short_name);
          setEmail(resp.data.sqlRun[0].email);
        }
      });
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }
    event.preventDefault();
    if (!name && !shortName && !email) {
      setNameErr("Name is required");
      setShortErr("Short name is required");
      setEmailError("Email is required");
      return false;
    }

    if (!name) {
      setNameErr("Name is required");
      return false;
    }

    let error = checkSpace("name", name);

    if (error) {
      setNameErr(error);
      return false;
    }

    if (!shortName) {
      setShortErr("Short name is required");
      return false;
    }

    error = checkSpace("short name", shortName);

    if (error) {
      setShortErr(error);
      return false;
    }

    if (shortName.length > 4) {
      setShortErr("Short name should not be more than 4 letters");
      return false;
    }

    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!email.match(emailReg)) {
      setEmailError("Please enter valid email");
      return false;
    }

    error = checkSpace("email", email);

    if (error) {
      setEmailError(error);
      return false;
    }

    let categoryData = {
      id: id,
      name: name,
      shortName: shortName,
      email: email,
    };
    setLoading(true);
    axios
      .post(baseUrl + "/frontapi/category-edit", categoryData)
      .then((res) => {
        var resp = res.data;
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.status === true) {
          toast.success("Category updated successfully");
          setTimeout(() => {
            window.location = "/categories";
          }, 3000);
          return false;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ContentLayout
      title="Edit Category"
      className="add-product-outer customer-detail-outer"
    >
      <form>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Name"
                className="form-control"
                onChange={handleChangeAll}
              />
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Short Name</label>
              <input
                type="text"
                name="shortName"
                value={shortName}
                maxLength={4}
                placeholder="Short Name"
                className="form-control"
                onChange={handleChangeAll}
              />
              <p style={{ color: "red" }}>{shortErr}</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                onChange={handleChangeAll}
              />
              <p style={{ color: "red" }}>{emailError}</p>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        limit={1}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
      <ButtonGroup
        onClick={handleSubmit}
        link="/categories"
        loading={loading}
      />
    </ContentLayout>
  );
}
export default CategoryEdit;
