import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Customer from "./components/Customer";
import Products from "./components/Products";
import ProductAdd from "./components/ProductAdd";
import CustomerAdd from "./components/CustomerAdd";
import Vendor from "./components/Vendor";
import VendorAdd from "./components/VendorAdd";
import CustomerDetails from "./components/CustomerDetails";
import VendorDetails from "./components/VendorDetails";
import CustomerEdit from "./components/CustomerEdit";
import VendorEdit from "./components/VendorEdit";
import PendingOrders from "./components/PendingOrders";
import VendorProcessOrder from "./components/VendorProcessOrder";
import OrderCompleted from "./components/OrderCompleted";
import AddOrders from "./components/AddOrders";
import PendingOrderDetails from "./components/PendingOrderDetails";
import CustomerProcessOrderDetails from "./components/CustomerProcessOrderDetails";
import ProductDetails from "./components/ProductDetails";
import ProductEdit from "./components/ProductEdit";
import InprocessOrders from "./components/InprocessOrders";
import Category from "./components/Category";
import CategoryAdd from "./components/CategoryAdd";
import CategoryEdit from "./components/CategoryEdit";
import Gst from "./components/Gst";
import GstAdd from "./components/GstAdd";
import GstEdit from "./components/GstEdit";
import Attribute from "./components/Attribute";
import AttributeAdd from "./components/AttributeAdd";
import AttributeEdit from "./components/AttributeEdit";
import CategoryManager from "./components/CategoryManager";
import AddCategoryManager from "./components/AddCategoryManager";
import CategoryManagerEdit from "./components/CategoryManagerEdit";
import SalesPerson from "./components/SalesPerson";
import AddSalesPerson from "./components/AddSalesPerson";
import SalesPersonEdit from "./components/SalesPersonEdit";
import OprationExecutive from "./components/OprationExecutive";
import AddOprationExecutive from "./components/AddOprationExecutive";
import OprationExecutiveEdit from "./components/OprationExecutiveEdit";
import Manufacture from "./components/Manufacture";
import SubCategory from "./components/SubCategory";
import SubCategoryAdd from "./components/SubCategoryAdd";
import SubCategoryEdit from "./components/SubCategoryEdit";
import VendorPendingOrders from "./components/VendorPendingOrders";
import VendorOrderCompleted from "./components/VendorOrderCompleted";
import TotalOrders from "./components/TotalOrders";
import VendorOrderDetail from "./components/VendorOrderDetail";
import ManufactureDetails from "./components/ManufactureDetails";
import ViewAssignVendor from "./components/ViewAssignVendor";
import OrderCompletedDetails from "./components/OrderCompletedDetails";
import HoldedQtyDetail from "./components/HoldedQtyDetail";
import VendorRejectedOrders from "./components/VendorRejectedOrders";
import Units from "./components/Units";
import UnitsAdd from "./components/UnitsAdd";
import UnitsEdit from "./components/UnitsEdit";
import RejectedOrders from "./components/RejectedOrders";
import NotificationDetail from "./components/NotificationDetail";
import ProductClone from "./components/ProductClone";
import CatManProducts from "./components/CatManProducts";
import CatManAddProducts from "./components/CatManAddProducts";
import CatManCloneProducts from "./components/CatManCloneProducts";
import CatManEditProducts from "./components/CatManEditProducts";
import MyCategories from "./components/MyCategories";
import CatManProductsDetail from "./components/CatManProductsDetail";
import VendorsPendingOrderDetails from "./components/VendorsPendingOrderDetails";
import Designation from "./components/Designation";
import DesignationAdd from "./components/DesignationAdd";
import DesignationEdit from "./components/DesignationEdit";
import AllSales from "./components/AllSales";
import MonthSales from "./components/MonthSales";
import PrevMonthSales from "./components/PrevMonthSales";
import YTDSales from "./components/YTDSales";
import AllSalesCatMan from "./components/AllSalesCatMan";
import InventoryInOut from "./components/InventoryInOut";
import AddInventory from "./components/AddInventory";
import DeliveredOrderDetails from "./components/DeliveredOrderDetails";
import AddOrdersManufacturer from "./components/AddOrdersManufacturer";
import RootLayout from "./components/RootLayout";
import VendorPipeline from "./components/VendorPipeline";
import ViewManufacturingMaterials from "./components/ViewManufacturingMaterials";
import SalesManager from "./components/SalesManager";
import AddSalesManager from "./components/AddSalesManager";
import SalesManagerEdit from "./components/SalesManagerEdit";
import Leads from "./components/Leads";
import AddLeads from "./components/AddLeads";
import LeadsEdit from "./components/LeadsEdit";
import AddSalesPersonByManager from "./components/AddSalesPersonByManager";
import SalesPersonManagerEdit from "./components/SalesPersonManagerEdit";
import CustomerRevenue from "./components/CustomerRevenue";
import Industry from "./components/Industry";
import IndustryAdd from "./components/IndustryAdd";
import IndustryEdit from "./components/IndustryEdit";
import Venture from "./components/Venture";
import VentureAdd from "./components/VentureAdd";
import VentureEdit from "./components/VentureEdit";
import LeadSource from "./components/LeadSource";
import LeadSourceAdd from "./components/LeadSourceAdd";
import LeadSourceEdit from "./components/LeadSourceEdit";
import Reminders from "./components/Reminders";
import RemindersAdd from "./components/RemindersAdd";
import RemindersEdit from "./components/RemindersEdit";
import LeadsView from "./components/LeadsView";
import IssueType from "./components/IssueType";
import IssueTypeAdd from "./components/IssueTypeAdd";
import IssueTypeEdit from "./components/IssueTypeEdit";
import Support from "./components/Support";
import DocumentType from "./components/DocumentType";
import AddDocumentType from "./components/AddDocumentType";
import DocumentTypeEdit from "./components/DocumentTypeEdit";
import CustomerDocuments from "./components/CustomerDocuments";
import AddCustomerDocuments from "./components/AddCustomerDocuments";
import CustomerDocumentsEdit from "./components/CustomerDocumentsEdit";
import Training from "./components/Training";
import AddTraining from "./components/AddTraining";
import TrainingEdit from "./components/TrainingEdit";
import TrainingManager from "./components/TrainingManager";
import Campaign from "./components/Campaign";
import AddCampaign from "./components/AddCampaign";
import CampaignEdit from "./components/CampaignEdit";
import CampaignManager from "./components/CampaignManager";
import ZoneAdd from "./components/ZoneAdd";
import ZoneEdit from "./components/ZoneEdit";
import Zone from "./components/Zone";

function App(props) {
  // if (localStorage.jwtToken) {
  //   const token = localStorage.jwtToken;
  //   const decoded = jwt_decode(token);
  //   const currentTime = Date.now() / 1000;
  //   if (decoded.exp < currentTime) {
  //     window.location.href = "/";
  //   }
  // }else{
  //   localStorage.clear()
  // }

  if (!localStorage.jwtToken) {
    localStorage.clear();
    // window.location.href = "/";
  }

  useEffect(() => {
    myFunction();
  }, []);

  const myFunction = () => {
    var element = document.getElementById("as-react-datatable-table-foot");
    if (element) {
      element.classList.add("align-items-center");
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<RootLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Customer */}
          <Route path="/customer" element={<Customer />} />
          <Route path="/customer-add" element={<CustomerAdd />} />
          <Route path="/customer-edit/:id" element={<CustomerEdit />} />
          <Route path="/customer-details" element={<CustomerDetails />} />
          <Route path="/customer-details/:id" element={<CustomerDetails />} />
          {/* Customer End*/}
          {/* Vendor */}
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/vendor-add" element={<VendorAdd />} />
          <Route
            path="/vendor-order-completed"
            element={<VendorOrderCompleted />}
          />
          <Route path="/vendor-details/:id" element={<VendorDetails />} />
          <Route path="/vendor-edit/:id" element={<VendorEdit />} />
          <Route
            path="/assign-vendor-details/:orderId"
            element={<ViewAssignVendor />}
          />
          <Route
            path="/vendor-pending-orders"
            element={<VendorPendingOrders />}
          />
          <Route path="/process-order" element={<VendorProcessOrder />} />
          <Route
            path="/vendor-order-details/:id"
            element={<VendorOrderDetail />}
          />
          <Route
            path="/view-order-manufacture/:id"
            element={<ViewManufacturingMaterials />}
          />
          <Route
            path="/vendors-order-details/:id"
            element={<VendorsPendingOrderDetails />}
          />
          <Route
            path="/vendor-rejected-orders"
            element={<VendorRejectedOrders />}
          />{" "}
          <Route path="/rejected-orders" element={<RejectedOrders />} />
          <Route path="/add-orders" element={<AddOrders />} />
          <Route
            path="/add-orders-manufacturer"
            element={<AddOrdersManufacturer />}
          />
          <Route path="/vendor-pipeline" element={<VendorPipeline />} />
          {/* Vendor End */}
          {/* Products */}
          <Route exact path="/products" element={<Products {...props} />} />
          <Route
            exact
            path="/products-CatMan"
            element={<CatManProducts {...props} />}
          />
          <Route
            exact
            path="/product-add"
            element={<ProductAdd {...props} />}
          />
          <Route
            exact
            path="/product-add-CatMan"
            element={<CatManAddProducts {...props} />}
          />
          <Route exact path="/product-add/:id" element={<ProductClone />} />
          <Route
            exact
            path="/product-add-CatMan/:id"
            element={<CatManCloneProducts />}
          />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/product-edit/:id" element={<ProductEdit />} />
          <Route
            path="/product-edit-CatMan/:id"
            element={<CatManEditProducts />}
          />
          <Route
            path="/product-details-CatMan/:id"
            element={<CatManProductsDetail />}
          />
          <Route path="/myCategories" element={<MyCategories />} />
          {/* Products End */}
          {/* Orders  */}
          <Route path="/pending-orders" element={<PendingOrders />} />
          <Route path="/order-completed" element={<OrderCompleted />} />
          <Route path="/total-orders" element={<TotalOrders />} />
          <Route
            path="/pending-order-details/:id"
            element={<PendingOrderDetails />}
          />
          <Route
            path="/process-order-details/:id"
            element={<OrderCompletedDetails />}
          />
          <Route path="/inprocess-orders" element={<InprocessOrders />} />
          <Route
            path="/inprocess-order-details/:id"
            element={<CustomerProcessOrderDetails />}
          />
          <Route
            path="/delivery-details/:id"
            element={<DeliveredOrderDetails />}
          />
          {/* Orders End */}
          {/* Manufacture & Buy */}
          <Route
            path="/manufacture-details/:orderId/:orderType"
            element={<ManufactureDetails />}
          />
          <Route
            path="/hold-qty-detail/:id/:orderId/"
            element={<HoldedQtyDetail />}
          />
          <Route
            path="/manufacture/:id/:subOrderId/"
            element={<Manufacture />}
          />
          {/* Manufacture & Buy End  */}
          {/* Category   */}
          <Route path="/categories" element={<Category />} />
          <Route path="/category-add" element={<CategoryAdd />} />
          <Route path="/category-edit/:id" element={<CategoryEdit />} />
          {/* Category End  */}
          {/* GST   */}
          <Route path="/gst" element={<Gst />} />
          <Route path="/gst-add" element={<GstAdd />} />
          <Route path="/gst-edit/:id" element={<GstEdit />} />
          {/* GST End  */}
          {/* Units  */}
          <Route path="/units" element={<Units />} />
          <Route path="/units-add" element={<UnitsAdd />} />
          <Route path="/units-edit/:id" element={<UnitsEdit />} />
          {/* Units End  */}
          {/* Designation  */}
          <Route path="/designation" element={<Designation />} />
          <Route path="/designation-add" element={<DesignationAdd />} />
          <Route path="/designation-edit/:id" element={<DesignationEdit />} />
          {/* Designation End  */}
          {/* Industry  */}
          <Route path="/industry" element={<Industry />} />
          <Route path="/industry-add" element={<IndustryAdd />} />
          <Route path="/industry-edit/:id" element={<IndustryEdit />} />
          {/* Industry End  */}
          {/* Issue Type  */}
          <Route path="/issue-type" element={<IssueType />} />
          <Route path="/issue-type-add" element={<IssueTypeAdd />} />
          <Route path="/issue-type-edit/:id" element={<IssueTypeEdit />} />
          {/* Issue Type End  */}
          {/* Venture  */}
          <Route path="/venture" element={<Venture />} />
          <Route path="/venture-add" element={<VentureAdd />} />
          <Route path="/venture-edit/:id" element={<VentureEdit />} />
          {/* Venture End  */}
          {/* Zone  */}
          <Route path="/zone" element={<Zone />} />
          <Route path="/zone-add" element={<ZoneAdd />} />
          <Route path="/zone-edit/:id" element={<ZoneEdit />} />
          {/* Zone End  */}
          {/* Lead Source  */}
          <Route path="/lead-source" element={<LeadSource />} />
          <Route path="/lead-source-add" element={<LeadSourceAdd />} />
          <Route path="/lead-source-edit/:id" element={<LeadSourceEdit />} />
          {/* Lead Source End  */}
          {/*Attribute    */}
          <Route path="/attributes" element={<Attribute />} />
          <Route path="/attribute-add" element={<AttributeAdd />} />
          <Route path="/attribute-edit/:id" element={<AttributeEdit />} />
          {/* Attribute End  */}
          {/*Document Type    */}
          <Route path="/document-type" element={<DocumentType />} />
          <Route path="/document-type-add" element={<AddDocumentType />} />
          <Route
            path="/document-type-edit/:id"
            element={<DocumentTypeEdit />}
          />
          {/* Document Type End  */}
          {/*Customer Document    */}
          <Route path="/customer-document" element={<CustomerDocuments />} />
          <Route
            path="/customer-document-add"
            element={<AddCustomerDocuments />}
          />
          <Route
            path="/customer-document-edit/:id"
            element={<CustomerDocumentsEdit />}
          />
          {/* Customer Document End  */}
          {/* Category Manager   */}
          <Route path="/category-manager" element={<CategoryManager />} />
          <Route
            path="/add-category-manager"
            element={<AddCategoryManager />}
          />
          <Route
            path="/category-manager-edit/:id"
            element={<CategoryManagerEdit />}
          />
          {/* Category Manager End  */}
          {/* Sub-Category   */}
          <Route path="/sub-categories" element={<SubCategory />} />
          <Route path="/sub-category-add" element={<SubCategoryAdd />} />
          <Route path="/sub-category-edit/:id" element={<SubCategoryEdit />} />
          {/* Sub-Category End  */}
          {/* Sales Manager   */}
          <Route path="/sales-manager" element={<SalesManager />} />
          <Route path="/add-sales-manager" element={<AddSalesManager />} />
          <Route
            path="/sales-manager-edit/:id"
            element={<SalesManagerEdit />}
          />
          <Route
            path="/add-sales-person-manager"
            element={<AddSalesPersonByManager />}
          />
          <Route
            path="/sales-person-manager-edit/:id"
            element={<SalesPersonManagerEdit />}
          />
          <Route path="/customer-revenue" element={<CustomerRevenue />} />
          {/* Sales Manager End  */}
          {/* Sales Person  */}
          <Route path="/sales-person" element={<SalesPerson />} />
          <Route path="/add-sales-person" element={<AddSalesPerson />} />
          <Route path="/sales-person-edit/:id" element={<SalesPersonEdit />} />
          {/* Sales Person End  */}
          {/* Leads  */}
          <Route path="/leads" element={<Leads />} />
          <Route path="/add-leads" element={<AddLeads />} />
          <Route path="/leads-edit/:id" element={<LeadsEdit />} />
          <Route path="/leads-view/:id" element={<LeadsView />} />
          {/* Leads End  */}
          {/* OPS Executive   */}
          <Route path="/operation-executive" element={<OprationExecutive />} />
          <Route
            path="/add-operation-executive"
            element={<AddOprationExecutive />}
          />
          <Route
            path="/opration-executive-edit/:id"
            element={<OprationExecutiveEdit />}
          />
          {/* OPS Executive End  */}
          {/* Reminder */}
          <Route path="/reminders" element={<Reminders />} />
          <Route path="/reminders-add" element={<RemindersAdd />} />
          <Route path="/reminders-edit/:id" element={<RemindersEdit />} />
          {/* Reminder End */}
          {/* Support */}
          <Route path="/support" element={<Support />} />
          {/* Support End */}
          <Route path="/notifications" element={<NotificationDetail />} />
          {/* Monthly Wise Sales */}
          <Route path="/all-sales" element={<AllSales />} />
          <Route path="/inventory-in-out" element={<InventoryInOut />} />
          <Route path="/add-inventory" element={<AddInventory />} />
          <Route path="/all-sales-catman" element={<AllSalesCatMan />} />
          <Route path="/month-sales" element={<MonthSales />} />
          <Route path="/prev-month-sales" element={<PrevMonthSales />} />
          <Route path="/ytd-sales" element={<YTDSales />} />
          {/* Training */}
          <Route path="/training" element={<Training />} />
          <Route path="/training-manager" element={<TrainingManager />} />
          <Route path="/training-add" element={<AddTraining />} />
          <Route path="/training-edit/:id" element={<TrainingEdit />} />
          {/* Training End */}
          {/*Campaign */}
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/campaign-add" element={<AddCampaign />} />
          <Route path="/campaign-edit/:id" element={<CampaignEdit />} />
          <Route path="/campaign-manager" element={<CampaignManager />} />
          {/* Campaign End */}
        </Route>
        {/* Monthly Wise Sales  End  */}
      </Routes>
    </Router>
  );
}
export default App;
