import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./BaseUrl";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const baseUrl = myConstList.baseUrl;

const ViewAssignVendor = () => {
  const [tableData, setTableData] = useState([]);
  const Params = useParams();
  const theOrderId = atob(Params.orderId);

  useEffect(() => {
    orderData();
  }, []);

  const orderData = async () => {
    const data = {
      againstOrderId: theOrderId,
      orderType: "customise",
    };
    await axios
      .post(baseUrl + "/frontapi/getManufactureDetail", data)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          setTableData(resp.data);
        }
      });
  };

  const CustomizeHtml = () => {
    const html = [];
    tableData.map((value, i) => {
      return html.push(
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Vendor Order Id: <b>{value.id}</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Product Name: <b>{value.prod_name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding ">
            <div className="person-detail">
              <p>
                Assigned to: <b>{value.name}</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Ordered Quantity:{" "}
                <b>
                  {value.quantity} {value.units}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Produced Quantity:{" "}
                <b>
                  {value.manufactured_quantity
                    ? `${value.manufactured_quantity} ${value.units}`
                    : "N/A"}
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Price: <b>{value.price} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                GST: <b>{value.tax}%</b>
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Total: <b>{value.total} INR</b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Customized Image:
                {value.product_file == null ||
                value.product_file === "NULL" ||
                value.product_file === "null" ? (
                  "Sent via Email"
                ) : (
                  <a
                    href={
                      baseUrl + "/static/customizeImage/" + value.product_file
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    {!value.product_file.match(/\.(jpg|jpeg|png|gif)$/) ? (
                      "View File"
                    ) : (
                      <img
                        src={
                          baseUrl +
                          "/static/customizeImage/" +
                          value.product_file
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </a>
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding">
            <div className="person-detail">
              <p>
                Sample Image:
                {/* {value.status === "1" ? ( */}
                <>
                  {value.sample_file == null ||
                  value.sample_file === "NULL" ||
                  value.sample_file === "null" ||
                  value.sample_file === ""
                    ? " Sent via Email"
                    : value.sample_file.split(",").map((image) => {
                        return (
                          <a
                            href={baseUrl + "/static/sampleImage/" + image}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary"
                          >
                            {!image.match(/\.(jpg|jpeg|png|gif)$/) ? (
                              "View File"
                            ) : (
                              <img
                                src={baseUrl + "/static/sampleImage/" + image}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </a>
                        );
                      })}
                  <div style={{ color: "red" }}>
                    {value.sample_file_status === "2" ? "Rejected" : ""}
                  </div>
                  <div style={{ color: "green" }}>
                    {value.sample_file_status === "1" ? "Accepted" : ""}
                  </div>
                  {(value.sample_file_status === "0" ||
                    value.sample_file_status == null) &&
                  value.status !== "0" &&
                  value.status !== "3" ? (
                    <select
                      className="form-control"
                      value={value.sample_file_status}
                      onChange={(e) => changeStatusProduct(e, value)}
                    >
                      <option>Select</option>
                      <option value="1">Accept</option>
                      <option value="2">Reject</option>
                    </select>
                  ) : (
                    ""
                  )}
                </>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 more-padding border-right">
            <div className="person-detail">
              <p>
                Status: {value.status === "1" ? <b>InProcess</b> : ""}
                {value.status === "0" ? <b>Pending</b> : ""}
                {value.status === "2" ? <b>Completed</b> : ""}
                {value.status === "3" ? <b>Rejected</b> : ""}
              </p>
            </div>
          </div>
          {tableData.length > 1 && <hr style={{ color: "white" }} />}
        </div>
      );
    });
    return html;
  };

  const changeStatusProduct = (e, value) => {
    let data = {
      orderId: value.id,
      status: e.target.value,
      subOrderId: value.against_sub_order_id,
    };

    if (e.target.value === "Select") {
      return false;
    }

    Conn(data);
    return false;
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => changeStatus(data),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const changeStatus = (data) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
    };

    axios
      .post(baseUrl + "/frontapi/change-sampleFile-status", data, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          orderData();
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>CUSTOMIZE ORDER DETAIL</b>
              </h2>
            </div>
            <div className=" customer-detail-outer">
              <div className="order-detail-outer-middle pending-list-outer">
                <div className="col-12">
                  <div className="person-contact customer-detail">
                    {CustomizeHtml()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </>
  );
};

export default ViewAssignVendor;
